import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EyeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const useColumns = (setModalVisible, setSubcontractorId) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const [businessBody, setBusinessBody] = useState(null);
  const isCompanyAdmin = user?.role === 'companies:ADMIN-COMPANY';

  const headers = [
    {
      label: 'name',
      key: 'name'
    },
    {
      label: 'siren_number',
      key: 'siren_number'
    },
    {
      label: 'siret_number',
      key: 'siret_number'
    },
    {
      label: 'vat_number',
      key: 'vat_number'
    },
    {
      label: 'business_body',
      key: 'business_body'
    },
    {
      label: 'Address_street_number',
      key: 'address.number'
    },
    {
      label: 'Address_street_name',
      key: 'address.street'
    },
    {
      label: 'Address_additional_information',
      key: 'address.additional'
    },
    {
      label: 'Address_postal_code',
      key: 'address.postal_code'
    },
    {
      label: 'Address_city',
      key: 'address.city'
    },
    {
      label: 'Address_country',
      key: 'address.country'
    },
    {
      label: 'contact_first_name',
      key: 'contact[0].first_name'
    },
    {
      label: 'contact_last_name',
      key: 'contact[0].last_name'
    },
    {
      label: 'contact_phone_number',
      key: 'contact[0].phone_number.number'
    }
  ];

  const columns = [
    {
      title: t('companies.form.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: 'true',
      width: 200
    },
    {
      title: t('companies.form.siret_number'),
      key: 'siret_number',
      dataIndex: 'siret_number',
      sorter: true,
      width: 200
    },
    {
      title: t('companies.form.vat_number'),
      key: 'vat_number',
      dataIndex: 'vat_number',
      sorter: true,
      width: 100
    },
    {
      title: t('companies.form.business_body'),
      key: 'business_body',
      dataIndex: 'business_body',
      filters: businessBody?.map((b) => ({ text: b?.label, value: b?._id })),
      sorter: true,
      render: (business_body) => business_body?.label || '-',
      width: 300
    },
    {
      title: t('companies.form.address_full'),
      key: 'address',
      dataIndex: 'address',
      render: (address) =>
        address &&
        `${address?.number || '-'} ${address?.street || '-'}, ${
          address?.postal_code || '-'
        } ${address?.city || '-'}`
    },
    {
      title: t('companies.form.contact'),
      key: 'contact',
      dataIndex: 'contact',
      render: (contact) =>
        Array.isArray(contact) ? (
          <div>
            {contact &&
              contact.map((c) => (
                <p>{`${c?.first_name || '-'} ${
                  (c?.last_name && c?.last_name) || '-'
                } ${c?.phone_number?.country_code || '-'} ${
                  c?.phone_number?.number || '-'
                }`}</p>
              ))}
          </div>
        ) : (
          `${contact?.first_name || '-'} ${contact?.last_name || '-'} ${
            contact?.phone_number?.country_code || '-'
          } ${contact?.phone_number?.number || '-'}`
        ),

      sorter: true
    },
    ...(isCompanyAdmin
      ? [
          {
            title: t('companies.form.incidents'),
            key: '_id',
            dataIndex: '_id',
            render: (_id) => (
              <Button
                onClick={() => {
                  setSubcontractorId(_id);
                  setModalVisible(true);
                }}
              >
                <EyeOutlined style={{ fontSize: 10 }} />
              </Button>
            ),
            sorter: true,
            width: 100
          }
        ]
      : [])
  ];

  const getBusinessBody = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/batchtitle?show=${true}`
      });
      setBusinessBody(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };
  useEffect(async () => {
    await getBusinessBody();
  }, []);

  return { headers, columns };
};
