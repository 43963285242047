import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Skeleton, Row, Avatar, Typography, Space, Col } from 'antd';
import QRCode from 'react-qr-code';
import DefaultAvatar from '../../assets/images/default-avatar.png';
import { QuietusLogo } from '../../utils/constants/customIcons';

const { Text } = Typography;

const BTPCardComponent = ({ isLoading, user }) => (
  <Row className="btp-card-wrapper">
    <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
      <div className="btp-card">
        <Row>
          <Col
            xs={16}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <Col className="btp-card-title">
              <h1>Carte BTP</h1>
              <QuietusLogo />
            </Col>
            <Row justify="end" align="bottom" className="btp-card-info">
              <Space direction="vertical" size={2}>
                <Text />
                <Text strong>{user?.last_name.toUpperCase() || '-'}</Text>
                <Text>{user?.first_name || '-'}</Text>
                <Row justify="end" style={{ gap: 12 }}>
                  {Array.isArray(user?.idCompany) ? (
                    user?.idCompany.map((c) => (
                      <Col style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text>{c.name}</Text>
                        <Text>{user.idCompany.siren_number || '-'}</Text>
                      </Col>
                    ))
                  ) : (
                    <Text>{user?.idCompany?.name || '-'}</Text>
                  )}
                </Row>
              </Space>
              <Space direction="vertical" size={2}>
                <Text>
                  {`Valable jusqu'au `}
                  {moment(user?.document_valid_until).format('DD/MM/YYYY') ||
                    '-'}
                </Text>
              </Space>
              <Space direction="vertical" size={2}>
                <Text />
                <Text>{`Carte n° ${user?.card_number}`}</Text>
              </Space>
            </Row>
          </Col>
          <Col xs={8}>
            <Row justify="end">
              <Avatar
                size={160}
                src={user && user?.photo ? user?.photo : DefaultAvatar}
              />
            </Row>
            <Row justify="end" className="qr-code-wrapper">
              {user?.qrCode && (
                <QRCode
                  title="Accéder au chantier"
                  value={user?.qrCode}
                  size={160}
                />
              )}
            </Row>
          </Col>
        </Row>
      </div>
    </Skeleton>
  </Row>
);

BTPCardComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    qrCode: PropTypes.shape({}),
    last_name: PropTypes.string,
    first_name: PropTypes.string,
    idCompany: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        siren_number: PropTypes.number
      })
    ),
    document_valid_until: PropTypes.string,
    card_number: PropTypes.string,
    photo: PropTypes.string
  }).isRequired
};

export default BTPCardComponent;
