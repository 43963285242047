import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useCompanyFields from '../company/fields';
import usePromoterFields from '../promoter/fields';

const NewStructureDrawer = ({
  resource,
  isVisible,
  closeDrawer,
  customNavigate
}) => {
  const { fields: companyFields } = useCompanyFields(null, null, true);
  const { fields: promoterFields } = usePromoterFields(null, null, true);

  return (
    <Drawer
      centered
      title=""
      open={isVisible}
      onClose={closeDrawer}
      width={800}
      footer={false}
    >
      <CreateUpdateContainer
        fields={resource === 'companies' ? companyFields : promoterFields}
        purpose="create"
        baseUrl={resource}
        resource={resource}
        isOverlay
        customNavigate={customNavigate}
        withHeader={false}
      />
    </Drawer>
  );
};

NewStructureDrawer.propTypes = {
  resource: PropTypes.string,
  isVisible: PropTypes.bool,
  closeDrawer: PropTypes.func,
  customNavigate: PropTypes.func
};

NewStructureDrawer.defaultProps = {
  resource: null,
  isVisible: false,
  customNavigate: null,
  closeDrawer: null
};

export default NewStructureDrawer;
