import React from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';
import { ListResource } from '../../components/ListResource/ListResource';
import { useColumns } from '../worksites/columns';

const CompanyWorksites = ({ idCompany }) => {
  const { user } = useAuthContext();
  const { headers: headersWorksites, columns: columnsWorksites } = useColumns();
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';

  return (
    <ListResource
      headless
      resourceName={`worksites/getworksitesCompany/${idCompany}`}
      columns={columnsWorksites}
      headers={headersWorksites}
      deleteAction={isAdmin}
      editAction={isAdmin}
      withCreateButton={isAdmin}
      resourceModelName="Worksite"
      populate="idController,idPromoter,companies,type,phase"
      path="/worksites"
    />
  );
};

CompanyWorksites.propTypes = {
  idCompany: PropTypes.string.isRequired
};

export default CompanyWorksites;
