import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Col, Popconfirm, Row } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  PlayCircleOutlined,
  StopOutlined,
  WarningOutlined
} from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import MobileContextProvider from '../MobileContext';

export const StartControlBtn = ({ user }) => {
  const { t } = useTranslation();
  const { startControl, control } = MobileContextProvider();

  return (
    <Col span={24}>
      <Button
        block
        type="add"
        className="control-btn"
        disabled={control?.status === 'DONE' || control?.status === 'CLOSED'}
        onClick={() => startControl(control?._id, user)}
        icon={
          control?.status === 'DONE' ? <StopOutlined /> : <PlayCircleOutlined />
        }
      >
        {control?.status === 'DONE'
          ? t('controls.control_closed')
          : t('controls.start_control')}
      </Button>
    </Col>
  );
};

export const StartedControlButtonsPanel = ({ controlId, security, mode }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const navigate = useNavigate();
  const [displayButtons, setDisplayButtons] = useState(false);
  const {
    closeControl,
    setControlState,
    setUserData,
    setArtisancheck,
    setManagerCheck,
    artisanCheck,
    managerCheck,
    control
  } = MobileContextProvider();

  const findWorkerByCardNumber = (workers, card_number) =>
    workers?.some((worker) => worker?.worker?.card_number === card_number);

  const getWorker = async (cardNumber, type) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/workers?${type}=${cardNumber}&populate=idCompany`
      });
      return data;
    } catch (e) {
      message(e);
    }
    return true;
  };

  const checkArtisanManager = async (value) => {
    try {
      const workerWithSameCardNumber = findWorkerByCardNumber(
        control?.workers,
        value
      );
      if (workerWithSameCardNumber) {
        const worker = await getWorker(value, 'card_number');
        setUserData(worker);
        setControlState(true);
      } else if (value) {
        try {
          const data = await getWorker(value, 'card_number');
          if (data?.length > 0) {
            const worksiteCompanies = await dispatchAPI('GET', {
              url: `/worksites/getworksiteCompanies/${controlId}?`
            });
            const isCompanyReferenced = worksiteCompanies.data.filter((c) =>
              data[0].idCompany?.some((wc) => c._id === wc._id)
            );
            if (isCompanyReferenced.length) {
              setUserData(data);
            } else {
              message('company_no_referenced');
              navigate(
                `${
                  mode === 'desktop' ? '/controls' : ''
                }/incidents/create/${controlId}`
              );
            }
          } else {
            message('unknown_user');
            setUserData({
              unknown_user: value
            });
          }
          setControlState(true);
        } catch (error) {
          message(error);
        }
      } else {
        navigate(
          `${
            mode === 'desktop' ? '/controls' : ''
          }/incidents/create/${controlId}`
        );
      }
    } catch (error) {
      message(error);
    }
    setArtisancheck(false);
    setManagerCheck(false);
  };

  return (
    <Col span={24} className="control-action-wrapper">
      <Row gutter={mode === 'desktop' ? [8, 8] : [12, 12]}>
        <Col xs={24} xl={6} xxl={4}>
          <Link
            to={{
              pathname: `${
                mode === 'desktop' ? '/controls' : ''
              }/controlqrcodetera/${controlId}`
            }}
          >
            <Button block type="primary" className="control-btn">
              {t('controls.check_card_tera')}
            </Button>
          </Link>
        </Col>
        <Col xs={24} xl={6} xxl={4}>
          <Link
            to={{
              pathname: `${
                mode === 'desktop' ? '/controls' : ''
              }/controlqrcode/${controlId}`
            }}
          >
            <Button block type="primary" className="control-btn">
              {t('controls.check_card')}
            </Button>
          </Link>
        </Col>
        <Col xs={24} xl={6} xxl={4}>
          <Button
            block
            type="primary"
            className="control-btn"
            onClick={() => setDisplayButtons(!displayButtons)}
          >
            {t('controls.display_buttons')}
          </Button>
        </Col>
        {displayButtons && (
          <>
            <Col xs={24} xl={6} xxl={4}>
              <Button
                block
                type="primary"
                className="control-btn"
                onClick={() => {
                  setArtisancheck(!artisanCheck);
                  setManagerCheck(false);
                }}
              >
                {t('controls.check_artisan')}
              </Button>
            </Col>
            {artisanCheck && (
              <Col xs={24} xl={6} xxl={4}>
                <Search
                  placeholder="Numéro de carte artisan(e)"
                  enterButton="Contrôler"
                  onSearch={checkArtisanManager}
                  className="control-search"
                />
              </Col>
            )}
            <Col xs={24} xl={6} xxl={4}>
              <Button
                block
                type="primary"
                className="control-btn"
                onClick={() => {
                  setManagerCheck(!managerCheck);
                  setArtisancheck(false);
                }}
              >
                {t('controls.check_manager')}
              </Button>
            </Col>
            {managerCheck && (
              <Col xs={24} xl={6} xxl={4}>
                <Search
                  placeholder="Numéro de siret"
                  enterButton="Contrôler"
                  onSearch={checkArtisanManager}
                  className="control-search"
                />
              </Col>
            )}
            <Col xs={24} xl={6} xxl={4}>
              <Button
                block
                type="primary"
                className="control-btn"
                onClick={() => {
                  setArtisancheck(false);
                  setManagerCheck(false);
                  navigate(
                    `${
                      mode === 'desktop' ? '/controls' : ''
                    }/controlspecial/${controlId}`
                  );
                }}
              >
                {t('controls.check_other_specials_workers')}
              </Button>
            </Col>
          </>
        )}
        {security && (
          <Col xs={24} xl={6} xxl={4}>
            <Link
              to={{
                pathname: `${
                  mode === 'desktop' ? '/controls' : ''
                }/controlsecurity/${controlId}`
              }}
            >
              <Button block type="primary" className="control-btn">
                {t('controls.check_security')}
              </Button>
            </Link>
          </Col>
        )}
        <Col xs={24} xl={6} xxl={4}>
          <Button
            block
            type="primary"
            className="control-btn"
            onClick={() => {
              navigate(
                `${
                  mode === 'desktop' ? '/controls' : ''
                }/incidents/create/${controlId}`
              );
            }}
          >
            {t('controls.incident')}
          </Button>
        </Col>
        <Col xs={24} xl={6} xxl={4}>
          <Button
            block
            type="primary"
            className="control-btn"
            onClick={() => {
              navigate(
                `${
                  mode === 'desktop' ? '/controls' : ''
                }/worksiteclosed/${controlId}`
              );
            }}
          >
            {t('controls.worksite_closed')}
          </Button>
        </Col>
        <Col xs={24} xl={6} xxl={4}>
          <Popconfirm
            title={t('controls.alert.close-control')}
            okText={t('controls.alert.close')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() => closeControl(controlId)}
            icon={<WarningOutlined />}
          >
            <Button
              block
              type="danger"
              className="control-btn"
              icon={<StopOutlined />}
            >
              {t('controls.close_control')}
            </Button>
          </Popconfirm>
        </Col>
        <Col xs={24} xl={6} xxl={4}>
          <Button
            block
            className="control-btn"
            onClick={() => window.open(`https://translate.google.fr/?hl=fr`)}
          >
            {t('controls.traduction')}
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export const ShortcutButton = ({ route, text }) => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() =>
        navigate({
          pathname: route
        })
      }
      type="link"
      size="small"
    >
      {text}
    </Button>
  );
};

StartControlBtn.propTypes = {
  control: PropTypes.shape({
    status: PropTypes.string,
    _id: PropTypes.string
  }),
  user: PropTypes.shape({})
};

StartControlBtn.defaultProps = {
  control: {},
  user: null
};

StartedControlButtonsPanel.propTypes = {
  controlId: PropTypes.string.isRequired,
  security: PropTypes.bool,
  mode: PropTypes.string,
  control: PropTypes.shape({
    workers: PropTypes.arrayOf(PropTypes.shape({}))
  })
};

StartedControlButtonsPanel.defaultProps = {
  security: false,
  mode: null,
  control: null
};

ShortcutButton.propTypes = {
  route: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};
