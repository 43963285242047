import React, { useEffect, useState } from 'react';
import { CSVReader } from 'react-papaparse';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { message, Modal, Spin, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useAuthContext } from '../contexts/AuthContext';

export const ImportButton = ({ resourceName }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  // Check the content size (number of element in a line)
  const checkLineSize = (data) => {
    const entriesNumber = data[1].data.length;
    for (let it = 2; it < data.length; it += 1) {
      if (data[it].data.length !== entriesNumber) {
        message.error(`Invalid number of entries at line ${it}`);
        return false;
      }
    }
    return true;
  };

  // Check the errors array generated by Papaparse
  const checkPapaparseError = (data) => {
    for (let it = 2; it < data.length; it += 1) {
      if (data[it].errors.length !== 0) {
        message.error(`An error appear at line ${it}`);
        return false;
      }
    }
    return true;
  };

  const handleOnFileLoad = async (data) => {
    // Add resourceName at the first index of the array
    data.splice(0, 0, resourceName);

    if (checkLineSize(data) && checkPapaparseError(data)) {
      setVisible(true);
      try {
        await dispatchAPI('POST', {
          url: `/import-template`,
          body: data
        });
        setVisible(false);
        message.success('File successfully uploaded');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (e) {
        setVisible(false);
        if (e.response) message.error(e.response.data.message);
      }
    }
  };

  useEffect(() => {}, []);
  return (
    <>
      <Modal
        closable={false}
        footer={false}
        open={visible}
        maskClosable={false}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Spin spinning size="large" style={{ margin: 16 }} />
        <br />
        <Typography.Text>
          {`${t(`import.messages.process-request`)} `}
        </Typography.Text>
      </Modal>
      <CSVReader
        onDrop={handleOnFileLoad}
        noDrag
        style={{
          dropArea: {
            border: 'none',
            padding: 0,
            display: 'block'
          }
        }}
        config={{
          comments: true,
          delimiter: ';',
          quoteChar: '"',
          escapeChar: '"',
          skipEmptyLines: true
        }}
        addRemoveButton
      >
        <UploadOutlined />
        &nbsp;&nbsp;
        {t('buttons.upload')}
      </CSVReader>
    </>
  );
};

ImportButton.propTypes = {
  resourceName: PropTypes.string.isRequired
};
