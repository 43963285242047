import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { message as antdMessage } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import CheckSpecialWorker from './CheckSpecialWorker';
import ControlSpecialWorker from './ControlSpecialWorker';

const SpecialsWorkers = ({ mode }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [workers, setWorkers] = useState([]);
  const [isNotRegistered, setIsNotRegistered] = useState(true);
  const [selectedWorker, setSelectedWorker] = useState();

  const postWorker = async () => {
    try {
      const { data } = await dispatchAPI('PATCH', {
        url: `/controls/worker/${id}?worker=${selectedWorker}`
      });
      if (data.length) {
        antdMessage.warn(t('controls.messages.already_checked'));
      } else {
        antdMessage.success(t('controls.messages.workerAdded'));
      }
      navigate(`/control${mode === 'desktop' ? 's' : ''}/${id}`);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const createWorker = () => {
    navigate(
      `${mode === 'desktop' ? '/controls' : ''}/workers/create/${id}/null`
    );
  };

  const getWorkers = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: `/workers` });
      setWorkers(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getWorkers();
    })();
  }, [getWorkers]);

  return (
    <div>
      {isNotRegistered ? (
        <CheckSpecialWorker
          isLoading={isLoading}
          setIsNotRegistered={setIsNotRegistered}
          workers={workers}
          setSelectedWorker={setSelectedWorker}
          postWorker={postWorker}
          selectedWorker={selectedWorker}
        />
      ) : (
        <ControlSpecialWorker
          createWorker={createWorker}
          id={id}
          setIsNotRegistered={setIsNotRegistered}
        />
      )}
    </div>
  );
};

export default SpecialsWorkers;

SpecialsWorkers.propTypes = {
  mode: PropTypes.string
};

SpecialsWorkers.defaultProps = {
  mode: null
};
