import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import IncidentTypesTable from './incidentTypesTable';

const initialColumns = [
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: (text) => <Input InitialValue={text} />
  }
];

const IncidentTypesSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [incidenttypesCols, setIncidentTypesCols] = useState(initialColumns);
  const newIncidentTypesCol = {
    label: 'Nouvelle ligne',
    icon: null,
    color: '#008CA0',
    show: true
  };

  const getIncidentTypesCols = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/incidenttypes' });
      setIncidentTypesCols(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addIncidentTypesCol = async () => {
    try {
      await dispatchAPI('POST', {
        url: '/incidenttypes',
        body: newIncidentTypesCol
      });
      getIncidentTypesCols();
    } catch (e) {
      message.error(e.message);
    }
  };

  const deleteIncidentTypesCol = async (incidenttypesCol) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/incidenttypes/${incidenttypesCol._id}`
      });
    } catch (e) {
      message.error(e.message);
    }
    getIncidentTypesCols();
  };

  const editIncidentTypesCol = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/incidenttypes/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    getIncidentTypesCols();
  };
  useEffect(() => {
    getIncidentTypesCols();
  }, []);

  return (
    <>
      <IncidentTypesTable
        incidentTypesCols={incidenttypesCols}
        editIncidentTypesCol={editIncidentTypesCol}
        deleteIncidentTypesCol={deleteIncidentTypesCol}
      />
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addIncidentTypesCol}
      >
        {t('settings.incidenttypesTable.addincidenttypescol')}
      </Button>
    </>
  );
};

export default IncidentTypesSettings;
