import { List, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const UtilsNumbers = () => {
  const { t } = useTranslation();

  const { Text } = Typography;

  const data = [
    {
      name: 'Numéro de Quietus',
      number: '0536090500'
    },
    {
      name: 'Pompiers',
      number: '18'
    },
    {
      name: 'Gendarmerie',
      number: '17'
    },
    {
      name: 'S.A.M.U',
      number: '15'
    },
    {
      name: 'Centre de lutte anti-poison',
      number: '0561493333'
    },
    {
      name: 'Service téléphonique de lutte contre les discriminations raciales',
      number: '114'
    }
  ];

  return (
    <List
      className="phone-number-list"
      dataSource={data}
      header={t('basiclayout.menu.utils_numbers')}
      renderItem={(elem) => (
        <List.Item style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Text className="control-info-label">{elem.name}</Text>
          <a href={`tel:${elem.number}`}>{elem.number}</a>
        </List.Item>
      )}
    />
  );
};

export default UtilsNumbers;
