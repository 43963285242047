import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Collapse, List, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useDownloadDocument } from '../../../utils/downloadDoc';

const { Panel } = Collapse;
const { Text } = Typography;

const WorkerCard = ({ user, cardNumber, artisanCard, userType, t }) => {
  const { downloadDocument } = useDownloadDocument();
  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel header="Informations" key="1">
        {user && (
          <List
            dataSource={user}
            renderItem={(elem) => (
              <>
                <List.Item>
                  <Text>{t('controls.mobile.show.name')}</Text>
                  <Text>{elem.first_name}</Text>
                </List.Item>
                <List.Item>
                  <Text>{t('controls.mobile.show.last_name')}</Text>
                  <Text>{elem.last_name}</Text>
                </List.Item>
                <List.Item>
                  <Text>{t('controls.mobile.show.mail')}</Text>
                  <Text>{elem.email}</Text>
                </List.Item>
              </>
            )}
          />
        )}
      </Panel>
      <Panel header="Entreprise(s)" key="2">
        {user && user?.[0]?.idCompany && user?.[0]?.idCompany?.length > 0 && (
          <List
            dataSource={user?.[0].idCompany}
            renderItem={(c) => (
              <List.Item
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <Text>{t('controls.mobile.show.company_name')}</Text>
                  <Text>{c.name}</Text>
                </div>
                {c.siren_number && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Text>{t('controls.mobile.show.SIREN')}</Text>
                    <Text>{c.siren_number}</Text>
                  </div>
                )}
              </List.Item>
            )}
          />
        )}
      </Panel>

      <Panel header="Carte BTP" key="3">
        {user && (
          <List
            dataSource={user}
            renderItem={(elem) => (
              <>
                {cardNumber ? (
                  <List.Item>
                    <Text>
                      {t('controls.mobile.show.justification.card_number')}
                    </Text>
                    <Text>{cardNumber}</Text>
                  </List.Item>
                ) : (
                  <List.Item>
                    <Text>
                      {t(`controls.mobile.show.justification.${userType}`)}
                    </Text>
                    <Text>{artisanCard}</Text>
                  </List.Item>
                )}
                <List.Item>
                  <Text>Validité</Text>
                  <Text>
                    {moment(elem.document_valid_until).format('DD/MM/YYYY')}
                  </Text>
                </List.Item>
              </>
            )}
          />
        )}
      </Panel>
      {user && user[0] && (
        <Panel
          header={`Justificatifs (${user[0]?.files?.length || 0})`}
          key="4"
        >
          <List
            dataSource={user[0].files}
            renderItem={(elem) => (
              <List.Item>
                <Text>{elem.fileName}</Text>
                <Button
                  onClick={() =>
                    downloadDocument({
                      _id: elem.fileId,
                      metadata: { originalName: elem.fileName }
                    })
                  }
                  type="link"
                >
                  <DownloadOutlined />
                </Button>
              </List.Item>
            )}
          />
        </Panel>
      )}
    </Collapse>
  );
};

WorkerCard.propTypes = {
  user: PropTypes.instanceOf(Array),
  t: PropTypes.func.isRequired,
  cardNumber: PropTypes.string,
  artisanCard: PropTypes.string,
  userType: PropTypes.string
};

WorkerCard.defaultProps = {
  user: [],
  artisanCard: '',
  userType: null,
  cardNumber: undefined
};

export default WorkerCard;
