import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { CardsBundle } from './CardsBundle/CardsBundle';
import { useAuthContext } from '../../contexts/AuthContext';
import MobileHeader from './MobileHeader';

const Today = ({ headless, path }) => {
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const { Title } = Typography;

  const today = new Date().toISOString().slice(0, 10);
  let tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow = tomorrow.toISOString().slice(0, 10);

  return (
    <div className="today-container">
      <div className="today-content">
        <MobileHeader title={t('inspector.today')} />
        <CardsBundle
          headless={headless}
          resourceName="controls"
          extraQuery={`date=${today}&inspector=${user._id}&status!=CREATED`}
          sort="start_time"
          resourceModelName="Control"
          populate="worksite,inspector,workers"
          path={path}
          type="control"
        />
      </div>
      <Title>{t('inspector.tomorrow')}</Title>
      <CardsBundle
        headless={headless}
        sort="start_time"
        resourceName="controls"
        extraQuery={`date=${tomorrow}&inspector=${user._id}&status!=CREATED`}
        headers={false}
        resourceModelName="Control"
        populate="worksite,inspector,workers"
        path={path}
        type="control"
      />
      <Title>{t('inspector.30_days')}</Title>
      <CardsBundle
        headless={headless}
        sort="start_time"
        resourceName="controls"
        extraQuery={`period=${tomorrow}&inspector=${user._id}&status!=CREATED`}
        headers={false}
        resourceModelName="Control"
        populate="worksite,inspector,workers"
        path={path}
        type="control"
      />
    </div>
  );
};

Today.propTypes = {
  headless: PropTypes.bool,
  path: PropTypes.string
};
Today.defaultProps = {
  headless: false,
  path: null
};

export default Today;
