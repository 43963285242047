import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const useFormatter = () => {
  const { t } = useTranslation();
  const formattedData = (data) => ({
    ...data,
    date: data?.date && moment(data?.date).format('DD/MM/YYYY'),
    start_time: data?.start_time && moment(data?.start_time).format('HH:mm'),
    end_time: data?.end_time && moment(data?.end_time).format('HH:mm'),
    incidents: data?.incidents && data?.incidents.length,
    workers: data?.workers && data?.workers.length,
    idController: `${data?.idController?.first_name} ${data?.idController?.last_name}`,
    role: data?.role && t(`users.tags.${data?.role.split(':')[1]}`),
    worker:
      data?.worker && `${data?.worker.first_name} ${data?.worker.last_name}`,
    business_body: data.business_body && data.business_body.label,
    startTime: data?.startTime && moment(data?.startTime).format('HH:mm'),
    endTime: data?.endTime && moment(data?.endTime).format('HH:mm')
  });
  return { formattedData };
};
