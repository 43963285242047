import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, Input } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';

const { TextArea } = Input;

const CommentModal = ({
  isModalOpen,
  setIsModalOpen,
  worksite,
  setRefresh
}) => {
  const { dispatchAPI } = useAuthContext();
  const [comment, setComment] = useState(worksite?.comment || '');

  const handleOk = async () => {
    setRefresh(true);
    await dispatchAPI('PATCH', {
      url: `/worksites/${worksite._id}`,
      body: {
        comment
      }
    });
    setRefresh(false);
    // setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      title="Notes sur le chantier"
      onCancel={handleCancel}
      open={isModalOpen}
      footer={[
        <Button key="back" danger type="link" onClick={handleCancel}>
          Annuler
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Enregister
        </Button>
      ]}
    >
      <TextArea
        rows={8}
        value={comment}
        onChange={(event) => setComment(event.target.value)}
      />
    </Modal>
  );
};

CommentModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  worksite: PropTypes.shape({
    comment: PropTypes.string,
    _id: PropTypes.string
  }).isRequired,
  setRefresh: PropTypes.func.isRequired
};

export default CommentModal;
