import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListUsers from './ListUsers';
import { Exception } from '../../components';
import CreateUpdateUser from './CreateUpdateUser';
import ShowUser from './ShowUser';

export const UserRouter = () => (
  <Routes>
    <Route
      path="/usersadmins"
      element={
        <ListUsers category="/admins:/i" type="admins" headless={false} />
      }
    />
    <Route
      path="usersadmins/create"
      element={<CreateUpdateUser purpose="create" type="admins" />}
    />
    <Route
      path="usersadmins/edit/:id"
      element={<CreateUpdateUser purpose="edit" type="admins" />}
    />
    <Route path="usersadmins/show/:id" element={<ShowUser type="admins" />} />
    <Route
      path="/userspromoters"
      element={
        <ListUsers category="/promoters:/i" type="promoters" headless={false} />
      }
    />
    <Route
      path="userspromoters/create"
      element={<CreateUpdateUser purpose="create" type="promoters" />}
    />
    <Route
      path="userspromoters/edit/:id"
      element={<CreateUpdateUser purpose="edit" type="promoters" />}
    />
    <Route
      path="userspromoters/show/:id"
      element={<ShowUser type="promoters" />}
    />
    <Route
      path="/userscompanies"
      element={
        <ListUsers category="/companies:/i" type="companies" headless={false} />
      }
    />
    <Route
      path="userscompanies/create"
      element={<CreateUpdateUser purpose="create" type="companies" />}
    />
    <Route
      path="userscompanies/edit/:id"
      element={<CreateUpdateUser purpose="edit" type="companies" />}
    />
    <Route
      path="userscompanies/show/:id"
      element={<ShowUser type="companies" />}
    />
    <Route
      path="/usersinspectors"
      element={
        <ListUsers
          category="/inspectors:/i"
          type="inspectors"
          headless={false}
        />
      }
    />
    <Route
      path="usersinspectors/create"
      element={<CreateUpdateUser purpose="create" type="inspectors" />}
    />
    <Route
      path="usersinspectors/edit/:id"
      element={<CreateUpdateUser purpose="edit" type="inspectors" />}
    />
    <Route
      path="usersinspectors/show/:id"
      element={<ShowUser type="inspectors" />}
    />
    <Route
      path="/usersworkers"
      element={
        <ListUsers category="/workers:/i" type="workers" headless={false} />
      }
    />
    <Route
      path="usersworkers/create/:cardNumber"
      element={<CreateUpdateUser purpose="create" type="workers" />}
    />
    <Route
      path="usersworkers/create/"
      element={<CreateUpdateUser purpose="create" type="workers" />}
    />
    <Route
      path="usersworkers/edit/:id"
      element={<CreateUpdateUser purpose="edit" type="workers" />}
    />
    <Route path="usersworkers/show/:id" element={<ShowUser type="workers" />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
