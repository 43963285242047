import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import { t } from 'i18next';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { usersData } from './listFields';
import { useFormatter } from '../../utils/formatter';
import { useAuthContext } from '../../contexts/AuthContext';
import { routes, subRoutes } from '../../utils/constants/adminRoutes';

const ListUsers = ({ headless, category, type, customResources }) => {
  const { user } = useAuthContext();
  const [displayArchive, setDisplayArchive] = useState(false);
  const populate = `${usersData?.[type]?.populate}`;
  const { columns, headers } = useColumns(type, false);
  const { formattedData } = useFormatter();
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';
  const isCompanyAdmin = user?.role === 'companies:ADMIN-COMPANY';

  return (
    <ListResource
      resourceName="users"
      extraQuery={`role=${category}${
        displayArchive ? '&isActive=false' : '&isActive=true'
      }`}
      columns={columns}
      headers={headers}
      showAction={{
        pathname: (record) =>
          `${routes.USERS}${subRoutes.USERS[type.toUpperCase()]}/show/${
            record?._id
          }`
      }}
      editAction={
        isCompanyAdmin ||
        (isAdmin &&
          !headless && {
            pathname: (record) =>
              `${routes.USERS}${subRoutes.USERS[type.toUpperCase()]}/edit/${
                record?._id
              }`
          })
      }
      deleteAction={isAdmin && !displayArchive}
      reintegrate={isAdmin && displayArchive}
      withCreateButton={isAdmin || (isCompanyAdmin && !headless)}
      resourceModelName="User"
      customResources={customResources && customResources}
      headless={headless}
      populate={populate}
      extraTitle={type}
      formatter={formattedData}
      multipleSelection={isAdmin && true}
      extraButtons={
        isAdmin && (
          <Switch
            onChange={() => setDisplayArchive(!displayArchive)}
            checkedChildren="Archivés"
            unCheckedChildren={t('users.switch')}
            style={{ marginRight: '20px' }}
          />
        )
      }
    />
  );
};

ListUsers.propTypes = {
  headless: PropTypes.bool.isRequired,
  category: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  customResources: PropTypes.instanceOf(Array)
};
ListUsers.defaultProps = {
  customResources: null
};

export default ListUsers;
