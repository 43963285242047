import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Row, Col, Skeleton, Typography, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { ContentCustom } from '../../../components';
import WorkerCard from './WorkerCard';
import MobileContextProvider from '../MobileContext';

const { Title } = Typography;

const WorkerDetails = ({ dataCard, mode }) => {
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const card = localStorage.getItem('invalid_card');
  const { userData, artisanCheck, managerCheck } = MobileContextProvider();

  const checkUser = () => {
    if (artisanCheck) return 'artisan';
    if (managerCheck) return 'manager';
    return false;
  };

  const UpdateControl = useCallback(
    async (user_data, cardData) => {
      setIsLoading(true);
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/controls/${id}`
        });
        try {
          if (!(JSON.stringify(data).split(user_data[0]?._id).length > 1)) {
            await dispatchAPI('PATCH', {
              url: `/controls/${id}`,
              body: {
                ...data,
                workers: [
                  ...data.workers,
                  {
                    worker: user_data[0]?._id,
                    isValid: cardData?.indValide === 'true',
                    date: moment()
                  }
                ]
              }
            });
            notification.success({
              message: t('controls.messages.workerAdded')
            });
            if (user_data[0].createIncident) {
              navigate(`/incidents/create/${id}/${dataCard.identifiantCarte}`);
            }
          } else {
            notification.warning({
              message: t('controls.messages.already_checked')
            });
          }
        } catch (e) {
          message(e);
        }
      } catch (e) {
        message(e);
      }

      setIsLoading(false);
    },
    [id]
  );

  const isAlreadyInTheControl = async (worker, validCard) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/controls/${id}/${worker?._id}?validCard=${validCard}`
      });
      const { isAlreadyCheck, isNowRegistered } = data;
      return { isAlreadyCheck, isNowRegistered };
    } catch (e) {
      message(e);
      return false;
    }
  };

  const getWorker = useCallback(async () => {
    setIsLoading(true);
    if (dataCard?.identifiantCarte !== undefined) {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/workers?card_number=${
            dataCard?.identifiantCarte
          }&populate=${'idCompany'}`
        });
        if (data?.length > 0) {
          const { isAlreadyCheck, isNowRegistered } =
            await isAlreadyInTheControl(data[0], dataCard?.indValide);
          const worksiteCompanies = await dispatchAPI('GET', {
            url: `/worksites/getworksiteCompanies/${id}?`
          });
          const isCompanyReferenced = worksiteCompanies.data.filter((c) =>
            data[0].idCompany?.some((wc) => c._id === wc._id)
          );
          if (isCompanyReferenced.length) {
            if (!dataCard?.indValide) {
              localStorage.removeItem('invalid_card');
              data[0].createIncident = true;
            }
            if (!isAlreadyCheck && !isNowRegistered) {
              navigate(
                `${
                  mode === 'desktop' ? '/controls' : ''
                }/workers/create/${id}/${
                  dataCard?.identifiantCarte
                }?user=${checkUser()}`
              );
            } else setUser(data);
          } else {
            message('company_no_referenced');
            navigate(`/incidents/create/${id}/${dataCard.identifiantCarte}`);
          }
        } else {
          message('unknown_user');
          navigate(
            `${mode === 'desktop' ? '/controls' : ''}/workers/create/${id}/${
              dataCard?.identifiantCarte
            }?user=${checkUser()}`
          );
        }
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    }
  });

  useEffect(async () => {
    if (dataCard?.indValide === false) message('invalid_card');
    if (dataCard?.identifiantCarte) {
      await getWorker();
    }
    if (userData.unknown_user) {
      navigate(
        `${mode === 'desktop' ? '/controls' : ''}/workers/create/${id}/${
          userData?.unknown_user
        }?user=${checkUser()}`
      );
    } else if (userData?.length) {
      setUser(userData);
    }
  }, []);

  useEffect(() => {
    if (user && user.length && !card) {
      (async () => {
        await UpdateControl(user, dataCard);
      })();
    }
  }, [user]);

  const workerDetailsTitle = () => {
    if (user) {
      switch (user[0]?.poste) {
        case 'artisan':
          return `Carte artisan n°${user[0].card_number}`;
        case 'Directeur' || 'Directeur_technique':
          return 'Directeur';
        case 'intern':
          return `Stagiaire`;
        case 'tenant':
          return `Locatier`;
        case 'service_providers':
          return `Prestataire`;
        default:
          return null;
      }
    }
    return null;
  };

  return (
    <ContentCustom>
      <Title>
        {dataCard?.identifiantCarte
          ? `Carte BTP n°${dataCard?.identifiantCarte}`
          : workerDetailsTitle()}
      </Title>
      <Row>
        <Skeleton loading={isLoading}>
          <Col span={24}>
            <WorkerCard
              user={user}
              cardNumber={dataCard?.identifiantCarte}
              artisanCard={
                !dataCard?.identifiantCarte && user && user[0]?.card_number
              }
              userType={checkUser()}
              t={t}
            />
          </Col>
          {user && user[0] && (
            <Col span={24} style={{ marginTop: 20 }}>
              <Button
                className="btn-pill btn-light"
                block
                size="medium"
                onClick={() => {
                  if (dataCard?.identifiantCarte) {
                    navigate(-1);
                  } else window.location.reload();
                }}
                style={{
                  width: '100%'
                }}
              >
                Retour au controle
              </Button>
              <Button
                onClick={() => navigate(`/workers/edit/${id}/${user[0]?._id}`)}
                className="btn-pill btn-light"
                icon="Editer la fiche"
                size="medium"
                block
                style={{
                  width: '100%',
                  marginTop: 10,
                  backgroundColor: '#ff4d4f',
                  color: '#fff'
                }}
              />
            </Col>
          )}
        </Skeleton>
      </Row>
    </ContentCustom>
  );
};

WorkerDetails.propTypes = {
  dataCard: PropTypes.shape({
    indValide: PropTypes.bool,
    identifiantCarte: PropTypes.string
  }),
  mode: PropTypes.string
};

WorkerDetails.defaultProps = {
  dataCard: {},
  mode: null
};

export default WorkerDetails;
