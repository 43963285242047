import React from 'react';
import PropTypes from 'prop-types';

import { ListResource } from '../../components/ListResource/ListResource';
import { useColumns } from '../users/columns';
import { usersData } from '../users/listFields';

const PromoterUsers = ({ idPromoter }) => {
  const type = 'promoters';
  const { columns } = useColumns(type, false);
  const populate = usersData[type].populate;

  return (
    <ListResource
      headless
      resourceName="users"
      extraQuery={`role=/${type}:/i&idPromoter=${idPromoter}&isActive=${true}`}
      columns={columns}
      headers={[]}
      resourceModelName="User"
      populate={populate}
      extraTitle={type}
      path={`/users/users${type}`}
    />
  );
};

PromoterUsers.propTypes = {
  idPromoter: PropTypes.string.isRequired
};

export default PromoterUsers;
