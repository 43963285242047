import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../components';
import ShowUser from './ShowUser';

export const UserPromoterRouter = () => (
  <Routes>
    <Route
      path="userspromoters/show/:id"
      element={<ShowUser type="promoters" />}
    />
    <Route
      path="/userscompanies/show/:id"
      element={<ShowUser type="companies" />}
    />
    <Route
      path="usersinspectors/show/:id"
      element={<ShowUser type="inspectors" />}
    />
    <Route path="*" element={<Exception />} />
  </Routes>
);
