import React from 'react';
import {
  HomeOutlined,
  UserOutlined,
  BuildOutlined,
  ShopOutlined,
  CheckSquareOutlined,
  SettingOutlined,
  ScheduleOutlined,
  CalendarOutlined,
  PhoneOutlined,
  ClockCircleOutlined,
  MailOutlined
} from '@ant-design/icons';
import { WorksiteIcon, HelmetIcon } from './customIcons';

const navMenuLogos = {
  HOME: <HomeOutlined />,
  USERS: <UserOutlined />,
  WORKERS: <HelmetIcon />,
  WORKSITES: <WorksiteIcon />,
  CALENDAR: <CalendarOutlined />,
  PLANNING: <ClockCircleOutlined />,
  PROMOTERS: <ShopOutlined />,
  COMPANIES: <BuildOutlined />,
  CONTROLS: <CheckSquareOutlined />,
  INCIDENTS: <ScheduleOutlined />,
  SETTINGS: <SettingOutlined />,
  TODAY: <CalendarOutlined />,
  UTILS_NUMBERS: <PhoneOutlined />,
  MAILS: <MailOutlined />
};

export default navMenuLogos;
