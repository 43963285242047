import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { CardsBundle } from './CardsBundle/CardsBundle';
import { useAuthContext } from '../../contexts/AuthContext';
import MobileHeader from './MobileHeader';

const Controls = ({ headless, path, setNavDisplay }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { TabPane } = Tabs;

  const today = new Date().toISOString().slice(0, 10);
  let tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow = tomorrow.toISOString().slice(0, 10);
  const tabList = [
    { name: 'DONE', extraQuery: 'status=DONE' },
    { name: 'TODAY', extraQuery: `date=${today}` },
    { name: 'TOMORROW', extraQuery: `date=${tomorrow}` }
  ];

  useEffect(() => {
    setNavDisplay('block');
  }, []);

  return (
    <div className="today-container">
      <MobileHeader title={t('inspector.controls')} />
      <div className="control_card_container">
        <Tabs defaultActiveKey="DONE" size="large" type="card">
          {tabList.map((tab) => (
            <TabPane key={tab.name} tab={t(`controls.mobile.tab.${tab.name}`)}>
              <CardsBundle
                headless={headless}
                resourceName="controls"
                extraQuery={`inspector=${user._id}&${tab.extraQuery}`}
                resourceModelName="Control"
                populate="worksite,inspector,workers"
                path={path}
                type="control"
              />
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

Controls.propTypes = {
  headless: PropTypes.bool,
  path: PropTypes.string,
  setNavDisplay: PropTypes.string
};
Controls.defaultProps = {
  headless: false,
  path: null,
  setNavDisplay: 'none'
};

export default Controls;
