import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import { t } from 'i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { ListResource } from '../../components';
import { useColumns } from './columns';

const ListPromoter = ({ headless, path, customResources }) => {
  const { user } = useAuthContext();
  const { headers, columns } = useColumns();
  const [displayArchive, setDisplayArchive] = useState(false);
  const isAdmin = ['admins:SUPER-ADMIN', 'admins:ADMIN'].includes(user?.role);

  return (
    <ListResource
      resourceName="promoters"
      headless={headless}
      columns={columns}
      headers={headers}
      resourceModelName="Promoter"
      populate="contact"
      path={path}
      customResources={customResources}
      scroll={{ x: 1500 }}
      extraQuery={`${displayArchive ? 'isActive=false' : 'isActive=true'}`}
      deleteAction={isAdmin && !displayArchive}
      reintegrate={isAdmin && displayArchive}
      extraButtons={
        <Switch
          onChange={() => setDisplayArchive(!displayArchive)}
          checkedChildren="Archivés"
          unCheckedChildren={t('users.switch')}
          style={{ marginRight: '20px' }}
        />
      }
    />
  );
};

ListPromoter.propTypes = {
  headless: PropTypes.bool,
  path: PropTypes.string,
  customResources: PropTypes.instanceOf(Array)
};
ListPromoter.defaultProps = {
  headless: false,
  path: null,
  customResources: null
};

export default ListPromoter;
