const commentList = (data) => {
  const { comment_title, content, author } = data;

  return [
    {
      label: 'comments.form.title',
      span: 1,
      content: comment_title || '-'
    },
    {
      label: 'comments.form.content',
      span: 1,
      content: content || '-'
    },
    {
      label: 'comments.form.author',
      span: 1,
      content: author || '-'
    }
  ];
};

export default commentList;
