/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import WorksitePhasesTable from './worksitePhasesTable';

const initialColumns = [
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: (text) => <Input InitialValue={text} />
  }
];

const WorksitePhasesSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [worksitephasesCols, setWorksitePhasesCols] = useState(initialColumns);
  const newWorksitePhasesCol = {
    label: 'Nouvelle ligne',
    icon: null,
    color: '#008CA0',
    show: true
  };

  const getWorksitePhasesCols = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/worksitephases' });
      setWorksitePhasesCols(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addWorksitePhasesCol = async () => {
    try {
      await dispatchAPI('POST', {
        url: '/worksitephases',
        body: newWorksitePhasesCol
      });
      getWorksitePhasesCols();
    } catch (e) {
      message.error(e.message);
    }
  };

  const deleteWorksitePhasesCol = async (worksitephasesCol) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/worksitephases/${worksitephasesCol._id}`
      });
    } catch (e) {
      message.error(e.message);
    }
    getWorksitePhasesCols();
  };

  const editWorksitePhasesCol = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/worksitephases/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    getWorksitePhasesCols();
  };
  useEffect(() => {
    getWorksitePhasesCols();
  }, []);

  return (
    <>
      <WorksitePhasesTable
        worksitephasesCols={worksitephasesCols}
        editWorksitePhasesCol={editWorksitePhasesCol}
        deleteWorksitePhasesCol={deleteWorksitePhasesCol}
      />
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addWorksitePhasesCol}
      >
        {t('settings.worksitephasesTable.addworksitephasescol')}
      </Button>
    </>
  );
};

export default WorksitePhasesSettings;
