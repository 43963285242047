import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ActionButtons = ({ mode, goBack, toggleFlash }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <Row className="action-btn-row">
      <Col xs={24} xl={6} xxl={4}>
        <Button
          onClick={() => {
            navigate({
              pathname: `${
                mode === 'desktop' ? '/controls' : ''
              }/incidents/create/${id}`
            });
            toggleFlash();
          }}
          className="btn-pill btn-light"
          shape="round"
          block
          size="large"
          style={{
            backgroundColor: 'var(--errorColor)',
            color: 'var(--clientColor)'
          }}
        >
          {t('controls.mobile.button.declare_incident')}
        </Button>
      </Col>
      <Col xs={24} xl={6} xxl={4}>
        <Button
          onClick={() => {
            goBack();
            toggleFlash();
          }}
          className="btn-pill btn-light"
          shape="round"
          block
          size="large"
          style={{
            backgroundColor: 'var(--errorColor)',
            color: 'var(--clientColor)',
            marginTop: 10
          }}
          icon={<CloseOutlined />}
        >
          Retour
        </Button>
      </Col>
    </Row>
  );
};

export default ActionButtons;

ActionButtons.propTypes = {
  mode: PropTypes.string,
  goBack: PropTypes.func,
  toggleFlash: PropTypes.func
};

ActionButtons.defaultProps = {
  mode: null,
  goBack: undefined,
  toggleFlash: undefined
};
