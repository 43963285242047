import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListWorkers from './ListWorkers';
import CreateUpdateWorker from './CreateUpdateWorker';
import ShowWorker from './ShowWorker';

export const WorkerRouter = () => (
  <Routes>
    <Route
      path="/"
      element={
        <ListWorkers category="/workers:/i" type="workers" headless={false} />
      }
    />
    <Route path="/create" element={<CreateUpdateWorker purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateWorker purpose="edit" />} />
    <Route path="/show/:id" element={<ShowWorker />} />
  </Routes>
);
