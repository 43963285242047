// Main nav menuItems
export const routes = {
  TODAY: '/today',
  WORKSITES: '/worksites',
  CONTROLS: '/controls',
  UTILS_NUMBERS: '/utils_numbers'
};

// Main nav subMenuItems
export const subRoutes = {};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings'
};

// url search parameters
export const pathSearches = {
  WORKSITES: '?p=1&pS=10'
};
