import React from 'react';
import PropTypes from 'prop-types';

import { ListResource } from '../../components/ListResource/ListResource';
import { useColumns } from '../worksites/columns';

const PromoterWorksites = ({ idPromoter }) => {
  const { headers: headersWorksites, columns: columnsWorksites } = useColumns();

  return (
    <ListResource
      headless
      resourceName="worksites"
      columns={columnsWorksites}
      headers={headersWorksites}
      resourceModelName="Worksite"
      extraQuery={`idPromoter=${idPromoter}`}
      populate="idController,idPromoter,type,phase"
      path="/worksites"
    />
  );
};

PromoterWorksites.propTypes = {
  idPromoter: PropTypes.string.isRequired
};

export default PromoterWorksites;
