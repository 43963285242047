import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListIncidents from './ListIncidents';
import CreateUpdateIncident from './CreateUpdateIncident';
import ShowIncident from './ShowIncident/ShowIncident';
import { useAuthContext } from '../../contexts/AuthContext';

export const IncidentRouter = () => {
  const { user } = useAuthContext();
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';
  return (
    <Routes>
      <Route path="/" element={<ListIncidents />} />
      {isAdmin && (
        <>
          <Route
            path="/create"
            element={<CreateUpdateIncident purpose="create" />}
          />
          <Route
            path="/edit/:id"
            element={<CreateUpdateIncident purpose="edit" />}
          />
        </>
      )}
      <Route path="/show/:id" element={<ShowIncident />} />
    </Routes>
  );
};
