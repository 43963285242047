import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const useColumns = () => {
  const { t } = useTranslation();

  const headers = [
    {
      label: 'content',
      key: 'content'
    },
    {
      label: 'created_at',
      key: 'created_at'
    },
    {
      label: 'user',
      key: 'user'
    }
  ];

  const columns = [
    {
      title: t('comments.form.content'),
      key: 'content',
      dataIndex: 'content',
      sorter: true
    },
    {
      title: t('comments.form.created_at'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: (created_at) => moment(created_at).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('comments.form.author'),
      key: 'user',
      dataIndex: 'user',
      sorter: true
    }
  ];

  return { headers, columns };
};
