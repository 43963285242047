import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { MobileCreateUpdateContainer } from '../../../components/CreateUpdateContainer/MobileCreateUpdateContainer';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import useFields from './fields';
import MobileContextProvider from '../MobileContext';

const CreateUpdateIncident = ({ purpose, mode }) => {
  const { controlId } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const cardNumber = params.get('cardNumber');
  const { user, dispatchAPI } = useAuthContext();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [control, setControl] = useState(null);
  const [workerControled, setWorkerControled] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUnkownCompany, setIsUnknownCompany] = useState(false);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const [dropdownOpen3, setDropdownOpen3] = useState(false);
  const [dropdownOpen4, setDropdownOpen4] = useState(false);
  const { customNav } = MobileContextProvider();

  const { fields, isFieldsLoading, unknownWorker, unknownCompany, documents } =
    useFields(
      isUnkownCompany,
      setIsUnknownCompany,
      cardNumber,
      dropdownOpen1,
      dropdownOpen2,
      dropdownOpen3,
      dropdownOpen4,
      setDropdownOpen1,
      setDropdownOpen2,
      setDropdownOpen3,
      setDropdownOpen4
    );

  const UpdateControl = async (incidentData) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/controls/${controlId}`
      });
      try {
        if (!(JSON.stringify(data).split(incidentData.worker).length > 1)) {
          await dispatchAPI('PATCH', {
            url: `/controls/${controlId}`,
            body: {
              ...data,
              workers: [
                ...data.workers,
                {
                  worker: incidentData.worker,
                  isValid: false,
                  date: moment()
                }
              ]
            }
          });
          notification.success({
            message: t('controls.messages.workerAdded')
          });
        } else {
          notification.warning({
            message: t('controls.messages.already_checked')
          });
        }
      } catch (e) {
        message(e);
      }
    } catch (e) {
      message(e);
    }
  };

  const getPrincipalCompany = async (worksite, business_body) => {
    let company;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `worksites/${worksite}`
      });
      company = data?.companies[business_body]?.principal;
    } catch (e) {
      message(e);
    }
    return company;
  };

  const customSubmit = async (incidentData) => {
    setIsLoading(true);
    const { data } = await dispatchAPI('GET', {
      url: `/controls/${controlId}`
    });

    try {
      if (
        !(JSON.stringify(data).split(incidentData.worker).length > 1) ||
        cardNumber
      ) {
        let principalCompany;
        if (incidentData.business_body) {
          principalCompany = await getPrincipalCompany(
            control.worksite,
            incidentData.business_body
          );
        }
        let documents_saved = null;
        const files = [];
        if (documents) {
          const formData = new FormData();
          documents.forEach((doc) => {
            formData.append('file', doc.originFileObj || doc);
            formData.append('type', doc.type);
          });
          documents_saved = await dispatchAPI('POST', {
            url: `/files/upload`,
            body: formData
          });
          if (documents_saved) {
            documents_saved.data.description.forEach((file) =>
              files.push(file.id)
            );
          }
        }
        let newBody = null;
        if (controlId && cardNumber) {
          newBody = {
            ...incidentData,
            control: control._id,
            worksite: control.worksite,
            inspector: user._id,
            status: '63579a1aac72b2ea963bba6c',
            date: Date.now(),
            worker: workerControled._id,
            ...(documents_saved
              ? {
                  documents: files
                }
              : {}),
            ...(unknownCompany
              ? { unknown_company: unknownCompany, company: null }
              : {}),
            ...(principalCompany
              ? {
                  principal_company: principalCompany
                }
              : {})
          };
        } else {
          newBody = {
            ...incidentData,
            ...(unknownWorker
              ? { unknown_worker: unknownWorker, worker: null }
              : {}),
            ...(unknownCompany
              ? { unknown_company: unknownCompany, company: null }
              : {}),
            ...(principalCompany
              ? {
                  principal_company: principalCompany
                }
              : {}),
            ...(controlId ? { control: controlId } : {}),
            inspector: user._id,
            worksite: control.worksite,
            date: Date.now(),
            status: '63579a1aac72b2ea963bba6c',
            ...(documents_saved
              ? {
                  documents: files
                }
              : {})
          };
        }

        try {
          await dispatchAPI('POST', {
            url: `/incidents/`,
            body: newBody
          });
          if (!cardNumber && !unknownWorker) UpdateControl(newBody);
          navigate(`/control${mode === 'desktop' ? 's' : ''}/${control._id}`);
        } catch (e) {
          message(e);
        }
      } else {
        notification.warning({
          message: t('controls.messages.already_checked')
        });
        navigate(`/control${mode === 'desktop' ? 's' : ''}/${control._id}`);
      }
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        control: controlId,
        date: data.date && moment(data.date)
      })
    },

    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...(controlId ? { control: controlId } : {})
      })
    },
    onCreateResource: {
      setBody: async (data) => {
        let documents_saved = null;
        const files = [];
        if (documents) {
          const formData = new FormData();
          documents.forEach((doc) => {
            formData.append('file', doc.originFileObj || doc);
            formData.append('type', doc.type);
          });
          documents_saved = await dispatchAPI('POST', {
            url: `/files/upload`,
            body: formData
          });
          if (documents_saved) {
            documents_saved.data.description.forEach((file) =>
              files.push(file.id)
            );
          }
        }
        return {
          ...data,
          ...(unknownWorker
            ? { unknown_worker: unknownWorker, worker: null }
            : {}),
          ...(documents_saved
            ? {
                files,
                justification_status: 'PENDING'
              }
            : {}),
          ...(unknownCompany
            ? { unknown_company: unknownCompany, company: null }
            : {}),
          ...(controlId ? { control: controlId } : {})
        };
      }
    }
  };

  const getControl = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/controls/${controlId}`
      });
      setControl(data);
    } catch (e) {
      message(e);
    }
  };

  const getWorker = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/workers?card_number=${cardNumber && cardNumber}`
      });
      setWorkerControled(data[0]);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    const card = localStorage.getItem('invalid_card');
    if (card) {
      localStorage.removeItem('invalid_card');
    }

    if (controlId) getControl();
    if (cardNumber) getWorker();
  }, [purpose]);

  const element = document.querySelector('#mainContent');

  element?.addEventListener('scroll', () => {
    setDropdownOpen1(false);
    setDropdownOpen2(false);
    setDropdownOpen3(false);
    setDropdownOpen4(false);
  });

  return (
    <MobileCreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="incidents"
      resource="incidents"
      config={config}
      customSubmit={controlId && customSubmit}
      isCustomSubmitLoad={isLoading}
      controlId={controlId}
      customNav={customNav}
    />
  );
};

CreateUpdateIncident.propTypes = {
  purpose: PropTypes.string.isRequired,
  mode: PropTypes.string
};

CreateUpdateIncident.defaultProps = {
  mode: null
};

export default CreateUpdateIncident;
