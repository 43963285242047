import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  Popconfirm,
  Row
} from 'antd';
import {
  DeleteOutlined,
  MenuOutlined,
  PlusOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ExportButton } from '../ExportButton/ExportButton';
import { ImportButton } from '../importButton';
import { Datatable } from '../DataTable/Datatable';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { useAuthContext } from '../../contexts/AuthContext';

const { Search } = Input;

export const ListResource = ({
  resourceName,
  tradKey,
  dataToFetch,
  columns,
  customActionColumn,
  headers,
  children,
  populate,
  extraQuery,
  extraHeader,
  extraButtons,
  exportUrl,
  withCreateButton,
  extraQueryCreate,
  withUploadButton,
  forceRefresh,
  resourceModelName,
  editAction,
  showAction,
  deleteAction,
  onDoubleClickAction,
  scroll,
  expandable,
  path,
  rowKey,
  headless,
  withSearchBar,
  ShowPagination,
  style,
  extraTitle,
  customResources,
  formatter,
  multipleSelection,
  reintegrate
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const pageSize = params.get('pS');
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [searchValue, setSearchValue] = useState(keyword);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const searchResource = (value) => {
    if (value) {
      const encodedValue = encodeURIComponent(value);
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}&k=${encodedValue}`
      });
    } else {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}`
      });
    }
  };

  const deleteResources = async () => {
    try {
      await dispatchAPI('DELETE', {
        url: `/${resourceName}/deleteMany`,
        body: { idsArray: selectedRowKeys }
      });
      window.location.reload();
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  const menu = (
    <Menu>
      {headers && (
        <Menu.Item key="export">
          <ExportButton
            dataName={resourceName}
            headers={headers}
            url={`/${exportUrl || resourceName}`}
            fileName={`${resourceName}.csv`}
            populate={populate}
            extraQuery={extraQuery}
            formatter={formatter}
          />
        </Menu.Item>
      )}
      <Menu.Item key="import">
        <ImportButton resourceName={resourceModelName} />
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {!headless && (
        <PageHeaderCustom
          title={t(
            `${tradKey || resourceName}.${
              extraTitle ? `${extraTitle}.` : ''
            }title`
          )}
          extra={extraHeader}
        />
      )}
      <ContentCustom>
        <Row justify="space-between" gutter={[8, 16]}>
          {withSearchBar && (
            <Col>
              <Search
                allowClear
                placeholder={t('placeholder.search')}
                defaultValue={searchValue}
                onSearch={(value) => searchResource(value)}
              />
            </Col>
          )}
          {withCreateButton && (
            <Col>
              <Row align="middle">
                {extraButtons}
                <Link
                  to={
                    path
                      ? `${path}/create${extraQueryCreate || ''}`
                      : `${pathname}/create${extraQueryCreate || ''}`
                  }
                >
                  <Button type="add">
                    {`${t('buttons.create')}`}
                    &nbsp;
                    <PlusOutlined />
                  </Button>
                </Link>
                {withUploadButton && (
                  <Dropdown overlay={menu}>
                    <Button type="link">
                      <MenuOutlined
                        style={{ fontSize: 16, color: 'var(--textColor)' }}
                      />
                    </Button>
                  </Dropdown>
                )}
              </Row>
            </Col>
          )}
        </Row>
        {selectedRowKeys.length > 0 && (
          <Popconfirm
            title={t('datatable.column.action.delete_many.title')}
            okText={t('datatable.column.action.delete_many.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete_many.cancel')}
            onConfirm={deleteResources}
            icon={<WarningOutlined />}
          >
            <Button danger style={{ marginTop: 16 }}>
              <DeleteOutlined type="delete" />
              {t('buttons.delete_selected')}
            </Button>
          </Popconfirm>
        )}
        <Row gutter={[8, 16]}>{children}</Row>
        <Datatable
          style={{ ...style, marginTop: 24 }}
          resourceName={dataToFetch || resourceName}
          columns={columns}
          customActionColumn={customActionColumn}
          extraQuery={extraQuery}
          populate={populate}
          forceRefresh={forceRefresh}
          editAction={editAction}
          showAction={showAction}
          deleteAction={deleteAction}
          onDoubleClickAction={onDoubleClickAction}
          scroll={scroll || { x: 1600 }}
          expandable={expandable}
          path={path}
          rowKey={rowKey}
          ShowPagination={ShowPagination}
          customResources={customResources}
          multipleSelection={multipleSelection}
          setSelectedRowKeys={setSelectedRowKeys}
          selectedRowKeys={selectedRowKeys}
          reintegrate={reintegrate}
        />
      </ContentCustom>
    </>
  );
};

ListResource.propTypes = {
  resourceName: PropTypes.string,
  tradKey: PropTypes.string,
  dataToFetch: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customActionColumn: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape({})),
  extraQuery: PropTypes.string,
  extraQueryCreate: PropTypes.string,
  extraHeader: PropTypes.element,
  extraButtons: PropTypes.element,
  exportUrl: PropTypes.string,
  populate: PropTypes.string,
  withCreateButton: PropTypes.bool,
  withUploadButton: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  resourceModelName: PropTypes.string,
  style: PropTypes.shape({}),
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  showAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  deleteAction: PropTypes.bool,
  onDoubleClickAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func
    })
  ]),
  scroll: PropTypes.shape({}),
  expandable: PropTypes.shape({}),
  path: PropTypes.string,
  rowKey: PropTypes.string,
  headless: PropTypes.bool,
  withSearchBar: PropTypes.bool,
  ShowPagination: PropTypes.bool,
  extraTitle: PropTypes.string,
  customResources: PropTypes.arrayOf({}),
  formatter: PropTypes.func,
  multipleSelection: PropTypes.bool,
  reintegrate: PropTypes.bool
};

ListResource.defaultProps = {
  tradKey: null,
  headers: null,
  extraQuery: null,
  extraQueryCreate: null,
  extraHeader: null,
  extraButtons: null,
  exportUrl: null,
  populate: null,
  style: null,
  customActionColumn: false,
  withCreateButton: true,
  withUploadButton: true,
  dataToFetch: null,
  forceRefresh: null,
  resourceModelName: null,
  editAction: true,
  showAction: true,
  deleteAction: true,
  onDoubleClickAction: true,
  scroll: null,
  expandable: undefined,
  path: null,
  rowKey: '_id',
  headless: false,
  withSearchBar: true,
  extraTitle: '',
  ShowPagination: true,
  customResources: null,
  formatter: null,
  multipleSelection: false,
  resourceName: null,
  reintegrate: false
};
