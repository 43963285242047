import React from 'react';
import PropTypes from 'prop-types';
import ListWorkers from '../../workers/ListWorkers';

const ControlWorkers = ({ idControl, type, control }) => {
  const idOfUsers = (idControl || [])
    .map((worker) => `${worker.worker}`)
    .join();

  return (
    <ListWorkers
      headless
      extraQuery={`_id=${idOfUsers || null}`}
      type={type}
      extraQueryCreate={`?idControl=${control._id}`}
    />
  );
};

export default ControlWorkers;

ControlWorkers.propTypes = {
  idControl: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
  control: PropTypes.shape({
    _id: PropTypes.string
  })
};

ControlWorkers.defaultProps = {
  idControl: [],
  type: null,
  control: null
};
