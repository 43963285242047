import React from 'react';
import moment from 'moment';
import PropTypes, { arrayOf } from 'prop-types';
import { Card, Row, Timeline, Col, Typography, Tag } from 'antd';
import {
  PlusCircleFilled,
  CheckOutlined,
  SendOutlined,
  FileExclamationOutlined,
  FileAddOutlined,
  FileExcelOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { historyColors } from '../../../utils/constants/tagColors';

const IncidentNewFeed = ({ incident }) => {
  const { t } = useTranslation();

  const sortByDateDesc = (a, b) => {
    if (a.date > b.date) return -1;
    if (a.date < b.date) return 1;
    return 0;
  };

  const iconByType = {
    create: <PlusCircleFilled style={{ color: 'green' }} />,
    reminder: <SendOutlined style={{ color: 'lightblue' }} />,
    change_doc_status: <FileExclamationOutlined style={{ color: 'purple' }} />,
    add_doc: <FileAddOutlined style={{ color: 'lighgreen' }} />,
    delete_doc: <FileExcelOutlined style={{ color: 'red' }} />,
    close_incident: <CheckOutlined style={{ color: 'green' }} />
  };

  const formatFeedElement = (element) => {
    const date = moment(element.date).format('DD/MM/YYYY à HH:mm');
    return (
      <Timeline.Item key={element.date}>
        <Typography.Title level={5}>{date}</Typography.Title>
        <Row style={{ gap: 6 }}>
          <Col>{iconByType[element?.type]}</Col>
          <Col>{`${t(`history.type.${element?.type || 'old_version'}`)} par ${
            element?.changed_by?.first_name || 'N'
          } ${element?.changed_by?.last_name || '/A'}`}</Col>
          {element?.value && (
            <Col span="24">
              <Tag color={historyColors[element?.value]}>
                {t(`history.value.${element?.value || 'old_version'}`)}
              </Tag>
            </Col>
          )}
          {element?.comment ||
            (element?.refused_comment && (
              <Col span="24">{`=> ${
                element?.comment || element?.refused_comment
              }`}</Col>
            ))}
        </Row>
      </Timeline.Item>
    );
  };

  const sortedHistory = incident?.history?.sort(sortByDateDesc) || [];

  return (
    <Card
      title="Fil d'actualité"
      bordered={false}
      style={{ maxHeight: '100%' }}
    >
      <Timeline className="incident-timeline">
        {sortedHistory.map((element) => formatFeedElement(element))}
      </Timeline>
    </Card>
  );
};

IncidentNewFeed.propTypes = {
  incident: PropTypes.shape({
    history: arrayOf(PropTypes.shape({}))
  })
};

IncidentNewFeed.defaultProps = {
  incident: {}
};

export default IncidentNewFeed;
