import React, { useEffect, useState } from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LoginForm from './LoginForm';
import ForgotPwdForm from './ForgotPwdForm';
import { useHandleResize } from '../../utils/HandleResize';
import ChangePwdForm from './ChangePwdForm';
import Logo from '../../assets/images/Quietus-logo.png';
import imgBackGround from '../../assets/images/construction_site_unsplash.png';

export const Login = () => {
  const { token } = useParams();
  const { t } = useTranslation();
  const { Title } = Typography;
  const { width } = useHandleResize();
  const [currentForm, setCurrentForm] = useState('login');
  const forms = {
    login: <LoginForm switchForm={(f) => setCurrentForm(f)} />,
    forgotPwd: <ForgotPwdForm switchForm={(f) => setCurrentForm(f)} />,
    changePwd: (
      <ChangePwdForm switchForm={(f) => setCurrentForm(f)} token={token} />
    )
  };

  useEffect(() => {
    if (token) setCurrentForm('changePwd');
  }, [token]);

  return (
    <div>
      <Layout
        style={{
          minHeight: '100vh',
          display: 'inherit',
          backgroundImage: `url(${imgBackGround})`,
          backgroundSize: 'cover'
        }}
      >
        <Row style={{ marginLeft: width > 992 ? '20vw' : 0 }}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            xl={{ span: 10 }}
            xxl={{ span: 8 }}
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              backdropFilter: width > 768 ? 'blur(4px)' : 'none'
            }}
          >
            <Row justify="center" align="middle" style={{ height: '100vh' }}>
              <Col xs={20} style={{ textAlign: 'center' }}>
                <img
                  style={{
                    width: '260px',
                    height: 'auto',
                    marginBottom: 50
                  }}
                  alt="Logo"
                  src={Logo}
                />
                <Title style={{ textAlign: 'center' }}>
                  {t(`login.title.${currentForm}`)}
                </Title>
                {forms[currentForm]}
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    </div>
  );
};
