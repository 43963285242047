import React from 'react';
import { Link } from 'react-router-dom';
import { routes, subRoutes } from '../../utils/constants/adminRoutes';

const companyList = (data) => {
  const {
    name,
    siret_number,
    vat_number,
    address,
    contact,
    phone_number,
    business_body
  } = data;

  return [
    {
      label: 'companies.form.name',
      span: 1,
      content: name || '-'
    },
    {
      label: 'companies.form.business_body',
      span: 2,
      content: business_body ? business_body.label : '-'
    },
    {
      label: 'companies.form.siret_number',
      span: 2,
      content: siret_number || '-'
    },
    {
      label: 'companies.form.vat_number',
      span: 2,
      content: vat_number || '-'
    },
    {
      label: 'companies.form.address_full',
      span: 2,
      content:
        address && address.number !== ''
          ? `${address.number || ''} ${address.street || ''} ${
              address.additional || ''
            } ${address.postal_code || ''} ${address.city || ''}`
          : '-'
    },
    {
      label: 'companies.form.contact',
      span: 1,
      content:
        contact?.length > 0
          ? (contact || []).map((c) => (
              <div key={c.email}>
                <Link
                  to={{
                    pathname: `${routes.USERS}${subRoutes.USERS.COMPANIES}/show/${c._id}`
                  }}
                >
                  {c.email}
                </Link>
              </div>
            ))
          : '-'
    },
    {
      label: 'users.form.phone_number_full',
      content:
        phone_number && phone_number.number ? (
          <div>
            {`${phone_number.country_code || '-'} ${phone_number.number.slice(
              0,
              1
            )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ') || '-'}
                    `}
          </div>
        ) : (
          '-'
        )
    }
  ];
};

export default companyList;
