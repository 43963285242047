import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useFormatter } from '../../utils/formatter';
import { Calendar } from '../../components/Calendar/Calendar';
import EventDrawer from './EventDrawer';
import EventModal from './EventModal';
import {
  annualCellRender,
  monthCellRender,
  weekCellRender
} from '../../components/Calendar/CellRenders.tsx';
import { headers } from '../planning/headers';

const { Option } = Select;

export const ShowCalendar = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { formattedData } = useFormatter();
  const [users, setUsers] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [forceRefresh, setForceRefresh] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [eventToUpdate, setEventToUpdate] = useState();

  const openDrawer = () => {
    setShowDrawer(true);
  };

  const closeOverlay = () => {
    setShowDrawer(false);
    setOpenModal(false);
    setForceRefresh(forceRefresh + 1);
  };

  const deleteEvent = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/events/${id}` });
      setForceRefresh(forceRefresh + 1);
    } catch (e) {
      message(e);
    }
  };

  const onsearchInspector = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const getUsers = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users?role=admins:SUPER-ADMIN,admins:ADMIN,inspectors:INSPECTOR`
      });
      setUsers(data);
    } catch (e) {
      message(e);
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getUsers();
    })();
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getUsers();
    })();
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {showDrawer && (
        <EventDrawer
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          users={users}
          purpose="create"
          closeOverlay={closeOverlay}
          idFromOverlay={eventToUpdate}
        />
      )}
      {openModal && (
        <EventModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          users={users}
          purpose="edit"
          closeOverlay={closeOverlay}
          idFromOverlay={eventToUpdate}
        />
      )}
      <PageHeaderCustom title={t('calendar.title')} />
      <Calendar
        modes={['week', 'month']}
        resourceName="events"
        monthCellRender={monthCellRender}
        weekCellRender={weekCellRender}
        annualCellRender={annualCellRender}
        initialMode="week"
        dayRange="weekends"
        hourStart={7}
        hourEnd={22}
        extraQuery={selectedUser ? `user=${selectedUser}` : `user=${null}`}
        forceRefresh={forceRefresh}
        withExtraHeader
        withCreateButton
        openDrawer={openDrawer}
        setOpenModal={setOpenModal}
        setEventToUpdate={setEventToUpdate}
        deleteEvent={deleteEvent}
        resource="calendar"
        populate="user"
        withUploadButton
        formatter={formattedData}
        headers={headers}
        withImportButton={false}
        extraButtons={
          <Select
            style={{ width: '250px', marginRight: 20 }}
            placeholder={t('calendar.placeholder.users')}
            showSearch
            loading={isLoading}
            filterOption={(input, option) => onsearchInspector(input, option)}
            onSelect={(value) => {
              setSelectedUser(value);
              setForceRefresh(forceRefresh + 1);
            }}
          >
            {users.map((user) => (
              <Option key={user._id} value={user._id}>
                {`${user.first_name} ${user.last_name}`}
              </Option>
            ))}
          </Select>
        }
      />
      <ContentCustom />
    </>
  );
};
