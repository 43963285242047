import React from 'react';
import { Avatar, Tag } from 'antd';
import { userRoles, activeStatus } from '../../utils/constants/tagColors';

const listContent = (
  { first_name, last_name, role, phone_number, email, poste, photo, isActive },
  t
) => [
  {
    label: '',
    content: photo && <Avatar src={photo} size={120} />,
    span: 3
  },
  {
    label: 'profile.personalInfo.first_name',
    content: first_name,
    span: 1
  },
  {
    label: 'profile.personalInfo.last_name',
    content: last_name,
    span: 1
  },
  {
    label: 'profile.personalInfo.email',
    content: email,
    span: 1
  },
  {
    label: 'profile.form.phone_number',
    span: 2,
    content:
      phone_number && phone_number.number ? (
        <div>
          {`${phone_number.country_code} ${phone_number.number.slice(
            0,
            1
          )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}
                    `}
        </div>
      ) : (
        '-'
      )
  },
  {
    label: 'profile.personalInfo.role',
    content: role && (
      <Tag color={userRoles[role.split(':')[1]]}>
        {t(`users.tags.${role.split(':')[1]}`)}
      </Tag>
    ),
    span: 1
  },
  {
    label: 'users.form.poste',
    content:
      (role && (
        <Tag color={userRoles[poste]}>{t(`users.tags.${poste}`)}</Tag>
      )) ||
      '-'
  },
  {
    label: 'users.form.status',
    span: 1,
    content:
      (role && (
        <Tag color={activeStatus[isActive]}>{t(`users.tags.${isActive}`)}</Tag>
      )) ||
      '-'
  }
];

export default listContent;
