import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { ListResource } from '../../components';
import { useColumns } from '../incidents/columns';
import { useFormatter } from '../../utils/formatter';
import { useAuthContext } from '../../contexts/AuthContext';
import { routes } from '../../utils/constants/companyRoutes';

const IncidentsModal = ({
  modalVisible,
  setModalVisible,
  subcontractorId,
  setSubcontractorId
}) => {
  const { user } = useAuthContext();
  const { headers, columns } = useColumns();
  const { formattedData } = useFormatter();
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';

  return (
    <Modal
      centered
      open={modalVisible}
      onCancel={() => {
        setModalVisible(false);
        setSubcontractorId();
      }}
      width={1500}
      footer={false}
    >
      <ListResource
        headless={false}
        resourceName="incidents"
        columns={columns}
        headers={headers}
        resourceModelName="Incident"
        populate="worksite,inspector,worker,company,type,status"
        formatter={formattedData}
        withCreateButton={isAdmin}
        withUploadButton={isAdmin}
        deleteAction={isAdmin}
        editAction={isAdmin}
        showAction={{
          pathname: (record) => `${routes.INCIDENTS}/show/${record?._id}`
        }}
        scroll={{ x: 1600 }}
        extraQuery={`company=${subcontractorId}`}
      />
    </Modal>
  );
};

IncidentsModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  subcontractorId: PropTypes.string,
  setSubcontractorId: PropTypes.func.isRequired
};

IncidentsModal.defaultProps = {
  subcontractorId: undefined
};

export default IncidentsModal;
