import React, { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Row,
  Col,
  Image,
  Skeleton,
  Typography,
  Tag,
  Badge,
  Card,
  Button
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MailOutlined,
  PhoneOutlined,
  EyeOutlined
} from '@ant-design/icons';
import {
  CompanyIcon,
  SubcontractorIcon
} from '../../utils/constants/customIcons';
import Hemlet from '../../assets/images/icons/hemlet.svg';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import InformationPanel from './InformationPanel';
import MapZone from '../worksites/MapZone';

const { Title, Text } = Typography;

export const customCompaniesArray = (worksite, companies) => {
  const newArray = [];
  const data = [];
  if (worksite && companies) {
    Object.entries(worksite?.companies).map((companie) => {
      const subcontractors = [];
      if (companie[1].principal) {
        if (companie[1]?.subcontractors?.length) {
          companie[1]?.subcontractors.map((sub) => {
            const index =
              companies && companies.findIndex((c) => c._id === sub);
            subcontractors.push(companies[index]?.name);
            return subcontractors;
          });
        }
        const filter = (i) => String(i.id) === String(companie[1].principal);
        if (newArray.find(filter)) {
          const indexOfNewArray = newArray.findIndex(filter);

          if (subcontractors?.length) {
            newArray[indexOfNewArray].subcontractors = subcontractors.concat(
              newArray[indexOfNewArray].subcontractors
            );
          }
        } else {
          newArray.push({
            id: companie[1]?.principal,
            batchTitle: companie[0],
            ...(companie[1]?.subcontractors?.length &&
              subcontractors.length && { subcontractors })
          });
        }
      }
      return newArray;
    });

    newArray?.map((idCompanie) => {
      if (companies) {
        const index =
          companies && companies.findIndex((c) => c._id === idCompanie.id);
        if (!data.includes(companies[index])) {
          data.push({
            id: idCompanie,
            name: companies[index]?.name,
            ...(idCompanie.subcontractors
              ? { subcontractors: idCompanie.subcontractors }
              : {}),
            key: idCompanie.id
          });
        }
      }
      return data;
    });
    return data;
  }
  return null;
};

const WorksiteDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [worksite, setWorksite] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [batchTitles, setBatchTitles] = useState(false);

  const populate =
    'idController,idPromoter,companies,companies.company,type,phase,referents';

  const getWorksiteWithDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/worksites/details/${id}?populate=${populate}`
      });
      setWorksite(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/companies?populate=contact,business_body'
      });
      setCompanies(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getBatchTitles = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/batchTitles'
      });
      setBatchTitles(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getWorksiteWithDetails();
      await getCompanies();
      await getBatchTitles();
    })();
  }, [getWorksiteWithDetails]);

  const collapseInfos = () => {
    if (worksite !== null)
      return [
        {
          title: t('controls.worksite'),
          content: [
            { title: 'Nom', value: worksite?.name || '-' },
            { title: 'Date de réunion', value: worksite.meetingDate || '-' },
            { title: 'Adresse', value: worksite.address_full || '-' },
            {
              title: "Donneur d'ordre",
              value: worksite.idPromoter?.name || '-'
            },
            { title: 'Nombre de lot', value: worksite.nbOfBatches || '-' },
            {
              title: 'Type de chantier',
              value: (
                <Tag color={worksite.type?.color || '-'}>
                  {worksite.type?.label || '-'}
                </Tag>
              )
            },
            {
              title: 'Phase du chantier',
              value: (
                <Tag color={worksite?.phase?.color || '-'}>
                  {worksite?.phase?.label || '-'}
                </Tag>
              )
            },
            {
              title: (
                <Button
                  block
                  type="primary"
                  className="control-btn"
                  width="100%"
                  onClick={() =>
                    window.open(
                      `https://maps.google.com?q=${Number(
                        worksite?.location?.latitude
                      )},${Number(worksite?.location?.longitude)}`
                    )
                  }
                >
                  Se rendre sur le chantier
                </Button>
              )
            }
          ]
        },
        {
          title: (
            <Row>
              {`${t('controls.companies')}`}
              <Badge
                style={{ marginLeft: 10, marginTop: 2 }}
                count={
                  Array.isArray(worksite.companies)
                    ? worksite?.companies?.length
                    : customCompaniesArray(worksite, companies)?.length || 0
                }
              />
            </Row>
          ),
          content: customCompaniesArray(worksite, companies)?.map((object) => ({
            title: '',
            value: (
              <Card
                title={
                  batchTitles &&
                  batchTitles.find((b) => b?._id === object.id.batchTitle).label
                }
              >
                <Row align="middle">
                  <CompanyIcon />
                  <h5>{object?.name || '-'}</h5>
                </Row>
                {object.subcontractors &&
                  object.subcontractors.map((name) => (
                    <Row align="middle">
                      <SubcontractorIcon />
                      <p>{name || '-'}</p>
                    </Row>
                  ))}
              </Card>
            )
          }))
        },
        {
          title: (
            <Row>
              {`${t('controls.referents')}`}
              <Badge
                style={{ marginLeft: 10, marginTop: 2 }}
                count={worksite?.referents?.length}
              />
            </Row>
          ),
          content: worksite?.referents?.map((contact) => ({
            title: '',
            value: (
              <Card className="contact-card">
                <p>{`${contact?.first_name || '-'} ${
                  contact?.last_name || '-'
                }`}</p>
                <p>
                  <a href={`mailto:${contact?.email}`}>
                    <Row align="middle" style={{ gap: 8 }}>
                      <MailOutlined />
                      {contact?.email || '-'}
                    </Row>
                  </a>
                </p>
                <a
                  href={`tel:${contact?.phone_number?.country_code}${contact?.phone_number?.number}`}
                >
                  <Row align="middle" style={{ gap: 8 }}>
                    <PhoneOutlined />
                    {contact?.phone_number?.country_code || '-'}
                    {contact?.phone_number?.number || '-'}
                  </Row>
                </a>
              </Card>
            )
          }))
        },
        {
          title: `${t('controls.address_full')}`,
          content: [
            {
              title: 'Adresse',
              value: (
                <Button
                  type="link"
                  onClick={() =>
                    window.open(
                      `https://maps.google.com?q=${Number(
                        worksite?.location?.latitude
                      )},${Number(worksite?.location?.longitude)}`
                    )
                  }
                >
                  {worksite.address_full || '-'}
                </Button>
              )
            },
            {
              title: 'Implantation',
              value: <MapZone worksite={worksite} width={300} height={300} />
            }
          ]
        },
        {
          title: (
            <Row>
              {`${t('controls.controls')}`}
              <Badge
                style={{ marginLeft: 10, marginTop: 2 }}
                count={worksite?.controls?.length || 0}
              />
            </Row>
          ),
          content: worksite?.controls?.map((elem) => ({
            title: '',
            value: (
              <Link
                to={{
                  pathname: `/control/${elem?._id}`
                }}
              >
                <Button type="primary" className="control-btn">
                  {elem.reference || '-'}
                  <ArrowRightOutlined />
                </Button>
              </Link>
            )
          }))
        },
        {
          title: (
            <Row>
              <Row>
                {`${t('controls.incidents_of_worksite')}`}
                <Badge
                  style={{ marginLeft: 10, marginTop: 2 }}
                  count={worksite?.incidents?.length || 0}
                />
              </Row>
            </Row>
          ),
          content: worksite?.incidents?.map((elem) => ({
            title: (
              <Row>
                <Text>
                  {`${
                    elem.worker
                      ? elem.worker.first_name || '-'
                      : elem.unknown_worker || '-'
                  } ${elem.worker ? elem.worker.last_name : ''}`}
                </Text>
              </Row>
            ),
            value: (
              <>
                <Tag color={elem?.type?.color || '-'}>
                  {elem?.type?.label || '-'}
                </Tag>
                <Tag color={elem?.status?.color || '-'}>
                  {elem?.status?.label || '-'}
                </Tag>
                <Link
                  to={{ pathname: `/incident/show/${elem?._id}` }}
                  className="incident-link"
                >
                  <EyeOutlined />
                </Link>
              </>
            )
          }))
        },
        {
          title: (
            <Row>
              {`${t('controls.comments')}`}
              <Badge
                style={{ marginLeft: 10, marginTop: 2 }}
                count={worksite?.comment ? 1 : 0}
              />
            </Row>
          ),
          content:
            worksite?.comment &&
            [worksite?.comment].map((elem) => ({
              title: elem,
              value: ''
            }))
        },
        {
          title: (
            <Row>
              {`${t('controls.workers')}`}
              <Badge
                style={{ marginLeft: 10, marginTop: 2 }}
                count={worksite?.workers?.length || 0}
              />
            </Row>
          ),
          content:
            worksite?.workers?.length > 0
              ? (worksite?.workers || []).map((elem) => ({
                  title: (
                    <Text>{`${elem?.worker?.first_name || '-'} ${
                      elem?.worker?.last_name || '-'
                    }`}</Text>
                  ),
                  value: (
                    <Text>
                      {elem?.isValid ? (
                        <CheckCircleOutlined
                          style={{ color: 'var(--addColor)' }}
                        />
                      ) : (
                        <CloseCircleOutlined
                          style={{ color: 'var(--errorColor)' }}
                        />
                      )}
                    </Text>
                  )
                }))
              : ''
        }
      ];
    return [];
  };

  return (
    <>
      <Row className="control-header">
        <Col span={4}>
          <Image src={Hemlet} preview={false} />
        </Col>
        <Col span={18} offset={2}>
          <Title>{worksite?.name}</Title>
          <Text>{worksite?.sncName || '-'}</Text>
          <Text>{worksite?.rcs_number || '-'}</Text>
        </Col>
      </Row>
      <Row value={worksite}>
        <Skeleton loading={isLoading}>
          <Col span={24} className="worksite-details-wrapper">
            {worksite !== null && (
              <InformationPanel content={worksite && collapseInfos()} />
            )}
          </Col>
        </Skeleton>
      </Row>
    </>
  );
};

export default WorksiteDetails;
