import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
  Button,
  Popconfirm,
  Skeleton,
  Row,
  Avatar,
  Tag,
  Col,
  Card,
  message as antdMessage,
  Typography
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  MailOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { userList, modificationsList, emailList } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import DefaultAvatar from '../../assets/images/default-avatar.png';
import BTPCardComponent from './BTPCardComponent';

const ShowUser = ({ type }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, user: userInfo } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [documents, setDocuments] = useState(null);
  const isAdmin =
    userInfo?.role === 'admins:SUPER-ADMIN' ||
    userInfo?.role === 'admins:ADMIN';
  const isCompanyAdmin = userInfo?.role === 'companies:ADMIN-COMPANY';

  const getUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${id}?populate=idPromoter,idCompany,files files.fileId`
      });
      setUser(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getUser();
    })();
  }, [getUser]);

  const deleteUser = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/users/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const sendPasswordEmail = async () => {
    try {
      await dispatchAPI('PATCH', { url: `/users/send_email/${id}` });
      antdMessage.success(t('users.message.email_sent'));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const draggerProps = {
    multiple: false,
    fileList: documents,
    beforeUpload: (newFile) => {
      setDocuments([newFile]);
      return false;
    },
    onRemove: (file) =>
      setDocuments(documents.filter((f) => f.uid !== file.uid))
  };

  const handlePostDocuments = async () => {
    try {
      const formData = new FormData();
      documents.forEach((doc) => {
        formData.append('file', doc.originFileObj || doc);
        formData.append('type', doc.type);
      });
      await dispatchAPI('POST', {
        url: `/users/upload_files/${id}`,
        body: formData
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const valided = async () => {
    user.modifications = null;
    try {
      await dispatchAPI('PATCH', {
        url: `users/${id}`,
        body: user
      });
      window.location.reload();
    } catch (e) {
      message(e);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={
          user?.isActive ? (
            `${t('users.show.title')}`
          ) : (
            <>
              {`${t('users.show.title')} - `}
              <Tag>{t(`users.tags.${user?.isActive}`)}</Tag>
            </>
          )
        }
        extra={
          <>
            {isAdmin && (
              <Popconfirm
                title={t('datatable.column.action.send_email_pwd.title')}
                okText={t('datatable.column.action.send_email_pwd.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.send_email_pwd.cancel')}
                onConfirm={sendPasswordEmail}
                icon={<WarningOutlined />}
              >
                <Button type="primary">
                  {`${t('buttons.send_email_pwd')} `}
                  <MailOutlined />
                </Button>
              </Popconfirm>
            )}
            {(isCompanyAdmin || isAdmin) && (
              <Link
                to={{ pathname: `${routes.USERS}/users${type}/edit/${id}` }}
              >
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
            )}
            {isAdmin && (
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={deleteUser}
                icon={<WarningOutlined />}
              >
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            )}
          </>
        }
      />
      <ContentCustom>
        <Card>
          <Typography.Title
            level={4}
            style={{
              fontWeight: 300,
              textUnderlineOffset: '8px',
              color: 'var(--userColor)',
              textDecoration: 'underline'
            }}
            strong={false}
          >
            Informations principales :
          </Typography.Title>
          <Row gutter={[24, 24]} style={{ marginTop: '50px' }}>
            <Col xs={24} md={4} xl={3}>
              <Skeleton
                loading={isLoading}
                title={0}
                paragraph={0}
                avatar={{ size: 64 }}
                active
              >
                <Avatar
                  size={64}
                  src={user && user.photo ? user.photo : DefaultAvatar}
                />
              </Skeleton>
            </Col>
            <Col xs={24} md={20} xl={21}>
              <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                <DescriptionList
                  data={userList(
                    user || {},
                    t,
                    type,
                    handlePostDocuments,
                    documents,
                    draggerProps
                  )}
                  translate
                />
              </Skeleton>
            </Col>
          </Row>
        </Card>
      </ContentCustom>
      {(user?.role?.startsWith('promoters') ||
        user?.role?.startsWith('companies')) && (
        <ContentCustom>
          <Card>
            <Typography.Title
              level={4}
              style={{
                fontWeight: 300,
                textUnderlineOffset: '8px',
                color: 'var(--userColor)',
                textDecoration: 'underline'
              }}
              strong={false}
            >
              Gestion des emails :
            </Typography.Title>
            <Row gutter={[24, 24]} style={{ marginTop: '50px' }}>
              <Col xs={24} md={4} xl={3} />
              <Col xs={24} md={20} xl={21}>
                <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                  <DescriptionList data={emailList(user || {})} translate />
                </Skeleton>
              </Col>
            </Row>
          </Card>
        </ContentCustom>
      )}
      {user?.modifications && (
        <ContentCustom>
          <Row>
            <Card>
              <Button style={{ float: 'right' }} onClick={valided}>
                {t('buttons.validate')}
              </Button>
              <DescriptionList
                data={modificationsList(user || {})}
                title="Modifications"
                translate
              />
            </Card>
          </Row>
        </ContentCustom>
      )}
      {user?.role.split(':')[0] === 'workers' && (
        <ContentCustom>
          <Row align="center">
            <BTPCardComponent isLoading={isLoading} user={user} />
          </Row>
        </ContentCustom>
      )}
    </>
  );
};

ShowUser.propTypes = {
  type: PropTypes.string.isRequired
};
export default ShowUser;
