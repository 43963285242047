export const usersData = {
  admins: {
    populate: 'idCompany,idPromoter',
    fields: [
      'photo',
      'last_name',
      'first_name',
      'email',
      'phone_number',
      'phone_number_2',
      'role',
      'isActive',
      'poste',
      'send_email'
    ]
  },
  inspectors: {
    populate: 'idCompany,idPromoter',
    fields: [
      'photo',
      'subcontracting',
      'last_name',
      'first_name',
      'email',
      'phone_number',
      'phone_number_2',
      'role',
      'isActive',
      'activate_mail',
      'send_email'
    ]
  },
  workers: {
    populate: 'idCompany',
    fields: [
      'photo',
      'isWCard',
      'card_number',
      'siret_number',
      'intern_contract_number',
      'document_valid_until',
      'idCompany',
      'first_name',
      'last_name',
      'email',
      'phone_number',
      'phone_number_2',
      'poste',
      'isActive',
      'files',
      'renter_proof',
      'provider_proof',
      'send_email'
    ]
  },
  promoters: {
    populate: 'idPromoter',
    fields: [
      'photo',
      'idPromoter',
      'last_name',
      'first_name',
      'email',
      'phone_number',
      'phone_number_2',
      'role',
      'isActive',
      'poste',
      'send_email',
      'incident_closed_email',
      'reminder_incident_email',
      'worksite_closed_email',
      'incident_email'
    ]
  },
  companies: {
    populate: 'idCompany',
    fields: [
      'photo',
      'idCompany',
      'last_name',
      'first_name',
      'email',
      'phone_number',
      'phone_number_2',
      'role',
      'isActive',
      'poste',
      'send_email',
      'incident_closed_email',
      'reminder_incident_email',
      'doc_refused_incident_email',
      'incident_email',
      'worksite_added_email'
    ]
  },
  admins_list: {
    fields: [
      'last_name',
      'first_name',
      'email',
      'phone_number',
      'phone_number_2',
      'role',
      'isActive',
      'poste',
      'status'
    ]
  },
  promoters_list: {
    fields: [
      'last_name',
      'first_name',
      'email',
      'phone_number',
      'phone_number_2',
      'idPromoter',
      'role',
      'isActive',
      'poste'
    ]
  },
  companies_list: {
    fields: [
      'last_name',
      'first_name',
      'email',
      'phone_number',
      'phone_number_2',
      'idCompany',
      'role',
      'isActive',
      'poste'
    ]
  },
  workers_list: {
    fields: [
      'last_name',
      'first_name',
      'email',
      'phone_number',
      'phone_number_2',
      'idCompany',
      'role',
      'isActive',
      'poste'
    ]
  },
  inspectors_list: {
    fields: [
      'last_name',
      'first_name',
      'poste',
      'email',
      'subcontracting',
      'phone_number',
      'phone_number_2',
      'role',
      'isActive',
      'status'
    ]
  }
};

export const requiredIsWCard = [
  'intern',
  'artisan',
  'gerant_TNS',
  'auto_entrepreneur',
  'service_providers',
  'subject_mastery',
  'CSPS',
  'other',
  'manager'
];
