import React, { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment';
import { Row, Col, Image, Skeleton, Typography, Tag, List, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import Hemlet from '../../assets/images/icons/hemlet.svg';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

const { Title, Text } = Typography;

const ControlDetails = () => {
  const { id } = useParams();
  const { user } = useAuthContext();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [control, setControl] = useState(null);
  const [userIsInspector, setUserIsInspector] = useState(false);

  const { t } = useTranslation();

  const getControl = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/check-control/${id}?populate=inspector,worksite`
      });
      if (data?.inspector._id === user._id) setUserIsInspector(true);
      setControl(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getControl();
    })();
  }, []);

  return control ? (
    <Skeleton loading={isLoading}>
      <Row className="control-header">
        <Col span={4}>
          <Image src={Hemlet} preview={false} />
        </Col>
        <Col span={18} offset={2}>
          <Title>{control?.worksite?.name}</Title>
          <Text>{control?.reference}</Text>
          <Tag style={{ marginLeft: '20px' }}>
            {t(`controls.tags.${control?.status}`)}
          </Tag>
        </Col>
      </Row>
      <List
        style={{ marginTop: 60, marginBottom: 60 }}
        size="small"
        dataSource={[
          {
            title: 'Date et heure',
            value: moment(control?.date).format('DD/MM/YYYY à HH:mm')
          },
          {
            title: 'Contrôleur',
            value: `${control?.inspector?.first_name} ${control?.inspector?.last_name}`
          },
          {
            title: 'Adresse',
            value: control?.worksite?.address_full
          },
          {
            title: 'Nombre de lot',
            value: control?.worksite?.nbOfBatches
          },
          {
            title: 'Phase du chantier',
            value: (
              <Tag color={control?.worksite?.phase?.color || '-'}>
                {control?.worksite?.phase?.label || '-'}
              </Tag>
            )
          }
        ]}
        renderItem={(elem) => (
          <List.Item>
            <Text className="control-info-label">{elem.title}</Text>
            <Text className="control-info-value">{elem.value}</Text>
          </List.Item>
        )}
      />
      <Col span={24}>
        <Link to={`/control/${control?._id}`}>
          <Button
            block
            type="add"
            className="control-btn"
            disabled={!userIsInspector}
          >
            Accéder au contrôle
          </Button>
        </Link>
      </Col>
    </Skeleton>
  ) : (
    <>
      <Row className="control-header">
        <Col span={4}>
          <Image src={Hemlet} preview={false} />
        </Col>
        <Col span={18} offset={2}>
          <Title>Chantier inconnue</Title>
          <Text>Contrôle inconnue</Text>
        </Col>
      </Row>
      <Row className="control-header">
        <Col span={18} offset={2} style={{ marginTop: 100, marginBottom: 60 }}>
          <Text>Aucun contrôle ce jour sur ce chantier </Text>
        </Col>
        <Col span={24}>
          <Link to="/">
            <Button
              block
              type="add"
              className="control-btn"
              disabled={!userIsInspector}
            >
              Retour
            </Button>
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default ControlDetails;
