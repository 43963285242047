import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Input, Col, Row, Dropdown, Button, Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { ExportButton } from '../ExportButton/ExportButton';
import { ImportButton } from '../importButton';
import { WeeklyCalendar } from './WeeklyCalendar/WeeklyCalendar.tsx';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { MonthAnnualCalendar } from './MonthAnnualCalendar/MonthAnnualCalendar.tsx';

export const Calendar = ({
  modes,
  resourceName,
  resourceModelName,
  extraQuery,
  weekCellRender,
  monthCellRender,
  annualCellRender,
  extraHeader,
  dayRange,
  customRoute,
  populate,
  forceRefresh,
  setForceRefresh,
  hourStart,
  hourEnd,
  ISOWeekName,
  noAllDayRow,
  initialMode,
  withExtraHeader,
  withSearchBar,
  withCreateButton,
  extraButtons,
  withUploadButton,
  withImportButton,
  openDrawer,
  headers,
  exportUrl,
  formatter,
  setOpenModal,
  setDate,
  setEventToUpdate,
  deleteEvent,
  setFirstDayOfWeek,
  selectedUser,
  selectedWorksite,
  firstDayOfWeek,
  resource
}) => {
  const { dispatchAPI } = useAuthContext();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const [events, setEvents] = useState([]);
  const [mode, setMode] = useState(initialMode);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const pageSize = params.get('pS');
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [searchValue, setSearchValue] = useState(keyword);

  const { Search } = Input;

  const searchResource = (value) => {
    if (value) {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}&k=${value}`
      });
    } else {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}`
      });
    }
  };

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  const fetchEvents = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${resourceName}${customRoute ? `/${customRoute}` : ''}${
          extraQuery ? `?${extraQuery}` : ''
        }${
          populate
            ? `${
                extraQuery ? `&populate=${populate}` : `?populate=${populate}`
              }`
            : ''
        }`
      });
      setEvents(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchEvents();
    })();
  }, [forceRefresh]);

  const menu = (
    <Menu>
      {headers && (
        <Menu.Item key="export">
          <ExportButton
            dataName={resourceName}
            headers={headers}
            url={`/${exportUrl || resourceName}`}
            fileName={`${resourceName}.csv`}
            populate={populate}
            extraQuery={extraQuery}
            formatter={formatter}
          />
        </Menu.Item>
      )}
      {withImportButton && (
        <Menu.Item key="import">
          <ImportButton resourceName={resourceModelName} />
        </Menu.Item>
      )}
    </Menu>
  );

  const displayMode = (forceRefreshLoc) => {
    switch (mode) {
      case 'week':
        return (
          <WeeklyCalendar
            modes={modes}
            mode="week"
            setMode={setMode}
            events={events}
            ISOWeekName={ISOWeekName}
            weekCellRender={weekCellRender}
            setOpenModal={setOpenModal}
            setDate={setDate}
            setEventToUpdate={setEventToUpdate}
            deleteEvent={deleteEvent}
            dayRange={dayRange}
            extraHeader={extraHeader}
            hourStart={hourStart}
            hourEnd={hourEnd}
            noAllDayRow={noAllDayRow}
            setFirstDayOfWeek={setFirstDayOfWeek}
            selectedUser={selectedUser}
            selectedWorksite={selectedWorksite}
            firstDayOfWeek={firstDayOfWeek}
            forceRefresh={forceRefreshLoc}
            setForceRefresh={setForceRefresh}
            resource={resource}
          />
        );
      case 'year':
        return (
          <MonthAnnualCalendar
            mode="year"
            cellRender={annualCellRender}
            extraHeader={extraHeader}
            modes={modes}
            setMode={setMode}
            events={events}
            dayRange={dayRange}
            setFirstDayOfWeek={setFirstDayOfWeek}
          />
        );
      default:
        return (
          <MonthAnnualCalendar
            mode="month"
            cellRender={monthCellRender}
            extraHeader={extraHeader}
            modes={modes}
            setMode={setMode}
            events={events}
            dayRange={dayRange}
          />
        );
    }
  };

  return (
    <ContentCustom>
      {withExtraHeader && (
        <Row
          justify="space-between"
          gutter={[8, 8]}
          style={{ marginBottom: 10 }}
        >
          {withSearchBar && (
            <Col>
              <Search
                allowClear
                placeholder={t('placeholder.search')}
                defaultValue={searchValue}
                onSearch={(value) => searchResource(value)}
              />
            </Col>
          )}
          {withCreateButton && (
            <Col span={24}>
              <Row align="middle" justify="start">
                <Col>{extraButtons}</Col>
              </Row>
              <Row align="middle" justify="end">
                <Button type="add" onClick={openDrawer}>
                  {`${t('buttons.create')}`}
                  &nbsp;
                  <PlusOutlined />
                </Button>
                {withUploadButton && (
                  <Dropdown overlay={menu}>
                    <Button type="link">
                      <MenuOutlined
                        style={{ fontSize: 16, color: 'var(--textColor)' }}
                      />
                    </Button>
                  </Dropdown>
                )}
              </Row>
            </Col>
          )}
        </Row>
      )}
      <Card className="calendar-wrapper">{displayMode(forceRefresh)}</Card>
    </ContentCustom>
  );
};

Calendar.propTypes = {
  modes: PropTypes.arrayOf(PropTypes.string).isRequired,
  resourceName: PropTypes.string,
  extraQuery: PropTypes.string,
  extraHeader: PropTypes.func,
  monthCellRender: PropTypes.func,
  weekCellRender: PropTypes.func,
  annualCellRender: PropTypes.func,
  dayRange: PropTypes.string,
  customRoute: PropTypes.string,
  populate: PropTypes.string,
  forceRefresh: PropTypes.bool,
  hourStart: PropTypes.number,
  hourEnd: PropTypes.number,
  noAllDayRow: PropTypes.bool,
  initialMode: PropTypes.string.isRequired,
  ISOWeekName: PropTypes.bool,
  withExtraHeader: PropTypes.bool,
  withSearchBar: PropTypes.bool,
  withCreateButton: PropTypes.bool,
  extraButtons: PropTypes.element,
  withUploadButton: PropTypes.bool,
  withImportButton: PropTypes.bool,
  openDrawer: PropTypes.func,
  headers: PropTypes.arrayOf(PropTypes.shape({})),
  exportUrl: PropTypes.string,
  formatter: PropTypes.func,
  resourceModelName: PropTypes.string,
  setOpenModal: PropTypes.func,
  setDate: PropTypes.func,
  setEventToUpdate: PropTypes.func,
  deleteEvent: PropTypes.func,
  setFirstDayOfWeek: PropTypes.func,
  selectedUser: PropTypes.string,
  selectedWorksite: PropTypes.string,
  firstDayOfWeek: PropTypes.string,
  setForceRefresh: PropTypes.func,
  resource: PropTypes.string.isRequired
};

Calendar.defaultProps = {
  extraQuery: undefined,
  extraHeader: null,
  monthCellRender: null,
  weekCellRender: null,
  annualCellRender: null,
  dayRange: undefined,
  customRoute: undefined,
  populate: undefined,
  forceRefresh: undefined,
  hourStart: undefined,
  hourEnd: undefined,
  noAllDayRow: undefined,
  ISOWeekName: true,
  withExtraHeader: false,
  withSearchBar: false,
  withCreateButton: false,
  extraButtons: null,
  withUploadButton: false,
  withImportButton: true,
  openDrawer: null,
  headers: null,
  exportUrl: null,
  formatter: null,
  resourceModelName: null,
  setOpenModal: null,
  setDate: null,
  setEventToUpdate: null,
  deleteEvent: null,
  setFirstDayOfWeek: null,
  selectedUser: null,
  selectedWorksite: null,
  firstDayOfWeek: null,
  setForceRefresh: null,
  resourceName: null
};
