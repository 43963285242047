/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import IncidentStatusesTable from './incidentStatusesTable';

const initialColumns = [
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: (text) => <Input InitialValue={text} />
  }
];

const IncidentStatusesSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [incidentstatusesCols, setIncidentStatusesCols] =
    useState(initialColumns);
  const newIncidentStatusesCol = {
    label: 'Nouvelle ligne',
    icon: null,
    color: '#008CA0',
    show: true
  };

  const getIncidentStatusesCols = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/incidentstatuses' });
      setIncidentStatusesCols(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addIncidentStatusesCol = async () => {
    try {
      await dispatchAPI('POST', {
        url: '/incidentstatuses',
        body: newIncidentStatusesCol
      });
      getIncidentStatusesCols();
    } catch (e) {
      message.error(e.message);
    }
  };

  const deleteIncidentStatusesCol = async (incidentstatusesCol) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/incidentstatuses/${incidentstatusesCol._id}`
      });
    } catch (e) {
      message.error(e.message);
    }
    getIncidentStatusesCols();
  };

  const editIncidentStatusesCol = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/incidentstatuses/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    getIncidentStatusesCols();
  };
  useEffect(() => {
    getIncidentStatusesCols();
  }, []);

  return (
    <>
      <IncidentStatusesTable
        incidentStatusesCols={incidentstatusesCols}
        editIncidentStatusesCol={editIncidentStatusesCol}
        deleteIncidentStatusesCol={deleteIncidentStatusesCol}
      />
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addIncidentStatusesCol}
      >
        {t('settings.incidentstatusesTable.addincidentstatusescol')}
      </Button>
    </>
  );
};

export default IncidentStatusesSettings;
