export const formDependencies = (value, resource, formValues, form) => {
  try {
    const key = Object.keys(value)[0];

    switch (resource) {
      case 'incidents':
        if (key === 'worksite') {
          form.setFieldsValue({
            ...formValues,
            control: null,
            inspector: null
          });
        }
        if (key === 'control') {
          form.setFieldsValue({
            ...formValues,
            inspector: null
          });
        }
        break;
      default:
        break;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};
