import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Upload, message as antdMessage } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import ListDocuments from '../listDocuments';

const { Dragger } = Upload;

const ControlDocuments = ({ id, control, documents, setDocuments }) => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  const props = {
    multiple: false,
    fileList: documents,
    beforeUpload: (newFile) => {
      setDocuments([newFile]);
      return false;
    },
    onRemove: (file) =>
      setDocuments(documents.filter((f) => f.uid !== file.uid))
  };

  const handlePostDocuments = async () => {
    try {
      const formData = new FormData();
      documents.forEach((doc) =>
        formData.append('documents', doc.originFileObj || doc)
      );

      await dispatchAPI('POST', {
        url: `/controls/upload/${id}`,
        body: formData
      });
      antdMessage.success(` document(s) téléchargé(s)`);
      window.location.reload();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} lg={8} xxl={6} style={{ height: 'fit-content' }}>
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Glisser / Déposer</p>
        </Dragger>
        {documents && (
          <Button type="primary" onClick={handlePostDocuments}>
            Enregistrer
          </Button>
        )}
      </Col>
      <Col xs={24} lg={16} xxl={18}>
        <ListDocuments data={control} />
      </Col>
    </Row>
  );
};

export default ControlDocuments;

ControlDocuments.propTypes = {
  id: PropTypes.string.isRequired,
  control: PropTypes.shape({}),
  documents: PropTypes.instanceOf(Array),
  setDocuments: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired
};

ControlDocuments.defaultProps = {
  control: {},
  documents: []
};
