export const userRoles = {
  admins: { ADMIN: 'red', 'SUPER-ADMIN': 'magenta' },
  promoters: { 'USER-PROMOTER': 'blue', 'ADMIN-PROMOTER': 'red' },
  companies: { 'USER-COMPANY': 'blue', 'ADMIN-COMPANY': 'red' },
  inspectors: { INSPECTOR: 'purple' },
  workers: { WORKER: 'blue' }
  // GUEST: 'green',
  // USER: 'blue',
  // 'SUPER-USER': 'geekblue',
  // ADMIN: 'red',
  // 'SUPER-ADMIN': 'magenta'
};

export const userPoste = {
  employee: 'blue',
  employee_cdd: 'purple',
  employee_cdi: 'blue',
  manager: 'orange',
  interim: 'yellow',
  entrepreneur: 'green',
  intern: 'brown'
};

export const userActivity = {
  ACTIVE: 'green',
  INACTIVE: 'red'
};

export const pendingTasksCat = {
  POST: 'green',
  PATCH: 'blue',
  DELETE: 'red'
};

export const docStatus = {
  MISSING: 'purple',
  PENDING: 'orange',
  ACCEPTED: 'green',
  REFUSED: 'red'
};

export const colorsRD = [
  { name: 'Orange', value: '#ff7a45' },
  { name: 'Rouge', value: '#ff4d4f' },
  { name: 'Jeaune', value: '#ffd666' },
  { name: 'Vert', value: '#95de64' },
  { name: 'Violet', value: '#b37feb' },
  { name: 'Rose', value: '#ff85c0' },
  { name: 'Volcano', value: '#ff9c6e' },
  { name: 'Gold', value: '#ffd700' },
  { name: 'Lime', value: '#a9d70b' },
  { name: 'Magenta', value: '#eb2f96' },
  { name: 'Gris', value: '#bfbfbf' },
  { name: 'Turquoise', value: '#40E0D0' }
];

export const historyColors = {
  MISSING: 'purple',
  PENDING: 'orange',
  ACCEPTED: 'green',
  REFUSED: 'red',
  FIRST_REMINDER: '#ff3333',
  SECOND_REMINDER: '#FF0000',
  THIRD_REMINDER: '#8B0000'
};

export const closureStatusColor = {
  PENDING: '#95de64',
  MAIL_REPORT: '#ff3333',
  FIRST_REMINDER: '#FF0000',
  RECEIPT_REMINDER: '#8B0000'
};

export const activeStatus = {
  true: 'green',
  false: 'red'
};
