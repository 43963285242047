import React from 'react';
import { Button, Row, Col } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { addWeeks, startOfWeek } from 'date-fns';

import { CalendarHeaderProps } from './types.tsx';
import { useTranslation } from 'react-i18next';
import { MonthName } from './MonthName.tsx';
import { SwitchModes } from '../SwitchModes.tsx';

export const CalendarHeader: React.FunctionComponent<CalendarHeaderProps> = ({
  startWeek,
  setStartWeek,
  ISOWeekName,
  modes,
  mode,
  setMode,
  extraHeader
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Row justify="space-between" style={{ margin: '0 0 12px' }}>
        <Col>
          <SwitchModes modes={modes} mode={mode} setMode={setMode} />
        </Col>
        <Col>{extraHeader}</Col>
        <Col
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div style={{ display: 'flex', marginRight: '20px' }}>
            <Button onClick={() => setStartWeek(startOfWeek(new Date()))}>
              {t('calendar.buttons.today')}
            </Button>
            <div style={{ display: 'flex', padding: '0 8px' }}>
              <Button
                style={{ margin: '0 4px' }}
                onClick={() => setStartWeek(addWeeks(startWeek, -1))}
              >
                <LeftOutlined />
              </Button>
              <Button
                style={{ margin: '0 4px' }}
                onClick={() => setStartWeek(addWeeks(startWeek, 1))}
              >
                <RightOutlined />
              </Button>
            </div>
          </div>
          <div style={{ alignSelf: 'center' }}>
            <MonthName
              startWeek={startWeek}
              ISOWeekName={ISOWeekName}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
