import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import { t } from 'i18next';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { useFormatter } from '../../utils/formatter';
import { useAuthContext } from '../../contexts/AuthContext';

const ListWorkers = ({
  headless,
  type,
  customResources,
  extraQuery,
  extraQueryCreate
}) => {
  const { user } = useAuthContext();
  const [displayArchive, setDisplayArchive] = useState(false);
  const populate = 'idCompany';
  const { columns, headers } = useColumns(type, false);
  const { formattedData } = useFormatter();
  const isAdmin = ['admins:SUPER-ADMIN', 'admins:ADMIN'].includes(user?.role);
  const isCompanyAdmin = user?.role === 'companies:ADMIN-COMPANY';

  return (
    <ListResource
      resourceName="workers"
      extraQuery={`${extraQuery}${
        displayArchive ? '&isActive=false' : '&isActive=true'
      }`}
      columns={columns}
      headers={headers}
      editAction={isCompanyAdmin || (isAdmin && !headless)}
      deleteAction={isAdmin && !displayArchive}
      reintegrate={isAdmin && displayArchive}
      withCreateButton={isAdmin || (isCompanyAdmin && !headless)}
      extraQueryCreate={extraQueryCreate || null}
      resourceModelName="Worker"
      customResources={customResources && customResources}
      headless={headless}
      populate={populate}
      extraTitle={type}
      formatter={formattedData}
      multipleSelection={isAdmin && true}
      path={`/${type}`}
      extraButtons={
        isAdmin && (
          <Switch
            onChange={() => setDisplayArchive(!displayArchive)}
            checkedChildren="Archivés"
            unCheckedChildren={t('users.switch')}
            style={{ marginRight: '20px' }}
          />
        )
      }
    />
  );
};

ListWorkers.propTypes = {
  headless: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  customResources: PropTypes.instanceOf(Array),
  extraQuery: PropTypes.string,
  extraQueryCreate: PropTypes.string
};
ListWorkers.defaultProps = {
  customResources: null,
  extraQuery: '',
  extraQueryCreate: null
};

export default ListWorkers;
