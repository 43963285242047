import React, { useCallback, useEffect, useState } from 'react';
import { Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useAuthContext } from '../../contexts/AuthContext';

const { Option } = Select;
const { RangePicker } = DatePicker;

const useFields = (users) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [worksites, setWorksites] = useState([]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/worksite'
      });
      setWorksites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const onsearchInspector = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const onsearchWorksite = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const fields = [
    {
      name: ['inspector'],
      rules: [{ required: true }],
      input: (
        <Select
          style={{ width: '300px' }}
          placeholder={t('calendar.placeholder.users')}
          showSearch
          loading={isFieldsLoading}
          filterOption={(input, option) => onsearchInspector(input, option)}
        >
          {(users || []).map((u) => (
            <Option key={u._id} value={u._id}>
              {`${u.first_name} ${u.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['worksite'],
      rules: [{ required: true }],
      input: (
        <Select
          style={{ width: '300px' }}
          showSearch
          loading={isFieldsLoading}
          filterOption={(input, option) => onsearchWorksite(input, option)}
        >
          {(worksites || []).map((worksite) => (
            <Option key={worksite._id} value={worksite._id}>
              {worksite.name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['period_start'],
      rules: [{ required: true }],
      tooltip: true,
      input: <RangePicker format="DD-MM-YYYY" picker="week" />
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
