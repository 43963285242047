import React, { useState, useEffect, useRef } from 'react';
import { Input, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import ActionButtons from './ActionButtons';
import WorkerDetails from './WorkerDetails';

export const ControlQRCodeTera = ({ setNavDisplay, mode }) => {
  const navigate = useNavigate();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const [controlState, setControlState] = useState(false);
  const [dataCard, setDataCard] = useState('');
  const [qrCodeScanned, setQrCodeScanned] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinLoading, setIsSpinLoading] = useState(false);
  const card = localStorage.getItem('invalid_card');
  const inputRef = useRef(null);

  const getAPIBTPValidation = async (hash) => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: `/btp-cards/${hash}` });
      return data;
    } catch (e) {
      message(e.toString());
      return {};
    } finally {
      setIsLoading(false);
    }
  };

  const resolveQRCodeScan = async () => {
    try {
      if (qrCodeScanned && !isLoading) {
        const hash = qrCodeScanned.slice(-64);
        const data = await getAPIBTPValidation(hash);
        setIsSpinLoading(false);
        if (Object.keys(data).length) {
          if (data && !data.indValide) {
            localStorage.setItem('invalid_card', JSON.stringify(data));
            message('Invalid card');
          }
          setDataCard(data);
          setControlState(true);
        } else {
          message('Unknown card');
        }
      }
    } catch (e) {
      message(e);
    }
    setIsSpinLoading(false);
  };

  const goBack = () => {
    setNavDisplay('none');
    navigate(-1);
  };

  useEffect(() => {
    setNavDisplay('none');
    if (card) {
      const cardData = JSON.parse(card);
      navigate(
        `/incidents/create/${id}/?cardNumber=${cardData.identifiantCarte}`
      );
    }
  }, [card, id, navigate, setNavDisplay]);

  useEffect(() => {
    setIsSpinLoading(true);
    const timeoutId = setTimeout(() => {
      resolveQRCodeScan();
    }, 100);
    return () => clearTimeout(timeoutId);
  }, [qrCodeScanned]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return !controlState && !card ? (
    <>
      <Spin
        spinning={isSpinLoading}
        size="large"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%'
        }}
      />
      <Input
        ref={inputRef}
        onChange={(event) => setQrCodeScanned(event.target.value)}
        style={{
          position: 'absolute',
          left: '-9999px'
        }}
      />
      <div
        style={{
          position: 'relative',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        Veuillez scanner la carte BTP
      </div>
      <ActionButtons mode={mode} goBack={goBack} />
    </>
  ) : (
    <WorkerDetails dataCard={dataCard || JSON.parse(card || '{}')} />
  );
};

ControlQRCodeTera.propTypes = {
  setNavDisplay: PropTypes.func.isRequired,
  mode: PropTypes.string
};

ControlQRCodeTera.defaultProps = {
  mode: null
};
