import { useTranslation } from 'react-i18next';

export const useColumns = () => {
  const { t } = useTranslation();

  const headers = [
    {
      label: 'name',
      key: 'name'
    },
    {
      label: 'siret_number',
      key: 'siret_number'
    },
    {
      label: 'vat_number',
      key: 'vat_number'
    },
    {
      label: 'Address_street_number',
      key: 'address.number'
    },
    {
      label: 'Address_street_name',
      key: 'address.street'
    },
    {
      label: 'Address_additional_information',
      key: 'address.additional'
    },
    {
      label: 'Address_postal_code',
      key: 'address.postal_code'
    },
    {
      label: 'Address_city',
      key: 'address.city'
    },
    {
      label: 'Address_country',
      key: 'address.country'
    },
    {
      label: 'contact_first_name',
      key: `contact.first_name`
    },
    {
      label: 'contact_last_name',
      key: `contact.last_name`
    },
    {
      label: 'contact_mail',
      key: `contact.email`
    }
  ];

  const columns = [
    {
      title: t('promoters.form.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('promoters.form.siret_number'),
      key: 'siret_number',
      dataIndex: 'siret_number',
      sorter: true,
      width: 180
    },
    {
      title: t('promoters.form.vat_number'),
      key: 'vat_number',
      dataIndex: 'vat_number',
      sorter: true,
      width: 100
    },
    {
      title: t('promoters.form.address_full'),
      key: 'address',
      dataIndex: 'address',
      render: (address) =>
        address
          ? `${address.number ? address.number : ''} ${
              address.street ? address.street : ''
            } ${address.postal_code ? address.postal_code : ''} ${
              address.city ? address.city : '-'
            }`
          : '-'
    },
    {
      title: t('promoters.form.contact'),
      key: 'contact',
      dataIndex: 'contact',
      render: (contact) =>
        contact
          ? `${contact.first_name || '-'} ${contact.last_name || '-'} ( ${
              contact.email || '-'
            } ) `
          : '-',
      sorter: true
    }
  ];

  return { headers, columns };
};
