import { createGlobalStyle } from 'styled-components';
import '../SCSS/inspector/index.scss';

export const InspectorTheme = createGlobalStyle`
  body {
    --semiBold: 700;
    --clientColor: #FFFFFF;
    --primaryColor: #008CA0;
    --addColor: #36B395;
    --bgLight-1: #FFFFFF;
    --bgLight-2: #F9FBFB;
    --bgLight-3: #E6F2F4;
    --bgLight-4: #EBF8FA;
    --itemHoverColor: var(--primaryColor);
    --componentBackground: #fff;
    --secondaryHover: var(--componentBackground);
    --subMenuBackground: var(--componentBackground);
    --menuDropdownBackground: var(--componentBackground);
    --pickerDropdown: var(--componentBackground);
    --primaryHover:  #DCF5F9;
    --secondaryColor: #40A9B8;
    --disabledColor: #bfbfbf;
    --success: #36B395;
    --danger: #B23E52;
    --bodyBackground: #F9FBFB;
    --grey: #E6EDF2;
    --textBlack: #262626;
    --textGrey: #6A7A7C;
    --textMuted: #B8B8B8;
    --textColor: var(--textBlack);
    --textColorTest: red;
    --textColorSecondary: #b8b8b8;
    --itemActiveBackground: #DCF5F9;
    --itemHoverBackground: #f0f0f0;
    --borderColor: #d9d9d9;
    --contrastBackground: #f7f7f7;
    --opacitySVG: 1;
    --errorColor: #B23E52;
    --primaryColorA04
    --primaryColorA07
    --primaryContrast
    --tabsGray
    --textColorInvert
    --textColorHover
    --textColorA015
    --textColorA075
    --borderColorA06
    --disabledColorA04
    --disabledColorA06
    --itemActiveColor
    --logo
    --logoSmall
    --error100
    --errorA08
  }
`;
