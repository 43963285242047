export const headers = [
  {
    label: 'user',
    key: 'user.first_name'
  },
  {
    label: 'user',
    key: 'user.last_name'
  },
  {
    label: 'type',
    key: 'type'
  },
  {
    label: 'startTime',
    key: 'startTime'
  },
  {
    label: 'endTime',
    key: 'endTime'
  },
  {
    label: 'date',
    key: 'date'
  },
  {
    label: 'worksite',
    key: 'worksite.name'
  },
  {
    label: 'comment',
    key: 'comment'
  }
];
