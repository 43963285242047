import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListWorksites from './ListWorksites';
import CreateUpdateWorksite from './CreateUpdateWorksite/CreateUpdateWorksite';
import { MapWrapper } from './map';
import ShowWorksite from './ShowWorksite/ShowWorksite';
import { useAuthContext } from '../../contexts/AuthContext';

export const WorksiteRouter = () => {
  const { user } = useAuthContext();
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';
  return (
    <Routes>
      <Route path="/" element={<ListWorksites />} />
      {isAdmin && (
        <>
          <Route
            path="/create"
            element={<CreateUpdateWorksite purpose="create" />}
          />
          <Route
            path="/edit/:id"
            element={<CreateUpdateWorksite purpose="edit" />}
          />
        </>
      )}

      <Route path="/show/:id" element={<ShowWorksite />} />
      <Route path="/map" element={<MapWrapper />} />
    </Routes>
  );
};
