import React from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ListResource } from '../../../components/ListResource/ListResource';
import { useColumns } from '../../incidents/columns';

const ControlIncidents = ({ control, idControl }) => {
  const { headers, columns } = useColumns();
  const { user } = useAuthContext();
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';

  return (
    <ListResource
      headless
      resourceName="incidents"
      columns={columns}
      headers={headers}
      deleteAction={isAdmin}
      editAction={isAdmin}
      withCreateButton={isAdmin}
      resourceModelName="Incident"
      populate="worksite,inspector,worker,company,type,status"
      extraQuery={`control=${idControl}`}
      extraQueryCreate={`?controlId=${idControl}&worksiteId=${control?.worksite._id}&inspectorId=${control?.inspector?._id}`}
      path="/incidents"
    />
  );
};

ControlIncidents.propTypes = {
  idControl: PropTypes.string.isRequired,
  control: PropTypes.shape({
    _id: PropTypes.string,
    worksite: PropTypes.shape({
      _id: PropTypes.string
    }),
    inspector: PropTypes.shape({
      _id: PropTypes.string
    })
  }).isRequired
};

export default ControlIncidents;
