import { useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowLeftOutlined, CameraOutlined } from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import { Button, message as antdMessage, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';

const { TextArea } = Input;

const WorksiteClosed = ({ mode }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const [documents, setDocuments] = useState([]);
  const [comment, setComment] = useState();
  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  const postWorksiteClosed = async () => {
    try {
      setButtonIsLoading(true);
      if (documents.length) {
        const formData = new FormData();
        documents.forEach((doc) =>
          formData.append('documents', doc.originFileObj || doc)
        );
        formData.append('comment', JSON.stringify(comment || 'Aucun'));
        await dispatchAPI('POST', {
          url: `/controls/closed/${id}`,
          body: formData
        });
        antdMessage.success(t('controls.messages.succes_upload'));
        navigate(`/control${mode === 'desktop' ? 's' : ''}/${id}`);
      } else antdMessage.error(t('controls.messages.join_proof'));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setButtonIsLoading(false);
  };

  const props = {
    multiple: true,
    fileList: documents,
    beforeUpload: (newFile) => {
      const newArray = [...documents];
      newArray.push(newFile);
      setDocuments(newArray);
      return false;
    },
    onRemove: (file) =>
      setDocuments(documents.filter((f) => f.uid !== file.uid))
  };

  return (
    <ContentCustom>
      <div className="worksite-closed-form">
        <Row align="middle">
          <Button
            onClick={() => navigate(-1)}
            type="link"
            icon={<ArrowLeftOutlined />}
            className="back-btn"
          />
          <h2>{t('controls.mobile.title.worksite_closed')}</h2>
        </Row>
        <Dragger {...props} height={200}>
          <p className="ant-upload-drag-icon">
            <CameraOutlined />
          </p>
          <p className="ant-upload-text">Prendre une photo</p>
        </Dragger>
        <TextArea
          rows={2}
          style={{ marginTop: 30 }}
          placeholder="Votre commentaire..."
          onChange={(event) => setComment(event.target.value)}
        />
        <div
          style={{
            marginTop: 30,
            display: 'flex',
            justifyContent: 'center',
            gap: 8
          }}
        >
          <Button
            type="primary"
            loading={buttonIsLoading}
            onClick={postWorksiteClosed}
            disabled={buttonIsLoading}
          >
            {t('controls.mobile.button.confirm')}
          </Button>
        </div>
      </div>
    </ContentCustom>
  );
};

export default WorksiteClosed;

WorksiteClosed.propTypes = {
  mode: PropTypes.string
};

WorksiteClosed.defaultProps = {
  mode: null
};
