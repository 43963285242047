import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { ListResource } from '../../components';
import { useColumns } from './columns';

const ListComments = ({ headless, path, fromMobile }) => {
  const { headers, columns } = useColumns();

  const { idControl } = useParams();

  return (
    <ListResource
      headless={headless}
      resourceName="comments"
      columns={columns}
      headers={headers}
      resourceModelName="Comment"
      path={path}
      withCreateButton={!fromMobile}
      withUploadButton={!fromMobile}
      extraQuery={fromMobile && `control=${idControl}`}
    />
  );
};

ListComments.propTypes = {
  headless: PropTypes.bool,
  path: PropTypes.string,
  fromMobile: PropTypes.bool
};
ListComments.defaultProps = {
  headless: false,
  path: null,
  fromMobile: false
};

export default ListComments;
