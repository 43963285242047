import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
  Button,
  Popconfirm,
  Skeleton,
  Row,
  Tabs,
  Col,
  Typography,
  Modal,
  Input,
  Card,
  Upload,
  message as antdMessage
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  InboxOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import listContent from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import CompanyWorksites from './CompanyWorksites';
import CompanyUsers from './CompanyUsers';
import ListDocuments from './listDocuments';
import CompanyWorkers from './CompanyWorkers';

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Dragger } = Upload;

const CommentModal = ({ isModalOpen, setIsModalOpen, company, setRefresh }) => {
  const { dispatchAPI } = useAuthContext();
  const [comment, setComment] = useState(company?.comment);

  const handleOk = async () => {
    setRefresh(true);
    await dispatchAPI('PATCH', {
      url: `/companies/${company._id}`,
      body: {
        comment
      }
    });
    setRefresh(false);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setComment(company?.comment);
  }, [company]);

  return (
    <Modal
      title="Notes sur l'entreprise"
      onCancel={handleCancel}
      open={isModalOpen}
      footer={[
        <Button key="back" danger type="link" onClick={handleCancel}>
          Annuler
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Enregistrer
        </Button>
      ]}
    >
      <TextArea
        rows={8}
        value={comment}
        onChange={(event) => setComment(event.target.value)}
      />
    </Modal>
  );
};

const ShowCompany = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [documents, setDocuments] = useState(null);
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';

  const showModal = () => {
    setIsModalOpen(true);
  };

  const getCompany = useCallback(async () => {
    setIsLoading(true);
    try {
      const populate = 'contact,documents,business_body';
      const { data } = await dispatchAPI('GET', {
        url: `/companies/${id}?populate=${populate}`
      });
      setCompany(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getCompany();
    })();
  }, [refresh]);

  const deleteCompany = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/companies/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const props = {
    multiple: false,
    fileList: documents,
    beforeUpload: (newFile) => {
      setDocuments([newFile]);
      return false;
    },
    onRemove: (file) =>
      setDocuments(documents.filter((f) => f.uid !== file.uid))
  };

  const handlePostDocuments = async () => {
    try {
      const formData = new FormData();
      documents.forEach((doc) =>
        formData.append('documents', doc.originFileObj || doc)
      );
      await dispatchAPI('POST', {
        url: `/companies/upload/${id}`,
        body: formData
      });
      antdMessage.success(` document(s) téléchargé(s)`);
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('companies.show.title')}
        extra={
          <>
            {isAdmin && (
              <Link to={{ pathname: `${routes.COMPANIES}/edit/${id}` }}>
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
            )}
            {isAdmin && (
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={deleteCompany}
                icon={<WarningOutlined />}
              >
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            )}
          </>
        }
      />
      <ContentCustom>
        <Row gutter={[24, 24]}>
          <Col xs={24} xl={16}>
            <Card title="Informations" bordered={false}>
              <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                <DescriptionList
                  data={listContent(company || {}, t)}
                  translate
                />
              </Skeleton>
            </Card>
          </Col>
          <Col xs={24} xl={8}>
            <Card
              title="Commentaire"
              bordered={false}
              extra={
                <Button type="link" onClick={showModal}>
                  <EditOutlined />
                </Button>
              }
            >
              {company?.comment ? (
                company?.comment
              ) : (
                <Typography.Text italic>
                  Aucun commentaire pour le moment
                </Typography.Text>
              )}
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: 24 }}>
          <Col xs={24}>
            <Card bordered={false}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Chantiers" key="1">
                  <CompanyWorksites idCompany={id} />
                </TabPane>
                <TabPane tab="Utilisateurs" key="2">
                  <CompanyUsers idCompany={id} type="companies" />
                </TabPane>
                <TabPane tab="Travailleurs" key="3">
                  <CompanyWorkers idCompany={id} type="workers" />
                </TabPane>
                <TabPane tab="Documents" key="4">
                  <Row gutter={[16, 16]}>
                    <Col
                      xs={24}
                      lg={8}
                      xxl={6}
                      style={{ height: 'fit-content' }}
                    >
                      <div>
                        <Dragger {...props}>
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">Glisser / Déposer</p>
                        </Dragger>
                        {documents && (
                          <Button type="primary" onClick={handlePostDocuments}>
                            Enregistrer
                          </Button>
                        )}
                      </div>
                    </Col>
                    <Col xs={24} lg={16} xxl={18}>
                      <ListDocuments data={company} />
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </ContentCustom>
      <CommentModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        company={company}
        setRefresh={setRefresh}
      />
    </>
  );
};

CommentModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  company: PropTypes.shape({
    comment: PropTypes.string,
    _id: PropTypes.string
  }),
  setRefresh: PropTypes.func.isRequired
};

CommentModal.defaultProps = {
  company: undefined
};

export default ShowCompany;
