import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';

const EventDrawer = ({
  showDrawer,
  setShowDrawer,
  users,
  purpose,
  closeOverlay,
  idFromOverlay
}) => {
  const { fields, isFieldsLoading } = useFields(users);

  const config = {
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        startTime: moment(
          moment(data.date).set({
            hour: moment(data.startTime).get('hour'),
            minute: moment(data.startTime).get('minute'),
            second: 0,
            millisecond: 0
          })
        ),
        endTime: moment(
          moment(data.date).set({
            hour: moment(data.endTime).get('hour'),
            minute: moment(data.endTime).get('minute'),
            second: 0,
            millisecond: 0
          })
        )
      })
    }
  };

  return (
    <Drawer
      centered
      open={showDrawer}
      onClose={() => {
        setShowDrawer(false);
      }}
      width={700}
      footer={false}
    >
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="events"
        resource="events"
        isOverlay
        customNavigate={closeOverlay}
        idFromOverlay={idFromOverlay}
        config={config}
      />
    </Drawer>
  );
};

EventDrawer.propTypes = {
  showDrawer: PropTypes.bool.isRequired,
  setShowDrawer: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  purpose: PropTypes.string,
  closeOverlay: PropTypes.func.isRequired,
  idFromOverlay: PropTypes.string
};

EventDrawer.defaultProps = {
  users: null,
  purpose: null,
  idFromOverlay: null
};

export default EventDrawer;
