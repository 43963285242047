import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { message as antdMessage, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

const MobileContext = createContext({});

/**
 * Context provider component for managing mobile data.
 * This context provides various state values and methods related to mobile.
 *
 * @component
 * @param {object} props - React component props
 * @param {React.ReactNode} props.children - The child components that will have access to the context
 * @returns {React.ReactNode} Wrapped children with the contract context
 */

export const MobileContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [control, setControl] = useState(null);
  const [controlState, setControlState] = useState(false);
  const [userData, setUserData] = useState(null);
  const [controlStarted, setControlStarted] = useState(false);
  const [worksite, setWorksite] = useState(null);
  const [incidentsOfWorsite, setIncidentsOfWorksite] = useState([]);
  const [documents, setDocuments] = useState(null);
  const [incidents, setIncidents] = useState([]);
  const [comments, setComments] = useState([]);
  const [companies, setCompanies] = useState(null);
  const [batchTitles, setBatchTitles] = useState(false);
  const [artisanCheck, setArtisancheck] = useState(false);
  const [managerCheck, setManagerCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getIncidents = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/incidents/?control=${id}&populate=worker,type,status`
      });
      setIncidents(data);
    } catch (e) {
      message(e);
    }
  };

  const getComments = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/comments/?control=${id}`
      });
      setComments(data);
    } catch (e) {
      message(e);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/companies?populate=contact,business_body'
      });
      setCompanies(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getBatchTitles = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/batchTitles'
      });
      setBatchTitles(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getIncidentsOfWorksite = async (worksiteId, idControl) => {
    try {
      if (worksiteId) {
        const { data } = await dispatchAPI('GET', {
          url: `/incidents/?worksite=${worksiteId}&control!=${idControl}&populate=worker,type,status`
        });
        setIncidentsOfWorksite(data);
      }
    } catch (e) {
      message(e);
    }
  };

  const getWorksite = async (worksiteId, idControl) => {
    try {
      const dataWorksite = await dispatchAPI('GET', {
        url: `/worksites/${worksiteId}?populate=idPromoter,phase,referents,type,${'companies.company'}`
      });
      setWorksite(dataWorksite.data);

      getIncidentsOfWorksite(dataWorksite.data._id, idControl);
    } catch (e) {
      message(e);
    }
  };

  const handlePostDocuments = async (id) => {
    try {
      const formData = new FormData();
      documents.forEach((doc) =>
        formData.append('documents', doc.originFileObj || doc)
      );

      await dispatchAPI('POST', {
        url: `/controls/upload/${id}`,
        body: formData
      });
      antdMessage.success(` document(s) téléchargé(s)`);
      window.location.reload();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const props = {
    multiple: false,
    fileList: documents,
    beforeUpload: (newFile) => {
      setDocuments([newFile]);
      return false;
    },
    onRemove: (file) =>
      setDocuments(documents.filter((f) => f.uid !== file.uid))
  };

  const downloadDocument = async (
    { _id, metadata: { originalName }, contentType },
    action
  ) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files/${_id}`,
        responseType: 'blob'
      });
      const blob = new Blob([data], { type: contentType });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      if (originalName.includes('.pdf') || action === 'show') {
        a.target = '_blank';
        a.click();
      } else {
        a.download = originalName;
        a.click();
        notification.success({ message: t('messages.export-success') });
      }
    } catch (e) {
      message(e);
    }
  };

  const startControl = async (id, user) => {
    if (control.status !== 'CLOSED') {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/controls?inspector=${user._id}&status=IN_PROGRESS`
        });
        if (!data.length) {
          const result = await dispatchAPI('PATCH', {
            url: `/controls/${id}`,
            body: {
              status: 'IN_PROGRESS',
              date: moment().format('YYYY/MM/DD UTC'),
              start_time: moment(),
              end_time: moment().add(1, 'hours')
            }
          });
          setControl({ ...control, status: result.data.status });
          setControlStarted(true);
        } else {
          notification.error({
            message: t('controls.messages.control_already_open'),
            description: data[0].reference
          });
        }
      } catch (e) {
        message(e);
      }
    } else {
      notification.error({
        message: 'Erreur',
        description: 'On ne peut pas relancer un contrôle terminé'
      });
    }
  };

  const closeControl = async (id) => {
    try {
      let result = null;
      if (worksite?.isSecurityActive) {
        if (control?.isSecurityCheck === true) {
          const newBody = { status: 'DONE', end_time: moment() };
          result = await dispatchAPI('PATCH', {
            url: `/controls/${id}`,
            body: newBody
          });
          setControl({ ...control, status: result.data.status });
          setControlStarted(false);
          notification.success({
            message: 'Contrôle terminé',
            description: ''
          });
        } else
          notification.warning({
            message: t('controls.messages.security_check')
          });
      } else {
        const newBody = { status: 'DONE', end_time: moment() };
        result = await dispatchAPI('PATCH', {
          url: `/controls/${id}`,
          body: newBody
        });
        setControl({ ...control, status: result.data.status });
        setControlStarted(false);
        notification.success({
          message: 'Contrôle terminé',
          description: ''
        });
      }
    } catch (e) {
      message(e);
    }
  };

  const isWorkermultipleIncident = (incident) => {
    if (incident.worker) {
      if (
        incidentsOfWorsite.find(
          (i) => i.worker?._id === incident.worker._id
        ) !== undefined
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const customNav = () => {
    setControlState(false);
    navigate(-1);
  };

  useEffect(() => {
    (async () => {})();
  }, []);

  return (
    <MobileContext.Provider
      value={{
        control,
        setControl,
        controlStarted,
        setControlStarted,
        getWorksite,
        worksite,
        incidentsOfWorsite,
        handlePostDocuments,
        props,
        documents,
        closeControl,
        startControl,
        getIncidents,
        incidents,
        getComments,
        comments,
        getCompanies,
        companies,
        getBatchTitles,
        batchTitles,
        isWorkermultipleIncident,
        controlState,
        setControlState,
        managerCheck,
        setManagerCheck,
        artisanCheck,
        setArtisancheck,
        userData,
        setUserData,
        isLoading,
        setIsLoading,
        downloadDocument,
        customNav
      }}
    >
      {children}
    </MobileContext.Provider>
  );
};

MobileContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(MobileContext);
