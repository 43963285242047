import React from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import Logo from '../assets/images/Quietus-logo.png';

export const useErrorMessage = () => {
  const { t } = useTranslation();
  return {
    message: (
      status = '404',
      duration = 4.5,
      key = undefined,
      notificationType = 'error'
    ) => {
      const s = status?.response?.status || status;
      const regex = /{([^:]+):/;
      const duplicateKey =
        status?.response?.data?.errors?.email?.message?.match(regex) &&
        status?.response?.data?.errors?.email?.message?.match(regex)[1]?.trim();

      if (duplicateKey) {
        notification[notificationType]({
          message: t(`errors.message.duplicate.${duplicateKey}`),
          key: key || 'network_feedback',
          duration
        });
      } else if (s !== 498) {
        notification[notificationType]({
          message: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {t(`errors.message.${s}`)}
              <img
                alt="Logo"
                src={Logo}
                style={{
                  maxWidth: '100px',
                  maxHeight: '100px',
                  marginRight: '16px'
                }}
              />
            </div>
          ),
          key: key || 'network_feedback',
          duration
        });
      }
    }
  };
};
