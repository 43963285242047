import React from 'react';
import { Button, Popconfirm, Tag } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import moment from 'moment';

const translations = {
  ABSENCE: 'Absence',
  PAID_VACATION: 'Congé payé',
  CONTROL: 'Contrôle',
  CREATED: 'CRÉÉ',
  SUSPENDED: 'SUSPENDU',
  PLANNED: 'PLANIFIÉ',
  DONE: 'TERMINÉ',
  IN_PROGRESS: 'EN COURS'
};

export const weeklyPlanningContent = (
  { _id, startTime, endTime, type, comment, status, worksite },
  handleButtonClick,
  deleteEvent
) => [
  {
    content: (
      <span className="event-timeslot">{`${moment(startTime)
        .format('HH:mm')
        .replace(':', 'h')} - ${moment(endTime)
        .format('HH:mm')
        .replace(':', 'h')}`}</span>
    ),
    span: 18
  },
  {
    content: (
      <span className="event-duration">
        {moment
          .utc(moment(endTime).diff(moment(startTime), 'minutes', true) * 60000)
          .format('HH:mm')
          .replace(':', 'h')}
      </span>
    ),
    span: 6
  },
  {
    content: type && <span className="event-type">{translations[type]}</span>,
    span: 24
  },
  {
    content: <p className="event-comment">{comment}</p>,
    span: 24
  },
  ...(type === 'CONTROL'
    ? [
        {
          content: status && translations[status],
          span: 12
        },
        {
          content: <Tag color={worksite?.color}>{worksite?.name}</Tag>,
          span: 12
        },
        {
          content: (
            <Button
              onClick={handleButtonClick}
              type="link"
              className="event-btn first"
            >
              <EditOutlined />
            </Button>
          )
        },
        {
          content: (
            <Popconfirm
              title="Voulez-vous vraiment supprimer cet évènement ?"
              okText="Valider"
              okButtonProps={{ type: 'danger' }}
              cancelText="Annuler"
              onConfirm={() => deleteEvent(_id)}
              icon={<WarningOutlined />}
            >
              <Button type="link" className="event-btn last">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          )
        }
      ]
    : [
        {
          content: status && translations[status],
          span: 24
        }
      ])
];
