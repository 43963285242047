import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Switch } from 'antd';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { useFormatter } from '../../utils/formatter';
import { useAuthContext } from '../../contexts/AuthContext';

const ListIncidents = ({ headless, path, fromMobile, extraQuery }) => {
  const { headers, columns } = useColumns();
  const [displayClosed, setDisplayClosed] = useState(false);
  const { idControl } = useParams();
  const { user } = useAuthContext();
  const { formattedData } = useFormatter();
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';

  return (
    <ListResource
      headless={headless}
      resourceName="incidents"
      columns={columns}
      headers={headers}
      resourceModelName="Incident"
      populate="worksite,inspector,worker,company,type,status"
      path={path}
      formatter={formattedData}
      withCreateButton={!fromMobile && isAdmin}
      withUploadButton={!fromMobile}
      deleteAction={isAdmin}
      editAction={isAdmin}
      scroll={{ x: 1600 }}
      extraQuery={`${extraQuery || (fromMobile && `control=${idControl}`)}${
        displayClosed
          ? `&status=634c60a28d00c8c79e6b8aba`
          : `&status!=634c60a28d00c8c79e6b8aba`
      }`}
      extraButtons={
        <Switch
          onChange={() => setDisplayClosed(!displayClosed)}
          checkedChildren="Clos"
          unCheckedChildren="Clos"
          style={{ marginRight: '20px' }}
        />
      }
    />
  );
};

ListIncidents.propTypes = {
  headless: PropTypes.bool,
  path: PropTypes.string,
  fromMobile: PropTypes.bool,
  extraQuery: PropTypes.string
};
ListIncidents.defaultProps = {
  headless: false,
  path: null,
  fromMobile: false,
  extraQuery: null
};

export default ListIncidents;
