import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Form,
  Checkbox,
  Upload,
  message as antdMessage,
  List,
  Popconfirm
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useParams, useNavigate } from 'react-router-dom';
import {
  CameraOutlined,
  CloseOutlined,
  DeleteOutlined,
  EyeOutlined,
  WarningOutlined,
  CheckOutlined
} from '@ant-design/icons';
import { drawerFormLayout } from '../../utils/constants/formLayout';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useDownloadDocument } from '../../utils/downloadDoc';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';

const { Item } = Form;

/*
POINTS PREVENTION & SECURITE SUR CHANTIER
- Port des EPI (équipements de prévention individuelle: casque, chaussures, gants, lunettes de protection, casque anti bruit)
- Propreté et nettoyage des zones de circulation 
- Présence des protections collectives (repose après interventions)
- Balisage des zones à risques (dispositifs de barrières , chapeaux de sécurité, mise en évidence des trous)

Cheminements horizontaux et verticaux conformes à la sécurité (fixation des échaffaudages, 
port des harnais pour les ouvrages en hauteur, filet ou protection anti-chute pour toitures)

Sanitaires
conformes aux préconisations usuelles (accès affichage mesure covid, poubelle,
eau, papier, savon, électricité, signalisation extérieure, feuille d’émargement de l’entreprise de nettoyage
dûment remplie)
Base de vie signalée et conforme aux mesures covid (point d’eau, table, chaises,
ventilation, chauffage, micro-onde, frigo )
Evacuation des déchets (bennes)
Protection incendie des points chauds
*/

const ControlSecurity = ({ setNavDisplay }) => {
  const { id } = useParams();
  const { Dragger } = Upload;
  const { message } = useErrorMessage();
  const navigate = useNavigate();
  const { dispatchAPI } = useAuthContext();

  const { t } = useTranslation();

  const [control, setControl] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [documents_saved, setDocuments_saved] = useState([]);

  const goBack = () => {
    setNavDisplay('block');
    navigate(-1);
  };

  const formatBody = (values) => {
    const newBody = {
      epi: !!values.security.find((w) => w === 'epi'),
      cleanness: !!values.security.find((w) => w === 'cleanness'),
      epc: !!values.security.find((w) => w === 'epc'),
      markup: !!values.security.find((w) => w === 'markup'),
      markupH: !!values.security.find((w) => w === 'markupH'),
      sanitary: !!values.security.find((w) => w === 'sanitary'),
      lifebase: !!values.security.find((w) => w === 'lifebase'),
      wasteDisposal: !!values.security.find((w) => w === 'wasteDisposal'),
      hotspot: !!values.security.find((w) => w === 'hotspot')
    };
    return newBody;
  };

  const formatFields = () => {
    if (control) {
      const security = control.security;
      const array = [];
      if (security.epi) array.push('epi');
      if (security.cleanness) array.push('cleanness');
      if (security.epc) array.push('epc');
      if (security.markup) array.push('markup');
      if (security.markupH) array.push('markupH');
      if (security.sanitary) array.push('sanitary');
      if (security.lifebase) array.push('lifebase');
      if (security.wasteDisposal) array.push('wasteDisposal');
      if (security.hotspot) array.push('hotspot');

      let result = {};
      result = { security: array };
      return result;
    }
    return {};
  };

  const onFinish = async (values) => {
    const newBody = formatBody(values);
    try {
      await dispatchAPI('PATCH', {
        url: `/controls/${id}`,
        body: {
          security: newBody,
          isSecurityCheck: true,
          documents: documents_saved
        }
      });
      goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handlePostDocuments = () => {
    const documentsIds = [...documents_saved];
    try {
      documents.forEach(async (doc) => {
        const formData = new FormData();

        formData.append('documents', doc.originFileObj || doc);
        formData.append('type', doc.type);
        const { data } = await dispatchAPI('POST', {
          url: `/files`,
          body: formData
        });
        documentsIds.push(data.description.id);
      });
      setDocuments_saved(documentsIds);
      antdMessage.success(` document(s) téléchargé(s)`);
      setDocuments([]);
    } catch (e) {
      if (e.response) antdMessage(e.response.status);
    }
  };

  const getControl = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/controls/${id}`
      });
      setControl(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getDocuments = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/controls/${id}/documents`
      });
      setDocuments_saved(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const options = [
    {
      label:
        'Port des EPI (équipements de prévention individuelle : casque, chaussures, gants, lunettes de protection, casque anti bruit)',
      value: 'epi'
    },
    {
      label: 'Propreté et nettoyage des zones de circulation',
      value: 'cleanness'
    },
    {
      label:
        'Présence des protections collectives (repose après interventions)',
      value: 'epc'
    },
    {
      label:
        'Balisage des zones à risques (dispositifs de barrières , chapeaux de sécurité, mise en évidence des trous)',
      value: 'markup'
    },
    {
      label: 'Cheminements horizontaux et verticaux conformes à la sécurité',
      value: 'markuph'
    },
    {
      label: 'Sanitaires conformes aux préconisations usuelles',
      value: 'sanitary'
    },
    {
      label: 'Base de vie signalée et conforme aux mesures covid',
      value: 'lifebase'
    },
    {
      label: 'Evacuation des déchets (bennes)',
      value: 'wasteDisposal'
    },
    {
      label: 'Protection incendie des points chauds',
      value: 'hotspot'
    }
  ];

  const props = {
    multiple: true,
    fileList: documents,
    beforeUpload: (newFile) => {
      const newArray = [...documents];
      newArray.push(newFile);
      setDocuments(newArray);
      return false;
    },
    onRemove: (file) =>
      setDocuments(documents.filter((f) => f.uid !== file.uid))
  };

  const deleteFile = async (fileId) => {
    try {
      await dispatchAPI(`DELETE`, { url: `files/${fileId}` });
      window.location.reload();
    } catch (error) {
      message(error);
    }
  };

  const { downloadDocument } = useDownloadDocument();

  useEffect(async () => {
    setIsFieldsLoading(true);
    setNavDisplay('none');
    await getControl();
    await getDocuments();
    setIsFieldsLoading(false);
  }, []);

  return (
    <ContentCustom>
      <div className="security-form">
        <Button
          onClick={goBack}
          type="link"
          icon={<CloseOutlined />}
          className="back-btn"
        />
        <Row style={{ width: '100%', marginTop: 35 }}>
          <Col span={24}>
            {!isFieldsLoading && (
              <>
                <Form
                  {...drawerFormLayout}
                  onFinish={onFinish}
                  initialValues={formatFields()}
                >
                  <Item
                    name={['security']}
                    label="POINTS PREVENTION & SECURITE SUR CHANTIER"
                  >
                    <Checkbox.Group options={options} />
                  </Item>
                  <Item name={['documents']} label="Justificatifs">
                    <Dragger {...props}>
                      <p className="ant-upload-drag-icon">
                        <CameraOutlined />
                      </p>
                      <p className="ant-upload-text">Glisser / Déposer</p>
                    </Dragger>
                    {documents.length ? (
                      <Button onClick={handlePostDocuments}>
                        Enregistrer les documents
                      </Button>
                    ) : null}
                  </Item>
                  <Button
                    className="circle-check-btn"
                    shape="circle"
                    size="large"
                    htmlType="submit"
                    icon={<CheckOutlined />}
                  />
                </Form>
                {documents_saved.length > 0 && (
                  <List
                    dataSource={documents_saved}
                    renderItem={(item) => (
                      <List.Item
                        actions={[
                          <Button
                            onClick={() => downloadDocument(item, 'show')}
                            type="link"
                          >
                            <EyeOutlined style={{ fontSize: 20 }} />
                          </Button>,
                          <Popconfirm
                            title={t('controls.mobile.security.delete.title')}
                            okText={t('controls.mobile.security.delete.ok')}
                            okButtonProps={{ type: 'danger' }}
                            cancelText={t(
                              'controls.mobile.security.delete.cancel'
                            )}
                            onConfirm={() => deleteFile(item._id)}
                            icon={<WarningOutlined />}
                          >
                            <DeleteOutlined
                              style={{ color: '#b51010', fontSize: 20 }}
                              type="delete"
                            />
                          </Popconfirm>
                        ]}
                      >
                        <List.Item.Meta title={item.metadata?.originalName} />
                      </List.Item>
                    )}
                  />
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
    </ContentCustom>
  );
};

ControlSecurity.propTypes = {
  setNavDisplay: PropTypes.string.isRequired
};

export default ControlSecurity;
