import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Exception } from '../../components';
import Controls from './Controls';
import Today from './Today';
import Worksites from './Worksites';
import WorksiteDetails from './WorksiteDetails';
import { ControlQRCode } from './ControlQRCode/ControlQRCode';
import { ControlQRCodeTera } from './ControlQRCode/ControlQRCodeTera';
import ControlSecurity from './ControlSecurity';
import CreateUpdateWorker from '../workers/CreateUpdateWorker';
import CreateUpdateIncident from './MobileCreateUpdateIncident/MobileCreateUpdateIncident';
import ListIncidents from '../incidents/ListIncidents';
import ShowComment from '../comments/ShowComment';
import CreateUpdateComment from '../comments/CreateUpdateComment';
import { routes } from '../../utils/constants/inspectorRoutes';
import ControlDetails from './ControlDetails/ControlDetails';
import ListComments from '../comments/ListComments';
import UtilsNumbers from './UtilsNumbers';
import SpecialsWorkers from './ControlQRCode/SpecialsWorkers';
import WorksiteClosed from './ControlQRCode/WorksiteClosed';
import ShowIncident from './showIncident';
import ShowWorker from './ShowWorker';
import CheckControl from './CheckControl';
import { MobileContextProvider } from './MobileContext';

export const MobileRouter = ({ setNavDisplay }) => (
  <MobileContextProvider>
    <Routes>
      <Route path="/*" element={<Today />} />
      <Route path={`/${routes.TODAY}/*`} element={<Today />} />
      <Route
        path={`${routes.WORKSITES}/*`}
        element={<Worksites setNavDisplay={setNavDisplay} />}
      />
      <Route
        path={`${routes.CONTROLS}/*`}
        element={<Controls setNavDisplay={setNavDisplay} />}
      />
      <Route path={`${routes.UTILS_NUMBERS}/*`} element={<UtilsNumbers />} />
      <Route path="/show/:id" element={<WorksiteDetails />} />
      <Route
        path="/control/:id"
        element={<ControlDetails setNavDisplay={setNavDisplay} />}
      />
      <Route
        path="workers/edit/:controlId/:id"
        element={<CreateUpdateWorker purpose="edit" type="workers" />}
      />
      <Route
        path="workers/create/:controlId/:card_number"
        element={<CreateUpdateWorker purpose="create" type="workers" />}
      />
      <Route
        path="/controlqrcodetera/:id"
        element={<ControlQRCodeTera setNavDisplay={setNavDisplay} />}
      />
      <Route
        path="/controlqrcode/:id"
        element={<ControlQRCode setNavDisplay={setNavDisplay} />}
      />
      <Route
        path="/controlsecurity/:id"
        element={<ControlSecurity setNavDisplay={setNavDisplay} />}
      />
      <Route
        path="/controlspecial/:id"
        element={<SpecialsWorkers setNavDisplay={setNavDisplay} />}
      />
      <Route
        path="/worksiteclosed/:id"
        element={<WorksiteClosed setNavDisplay={setNavDisplay} />}
      />
      <Route
        path="/incidents/create/:controlId/:cardNumber"
        element={<CreateUpdateIncident purpose="create" />}
      />
      <Route
        path="/incidents/create/:controlId/"
        element={<CreateUpdateIncident purpose="create" />}
      />
      <Route
        path="/incidents/edit/:id"
        element={<CreateUpdateIncident purpose="edit" />}
      />
      <Route path="/incident/show/:id" element={<ShowIncident />} />
      <Route path="/worker/show/:id" element={<ShowWorker />} />
      <Route
        path="/incidents/:idControl"
        element={<ListIncidents headless fromMobile path="/incidents" />}
      />
      <Route
        path="/comments/:idControl"
        element={<ListComments headless fromMobile path="/comments" />}
      />
      <Route
        path="/comments/create/:controlId"
        element={<CreateUpdateComment purpose="create" />}
      />
      <Route path="/comment/show/:id" element={<ShowComment />} />
      <Route path="/checkcontrol/:id" element={<CheckControl />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </MobileContextProvider>
);

MobileRouter.propTypes = {
  setNavDisplay: PropTypes.string
};

MobileRouter.defaultProps = {
  setNavDisplay: 'none'
};
