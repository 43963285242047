import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import { t } from 'i18next';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { useFormatter } from '../../utils/formatter';
import { useAuthContext } from '../../contexts/AuthContext';
import IncidentsModal from './IncidentsModal';

const Listcompany = ({ headless, path, customResources }) => {
  const { user } = useAuthContext();
  const [modalVisible, setModalVisible] = useState(false);
  const [subcontractorId, setSubcontractorId] = useState();
  const { headers, columns } = useColumns(setModalVisible, setSubcontractorId);
  const [displayArchive, setDisplayArchive] = useState(false);
  const { formattedData } = useFormatter();
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';
  const isCompanyAdmin = user?.role === 'companies:ADMIN-COMPANY';

  return (
    <>
      <ListResource
        headless={headless}
        resourceName="companies"
        columns={columns}
        headers={headers}
        resourceModelName="Company"
        populate="contact,business_body"
        path={path}
        formatter={formattedData}
        deleteAction={isAdmin && !displayArchive}
        reintegrate={isAdmin && displayArchive}
        editAction={isAdmin}
        withCreateButton={isAdmin}
        customResources={customResources}
        extraQuery={`sort=name&isActive=${displayArchive ? 'false' : 'true'}`}
        extraButtons={
          <Switch
            onChange={() => setDisplayArchive(!displayArchive)}
            checkedChildren="Archivés"
            unCheckedChildren={t('companies.switchButton')}
            style={{ marginRight: '20px' }}
          />
        }
      />
      {isCompanyAdmin && (
        <ListResource
          headless={headless}
          resourceName="subcontractor"
          columns={columns}
          headers={headers}
          resourceModelName="Subcontractor"
          populate="contact,business_body"
          path={path}
          formatter={formattedData}
          deleteAction={isAdmin}
          editAction={isAdmin}
          withCreateButton={isAdmin}
          customResources={customResources}
          extraQuery={`sort=name&isActive=${
            displayArchive ? 'false' : 'true'
          }&id=${user._id}`}
        />
      )}
      <IncidentsModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        subcontractorId={subcontractorId}
        setSubcontractorId={setSubcontractorId}
      />
    </>
  );
};

Listcompany.propTypes = {
  headless: PropTypes.bool,
  path: PropTypes.string,
  customResources: PropTypes.arrayOf(PropTypes.shape())
};
Listcompany.defaultProps = {
  headless: false,
  path: null,
  customResources: null
};

export default Listcompany;
