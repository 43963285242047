import React, { useCallback, useEffect, useState } from 'react';
import { Input, Select, Tag, Form, Space, Button, Divider, Switch } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { userRoles } from '../../utils/constants/tagColors';

const { Option } = Select;
const { Item } = Form;

const useFields = (setOpen, open, withoutContacts) => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [businessBody, setBusinessBody] = useState([]);
  const [activateMail, setActivateMail] = useState(false);

  const switchActivateMail = (checked) => {
    setActivateMail(checked);
  };

  const onsearch = (input, option) => {
    const result = option?.children?.props.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const onsearchBusinessBody = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['business_body'],
      required: true,
      input: (
        <Select
          showSearch
          filterOption={(input, option) => onsearchBusinessBody(input, option)}
        >
          {businessBody.map((i) => (
            <Option key={i._id} value={i._id}>
              {i.label}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['siret_number']
    },
    {
      name: ['vat_number']
    },
    {
      label: 'address.number',
      name: ['address', 'number'],
      input: <Input type="number" />
    },
    {
      label: 'address.street',
      name: ['address', 'street']
    },
    {
      label: 'address.additional',
      name: ['address', 'additional']
    },
    {
      label: 'address.postcode',
      name: ['address', 'postal_code']
    },
    {
      label: 'address.city',
      name: ['address', 'city']
    },
    ...(!withoutContacts
      ? [
          {
            name: ['contact'],
            input: (
              <Select
                loading={isFieldsLoading}
                showSearch
                mode="multiple"
                filterOption={(input, option) => onsearch(input, option)}
                dropdownRender={(menu) => (
                  <>
                    <Space style={{ padding: '0 8px 4px' }}>
                      <Button
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={() => setOpen(true)}
                      >
                        Créer un nouvel utilisateur
                      </Button>
                    </Space>
                    <Divider style={{ margin: '8px 0' }} />
                    {menu}
                  </>
                )}
              >
                {(users || []).map((user) => {
                  if (user?.role?.includes('companie')) {
                    return (
                      <Option key={user._id} value={user._id} label={user._id}>
                        <Tag color={userRoles[user]}>
                          {`${user.first_name} ${user.last_name} ( ${user.email} ) `}
                        </Tag>
                      </Option>
                    );
                  }
                  return null;
                })}
              </Select>
            )
          }
        ]
      : []),
    {
      name: ['activate_mail'],
      label: 'activate_mail',
      valuePropName: 'checked',
      input: <Switch onChange={switchActivateMail} checked={activateMail} />
    },
    {
      name: ['phone_number'],
      rules: [{ required: false }],
      label: 'phone_number.number',
      input: (
        <Input.Group compact>
          <Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Item>
          <Item noStyle name={['phone_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Item>
        </Input.Group>
      )
    }
  ];

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users/' });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getUsers();
    setIsFieldsLoading(false);
  }, [open]);

  const getBusinessBody = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/batchtitle?show=${true}`
      });
      setBusinessBody(data);
    } catch (error) {
      if (error) message(error.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getSelectOptions();
      await getBusinessBody();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
