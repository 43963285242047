import React from 'react';
import { Spin, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ErrorBoundary } from 'react-error-boundary';
import { LanguageContextProvider } from '../contexts/LanguageContext';
import { AuthContextProvider, useAuthContext } from '../contexts/AuthContext';
import { ThemeContextProvider } from '../contexts/ThemeContext';
import { FallbackComponent } from './FallbackModal/FallbackComponent';
import { SocketContextProvider } from '../contexts/SocketContext';
import { RouterDispatch } from '../routers/RouterDispatch';
import { OfflineTray } from './OfflineTray/OfflineTray';

Spin.setDefaultIndicator(<LoadingOutlined spin />);

const handleError = async (error, { componentStack }, user) => {
  if (
    process.env.NODE_ENV === 'production' ||
    process.env.NODE_ENV === 'staging'
  ) {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/gitlab-issues`, {
        method: 'POST',
        body: JSON.stringify({
          node_env: process.env.NODE_ENV,
          error: { title: error?.message, description: error.toString() },
          path: window.location.href,
          stack: componentStack,
          user
        }),
        headers: new Headers({ 'Content-type': 'application/json' })
      });
    } catch (e) {
      notification.error({
        message: "Impossible de signaler l'erreur",
        key: 'gitlab_error'
      });
    }
  }
};

const ErrorBoundaryWrapper = ({ children }) => {
  const { user } = useAuthContext();

  return (
    <ErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={(error, componentStack) =>
        handleError(error, componentStack, user)
      }
    >
      {children}
    </ErrorBoundary>
  );
};

const App = () => (
  <AuthContextProvider>
    <LanguageContextProvider>
      <ThemeContextProvider>
        <SocketContextProvider>
          <ErrorBoundaryWrapper>
            <OfflineTray />
            <RouterDispatch />
          </ErrorBoundaryWrapper>
        </SocketContextProvider>
      </ThemeContextProvider>
    </LanguageContextProvider>
  </AuthContextProvider>
);

export default App;
