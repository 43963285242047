import React, { useCallback, useEffect, useState } from 'react';
import { Input, Select, DatePicker, TimePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useAuthContext } from '../../contexts/AuthContext';

const { Option } = Select;
const { TextArea } = Input;

const useFields = (users) => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState();

  const disabledHours = () => {
    const hours = [0, 1, 2, 3, 4, 5, 6, 22, 23];
    return hours;
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/events/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const onsearchInspector = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const fields = [
    {
      name: ['user'],
      rules: [{ required: true }],
      input: (
        <Select
          style={{ width: '300px' }}
          placeholder={t('calendar.placeholder.users')}
          showSearch
          loading={isFieldsLoading}
          filterOption={(input, option) => onsearchInspector(input, option)}
        >
          {(users || []).map((u) => (
            <Option key={u._id} value={u._id}>
              {`${u.first_name} ${u.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['type'],
      rules: [{ required: true }],
      input: (
        <Select style={{ width: '300px' }} showSearch loading={isFieldsLoading}>
          {(enums?.types || []).map((type) => (
            <Option key={type} value={type}>
              {t(`events.form.enums.${type}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['date'],
      rules: [{ required: true }],
      input: <DatePicker />
    },
    {
      name: ['startTime'],
      rules: [{ required: true }],
      input: <TimePicker format="HH:mm" disabledHours={disabledHours} />
    },
    {
      name: ['endTime'],
      rules: [{ required: true }],
      input: <TimePicker format="HH:mm" disabledHours={disabledHours} />
    },
    {
      name: ['comment'],
      input: <TextArea rows={5} />
    },
    {
      name: ['created_by'],
      initialValue: user._id,
      hidden: true
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
