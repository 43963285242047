import React, {
  useEffect,
  Children,
  useRef,
  isValidElement,
  cloneElement,
  useState
} from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import PropTypes from 'prop-types';
import { createCustomEqual } from 'fast-equals';
import axios from 'axios';
import { Row } from 'antd';

const render = (status) => <h1>{status}</h1>;

export const MapWrapper = ({
  clicks,
  setClicks,
  markers,
  setPredictedAddress
}) => {
  const [zoom, setZoom] = useState(6);
  const [center, setCenter] = useState(
    clicks.lat && clicks.lng
      ? { lat: Number(clicks.lat), lng: Number(clicks.lng) }
      : {
          lat: 46.2276,
          lng: 2.2137
        }
  );

  const onClick = async (e) => {
    const coordinates = e.latLng.toJSON();
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates.lat},${coordinates.lng}&key=${process.env.REACT_APP_GOOGLE_MAP_API}`;
    const result = await axios.get(url);
    const city = result.data.results[0].address_components[2]?.long_name;
    const address_full = result.data.results[0].formatted_address;
    setPredictedAddress([
      { formatted_address: result.data.results[0].formatted_address }
    ]);
    setClicks({ ...coordinates, address_full, city });
    setCenter(coordinates);
  };

  const onIdle = (m) => {
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
  };

  const form = (
    <div
      style={{
        padding: '1rem',
        flexBasis: '250px',
        height: '100%',
        overflow: 'auto',
        display: 'none'
      }}
    >
      <pre key={0}>{JSON.stringify(clicks)}</pre>
    </div>
  );
  return (
    <Row style={{ height: 'calc(100% - 20px)', margin: 10 }}>
      <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAP_API} render={render}>
        <Map
          center={center}
          onClick={onClick}
          onIdle={onIdle}
          zoom={zoom}
          style={{ flexGrow: '1', height: '100%' }}
        >
          {markers ? (
            markers.map((m) => (
              <Marker
                key={m.name}
                position={{
                  lat: parseFloat(m.position?.lat),
                  lng: parseFloat(m.position?.lng)
                }}
                label={m.name}
              />
            ))
          ) : (
            <Marker
              key={0}
              position={{
                lat: parseFloat(clicks.lat),
                lng: parseFloat(clicks.lng)
              }}
            />
          )}
        </Map>
      </Wrapper>
      {form}
    </Row>
  );
};

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
  if (
    a instanceof window.google.maps.LatLng ||
    b instanceof window.google.maps.LatLng
  ) {
    return new window.google.maps.LatLng(a).equals(
      new window.google.maps.LatLng(b)
    );
  }
  return deepEqual(a, b);
});

const useDeepCompareMemoize = (value) => {
  const ref = useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
};

const useDeepCompareEffectForMaps = (callback, dependencies) => {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
};

const Map = ({ onClick, onIdle, children, style, ...options }) => {
  const ref = useRef(null);
  const [map, setMap] = useState();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      );
      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);
  return (
    <>
      <div ref={ref} style={style} />
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, { map });
        }
        return null;
      })}
    </>
  );
};

const Marker = (options) => {
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);
  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);
  return null;
};

MapWrapper.propTypes = {
  clicks: PropTypes.instanceOf(Object),
  setClicks: PropTypes.func,
  markers: PropTypes.arrayOf(PropTypes.shape({})),
  setPredictedAddress: PropTypes.func
};

MapWrapper.defaultProps = {
  clicks: {},
  setClicks: null,
  markers: null,
  setPredictedAddress: null
};

Map.propTypes = {
  onClick: PropTypes.func,
  onIdle: PropTypes.func,
  style: PropTypes.shape({})
};

Map.defaultProps = {
  onClick: null,
  onIdle: null,
  style: {}
};
