import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { routes } from '../../../utils/constants/adminRoutes';
import { WorksiteIcon } from '../../../utils/constants/customIcons';

const ExtraButtons = ({ id, isDocLoading, generateControlReport }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';

  const deleteWorksite = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/controls/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      {isAdmin && (
        <Link to={{ pathname: `/controls/${id}` }}>
          <Button type="primary" style={{ marginRight: 5 }}>
            {`${t('buttons.control')} `}
            <WorksiteIcon />
          </Button>
        </Link>
      )}
      {isAdmin && (
        <Button
          style={{ marginRight: 5 }}
          type="primary"
          loading={isDocLoading}
          onClick={() => generateControlReport()}
        >
          {`${t('buttons.download_report')} `}
          <DownloadOutlined />
        </Button>
      )}
      {isAdmin && (
        <Link to={{ pathname: `${routes.CONTROLS}/edit/${id}` }}>
          <Button type="primary">
            {`${t('buttons.edit')} `}
            <EditOutlined />
          </Button>
        </Link>
      )}
      {isAdmin && (
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={deleteWorksite}
          icon={<WarningOutlined />}
        >
          <Button style={{ marginRight: 5 }} type="link" danger>
            {`${t('buttons.delete')} `}
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      )}
    </>
  );
};

ExtraButtons.propTypes = {
  id: PropTypes.string.isRequired,
  isDocLoading: PropTypes.bool.isRequired,
  generateControlReport: PropTypes.func.isRequired
};

export default ExtraButtons;
