import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from './ErrorMessage';
import { useAuthContext } from '../contexts/AuthContext';

export const useDownloadDocument = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const downloadDocument = async (
    { _id, metadata: { originalName }, contentType },
    action
  ) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files/${_id}`,
        responseType: 'blob'
      });
      const blob = new Blob([data], { type: contentType });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      if (originalName.includes('.pdf') || action === 'show') {
        a.target = '_blank';
        a.click();
      } else {
        a.download = originalName;
        a.click();
        notification.success({ message: t('messages.export-success') });
      }
    } catch (e) {
      message(e);
    }
  };

  return { downloadDocument };
};
