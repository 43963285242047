import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from './AuthContext';
import { useErrorMessage } from '../utils/ErrorMessage';
import { useSocketContext } from './SocketContext';

const QuietusContext = createContext({});

export const QuietusContextProvider = ({ children }) => {
  const { dispatchAPI, user } = useAuthContext();
  const { socket } = useSocketContext();
  const { message } = useErrorMessage();
  const [INCIDENTS, setIncidentsDoc] = useState(0);
  const [incidentModified, setIncidentModified] = useState(false);
  const [UNKNOWNCOMPANY, setUnknownCompany] = useState(0);
  const [USERSMODIFIED, setUsersModified] = useState(0);
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';

  const getIncidentDocStatusPending = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/incidents?justification_status=PENDING`
      });
      setIncidentsDoc(data.length);
    } catch (e) {
      message(e);
      setIncidentsDoc(0);
    }
  }, []);

  const getIncidentWithUnknownCompany = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/incidents?unknown_company!=null&status!=634c60a28d00c8c79e6b8aba`
      });
      setUnknownCompany(data.length);
    } catch (e) {
      message(e);
      setUnknownCompany(0);
    }
  }, []);

  const getUsersWorkersModified = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/modified`
      });
      setUsersModified(data.length);
    } catch (e) {
      message(e);
      setUnknownCompany(0);
    }
  }, []);

  useEffect(() => {
    if (user && isAdmin)
      (async () => {
        await getIncidentDocStatusPending();
        await getIncidentWithUnknownCompany();
        await getUsersWorkersModified();
      })();
    // eslint-disable-next-line
  }, [incidentModified]);

  useEffect(() => {
    if (socket) {
      socket.on('newMessage', () => {
        getIncidentDocStatusPending();
      });
    }
  }, [socket]);

  return (
    <QuietusContext.Provider
      value={{
        INCIDENTS,
        UNKNOWNCOMPANY,
        USERSMODIFIED,
        setIncidentModified,
        incidentModified
      }}
    >
      {children}
    </QuietusContext.Provider>
  );
};

QuietusContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(QuietusContext);
