import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';

const ControlModal = ({
  openModal,
  setOpenModal,
  users,
  purpose,
  closeOverlay,
  idFromOverlay,
  date
}) => {
  const { fields, isFieldsLoading } = useFields(users, date);

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        date:
          Object.keys(date).length !== 0
            ? date.date && moment(date.date)
            : data.date && moment(data.date),
        start_time:
          Object.keys(date).length !== 0
            ? moment(
                moment(date.date).set({
                  hour: parseInt(date.hour.split(':')[0], 10),
                  minute: 0,
                  second: 0,
                  millisecond: 0
                })
              )
            : data.start_time && moment(data.start_time),
        end_time: data.end_time && moment(data.end_time)
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        date:
          data.date &&
          moment(data.date).set({
            hour: 12,
            minute: 0,
            second: 0,
            millisecond: 0
          }),
        start_time:
          data.start_time &&
          moment(data.start_time).date(moment(data.date).date),
        end_time:
          data.end_time &&
          moment(
            moment(data.date).set({
              hour: moment(data.end_time).hour(),
              minute: 0,
              second: 0,
              millisecond: 0
            })
          ),
        ...(Object.keys(date).length !== 0
          ? {
              status: 'PLANNED'
            }
          : {})
      })
    }
  };

  return (
    <Modal
      centered
      open={openModal}
      onCancel={() => {
        setOpenModal(false);
      }}
      width={700}
      footer={false}
    >
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="controls"
        resource="controls"
        isOverlay
        customNavigate={closeOverlay}
        idFromOverlay={idFromOverlay}
        config={config}
      />
    </Modal>
  );
};

ControlModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  purpose: PropTypes.string,
  closeOverlay: PropTypes.func.isRequired,
  idFromOverlay: PropTypes.string,
  date: PropTypes.string
};

ControlModal.defaultProps = {
  users: null,
  purpose: null,
  idFromOverlay: null,
  date: undefined
};

export default ControlModal;
