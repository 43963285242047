import React from 'react';
import PropTypes from 'prop-types';

import { ListResource } from '../../components/ListResource/ListResource';
import { useColumns } from '../workers/columns';
import { useAuthContext } from '../../contexts/AuthContext';

const CompanyWorkers = ({ idCompany, type }) => {
  const { columns } = useColumns(type, false);
  const { user } = useAuthContext();
  const populate = 'idCompany';
  const isAdmin = ['admins:SUPER-ADMIN', 'admins:ADMIN'].includes(user?.role);

  return (
    <ListResource
      headless
      resourceName="workers"
      extraQuery={`idCompany=${idCompany}`}
      columns={columns}
      deleteAction={isAdmin}
      editAction={isAdmin}
      withCreateButton={isAdmin}
      headers={[]}
      resourceModelName="Worker"
      populate={populate}
      extraTitle={type}
      path={`/${type}`}
    />
  );
};

CompanyWorkers.propTypes = {
  idCompany: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default CompanyWorkers;
