import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { outOfNavRoutes } from '../utils/constants/inspectorRoutes';
import { Login } from '../routes/login';
import { useAuthContext } from '../contexts/AuthContext';
import { InspectorLayout } from '../components/layouts/InspectorLayout';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { InspectorTheme } from '../assets/styles/Theme/InspectorTheme';
import { MobileRouter } from '../routes/mobile/MobileRouter';

const RequireAuth = ({ location, nav_display }) => {
  const { isValid } = useAuthContext();

  if (!isValid) {
    return Navigate({
      to: {
        pathname: outOfNavRoutes.LOGIN
      },
      state: { from: location }
    });
  }

  return <InspectorLayout nav_display={nav_display} />;
};

RequireAuth.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.shape({
        name: PropTypes.string
      })
    })
  })
};

RequireAuth.defaultProps = {
  location: { pathname: '/' }
};

export const InspectorRouter = () => {
  const [nav_display, setNavDisplay] = useState('');
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'inspector';
    }
  });

  return (
    <BrowserRouter>
      <InspectorTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route element={<RequireAuth nav_display={nav_display} />}>
          <Route
            path="*"
            element={<MobileRouter setNavDisplay={setNavDisplay} />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

RequireAuth.propTypes = {
  nav_display: PropTypes.string
};
RequireAuth.defaultProps = {
  nav_display: ''
};
