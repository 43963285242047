import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Card, Col, Tag } from 'antd';
import { useDrag } from 'react-dnd';

export const DraggableCard = ({ content }) => {
  const { _id, reference, period_start, period_end, worksite } = content;

  const [{ isDragging }, drag] = useDrag(() => {
    const draggableItem = { _id, reference, type: 'CONTROL' };
    return {
      type: 'GRID',
      item: draggableItem,
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    };
  });

  const border = isDragging
    ? '1px solid var(--primaryColor)'
    : '1px solid var(--borderColor)';
  const backgroundColor = '#fff';
  const cursor = isDragging ? 'grabbing' : 'grab';

  return (
    <Col span={3}>
      <Card
        title={reference}
        ref={drag}
        style={{
          flex: '0 0 calc(10% - 16px)',
          border,
          backgroundColor,
          cursor
        }}
      >
        <p>
          Du{' '}
          {`${moment(period_start).format('DD.MM.YYYY')} au ${moment(
            period_end
          ).format('DD.MM.YYYY')}`}
        </p>
        <Tag color={worksite?.color}>{worksite?.name}</Tag>
      </Card>
    </Col>
  );
};

DraggableCard.propTypes = {
  content: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    reference: PropTypes.string,
    period_start: PropTypes.string,
    period_end: PropTypes.string,
    type: PropTypes.string,
    worksite: PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string
    }).isRequired
  })
};

DraggableCard.defaultProps = {
  content: PropTypes.shape({
    reference: null,
    period_start: null,
    period_end: null,
    type: undefined
  })
};
