import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';

export const listContentPrincipal = (data, comments) => {
  const { t } = useTranslation();
  const { reference, status, inspector, date, start_time, worksite } = data;
  const { user } = useAuthContext();
  const isAdmin = [
    'admins:SUPER-ADMIN',
    'admins:ADMIN',
    'promoters:ADMIN-PROMOTER'
  ].includes(user?.role);

  return [
    {
      label: 'controls.form.reference',
      span: 1,
      content: reference || '-'
    },
    {
      label: 'controls.form.status',
      span: 1,
      content: <Tag>{t(`controls.tags.${status}`)}</Tag> || '-'
    },
    {
      label: 'controls.form.datetime',
      span: 1,
      content: date
        ? `Le ${moment(date).format('DD/MM/YYYY')} à ${moment(
            start_time
          ).format('HH:mm')}`
        : '-'
    },
    {
      label: 'controls.form.inspector',
      span: 1,
      content:
        (inspector && isAdmin ? (
          <Link to={`/users/usersinspectors/show/${inspector?._id}`}>{`${
            inspector?.first_name || '-'
          } ${inspector?.last_name || '-'}`}</Link>
        ) : (
          `${inspector?.first_name || '-'}`
        )) || '-'
    },
    {
      label: 'controls.form.worksite',
      span: 2,
      content:
        (worksite && (
          <Link
            to={`/worksites/show/${worksite?._id}`}
          >{`${worksite?.name}`}</Link>
        )) ||
        '-'
    },
    {
      label: 'controls.form.comment',
      span: 3,
      content: comments ? comments.map((comment) => comment?.content) : '-'
    }
  ];
};

export const listContentSecurity = (data) => {
  // const { t } = useTranslation();
  const { security } = data;
  /*
    epi: { type: Boolean, default: false },
    cleanness: { type: Boolean, default: false },
    epc: { type: Boolean, default: false },
    markup: { type: Boolean, default: false },
    markup: { type: Boolean, default: false },
    sanitary: { type: Boolean, default: false },
    lifebase: { type: Boolean, default: false },
    wasteDisposal: { type: Boolean, default: false },
    hotspot: { type: Boolean, default: false }

    POINTS PREVENTION & SECURITE SUR CHANTIER
    - Port des EPI (équipements de prévention individuelle: casque, chaussures, gants, lunettes de protection, casque anti bruit)
    - Propreté et nettoyage des zones de circulation 
    - Présence des protections collectives (repose après interventions)
    - Balisage des zones à risques (dispositifs de barrières , chapeaux de sécurité, mise en évidence des trous)

    Cheminements horizontaux et verticaux conformes à la sécurité (fixation des échaffaudages, 
    port des harnais pour les ouvrages en hauteur, filet ou protection anti-chute pour toitures)

    Sanitaires
    conformes aux préconisations usuelles (accès affichage mesure covid, poubelle,
    eau, papier, savon, électricité, signalisation extérieure, feuille d’émargement de l’entreprise de nettoyage
    dûment remplie)
    Base de vie signalée et conforme aux mesures covid (point d’eau, table, chaises,
    ventilation, chauffage, micro-onde, frigo )
    Evacuation des déchets (bennes)
    Protection incendie des points chauds

  */

  return [
    {
      label: 'controls.form.security.epi',
      span: 3,
      content: security?.epi ? (
        <Tag color="#36B395">OUI</Tag>
      ) : (
        <Tag color="#B23E52">NON</Tag>
      )
    },
    {
      label: 'controls.form.security.cleanness',
      span: 3,
      content: security?.cleanness ? (
        <Tag color="#36B395">OUI</Tag>
      ) : (
        <Tag color="#B23E52">NON</Tag>
      )
    },
    {
      label: 'controls.form.security.epc',
      span: 3,
      content: security?.epc ? (
        <Tag color="#36B395">OUI</Tag>
      ) : (
        <Tag color="#B23E52">NON</Tag>
      )
    },
    {
      label: 'controls.form.security.markup',
      span: 3,
      content: security?.markup ? (
        <Tag color="#36B395">OUI</Tag>
      ) : (
        <Tag color="#B23E52">NON</Tag>
      )
    },
    {
      label: 'controls.form.security.markupH',
      span: 3,
      content: security?.markup ? (
        <Tag color="#36B395">OUI</Tag>
      ) : (
        <Tag color="#B23E52">NON</Tag>
      )
    },
    {
      label: 'controls.form.security.sanitary',
      span: 3,
      content: security?.sanitary ? (
        <Tag color="#36B395">OUI</Tag>
      ) : (
        <Tag color="#B23E52">NON</Tag>
      )
    },
    {
      label: 'controls.form.security.lifebase',
      span: 3,
      content: security?.lifebase ? (
        <Tag color="#36B395">OUI</Tag>
      ) : (
        <Tag color="#B23E52">NON</Tag>
      )
    },
    {
      label: 'controls.form.security.wasteDisposal',
      span: 3,
      content: security?.wasteDisposal ? (
        <Tag color="#36B395">OUI</Tag>
      ) : (
        <Tag color="#B23E52">NON</Tag>
      )
    },
    {
      label: 'controls.form.security.hotspot',
      span: 3,
      content: security?.hotspot ? (
        <Tag color="#36B395">OUI</Tag>
      ) : (
        <Tag color="#B23E52">NON</Tag>
      )
    }
  ];
};
