import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import img404 from '../../assets/images/404.svg';

const StyledException = styled.div`
  width: 100%;
  text-align: center;
`;

const StyledImgBlock = styled.div`
  width: 50%;
  margin: auto;
`;

const ExceptionTitle = styled.h1`
  color: #434e59;
  font-weight: 600;
  font-size: 72px;
`;

export const Exception = () => {
  const { t } = useTranslation();

  return (
    <StyledException>
      <ExceptionTitle>404</ExceptionTitle>
      <div style={{ fontSize: 20, marginBottom: 20 }}>
        {t('404.message')}
        <br />
        <Link to="/">
          <Button type="primary">{t('404.button')}</Button>
        </Link>
      </div>
      <StyledImgBlock>
        <img src={img404} alt="" />
      </StyledImgBlock>
    </StyledException>
  );
};
