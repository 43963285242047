import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import {
  Button,
  Popconfirm,
  Row,
  Skeleton,
  Tabs,
  Upload,
  Col,
  Card,
  message as antdMessage
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  WarningOutlined,
  DownloadOutlined,
  EditOutlined,
  DeleteOutlined,
  InboxOutlined
} from '@ant-design/icons';
import ListWorkers from '../../workers/ListWorkers';
import ListControls from '../../controls/ListControls';
import ListIncidents from '../../incidents/ListIncidents';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import InformationPanel from './InformationPanel';
import ListDocuments from '../listDocuments';
import ListCompanyOfWorksite from './ListCompaniesOfWorksite';
import { useGenerateReport } from '../../../utils/generateReport';
import ReportWorksiteModal from './ReportWorksiteModal';

const { TabPane } = Tabs;
const { Dragger } = Upload;

const ShowWorksite = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { generateReport } = useGenerateReport();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { user, dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isDocLoading, setIsDocLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [worksite, setWorksite] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [workers, setWorkers] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('1');
  const isCompany =
    user?.role === 'companies:ADMIN-COMPANY' ||
    user?.role === 'companies:USER-COMPANY';

  const { pathname } = useLocation();
  const userRoleCondition = user?.role?.split(':')[0] === 'admins';

  const getWorksite = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/worksites/${id}?populate=type,phase,idController,idPromoter,companies,companies.company,companies.responsable,referents,documents,promoter_users`
      });
      setWorksite(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const getWorkers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/workers?isActive=true&populate=idCompany'
      });
      setWorkers(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/companies?populate=contact,business_body'
      });
      setCompanies(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getWorksite();
      await getCompanies();
      await getWorkers();
    })();
  }, [refresh]);

  const deleteWorksite = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/worksites/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const generateWorksiteReport = async (startDate, endDate) => {
    setIsDocLoading(true);
    await generateReport('worksite', id, startDate, endDate);
    setIsDocLoading(false);
  };

  const customCompaniesArray = () => {
    const newArray = [];
    const data = [];
    if (worksite && companies) {
      Object.entries(worksite?.companies).map((companie) => {
        const subcontractors = [];
        if (companie[1].principal) {
          if (companie[1]?.subcontractors?.length) {
            companie[1]?.subcontractors.map((sub) => {
              const index =
                companies && companies.findIndex((c) => c._id === sub);
              subcontractors.push(companies[index]);
              return subcontractors;
            });
          }
          const filter = (i) => String(i.id) === String(companie[1].principal);
          if (newArray.find(filter)) {
            const indexOfNewArray = newArray.findIndex(filter);
            if (subcontractors?.length) {
              newArray[indexOfNewArray].subcontractors = subcontractors.concat(
                newArray[indexOfNewArray].subcontractors
              );
            }
          } else {
            newArray.push({
              id: companie[1]?.principal,
              ...(companie[1]?.subcontractors?.length &&
                subcontractors?.length && { subcontractors })
            });
          }
        }
        return newArray;
      });

      newArray?.map((idCompanie) => {
        if (companies) {
          const index =
            companies && companies.findIndex((c) => c._id === idCompanie.id);
          if (!data.includes(companies[index])) {
            data.push({
              ...companies[index],
              ...(idCompanie.subcontractors
                ? { subcontractors: idCompanie.subcontractors }
                : {}),
              key: idCompanie.id
            });
          }
        }
        return data;
      });
      data.sort((a, b) => a?.name?.localeCompare(b?.name));
      return data;
    }
    return null;
  };

  const customWorkersArray = () => {
    const tabOfIdCompanies = [];
    const arrayWorkersOfWorksite = [];
    if (customCompaniesArray()) {
      customCompaniesArray().map((companie) => {
        tabOfIdCompanies.push(companie._id);
        if (companie?.subcontractors) {
          companie.subcontractors.map((sub) => tabOfIdCompanies.push(sub?._id));
        }
        return tabOfIdCompanies;
      });
      workers?.map((worker) => {
        if (Array.isArray(worker.idCompany)) {
          if (
            tabOfIdCompanies.some((idCompany) =>
              worker.idCompany.some(
                (idcompOfworker) =>
                  JSON.stringify(idCompany) ===
                  JSON.stringify(idcompOfworker._id)
              )
            )
          ) {
            arrayWorkersOfWorksite.push(worker);
          }
          return arrayWorkersOfWorksite;
        }
        if (tabOfIdCompanies.includes(worker?.idCompany?._id)) {
          arrayWorkersOfWorksite.push(worker);
          return arrayWorkersOfWorksite;
        }
        return arrayWorkersOfWorksite;
      });
      return arrayWorkersOfWorksite;
    }
    return null;
  };

  const headerExtra = () => (
    <>
      <Button
        type="primary"
        loading={isDocLoading}
        onClick={() => setOpenModal(true)}
      >
        {`${t('buttons.download_report')} `}
        <DownloadOutlined />
      </Button>
      <Link to={{ pathname: `/worksites/edit/${id}` }}>
        <Button type="primary">
          {`${t('buttons.edit')} `}
          <EditOutlined />
        </Button>
      </Link>
      <Popconfirm
        title={t('datatable.column.action.delete.title')}
        okText={t('datatable.column.action.delete.ok')}
        okButtonProps={{ type: 'danger' }}
        cancelText={t('datatable.column.action.delete.cancel')}
        onConfirm={deleteWorksite}
        icon={<WarningOutlined />}
      >
        <Button type="link" danger>
          {`${t('buttons.delete')} `}
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    </>
  );

  const props = {
    multiple: false,
    fileList: documents,
    beforeUpload: (newFile) => {
      setDocuments([newFile]);
      return false;
    },
    onRemove: (file) =>
      setDocuments(documents.filter((f) => f.uid !== file.uid))
  };

  const handlePostDocuments = async () => {
    try {
      const formData = new FormData();
      documents.forEach((doc) =>
        formData.append('documents', doc.originFileObj || doc)
      );

      await dispatchAPI('POST', {
        url: `/worksites/upload/${id}`,
        body: formData
      });
      antdMessage.success(`document(s) téléchargé(s)`);
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handleTabChange = (key) => {
    navigate(`${pathname}#tabs`);
    setActiveTabKey(key);
  };

  useEffect(() => {
    const tabsElement = document.getElementById('tabs');
    if (tabsElement) {
      setTimeout(() => {
        tabsElement.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [activeTabKey]);

  return (
    <>
      <ReportWorksiteModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        generateWorksiteReport={generateWorksiteReport}
        worksite={worksite}
      />
      <PageHeaderCustom
        title={`${t('worksites.show.title')} - ${worksite?.name}`}
        extra={userRoleCondition && headerExtra()}
      />
      <ContentCustom>
        <Row value={worksite}>
          <Skeleton loading={isLoading}>
            {worksite && (
              <InformationPanel
                worksite={worksite}
                isLoading={isLoading}
                setRefresh={setRefresh}
              />
            )}
          </Skeleton>
        </Row>
        <Row style={{ marginTop: 24 }}>
          <Col xs={24}>
            <Card bordered={false}>
              <Skeleton loading={isLoading}>
                <div id="tabs">
                  <Tabs
                    activeKey={activeTabKey}
                    defaultActiveKey={activeTabKey}
                    onChange={(key) => {
                      handleTabChange(key);
                    }}
                  >
                    {worksite && (
                      <TabPane tab={t('worksites.tabs.controls')} key="1">
                        <ListControls
                          headless
                          path="/controls"
                          extraQuery={`worksite=${id}`}
                        />
                      </TabPane>
                    )}
                    {worksite && (
                      <TabPane tab={t('worksites.tabs.incidents')} key="2">
                        <ListIncidents
                          headless
                          path="/incidents"
                          extraQuery={`worksite=${id}`}
                        />
                      </TabPane>
                    )}
                    {worksite && !isCompany && (
                      <TabPane tab={t('worksites.tabs.companies')} key="3">
                        <ListCompanyOfWorksite
                          customResources={customCompaniesArray()}
                        />
                      </TabPane>
                    )}
                    {customWorkersArray && (
                      <TabPane tab={t('worksites.tabs.workers')} key="4">
                        <ListWorkers
                          headless
                          category="/workers:/i"
                          type="workers"
                          customResources={customWorkersArray()}
                          path="/users/workers"
                        />
                      </TabPane>
                    )}
                    <TabPane tab="Documents" key="6">
                      <Row gutter={[16, 16]}>
                        <Col
                          xs={24}
                          lg={8}
                          xxl={6}
                          style={{ height: 'fit-content' }}
                        >
                          <div>
                            <Dragger {...props}>
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                              </p>
                              <p className="ant-upload-text">
                                Glisser / Déposer
                              </p>
                            </Dragger>
                            {documents && (
                              <Button
                                type="primary"
                                onClick={handlePostDocuments}
                              >
                                Enregistrer
                              </Button>
                            )}
                          </div>
                        </Col>
                        <Col xs={24} lg={16} xxl={18}>
                          <ListDocuments data={worksite} />
                        </Col>
                      </Row>
                    </TabPane>
                  </Tabs>
                </div>
              </Skeleton>
            </Card>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};

export default ShowWorksite;
