import { useTranslation } from 'react-i18next';
import { useErrorMessage } from './ErrorMessage';
import { useAuthContext } from '../contexts/AuthContext';

export const useGenerateReport = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const generateReport = async (modelName, id, startDate, endDate) => {
    try {
      const { data, headers } = await dispatchAPI('GET', {
        url: `/files/generate/${modelName}/${id}?startDate=${startDate}&endDate=${endDate}`,
        responseType: 'blob'
      });
      const blob = new Blob([data], {
        type: 'application/pdf'
      });
      const fileName = headers['content-disposition'].substring(
        headers['content-disposition'].indexOf('=') + 1,
        headers['content-disposition']?.length
      );
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}`;
      a.click();
      message.success(t('export.messages.success'));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return { generateReport };
};
