import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { userRoles, userPoste } from '../../utils/constants/tagColors';
import { usersData } from './listFields';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const useColumns = (type, isfrommodal) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [promoters, setPromoters] = useState([]);
  const [companies, setCompanies] = useState([]);

  const dataHeaders = [
    {
      label: 'idCompany',
      key: 'idCompany.name'
    },
    {
      label: 'idPromoter',
      key: 'idPromoter.name'
    },
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'email',
      key: 'email'
    },
    {
      label: 'phone_number',
      key: 'phone_number.number'
    },
    {
      label: 'role',
      key: 'role'
    },
    {
      label: 'poste',
      key: 'poste'
    },
    {
      label: 'name',
      key: 'name'
    },
    {
      label: 'document_valid_until',
      key: 'document_valid_until'
    },
    {
      label: 'start_work_at',
      key: 'start_work_at'
    },
    {
      label: 'end_work_at',
      key: 'end_work_at'
    },
    {
      label: 'isWCard',
      key: 'isWCard'
    },
    {
      label: 'subcontracting',
      key: 'subcontracting'
    },
    {
      label: 'card_number',
      key: 'card_number'
    }
  ];

  const data = [
    {
      name: 'idCompany',
      title: t('users.form.idCompany'),
      key: 'idCompany',
      dataIndex: 'idCompany',
      sorter: true,
      render: (idCompany) =>
        Array.isArray(idCompany)
          ? idCompany?.map((company) => <div>{company.name}</div> || '-')
          : idCompany?.name || '-',
      filters: companies.map((r) => ({
        text: r.name,
        value: r._id
      }))
    },
    {
      name: 'idPromoter',
      title: t('users.form.idPromoter'),
      key: 'idPromoter',
      dataIndex: 'idPromoter',
      sorter: true,
      render: (promoter) => (promoter && promoter.name) || '-',
      filters: promoters.map((r) => ({
        text: r.name,
        value: r._id
      }))
    },
    {
      name: 'last_name',
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name' || '-',
      sorter: true
    },
    {
      name: 'first_name',
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name' || '-',
      sorter: true
    },
    {
      name: 'email',
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email' || '-',
      sorter: true,
      width: '23%'
    },
    {
      name: 'phone_number',
      title: t('users.form.phone_number.number'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: (phone_number) =>
        (phone_number &&
          `${phone_number.country_code || '-'} ${
            phone_number.number || '-'
          }`) ||
        '-',
      sorter: true
    },
    {
      name: 'role',
      title: t('users.form.role'),
      key: 'role',
      dataIndex: 'role',
      width: 210,
      render: (role) => (
        <Tag color={userRoles[type][role.split(':')[1]]}>
          {t(`users.tags.${role.split(':')[1]}`) || '-'}
        </Tag>
      ),
      sorter: true
    },
    {
      name: 'poste',
      title: t('users.form.poste'),
      key: 'poste',
      dataIndex: 'poste',
      render: (poste) => (
        <Tag color={userPoste[poste]}>{t(`users.tags.${poste}`) || '-'}</Tag>
      ),
      sorter: true,
      filters: Object.entries(userPoste).map((r) => ({
        text: t(`users.tags.${r[0]}`),
        value: r[0]
      }))
    },
    {
      name: 'name',
      title: t('users.form.worker_card'),
      key: 'worker_card',
      dataIndex: 'worker_card' || '-',
      sorter: true
    },
    {
      name: 'document_valid_until',
      title: t('users.form.document_valid_until'),
      key: 'document_valid_until',
      dataIndex: 'document_valid_until' || '-',
      sorter: true
    },
    {
      name: 'start_work_at',
      title: t('users.form.start_work_at'),
      key: 'start_work_at',
      dataIndex: 'start_work_at' || '-',
      sorter: true
    },
    {
      name: 'end_work_at',
      title: t('users.form.end_work_at'),
      key: 'end_work_at',
      dataIndex: 'end_work_at' || '-',
      sorter: true
    },
    {
      name: 'isWCard',
      title: t('users.form.isWCard'),
      key: 'isWCard',
      dataIndex: 'isWCard' || '-',
      sorter: true
    },
    {
      name: 'subcontracting',
      title: t('users.form.subcontracting'),
      key: 'subcontracting',
      dataIndex: 'subcontracting' || '-',
      sorter: true,
      render: (sub) => (sub ? t(`users.form.${sub}`) : '-')
    },
    {
      name: 'card_number',
      title: t('users.form.card_number'),
      key: 'card_number',
      dataIndex: 'card_number' || '-',
      sorter: true
    }
  ];

  const getPromoters = async () => {
    try {
      const result = await dispatchAPI('GET', { url: 'promoters' });
      setPromoters(result.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const result = await dispatchAPI('GET', { url: 'companies' });
      setCompanies(result.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getPromoters();
    getCompanies();
  }, []);

  const headersToDisplay = usersData[`${type}_list`]?.fields;

  const headers = dataHeaders.filter((elem) =>
    headersToDisplay?.includes(elem.label)
  );

  const columnsToDisplay = usersData[`${type}_list`]?.fields;

  const columns = data.filter(
    (elem) =>
      columnsToDisplay?.includes(elem.name) &&
      !(
        (elem.name[0] === 'idCompany' || elem.name[0] === 'idPromoter') &&
        isfrommodal
      )
  );
  return { columns, headers };
};
