import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { MapWrapper } from './map';

const MapZone = ({ worksite, width, height }) => {
  const [clicks, setClicks] = useState({
    lat: parseFloat(worksite?.location?.latitude, 10) || 0,
    lng: parseFloat(worksite?.location?.longitude) || 0
  });

  return (
    <div style={{ height, width }}>
      <MapWrapper clicks={clicks} setClicks={setClicks} />
    </div>
  );
};

MapZone.propTypes = {
  worksite: PropTypes.shape({
    location: PropTypes.shape({
      latitude: PropTypes.string,
      longitude: PropTypes.string
    })
  }).isRequired,
  width: PropTypes.string,
  height: PropTypes.string
};

MapZone.defaultProps = {
  width: 10,
  height: 10
};

export default MapZone;
