import React from 'react';
import { Descriptions, Tag } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

const WeeklyPopoverContent = ({ data }) => {
  const { startTime, endTime, type, comment, status, worksite } = data;
  const translations = {
    ABSENCE: 'Absence',
    PAID_VACATION: 'Congé payé',
    CONTROL: 'Contrôle',
    CREATED: 'CRÉÉ',
    SUSPENDED: 'SUSPENDU',
    PLANNED: 'PLANIFIÉ',
    DONE: 'TERMINÉ',
    IN_PROGRESS: 'EN COURS'
  };

  return (
    <Descriptions style={{ width: 200 }} layout="horizontal">
      <Descriptions.Item label="Horaires" span="24">
        {moment(startTime).format('HH:mm').replace(':', 'h')} -
        {moment(endTime).format('HH:mm').replace(':', 'h')}
      </Descriptions.Item>
      <Descriptions.Item label="Durée" span="24">
        {moment
          .utc(moment(endTime).diff(moment(startTime), 'minutes', true) * 60000)
          .format('HH:mm')
          .replace(':', 'h') || '_'}
      </Descriptions.Item>
      <Descriptions.Item label="Type" span="24">
        {translations[type] || '_'}
      </Descriptions.Item>
      <Descriptions.Item label="Commentaire" span="24">
        {comment || '_'}
      </Descriptions.Item>
      {type === 'CONTROL' ? (
        <>
          <Descriptions.Item label="Statut" span="24">
            {translations[status] || '_'}
          </Descriptions.Item>
          <Descriptions.Item label="Chantier" span="24">
            <Tag color={worksite?.color}>{worksite?.name}</Tag>
          </Descriptions.Item>
        </>
      ) : (
        <Descriptions.Item label="Type" span="24">
          {translations[status]}
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

WeeklyPopoverContent.propTypes = {
  data: PropTypes.shape(PropTypes.shape({})).isRequired
};

export default WeeklyPopoverContent;
