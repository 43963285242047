import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Drawer } from 'antd';
import { MapWrapper } from './map';

export const DrawerMap = ({
  refresh,
  setRefresh,
  clicks,
  setClicks,
  setPredictedAddress
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        type="link"
        style={{ padding: 0, height: 'auto' }}
        onClick={() => setRefresh(!refresh)}
      >
        {`${t(`drawerform.openmap`)}`}
      </Button>
      <Drawer
        width="700px"
        title={t(`drawerform.addaddress`)}
        open={refresh}
        onCancel={() => setRefresh(!refresh)}
        onClose={() => setRefresh(!refresh)}
        footer={[]}
      >
        <MapWrapper
          clicks={clicks}
          setClicks={setClicks}
          setPredictedAddress={setPredictedAddress}
        />
      </Drawer>
    </>
  );
};

DrawerMap.propTypes = {
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
  clicks: PropTypes.instanceOf(Object).isRequired,
  setClicks: PropTypes.func.isRequired,
  setPredictedAddress: PropTypes.func.isRequired
};
