import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListMails from './ListMails';
import ShowMail from './ShowMail';

export const MailRouter = () => (
  <Routes>
    <Route path="/" element={<ListMails />} />
    <Route path="/show/:id" element={<ShowMail />} />
  </Routes>
);
