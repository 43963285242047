import React from 'react';
import { Card, Button, Typography, Tag } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { LocationIcon } from '../../../utils/constants/customIcons';

const { Text } = Typography;

const WorksiteCard = ({ card }) => (
  <Card key={card._id} bordered={false}>
    <div className="card-side-left">
      <Text className="card-title">{card.name}</Text>
      <Text className="card-text">
        <Tag color={card?.type?.color}>{card?.type?.label}</Tag>
        <Tag color={card?.phase?.color}>{card?.phase?.label}</Tag>
      </Text>
      <Text className="card-text">{card?.idPromoter?.name}</Text>
      <div className="address">
        <LocationIcon />
        <Text> {card?.address_full}</Text>
      </div>
    </div>
    <Link
      to={{
        pathname: `/show/${card?._id}`
      }}
    >
      <Button className="btn-pill btn-light" shape="round" icon="" size="large">
        Ouvrir
      </Button>
    </Link>
  </Card>
);

WorksiteCard.propTypes = {
  card: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.shape({
      color: PropTypes.string,
      label: PropTypes.string
    }),
    phase: PropTypes.shape({
      color: PropTypes.string,
      label: PropTypes.string
    }),
    idPromoter: PropTypes.shape({ name: PropTypes.string }),
    address_full: PropTypes.string
  }).isRequired
};

export default WorksiteCard;
