import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Carousel, Button, Row, Card, Col } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { useDrop } from 'react-dnd';
import { DraggableCard } from './DraggableCard';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

const CarouselWithCards = ({
  resourceName,
  extraQuery,
  populate,
  forceRefresh,
  chunkSize,
  setForceRefresh
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [resources, setResources] = useState([]);
  const [totalCards, setTotalCards] = useState(0);
  const [visibleCards, setVisibleCards] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const forceRefreshRef = useRef(forceRefresh);
  useEffect(() => {
    forceRefreshRef.current = forceRefresh;
  }, [forceRefresh]);

  const updateControl = async (id) => {
    const body = {
      start_time: null,
      end_time: null,
      status: 'CREATED'
    };
    try {
      const { data } = await dispatchAPI('PATCH', {
        url: `/controls/planning/${id}`,
        body
      });
      if (data) setForceRefresh(forceRefreshRef.current + 1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'GRID',
    drop: async (item) => {
      if (item.events.type === 'CONTROL') {
        await updateControl(item.events.key);
      }
      return {};
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  }));

  const dropStyle = {
    backgroundColor: isOver
      ? 'var(--itemActiveBackground)'
      : 'var(--clientColor)'
  };

  const carouselRef = React.createRef();

  const next = () => {
    const nextIndex = (currentIndex + 1) % resources.length;
    setCurrentIndex(nextIndex);
    setVisibleCards(Math.min(chunkSize, resources[nextIndex].length));
    carouselRef.current.next();
  };

  const prev = () => {
    const prevIndex = (currentIndex - 1 + resources.length) % resources.length;
    setCurrentIndex(prevIndex);
    setVisibleCards(Math.min(chunkSize, resources[prevIndex].length));
    carouselRef.current.prev();
  };

  const fetchResources = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${resourceName}${extraQuery ? `?${extraQuery}` : ''}${
          populate
            ? `${
                extraQuery ? `&populate=${populate}` : `?populate=${populate}`
              }`
            : ''
        }`
      });
      const chunks = [];
      for (let i = 0; i < data.length; i += chunkSize) {
        chunks.push(data.slice(i, i + chunkSize));
      }
      setResources(chunks);
      setTotalCards(data.length);
      setVisibleCards(Math.min(chunkSize, data.length));
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    fetchResources();
  }, [forceRefresh]);

  return (
    <Card ref={drop} className="carousel-wrapper" style={{ ...dropStyle }}>
      <Row justify="center" align="middle">
        <Col span={1}>
          <Button
            type="primary"
            shape="circle"
            icon={<LeftOutlined />}
            onClick={prev}
          />
        </Col>
        <Col span={22}>
          <Carousel
            ref={carouselRef}
            dots={false}
            className="carousel-container"
          >
            {resources.map((resource, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Row key={index} gutter={[8, 8]}>
                {resource.map((r) => (
                  <DraggableCard key={r._id} _id={r._id} content={r} />
                ))}
              </Row>
            ))}
          </Carousel>
        </Col>
        <Col span={1}>
          <Button
            type="primary"
            shape="circle"
            icon={<RightOutlined />}
            onClick={next}
            style={{ float: 'right' }}
          />
        </Col>
      </Row>
      <Row
        justify="end"
        className="event-counter"
      >{`${visibleCards} contrôles affichés sur ${totalCards}`}</Row>
    </Card>
  );
};

export default CarouselWithCards;

CarouselWithCards.propTypes = {
  resourceName: PropTypes.string.isRequired,
  extraQuery: PropTypes.string,
  populate: PropTypes.string,
  forceRefresh: PropTypes.bool,
  chunkSize: PropTypes.number,
  setForceRefresh: PropTypes.func
};

CarouselWithCards.defaultProps = {
  extraQuery: undefined,
  populate: undefined,
  forceRefresh: undefined,
  chunkSize: null,
  setForceRefresh: null
};
