/* eslint-disable no-console */
import React, { useState } from 'react';
import { Button, Form, Popconfirm, Switch, Table, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CloseOutlined,
  EditOutlined,
  SaveOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import EditableCell from '../EditableCell';

const batchTitlesTable = ({
  batchTitlesCols,
  editBatchTitlesCol,
  deleteBatchTitlesCol
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [colorPicker, setColorPicker] = useState('');
  const [switchLoading, setSwitchLoading] = useState(false);

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      label: record.label,
      color: record.color,
      show: record.show,
      ...record
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };
  const setColor = async ({ color }) => {
    await form.setFieldsValue({
      color: colorPicker || color
    });
  };

  const saveSwitch = (record) => {
    try {
      editBatchTitlesCol(record._id, record);
      setEditingKey('');
    } catch (e) {
      console.log('Error with Switch change: ', e);
    }
  };

  const save = async (record) => {
    await setColor(record);
    try {
      const row = await form.validateFields();
      editBatchTitlesCol(record._id, row);
      setEditingKey('');
    } catch (e) {
      console.log('Validate Failed:', e);
    }
  };

  const columns = [
    {
      title: t('settings.batchtitlesTable.label'),
      dataIndex: 'label',
      width: '20%',
      editable: true
    },
    {
      title: t('settings.batchtitlesTable.color'),
      dataIndex: 'color',
      width: '20%',
      editable: true,
      render: (color) => <Tag color={color}>{color}</Tag>
    },
    {
      title: t('settings.batchtitlesTable.show'),
      dataIndex: 'show',
      width: '20%',
      editable: true,
      render: (value, record) => (
        <Switch
          loading={switchLoading}
          checked={value}
          onClick={() => {
            setSwitchLoading(true);
            setTimeout(() => {
              // eslint-disable-next-line no-param-reassign
              record.show = !value;
              saveSwitch(record);
              setSwitchLoading(false);
            }, 500);
          }}
        />
      )
    },
    {
      title: t('settings.batchtitlesTable.action'),
      dataIndex: 'operation',
      width: '20%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div>
            <Button
              // eslint-disable-next-line no-script-url
              href="javascript:;"
              onClick={() => save(record)}
              style={{ borderRadius: 0 }}
            >
              {t('buttons.save')}
              <SaveOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.confirm-deletion')}
              onConfirm={cancel}
            >
              <Button style={{ borderRadius: 0 }}>
                {t('buttons.cancel')}
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </div>
        ) : (
          <div>
            <Button
              onClick={() => edit(record)}
              disabled={editingKey !== ''}
              type="primary"
              style={{ borderRadius: 0 }}
            >
              {`${t('buttons.edit')}`}
              <EditOutlined />
            </Button>
            {record?.label === 'Nouvelle ligne' && (
              <Button
                onClick={() => deleteBatchTitlesCol(record)}
                disabled={editingKey !== ''}
                type="primary"
                style={{ borderRadius: 0 }}
              >
                {`${t('buttons.delete')}`}
                <DeleteOutlined />
              </Button>
            )}
          </div>
        );
      }
    }
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: ['color', 'icon'].includes(col.dataIndex)
          ? 'select'
          : 'text',
        selectType: col.dataIndex === 'color' ? 'color' : 'icon',
        checkType: col.dataIndex === 'show',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        colorPicker,
        setColorPicker
      })
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={batchTitlesCols}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

export default batchTitlesTable;
