import React from 'react';
import { Typography, Collapse, List } from 'antd';
import PropTypes from 'prop-types';

const { Panel } = Collapse;
const { Text } = Typography;

const InformationPanel = ({ content }) => (
  <Collapse accordion defaultActiveKey={['0']} className="control-collapse">
    {content.map((panel, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Panel header={panel.title} key={index}>
        <List
          size="small"
          dataSource={panel.content}
          renderItem={(elem) => (
            <List.Item>
              <Text className="control-info-label">{elem.title}</Text>
              <Text className="control-info-value">{elem.value}</Text>
            </List.Item>
          )}
        />
      </Panel>
    ))}
  </Collapse>
);

InformationPanel.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default InformationPanel;
