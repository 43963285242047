import React from 'react';
import { Link } from 'react-router-dom';
import { routes, subRoutes } from '../../utils/constants/adminRoutes';

const promoterList = (data) => {
  const { name, siret_number, vat_number, address, contact } = data;

  return [
    {
      label: 'promoters.form.name',
      span: 2,
      content: name || '-'
    },
    {
      label: 'promoters.form.siret_number',
      span: 2,
      content: siret_number || '-'
    },
    {
      label: 'promoters.form.vat_number',
      span: 2,
      content: vat_number || '-'
    },
    {
      label: 'promoters.form.address_full',
      span: 2,
      content:
        address && address.number !== ''
          ? `${address.number || ''} ${address.street || ''} ${
              address.additional || ''
            } ${address.postal_code || ''} ${address.city || ''}`
          : '-'
    },
    {
      label: 'promoters.form.contact',
      span: 2,
      content: contact ? (
        <Link
          to={{
            pathname: `${routes.USERS}${subRoutes.USERS.PROMOTERS}/show/${contact._id}`
          }}
        >
          {contact.email}
        </Link>
      ) : (
        '-'
      )
    }
  ];
};

export default promoterList;
