import React from 'react';
import { Bar } from '@ant-design/plots';
import { Alert, Card } from 'antd';
import PropTypes from 'prop-types';
import { InfoCircleOutlined } from '@ant-design/icons';

export const bar = (data, type, isStack) => {
  const config = {
    data,
    isStack,
    xField: type,
    yField: 'name',
    seriesField: isStack ? 'type' : 'name',
    height: 200,
    legend: isStack,
    label: {
      position: 'middle',
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif"
    }
  };
  return <Bar {...config} />;
};
const CardBar = ({ dataAdmin, title, info }) => {
  const data = dataAdmin;

  return (
    <Card
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {title}
          {info && <InfoCircleOutlined className="dashboard-info-icon" />}
          {info}
        </div>
      }
    >
      <Alert
        description={bar(data, 'worksite', false)}
        className="dashboard-alert"
      />
    </Card>
  );
};

CardBar.propTypes = {
  dataAdmin: PropTypes.instanceOf(Array),
  title: PropTypes.string.isRequired,
  info: PropTypes.element
};

CardBar.defaultProps = {
  dataAdmin: undefined,
  info: null
};

export default CardBar;
