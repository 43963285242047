import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListUsers from './ListUsers';
import { Exception } from '../../components';
import CreateUpdateUser from './CreateUpdateUser';
import ShowUser from './ShowUser';
import ShowWorker from '../workers/ShowWorker';
import { useAuthContext } from '../../contexts/AuthContext';

export const UserCompanyRouter = () => {
  const { user } = useAuthContext();
  const isCompanyAdmin = user?.role === 'companies:ADMIN-COMPANY';

  return (
    <Routes>
      <Route
        path="/userscompanies"
        element={
          <ListUsers
            category="/companies:/i"
            type="companies"
            headless={false}
          />
        }
      />
      {isCompanyAdmin && (
        <>
          <Route
            path="userscompanies/edit/:id"
            element={<CreateUpdateUser purpose="edit" type="companies" />}
          />
          <Route
            path="userscompanies/create"
            element={<CreateUpdateUser purpose="create" type="companies" />}
          />
        </>
      )}
      <Route
        path="userscompanies/show/:id"
        element={<ShowUser type="companies" />}
      />
      {isCompanyAdmin && (
        <>
          <Route
            path="/usersworkers"
            element={
              <ListUsers
                category="/workers:/i"
                type="workers"
                headless={false}
              />
            }
          />
          <Route
            path="usersworkers/create/:cardNumber"
            element={<CreateUpdateUser purpose="create" type="workers" />}
          />
          <Route
            path="usersworkers/create/"
            element={<CreateUpdateUser purpose="create" type="workers" />}
          />
          <Route
            path="usersworkers/edit/:id"
            element={<CreateUpdateUser purpose="edit" type="workers" />}
          />
          <Route
            path="usersworkers/show/:id"
            element={<ShowUser type="workers" />}
          />
          <Route
            path="usersworkers/show/:id"
            element={<ShowWorker type="workers" />}
          />
        </>
      )}

      <Route path="*" element={<Exception />} />
    </Routes>
  );
};
