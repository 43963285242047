import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { Input, Select, DatePicker, TimePicker } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const useFields = (users, date) => {
  const { t } = useTranslation();
  const [startTime, setStartTime] = useState(
    Object.keys(date).length !== 0 &&
      moment(date.date).set({
        hour: parseInt(date.hour.split(':')[0], 10),
        minute: 0,
        second: 0,
        millisecond: 0
      })
  );

  const handleTimeSelect = (momentValue) => {
    setStartTime(momentValue);
  };

  const disabledHours = useCallback(
    (type) => {
      const hours = [0, 1, 2, 3, 4, 5, 6, 22, 23];
      if (startTime && type !== 'start') {
        const startHour = startTime.hour();
        for (let i = 0; i <= startHour; i += 1) {
          if (!hours.includes(i)) {
            hours.push(i);
          }
        }
      }
      return hours;
    },
    [startTime]
  );

  const onsearchInspector = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const fields = [
    {
      name: ['reference'],
      rules: [{ required: true }],
      input: <Input disabled />
    },
    {
      name: ['inspector'],
      rules: [{ required: true }],
      input: (
        <Select
          style={{ width: '300px' }}
          placeholder={t('calendar.placeholder')}
          showSearch
          filterOption={(input, option) => onsearchInspector(input, option)}
        >
          {(users || []).map((u) => (
            <Option key={u._id} value={u._id}>
              {`${u.first_name} ${u.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          defaultValue={Object.keys(date).length !== 0 && moment(date.date)}
          disabled
        />
      )
    },
    {
      name: ['start_time'],
      rules: [{ required: true }],
      input: (
        <TimePicker
          format="HH:mm"
          disabledHours={() => disabledHours('start')}
          defaultValue={
            Object.keys(date).length !== 0 &&
            moment(date.date).set({
              hour: parseInt(date.hour.split(':')[0], 10),
              minute: 0,
              second: 0,
              millisecond: 0
            })
          }
          onSelect={handleTimeSelect}
        />
      )
    },
    {
      name: ['end_time'],
      rules: [{ required: true }],
      input: <TimePicker format="HH:mm" disabledHours={disabledHours} />
    }
  ];

  return {
    fields
  };
};

export default useFields;
