import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Table, Divider, Button, message, Popconfirm } from 'antd';
import {
  EyeOutlined,
  DownloadOutlined,
  WarningOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { useDownloadDocument } from '../../utils/downloadDoc';
import { useAuthContext } from '../../contexts/AuthContext';

const ListDocuments = ({ data }) => {
  const documents = data?.documents;
  const iconSize = 18;

  const { t } = useTranslation();
  const { downloadDocument } = useDownloadDocument();
  const { dispatchAPI, user } = useAuthContext();
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';

  const deleteFile = async (id) => {
    try {
      await dispatchAPI(`DELETE`, { url: `files/${id}` });
      window.location.reload();
    } catch (error) {
      message(error);
    }
  };

  const columns = [
    {
      title: t('incidents.form.documents.name'),
      key: 'metadata',
      dataIndex: 'metadata',
      render: (metadata) => metadata?.originalName
    },
    {
      title: t('incidents.form.documents.date_of upload'),
      key: 'documents',
      dataIndex: 'documents',
      render: (metadata) =>
        moment(metadata?.uploadDate).format('DD / MM / YYYY')
    },
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <>
          <Button onClick={() => downloadDocument(record, 'show')} type="link">
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Button>

          <Divider type="vertical" />
          <Button onClick={() => downloadDocument(record)} type="link">
            <DownloadOutlined style={{ fontSize: iconSize }} />
          </Button>
          <Divider type="vertical" />
          {isAdmin ? (
            <Popconfirm
              title={t('incidents.files.delete.title')}
              okText={t('incidents.files.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('incidents.files.delete.cancel')}
              onConfirm={() => deleteFile(record._id)}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: '#b51010', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          ) : (
            []
          )}
        </>
      )
    }
  ];
  return <Table dataSource={documents} columns={columns} pagination={false} />;
};

ListDocuments.propTypes = {
  data: PropTypes.shape()
};
ListDocuments.defaultProps = {
  data: null
};

export default ListDocuments;
