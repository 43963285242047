import { Col, Row, Tag, Popover } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { weeklyContent } from '../../routes/calendar/weeklyContent';
import { weeklyPlanningContent } from '../../routes/planning/weeklyPlanningContent';
import WeeklyPopoverContent from '../../routes/planning/WeeklyPopoverContent';
import { routes } from '../../utils/constants/adminRoutes';

const Cell = styled.div`
  list-style: none;
  user-select: none;
  width: 100%;
  height: 100%;
`;

type DataObject = {
  date: string;
  hour: string;
};

interface IEventItem {
  _id: string;
  date: Date;
  startTime?: string;
  endTime?: string;
  type?: string;
  comment?: string;
  status?: string;
  worksite?: {
    name: string;
    color: string;
  };
}

export const weekCellRender = (
  eventItem: IEventItem,
  allDay: boolean,
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
  setEventToUpdate: React.Dispatch<React.SetStateAction<string>>,
  setDate: React.Dispatch<React.SetStateAction<DataObject>>,
  deleteEvent: React.Dispatch<React.SetStateAction<string>>,
  resource: string
) => {
  const handleButtonClick = () => {
    setEventToUpdate(eventItem._id);
    setOpenModal(true);
  };
  const completeEventItem = {
    ...eventItem,
    startTime: eventItem.startTime || null,
    endTime: eventItem.endTime || null,
    type: eventItem.type || null,
    comment: eventItem.comment || null,
    status: eventItem.status || null,
    worksite: eventItem.worksite || null
  };

  const content =
    resource === 'planning'
      ? weeklyPlanningContent(completeEventItem, handleButtonClick, deleteEvent)
      : weeklyContent(completeEventItem, handleButtonClick, deleteEvent);

  return (
    <Row justify="end">
      {(content || []).map((c) => (
        <Popover
          trigger="click"
          content={<WeeklyPopoverContent data={completeEventItem} />}
          placement="right"
        >
          <Col span={c.span}>{c.content}</Col>
        </Popover>
      ))}
    </Row>
  );
};

export const monthCellRender = (value: moment.Moment, events: Array<any>) => {
  const date = value;

  const eventsToDisplay = (events || []).filter(
    (event) =>
      date.day() !== 0 &&
      date.format('YYYY-MM-DD') >= moment(event.date).format('YYYY-MM-DD') &&
      date.format('YYYY-MM-DD') <= moment(event.date).format('YYYY-MM-DD')
  );

  return eventsToDisplay.map((event) => {
    const { startTime, endTime, type } = event;

    type TranslationsType = {
      ABSENCE: string;
      PAID_VACATION: string;
      CONTROL: string;
      CREATED: string;
      SUSPENDED: string;
      PLANNED: string;
      DONE: string;
      IN_PROGRESS: string;
    };

    function getTranslations<T extends Record<string, keyof TranslationsType>>(
      keys: T
    ): Record<keyof T, string> {
      const translations = {
        ABSENCE: 'Absence',
        PAID_VACATION: 'Congé payé',
        CONTROL: 'Contrôle',
        CREATED: 'CRÉÉ',
        SUSPENDED: 'SUSPENDU',
        PLANNED: 'PLANIFIÉ',
        DONE: 'TERMINÉ',
        IN_PROGRESS: 'EN COURS'
      };
      const result = {} as Record<keyof T, string>;
      for (const key in keys) {
        if (keys[key] in translations) {
          result[key] = translations[keys[key]];
        }
      }
      return result;
    }

    const translations = {
      ABSENCE: 'Absence',
      PAID_VACATION: 'Congé payé',
      CONTROL: 'Contrôle',
      CREATED: 'CRÉÉ',
      SUSPENDED: 'SUSPENDU',
      PLANNED: 'PLANIFIÉ',
      DONE: 'TERMINÉ',
      IN_PROGRESS: 'EN COURS'
    };

    const eventTranslations = getTranslations({ type: event.type });

    return (
      <li key={event._id}>
        <Tag color={event.backgroundColor}>
          <Row
            style={{
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
            {
              <Col span={24}>
                {`${moment(startTime).format('LT')} à 
              ${moment(endTime).format('LT')} - 
              ${type && eventTranslations.type}`}
              </Col>
            }
          </Row>
        </Tag>
      </li>
    );
  });
};

export const annualCellRender = (value: moment.Moment, events: Array<any>) => {
  const month = moment(value).month();
  return (
    <Cell>
      {(events || [])
        .filter(
          (event) =>
            month >= moment(event.date).month() &&
            month <= moment(event.date).month()
        )
        .sort((a, b) => moment(b.date).day() - moment(a.date).day())
        .map((event) => (
          <li key={event._id}>
            <Link
              to={{
                pathname: `${routes.CALENDAR}/show/${event._id}`
              }}
            >
              {moment(event.date).format('DD-MM-YYYY')}
            </Link>
          </li>
        ))}
    </Cell>
  );
};
