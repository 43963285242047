import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { outOfNavRoutes, routes } from '../utils/constants/adminRoutes';
import { Login } from '../routes/login';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { AdminLayout } from '../components/layouts/AdminLayout';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/Settings';
import { UserRouter } from '../routes/users/UserRouter';
import { WorksiteRouter } from '../routes/worksites/WorksiteRouter';
import { CompanyRouter } from '../routes/company/CompanyRouter';
import { PromoterRouter } from '../routes/promoter/PromoterRouter';
import { ControlRouter } from '../routes/controls/ControlRouter';
import { IncidentRouter } from '../routes/incidents/IncidentRouter';
import { AdminTheme } from '../assets/styles/Theme/AdminTheme';
import AdminHome from '../routes/home/AdminHome';
import { WorkerRouter } from '../routes/workers/WorkerRouter';
import { ShowCalendar } from '../routes/calendar/ShowCalendar';
import { Planning } from '../routes/planning/Planning';
import { MailRouter } from '../routes/mail/MailRouter';

const RequireAuth = ({ isValid }) => {
  const location = useLocation();

  if (!isValid) {
    return (
      <Navigate to={outOfNavRoutes.LOGIN} state={{ from: location }} replace />
    );
  }

  return <AdminLayout />;
};

RequireAuth.propTypes = {
  isValid: PropTypes.bool.isRequired
};

export const AdminRouter = () => {
  const { user, isValid } = useAuthContext();
  const isSuperAdmin = user?.role === 'admins:SUPER-ADMIN';

  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'admin';
    }
  }, []);

  return (
    <BrowserRouter>
      <AdminTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route element={<RequireAuth isValid={isValid} />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          {/* ----------------------------------------------------- */}
          {isSuperAdmin && (
            <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
          )}
          <Route path={routes.HOME} element={<AdminHome />} />
          <Route path={`${routes.USERS}/*`} element={<UserRouter />} />
          <Route path={`${routes.WORKERS}/*`} element={<WorkerRouter />} />
          <Route path={`${routes.WORKSITES}/*`} element={<WorksiteRouter />} />
          <Route path={`${routes.CALENDAR}/*`} element={<ShowCalendar />} />
          <Route path={`${routes.PLANNING}/*`} element={<Planning />} />
          <Route path={`${routes.CONTROLS}/*`} element={<ControlRouter />} />
          <Route path={`${routes.INCIDENTS}/*`} element={<IncidentRouter />} />
          <Route path={`${routes.MAILS}/*`} element={<MailRouter />} />
          <Route
            path={`${routes.PROMOTERS}/*`}
            element={<PromoterRouter type="promoters" />}
          />
          <Route
            path={`${routes.COMPANIES}/*`}
            element={<CompanyRouter type="companies" />}
          />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
