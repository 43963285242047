import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Row, Tag, Badge, Card, Button, Typography, Col, Upload } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  WarningOutlined,
  MailOutlined,
  PhoneOutlined,
  EyeOutlined,
  DownloadOutlined,
  InboxOutlined
} from '@ant-design/icons';
import {
  CompanyIcon,
  SubcontractorIcon
} from '../../../utils/constants/customIcons';
import { ShortcutButton } from './ControlDetailsComponents';
import MapZone from '../../worksites/MapZone';
import { customCompaniesArray } from '../WorksiteDetails';
import MobileContextProvider from '../MobileContext';

const { Text } = Typography;
const { Dragger } = Upload;

const collapseInfos = (t, mode, id) => {
  const {
    handlePostDocuments,
    props,
    documents,
    downloadDocument,
    worksite,
    control,
    companies,
    batchTitles,
    incidents,
    comments,
    isWorkermultipleIncident,
    incidentsOfWorsite
  } = MobileContextProvider();

  if (worksite !== null && control !== null)
    return [
      {
        title: t('controls.worksite'),
        content: [
          { title: 'Nom', value: worksite?.name || '-' },
          { title: 'Date de réunion', value: worksite?.meetingDate || '-' },
          { title: 'Adresse', value: worksite?.address_full || '-' },
          {
            title: "Donneur d'ordre",
            value: worksite?.idPromoter?.name || '-'
          },
          { title: 'Nombre de lot', value: worksite?.nbOfBatches || '-' },
          {
            title: 'Type de chantier',
            value: (
              <Tag color={worksite?.type?.color || '-'}>
                {worksite?.type?.label || '-'}
              </Tag>
            )
          },
          {
            title: 'Phase du chantier',
            value: (
              <Tag color={worksite?.phase?.color || '-'}>
                {worksite?.phase?.label || '-'}
              </Tag>
            )
          }
        ]
      },
      {
        title: (
          <Row>
            {`${t('controls.companies')}`}
            <Badge
              style={{ marginLeft: 10, marginTop: 2 }}
              count={
                Array.isArray(worksite?.companies)
                  ? worksite?.companies?.length
                  : customCompaniesArray(worksite, companies)?.length || 0
              }
            />
          </Row>
        ),
        content: (customCompaniesArray(worksite, companies) || []).map(
          (object) => ({
            title: '',
            value: (
              <Card
                title={
                  batchTitles &&
                  batchTitles.find((b) => b?._id === object.id.batchTitle).label
                }
                className="control-company"
              >
                {mode === 'desktop' ? (
                  <Link to={{ pathname: `/companies/show/${object.key}` }}>
                    <Row align="middle">
                      <CompanyIcon />
                      <h5>{object.name || '-'}</h5>
                    </Row>
                  </Link>
                ) : (
                  <Row align="middle">
                    <CompanyIcon />
                    <h5>{object.name || '-'}</h5>
                  </Row>
                )}
                {object.subcontractors &&
                  (object.subcontractors || []).map((name) => (
                    <Row align="middle">
                      <SubcontractorIcon />
                      <p>{name || '-'}</p>
                    </Row>
                  ))}
              </Card>
            )
          })
        )
      },
      {
        title: (
          <Row>
            {`${t('controls.referents')}`}
            <Badge
              style={{ marginLeft: 10, marginTop: 2 }}
              count={worksite?.referents?.length}
            />
          </Row>
        ),
        content: (worksite?.referents || []).map((contact) => ({
          title: '',
          value: (
            <Card className="contact-card">
              <p>{`${contact?.first_name || '-'} ${
                contact?.last_name || '-'
              }`}</p>
              <p>
                <a href={`mailto:${contact?.email}`}>
                  <Row align="middle" style={{ gap: 8 }}>
                    <MailOutlined />
                    {contact?.email || '-'}
                  </Row>
                </a>
              </p>
              <a
                href={`tel:${contact?.phone_number?.country_code}${contact?.phone_number?.number}`}
              >
                <Row align="middle" style={{ gap: 8 }}>
                  <PhoneOutlined />
                  {contact?.phone_number?.country_code || '-'}
                  {contact?.phone_number?.number || '-'}
                </Row>
              </a>
            </Card>
          )
        }))
      },
      {
        title: `${t('controls.address_full')}`,
        content: [
          {
            title: 'Adresse',
            value: (
              <Button
                type="link"
                onClick={() =>
                  window.open(
                    `https://maps.google.com?q=${Number(
                      worksite?.location?.latitude
                    )},${Number(worksite?.location?.longitude)}`
                  )
                }
              >
                {worksite?.address_full || '-'}
              </Button>
            )
          },
          {
            title: 'Implantation',
            value: <MapZone worksite={worksite} width={300} height={300} />
          }
        ]
      },
      {
        title: (
          <Row>
            {`${t('controls.workers')}`}
            <Badge
              style={{ marginLeft: 10, marginTop: 2 }}
              count={control ? control?.workers?.length : 0}
            />
          </Row>
        ),
        content: (control?.workers || []).map((elem) => ({
          title: (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {mode === 'desktop' ? (
                <Link to={{ pathname: `/workers/show/${elem?.worker?._id}` }}>
                  <Text>
                    {`${elem.worker?.first_name || '-'} ${
                      elem.worker?.last_name || '-'
                    }`}
                  </Text>
                </Link>
              ) : (
                <Text>
                  {`${elem.worker?.first_name || '-'} ${
                    elem.worker?.last_name || '-'
                  }`}
                </Text>
              )}
            </>
          ),
          value: (
            <Row align="middle">
              <Link
                to={{
                  pathname: `/worker${mode === 'desktop' ? 's' : ''}/show/${
                    elem?.worker?._id
                  }`
                }}
                className="incident-link"
              >
                <EyeOutlined style={{ marginRight: 16 }} />
              </Link>
              {elem.isValid ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
            </Row>
          )
        }))
      },
      {
        title: (
          <Row>
            <Row>
              {`${t('controls.incidents_of_control')}`}
              <Badge
                style={{ marginLeft: 10, marginTop: 2 }}
                count={incidents?.length || 0}
              />
            </Row>
          </Row>
        ),
        content: (incidents || []).map((elem) => ({
          title: (
            <Row align="middle" style={{ gap: 8 }}>
              {isWorkermultipleIncident(elem) && (
                <WarningOutlined style={{ color: 'var(--errorColor)' }} />
              )}
              <Text>
                {`${
                  elem.worker
                    ? elem.worker.first_name || '-'
                    : elem.unknown_worker || '-'
                } ${elem.worker ? elem.worker.last_name || '-' : ''}`}
              </Text>
            </Row>
          ),
          value: (
            <>
              <Tag color={elem?.type?.color || '-'}>
                {elem?.type?.label || '-'}
              </Tag>
              <Tag color={elem?.status?.color || '-'}>
                {elem?.status?.label || '-'}
              </Tag>
              <Link
                to={{
                  pathname: `/incident${mode === 'desktop' ? 's' : ''}/show/${
                    elem?._id
                  }`
                }}
              >
                <EyeOutlined />
              </Link>
            </>
          )
        }))
      },
      {
        title: (
          <Row>
            <Row>
              {`${t('controls.incidents_of_worksite')}`}
              <Badge
                style={{ marginLeft: 10, marginTop: 2 }}
                count={incidentsOfWorsite?.length || 0}
              />
            </Row>
          </Row>
        ),
        content: (incidentsOfWorsite || []).map((elem) => ({
          title: (
            <Row>
              <Text>
                {`${
                  elem.worker
                    ? elem.worker.first_name || '-'
                    : elem.unknown_worker || '-'
                } ${elem.worker ? elem.worker.last_name || '-' : ''}`}
              </Text>
            </Row>
          ),
          value: (
            <>
              <Tag color={elem?.type?.color || '-'}>
                {elem?.type?.label || '-'}
              </Tag>
              <Tag color={elem?.status?.color || '-'}>
                {elem?.status?.label || '-'}
              </Tag>
              <Link
                to={{
                  pathname: `/incident${mode === 'desktop' ? 's' : ''}/show/${
                    elem?._id
                  }`
                }}
              >
                <EyeOutlined />
              </Link>
            </>
          )
        }))
      },
      {
        title: (
          <Row align="middle" style={{ gap: 8 }}>
            {`${t('controls.comments')}`}
            <Badge
              style={{ marginLeft: 10, marginTop: 2 }}
              count={comments?.length || 0}
            />
            {mode !== 'desktop' && (
              <ShortcutButton
                route={`/comments/create/${id}`}
                text="+ Nouveau"
              />
            )}
          </Row>
        ),
        content: (comments || []).map((elem) => ({
          title: <Text>{elem.content || '-'}</Text>,
          value: (
            <Text>{moment(elem.created_at).format('DD/MM/YYYY HH:mm')}</Text>
          )
        }))
      },
      {
        title: (
          <Row align="middle" style={{ gap: 8 }}>
            {`${t('controls.documents')}`}
            <Badge
              style={{ marginLeft: 10, marginTop: 2 }}
              count={control?.documents?.length || 0}
            />
          </Row>
        ),
        content: [
          ...((control?.documents || []).map((elem) => ({
            title: <Text>{elem.metadata?.originalName || '-'}</Text>,
            value: (
              <Button onClick={() => downloadDocument(elem)} type="link">
                <DownloadOutlined style={{ fontSize: 18 }} />
              </Button>
            )
          })) || []),
          mode !== 'desktop'
            ? {
                title: (
                  <Col
                    xs={24}
                    lg={8}
                    xxl={6}
                    style={{ height: 'fit-content', marginTop: 16 }}
                  >
                    <Dragger {...props}>
                      <InboxOutlined style={{ fontSize: 28 }} />
                      <p className="ant-upload-text">Glisser / Déposer</p>
                    </Dragger>
                    {documents && (
                      <Button
                        type="primary"
                        onClick={() => handlePostDocuments(id)}
                      >
                        Enregistrer
                      </Button>
                    )}
                  </Col>
                ),
                value: null
              }
            : null
        ].filter(Boolean)
      }
    ];
  return [];
};

export default collapseInfos;
