import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Collapse } from 'antd';
import WorksiteTypesSettings from './worksiteTypes/worksiteTypesSettings';
import WorksitePhasesSettings from './worksitePhases/worksitePhasesSettings';
import IncidentStatusesSettings from './incidentStatuses/incidentStatusesSettings';
import IncidentTypesSettings from './incidentTypes/incidentTypesSettings';
import CommentsSettings from './comments/commentsSettings';
import BatchTitlesSettings from './batchTitles/batchTitlesSettings';
import { PageHeaderCustom, ContentCustom } from '../../components';

const { Panel } = Collapse;

const LineSetting = styled.div`
  padding: 16px;
  margin-bottom: 30px;
  p {
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
  }
`;

export const Settings = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeaderCustom title={t('settings.title')} />
      <ContentCustom>
        <Collapse defaultActiveKey={['1']} className="settings-collapse">
          <Panel header="Types de chantier" key="1">
            <LineSetting>
              <p>{t('settings.worksitetypesTable.title')}</p>
              <WorksiteTypesSettings />
            </LineSetting>
          </Panel>
          <Panel header="Phases de chantier" key="2">
            <LineSetting>
              <p>{t('settings.worksitephasesTable.title')}</p>
              <WorksitePhasesSettings />
            </LineSetting>
          </Panel>
          <Panel header="Statuts des incidents" key="3">
            <LineSetting>
              <p>{t('settings.incidentstatusesTable.title')}</p>
              <IncidentStatusesSettings />
            </LineSetting>
          </Panel>
          <Panel header="Types d'incidents" key="4">
            <LineSetting>
              <p>{t('settings.incidenttypesTable.title')}</p>
              <IncidentTypesSettings />
            </LineSetting>
          </Panel>
          <Panel header="Phrases de commentaires" key="5">
            <LineSetting>
              <p>{t('settings.commentsTable.title')}</p>
              <CommentsSettings />
            </LineSetting>
          </Panel>
          <Panel header="Lots BTP" key="7">
            <LineSetting>
              <p>{t('settings.batchtitlesTable.title')}</p>
              <BatchTitlesSettings />
            </LineSetting>
          </Panel>
        </Collapse>
      </ContentCustom>
    </>
  );
};
