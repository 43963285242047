import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import navMenuLogos from '../../utils/constants/navMenuLogos';
import { useHandleResize } from '../../utils/HandleResize';
import { useAuthContext } from '../../contexts/AuthContext';
import {
  routes,
  subRoutes,
  pathSearches
} from '../../utils/constants/adminRoutes';

const { SubMenu } = Menu;

export const AdminNavMenu = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const location = useLocation();
  const { width } = useHandleResize();
  const path = location?.pathname || '/';
  const isSuperAdmin = user?.role === 'admins:SUPER-ADMIN';
  const activeKeys = path
    .replace('/', '')
    .split('/')
    .map((p) => `/${p}`);

  return (
    <Menu
      style={{
        padding: '16px 0',
        background: 'var(--primaryColor)'
      }}
      selectedKeys={activeKeys}
      defaultOpenKeys={activeKeys}
      mode="vertical"
      onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
    >
      {Object.entries(routes)
        .filter((f) => (!isSuperAdmin ? f[0] !== 'SETTINGS' : f))
        .map(([menuItem, p]) =>
          subRoutes[menuItem] ? (
            <SubMenu
              key={p}
              icon={navMenuLogos[menuItem]}
              title={t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
            >
              {Object.entries(subRoutes[menuItem]).map(
                ([subMenuItem, subPath]) => (
                  <Menu.Item key={subPath} disabled={subPath === ''}>
                    <NavLink
                      to={`${p}${subPath}${
                        pathSearches[subMenuItem]
                          ? pathSearches[subMenuItem]
                          : ''
                      }`}
                    >
                      <span>
                        {t(`basiclayout.submenu.${subMenuItem.toLowerCase()}`)}
                      </span>
                    </NavLink>
                  </Menu.Item>
                )
              )}
            </SubMenu>
          ) : (
            <Menu.Item key={p} disabled={p === ''}>
              <NavLink
                to={`${p}${
                  pathSearches[menuItem] ? pathSearches[menuItem] : ''
                }`}
              >
                {navMenuLogos[menuItem]}
                <span>{t(`basiclayout.menu.${menuItem.toLowerCase()}`)}</span>
              </NavLink>
            </Menu.Item>
          )
        )}
    </Menu>
  );
};

AdminNavMenu.propTypes = {
  setCollapsed: PropTypes.func.isRequired
};
