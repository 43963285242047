import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';

const EventModal = ({
  openModal,
  setOpenModal,
  users,
  purpose,
  closeOverlay,
  idFromOverlay
}) => {
  const { fields, isFieldsLoading } = useFields(users);

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        date: data.date && dayjs(data.date),
        startTime: data.startTime && dayjs(data.startTime),
        endTime: data.endTime && dayjs(data.endTime)
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        date: data.date && dayjs(data.date),
        startTime: data.startTime && dayjs(data.startTime),
        endTime: data.endTime && dayjs(data.endTime)
      })
    }
  };

  return (
    <Modal
      centered
      open={openModal}
      onCancel={() => {
        setOpenModal(false);
      }}
      width={700}
      footer={false}
    >
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="events"
        resource="events"
        isOverlay
        customNavigate={closeOverlay}
        idFromOverlay={idFromOverlay}
        config={config}
      />
    </Modal>
  );
};

EventModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  purpose: PropTypes.string,
  closeOverlay: PropTypes.func.isRequired,
  idFromOverlay: PropTypes.string
};

EventModal.defaultProps = {
  users: null,
  purpose: null,
  idFromOverlay: null
};

export default EventModal;
