import React from 'react';
import { Button, message, Tag } from 'antd';
import { Link } from 'react-router-dom';
import Dragger from 'antd/lib/upload/Dragger';
import { FileDoneOutlined } from '@ant-design/icons';
import { userPoste } from '../../utils/constants/tagColors';
import { useDownloadDocument } from '../../utils/downloadDoc';

const workerList = (
  data,
  t,
  handlePostDocuments,
  documents,
  draggerProps,
  incidents
) => {
  const {
    last_name,
    first_name,
    email,
    phone_number,
    poste,
    idCompany,
    unknown_company,
    business_body,
    files,
    card_number
  } = data;
  const { downloadDocument } = useDownloadDocument();
  const postDocument = async () => {
    await handlePostDocuments();
    message.success(t('users.message.post_documents'));
    window.location.reload();
  };

  const workerContent = [
    {
      label: 'workers.form.last_name',
      content: last_name || '-'
    },
    {
      label: 'workers.form.first_name',
      content: first_name || '-'
    },
    {
      label: 'workers.form.email',
      content: email || '-'
    },
    {
      label: 'workers.form.card_number',
      content: card_number || '-'
    },
    {
      label: 'workers.form.phone_number_full',
      content:
        phone_number && phone_number.number ? (
          <div>
            {`${phone_number.country_code} ${phone_number.number.slice(
              0,
              1
            )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}
                    `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: 'workers.form.poste',
      content:
        <Tag color={userPoste[poste]}>{t(`workers.tags.${poste}`)}</Tag> || '-'
    },
    {
      label: 'workers.form.idCompany',
      content: idCompany
        ? idCompany.map((company) => (
            <p>
              <Link to={`/companies/show/${company._id}`}>{company?.name}</Link>
            </p>
          ))
        : '-'
    },
    {
      label: 'workers.form.files',
      content: files?.length ? (
        <>
          {(files || []).map((file) => (
            <Button
              onClick={() => downloadDocument(file?.fileId, 'show')}
              style={{ marginBottom: 10 }}
            >
              {file?.fileName}
            </Button>
          ))}
          <>
            <Dragger {...draggerProps}>
              <p className="ant-upload-drag-icon">
                <FileDoneOutlined style={{ color: 'var(--textColor)' }} />
              </p>
              <p className="ant-upload-text">{t('files.create.action')}</p>
            </Dragger>
            {documents && <Button onClick={postDocument}>Enregistrer</Button>}
          </>
        </>
      ) : (
        <>
          <Dragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
              <FileDoneOutlined style={{ color: 'var(--textColor)' }} />
            </p>
            <p className="ant-upload-text">{t('files.create.action')}</p>
          </Dragger>
          {documents && <Button onClick={postDocument}>Enregistrer</Button>}
        </>
      )
    },
    {
      label: 'workers.form.incidents',
      content: incidents
        ? (incidents || []).map((incident) => (
            <p>
              <Link
                to={`/incidents/show/${incident?._id}`}
              >{`Incident: Statut "${`${
                incident?.status?.label || '_'
              }`}"`}</Link>
            </p>
          ))
        : '-'
    }
  ];
  const isUnknownCompany = [
    {
      label: 'workers.form.unknown_company',
      content: unknown_company || '-',
      disable: false
    },
    {
      label: 'workers.form.business_body',
      content: business_body?.label || '-',
      disable: false
    }
  ];

  const list = unknown_company
    ? [...workerContent, ...isUnknownCompany]
    : workerContent;
  return list;
};

export default workerList;
