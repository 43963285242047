import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { useAuthContext } from './AuthContext';

const SocketContext = createContext({});

export const SocketContextProvider = ({ children }) => {
  const { token } = useAuthContext();
  const [socket, setSocket] = useState();

  useEffect(() => {
    if (token) {
      setSocket(io(`${process.env.REACT_APP_API_URL}?token=${token}`));
    } else if (socket && !token) {
      socket.emit('logout');
      socket.close();
    }
    return () => {
      if (socket) socket.close();
    };
  }, [token]);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => useContext(SocketContext);
