import React from 'react';
import { Button, Col, Drawer, Form, message, Row, Typography } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { drawerFormLayout } from '../../utils/constants/formLayout';
import useFields from './fields';
import { useGenerateFormItem } from '../../utils/GenerateFormItem';

const { Title } = Typography;

const NewUserDrawer = ({
  type,
  isVisible,
  setVisibilityUserDrawer,
  createUser,
  isLoading
}) => {
  const { t } = useTranslation();

  const generateFields = useGenerateFormItem();
  const { fields } = useFields(type, true);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    await createUser(values);
    form.resetFields();
  };

  const onFinishFailed = () => {
    message.error(t('errors.message.required'));
  };

  const fieldsToInclude = [
    'last_name',
    'first_name',
    'send_email',
    'poste',
    'email',
    'role'
  ];

  return (
    <Drawer
      open={isVisible}
      onClose={() => {
        setVisibilityUserDrawer(false);
      }}
      width="50%"
    >
      <Col style={{ width: '100%', marginBottom: '5%' }}>
        <Title level={3}>{t(`users.form.title.create`)}</Title>
      </Col>
      <Form
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...drawerFormLayout}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        {fields
          .filter((f) =>
            fieldsToInclude.some((field) => f.name.includes(field))
          )
          .map((field) => generateFields('users', field))}
        <Form.Item>
          <Row justify="end">
            <Button
              style={{ margin: '0 10px' }}
              type="add"
              htmlType="submit"
              loading={isLoading}
              disabled={isLoading}
            >
              {t('buttons.save')}
              <CheckOutlined />
            </Button>
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              danger
              onClick={() => {
                setVisibilityUserDrawer(false);
                form.resetFields();
              }}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

NewUserDrawer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  setVisibilityUserDrawer: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  getUser: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

NewUserDrawer.defaultProps = {
  users: []
};

export default NewUserDrawer;
