import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import moment from 'moment';
import useFields from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useAuthContext } from '../../contexts/AuthContext';

const CreateUpdateIncident = ({ purpose }) => {
  const {
    fields,
    isFieldsLoading,
    unknownWorker,
    unknownCompany,
    documents,
    setDocuments
  } = useFields(purpose);
  const { dispatchAPI } = useAuthContext();

  const getPrincipalCompany = async (worksite, business_body) => {
    let company;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `worksites/${worksite}`
      });
      company = data?.companies[business_body]?.principal;
    } catch (e) {
      message(e);
    }
    return company;
  };

  const config = {
    onGetResource: {
      setFields: (data) => {
        setDocuments(
          data.files.map((file) => ({
            ...file,
            name: file.fileName,
            uid: file.fileId
          }))
        );
        return {
          ...data,
          date: data.date && moment(data.date)
        };
      }
    },

    onUpdateResource: {
      setBody: async (data) => {
        let documents_saved;
        const files = [];
        if (documents?.length) {
          const formData = new FormData();
          documents.forEach((doc) => {
            formData.append('file', doc.originFileObj || doc);
            formData.append('type', doc.type);
          });
          documents_saved = await dispatchAPI('POST', {
            url: `/files/upload`,
            body: formData
          });
          if (documents_saved) {
            documents_saved.data.description.forEach((file) =>
              files.push({ fileId: file.id, fileName: file.fileName })
            );
          }
        }
        return {
          ...data,
          ...(documents_saved
            ? {
                files
              }
            : { files: documents })
        };
      }
    },

    onCreateResource: {
      setBody: async (data) => {
        let documents_saved;
        const files = [];
        if (documents?.length) {
          const formData = new FormData();
          documents.forEach((doc) => {
            formData.append('documents', doc.originFileObj || doc);
            formData.append('type', doc.type);
          });
          documents_saved = await dispatchAPI('POST', {
            url: `/files`,
            body: formData
          });
          if (documents_saved) {
            documents_saved.data.description.forEach((file) =>
              files.push({ fileId: file.id, fileName: file.fileName })
            );
          }
        }
        let principalCompany;
        if (data.business_body && data.worksite) {
          principalCompany = await getPrincipalCompany(
            data.worksite,
            data.business_body
          );
        }
        return {
          ...data,
          ...(unknownWorker
            ? { unknown_worker: unknownWorker, worker: null }
            : {}),
          ...(unknownCompany
            ? {
                unknown_company: unknownCompany,
                company: null
              }
            : {}),
          ...(principalCompany
            ? {
                principal_company: principalCompany
              }
            : {}),
          ...(documents_saved
            ? {
                files,
                justification_status: 'PENDING'
              }
            : {})
        };
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="incidents"
      resource="incidents"
      config={config}
      needFormDependencies
    />
  );
};

CreateUpdateIncident.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateIncident;
