import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Typography } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import navMenuLogos from '../../utils/constants/navMenuLogos';
import {
  routes,
  subRoutes,
  pathSearches
} from '../../utils/constants/inspectorRoutes';

const { SubMenu } = Menu;
const { Title, Text } = Typography;

export const InspectorNavMenu = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const path = location?.pathname || '/';
  const activeKeys = path
    .replace('/', '')
    .split('/')
    .map((p) => `/${p}`);

  return (
    <Menu selectedKeys={activeKeys} defaultOpenKeys={activeKeys}>
      {Object.entries(routes).map(([menuItem, p]) =>
        subRoutes[menuItem] ? (
          <SubMenu
            key={p}
            icon={navMenuLogos[menuItem]}
            title={t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
          >
            {Object.entries(subRoutes[menuItem]).map(
              ([subMenuItem, subPath]) => (
                <Menu.Item key={subPath} disabled={subPath === ''}>
                  <NavLink
                    to={`${p}${subPath}${
                      pathSearches[subMenuItem] ? pathSearches[subMenuItem] : ''
                    }`}
                  >
                    <Title level={2}>
                      {t(`basiclayout.submenu.${subMenuItem.toLowerCase()}`)}
                    </Title>
                  </NavLink>
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={p} disabled={p === ''}>
            <NavLink
              to={`${p}${pathSearches[menuItem] ? pathSearches[menuItem] : ''}`}
            >
              {navMenuLogos[menuItem]}
              <Text>{t(`basiclayout.menu.${menuItem.toLowerCase()}`)}</Text>
            </NavLink>
          </Menu.Item>
        )
      )}
    </Menu>
  );
};
