import React, { useState, useEffect } from 'react';
import { startOfWeek, endOfWeek } from 'date-fns';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import moment from 'moment';
import Calendar from './CalendarBody.tsx';
import { CalendarHeader } from './CalendarHeader.tsx';
import { GenericEvent, CalendarContainerProps } from './types.tsx';
import { daysToWeekObject } from './utils.ts';
import { createEvents } from './createEvents.tsx';
import CarouselWithCards from '../../Carousel/CarouselWithCards';

export function WeeklyCalendar<T extends GenericEvent>({
  events,
  onEventClick,
  onSelectDate,
  dayRange = 'no-weekend',
  hourStart = 0,
  hourEnd = 26,
  value,
  ISOWeekName = true,
  modes,
  mode,
  setMode,
  weekCellRender,
  setOpenModal,
  setDate,
  setEventToUpdate,
  deleteEvent,
  extraHeader,
  noAllDayRow,
  setFirstDayOfWeek,
  selectedUser,
  selectedWorksite,
  firstDayOfWeek,
  forceRefresh,
  setForceRefresh,
  resource
}: CalendarContainerProps<T>) {
  const [startWeek, setStartWeek] = useState(startOfWeek(value || new Date()));

  const weekPeriod = {
    startDate: startWeek,
    endDate: endOfWeek(startWeek),
    ISOWeekName
  };

  useEffect(() => {
    if (value && startOfWeek(value).getTime() !== startWeek.getTime()) {
      setStartWeek(value);
    }
  }, [value]);

  useEffect(() => {
    if (resource === 'planning') {
      onSelectDate && onSelectDate(startWeek);
      setFirstDayOfWeek(startWeek);
    }
  }, [startWeek]);

  const createEventsFromRawData = createEvents(
    events,
    weekCellRender,
    setOpenModal,
    setEventToUpdate,
    setDate,
    deleteEvent,
    resource
  );

  const weekObject = daysToWeekObject(createEventsFromRawData, startWeek);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {resource === 'planning' ? (
          <CarouselWithCards
            resourceName="controls"
            extraQuery={`inspector=${selectedUser || ''}&worksite=${
              selectedWorksite || ''
            }&period_start=${
              firstDayOfWeek ? moment(firstDayOfWeek) : null
            }&status=CREATED`}
            populate={'worksite'}
            chunkSize={8}
            forceRefresh={forceRefresh}
            setForceRefresh={setForceRefresh}
          />
        ) : (
          []
        )}
        <CalendarHeader
          startWeek={startWeek}
          setStartWeek={setStartWeek}
          ISOWeekName={ISOWeekName}
          modes={modes}
          mode={mode}
          setMode={setMode}
          extraHeader={extraHeader}
        />
        <Calendar
          weekDates={weekPeriod}
          getDayEvents={weekObject}
          onEventClick={onEventClick}
          dayRange={dayRange}
          hourStart={hourStart}
          hourEnd={hourEnd}
          noAllDayRow={noAllDayRow}
          setOpenModal={setOpenModal}
          setEventToUpdate={setEventToUpdate}
          setDate={setDate}
        />
      </DndProvider>
    </>
  );
}
