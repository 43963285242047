import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Skeleton, Row, Tabs, Col, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { listContentPrincipal, listContentSecurity } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import ExtraButtons from './ExtraButtons';
import ControlIncidents from './ControlIncidents';
import ControlWorkers from './ControlWorkers';
import ControlDocuments from './ControlDocuments';
import { useGenerateReport } from '../../../utils/generateReport';

const { TabPane } = Tabs;

const ShowControl = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { generateReport } = useGenerateReport();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [control, setControl] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [comments, setComments] = useState(null);
  const [worksite, setWorksite] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [isDocLoading, setIsDocLoading] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const generateControlReport = async () => {
    setIsDocLoading(true);
    await generateReport('control', id);
    setIsDocLoading(false);
  };

  const getWorksite = async (worksiteId) => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/worksites/${worksiteId}?`
      });
      setWorksite(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  const getControl = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/controls/${id}?populate=inspector,worksite,documents,comments`
      });
      setControl(data);
      await getWorksite(data?.worksite?._id);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id, refresh]);

  const getComments = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/comments/?control=${id}`
      });
      setComments(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getControl();
      await getComments();
    })();
  }, [getControl]);

  const handleTabChange = (key) => {
    navigate(`${pathname}#tabs`);
    setActiveTabKey(key);
  };

  useEffect(() => {
    const tabsElement = document.getElementById('tabs');
    if (tabsElement) {
      setTimeout(() => {
        tabsElement.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [activeTabKey]);

  return (
    <>
      <PageHeaderCustom
        title={`${t('controls.show.title')} - ${control?.reference}`}
        extra={
          <ExtraButtons
            id={id}
            isDocLoading={isDocLoading}
            generateControlReport={generateControlReport}
          />
        }
      />
      <ContentCustom>
        <Row>
          <Col xs={24}>
            <Card bordered={false}>
              <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                <DescriptionList
                  data={listContentPrincipal(control || {}, comments)}
                  translate
                />
              </Skeleton>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: 24 }}>
          <Col xs={24}>
            <div id="tabs">
              <Card bordered={false}>
                <Tabs
                  style={{ width: '100%' }}
                  activeKey={activeTabKey}
                  defaultActiveKey={activeTabKey}
                  onChange={(key) => {
                    handleTabChange(key);
                  }}
                >
                  <TabPane tab="Incidents" key="1">
                    <ControlIncidents control={control} idControl={id} />
                  </TabPane>
                  <TabPane tab="Travailleurs" key="2">
                    <ControlWorkers
                      idControl={control?.workers}
                      type="workers"
                      control={control}
                    />
                  </TabPane>
                  <TabPane tab="Documents" key="4">
                    <ControlDocuments
                      id={id}
                      control={control}
                      documents={documents}
                      setDocuments={setDocuments}
                      refresh={() => setRefresh(!refresh)}
                    />
                  </TabPane>
                </Tabs>
              </Card>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {worksite?.isSecurityActive && (
            <Col xs={24}>
              <Card bordered={false}>
                <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                  <DescriptionList
                    data={listContentSecurity(control || {}, t)}
                    translate
                  />
                </Skeleton>
              </Card>
            </Col>
          )}
        </Row>
      </ContentCustom>
    </>
  );
};

export default ShowControl;
