import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, Input } from 'antd';

import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { PageHeaderCustom } from '../../components/PageHeader/PageHeader';
import { useAuthContext } from '../../contexts/AuthContext';

const { TextArea } = Input;

const CommentExampleSelect = ({ setComment }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [commentExamples, setCommentExamples] = useState([]);

  const getCommentExample = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/commentexamples/?show=true`
      });
      setCommentExamples(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(async () => {
    await getCommentExample();
  }, []);

  return (
    <>
      {commentExamples.map((example) => (
        <Button
          key={example._id}
          style={{ backgroundColor: example.color, color: 'white' }}
          onClick={() => setComment(example.label)}
        >
          {example.label}
        </Button>
      ))}
    </>
  );
};

const CreateUpdateComment = ({ purpose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { user, dispatchAPI } = useAuthContext();
  const { controlId } = useParams();
  const [comment, setComment] = useState('');

  const createComment = async () => {
    const body = {
      content: comment,
      user: user._id,
      control: controlId
    };
    try {
      await dispatchAPI('POST', {
        url: `/comments`,
        body
      });
      navigate(-1);
    } catch (e) {
      message(e);
    }
  };

  return (
    <>
      <PageHeaderCustom title={t(`comments.form.title.${purpose}`)} />
      <TextArea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        className="control-comment-input"
      />
      <Row>
        <Col span={12}>
          <Button type="link" block danger onClick={() => navigate(-1)}>{`${t(
            'buttons.cancel'
          )} `}</Button>
        </Col>
        <Col span={12}>
          <Button type="primary" block onClick={createComment}>{`${t(
            'buttons.save'
          )} `}</Button>
        </Col>
      </Row>
    </>
  );
};

CreateUpdateComment.propTypes = {
  purpose: PropTypes.string.isRequired
};
CommentExampleSelect.propTypes = {
  setComment: PropTypes.func.isRequired
};
export default CreateUpdateComment;
