import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Select, Tag, Row, Input, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { docStatus } from '../../../utils/constants/tagColors';

const { Option } = Select;
const { TextArea } = Input;

const DocumentStatusModal = ({
  incident,
  openModalDocStatus,
  setOpenModalDocStatus,
  patchDocStatus,
  enums,
  patchIsLoading
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onClose = () => {
    setOpenModalDocStatus(false);
    form.resetFields();
  };

  return (
    <Modal
      title={t('incidents.form.modal.title')}
      centered
      style={{ top: 20 }}
      visible={openModalDocStatus}
      onCancel={() => onClose()}
      width={450}
      footer={false}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={patchDocStatus}
        initialValues={incident}
      >
        <Form.Item
          name="justification_status"
          label={t('incidents.form.justification_status')}
          rules={[{ required: true, message: t('errors.form.REQUIRED') }]}
        >
          <Select
            showSearch
            style={{ width: '100%', textAlign: 'left', borderRadius: 5 }}
          >
            {enums?.justification?.map((justification) => (
              <Option value={justification} key={justification}>
                <Tag color={docStatus[justification]}>
                  {t(`incidents.form.tags.${justification}`)}
                </Tag>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) =>
            getFieldValue('justification_status') === 'REFUSED' && (
              <>
                <Form.Item
                  name="refused_comment"
                  label={t('incidents.form.refused_comment')}
                  rules={[
                    { required: true, message: t('errors.form.REQUIRED') }
                  ]}
                >
                  <TextArea />
                </Form.Item>
                <Form.Item
                  name="sendEmail"
                  label={t('incidents.form.sendEmail')}
                >
                  <Switch
                    checkedChildren={t('switch.yes')}
                    unCheckedChildren={t('switch.no')}
                  />
                </Form.Item>
              </>
            )
          }
        </Form.Item>
        <Row style={{ marginTop: 25 }}>
          <Button type="primary" htmlType="submit" loading={patchIsLoading}>
            {t('buttons.validate')}
            <CheckOutlined />
          </Button>
          <Button danger type="link" onClick={() => onClose()}>
            {t('buttons.cancel')}
            <CloseOutlined />
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

DocumentStatusModal.propTypes = {
  openModalDocStatus: PropTypes.bool.isRequired,
  setOpenModalDocStatus: PropTypes.func.isRequired,
  patchDocStatus: PropTypes.func.isRequired,
  incident: PropTypes.shape({
    justification_status: PropTypes.string,
    refused_comment: PropTypes.string
  }).isRequired,
  enums: PropTypes.shape({
    justification: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  patchIsLoading: PropTypes.bool.isRequired
};

export default DocumentStatusModal;
