import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Card, Col, Row, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ContentCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { bar } from './CardBar';
import CardDonuts from './CardDonuts';

const Home = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [dataAdmin, setDataAdmin] = useState(null);
  const [subContractors, setSubContractors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const getData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/dashboard/admins'
      });
      setDataAdmin(data);
    } catch (error) {
      message(error);
    }
  };

  const getSubcontractorsWorkersControled = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/dashboard/companies'
      });
      setSubContractors(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getData();
      await getSubcontractorsWorkersControled();
      setIsLoading(false);
    })();
  }, []);

  return isLoading ? (
    <Spin
      spinning={isLoading}
      size="large"
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%'
      }}
    />
  ) : (
    dataAdmin && (
      <ContentCustom>
        <Row className="dashboard-row">
          <Col xs={24} lg={12}>
            <CardDonuts
              dataAdmin={dataAdmin?.whitoutCard?.data}
              title={t('dashboard.incidents_numbers_per_type')}
              total={dataAdmin?.whitoutCard?.total}
              info={
                <div className="dashboard-card-infos">
                  <p>{t('dashboard.info.incidents_per_type.info')}</p>
                  <p>{t('dashboard.info.incidents_per_type.center')}</p>
                </div>
              }
            />
          </Col>
          <Col xs={24} lg={12}>
            <Card
              title={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  {t('dashboard.control_of_subcontractor_workers')}
                  <InfoCircleOutlined className="dashboard-info-icon" />
                  <div className="dashboard-card-infos">
                    <p>
                      {t('dashboard.info.subcontractor_workers_contoled.info')}
                    </p>
                  </div>
                </div>
              }
            >
              <Alert
                description={bar(subContractors, 'control', 'true')}
                className="dashboard-alert"
              />
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <CardDonuts
              dataAdmin={dataAdmin?.incidentsPerControl?.data}
              title={t('dashboard.workers_controled_and_incidents_numbers')}
              total={dataAdmin?.incidentsPerControl?.total}
              info={
                <div className="dashboard-card-infos">
                  <p>{t('dashboard.info.incidents_per_type.center')}</p>
                </div>
              }
            />
          </Col>
          <Col xs={24} lg={12}>
            <Card
              title={t('dashboard.incidents_list')}
              className="incident-list"
            >
              {dataAdmin?.incidentsOpen?.map((incident) => (
                <Alert
                  key={incident?._id}
                  description={
                    <Link to={{ pathname: `/incidents/show/${incident._id}` }}>
                      <p>
                        {incident.worker?.first_name}{' '}
                        {incident.worker?.lats_name} {' - '}
                        {incident.type?.label}{' '}
                      </p>
                      <p>{moment(incident?.date).format('DD/MM/YYYY')}</p>
                    </Link>
                  }
                />
              ))}
            </Card>
          </Col>
        </Row>
      </ContentCustom>
    )
  );
};

export default Home;
