import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Popconfirm, Select, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined, WarningOutlined } from '@ant-design/icons';
import { tailFormItemLayout } from '../../../utils/constants/formLayout';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';

const { Option } = Select;

const CheckSpecialWorker = ({
  isLoading,
  setIsNotRegistered,
  workers,
  setSelectedWorker,
  postWorker,
  selectedWorker
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onsearch = (input, option) => {
    const result = option?.children?.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  return (
    <ContentCustom>
      <Row align="middle">
        <Button
          onClick={() => navigate(-1)}
          type="link"
          icon={<ArrowLeftOutlined />}
          className="back-btn"
        />
        <h2>{t('controls.mobile.title.check_worker')}</h2>
      </Row>
      <div className="check-worker-form">
        <Form>
          <Form.Item
            {...tailFormItemLayout}
            style={{ marginTop: 40 }}
            name="worker"
            label={t('controls.mobile.show.choose_worker')}
          >
            <Select
              loading={isLoading}
              showSearch
              allowClear
              filterOption={(input, option) => onsearch(input, option)}
              onChange={(value) => setSelectedWorker(value)}
              style={{ width: '100%', textAlign: 'middle' }}
            >
              {(workers || []).map((w) => (
                <Option key={w._id} value={w._id}>
                  {`${w.first_name} ${w.last_name}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
        <Row
          style={{
            marginTop: 30,
            gap: 8
          }}
        >
          <Button onClick={() => setIsNotRegistered(false)}>
            {t('controls.mobile.button.worker_not_found')}
          </Button>
          <Popconfirm
            title={t('controls.alert.worker')}
            okText={t('controls.alert.validate')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('controls.alert.cancel')}
            onConfirm={() => postWorker()}
            icon={<WarningOutlined />}
            placement="topRight"
          >
            <Button type="primary" disabled={!selectedWorker}>
              {t('controls.mobile.button.add_worker')}
            </Button>
          </Popconfirm>
        </Row>
      </div>
    </ContentCustom>
  );
};

export default CheckSpecialWorker;

CheckSpecialWorker.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setIsNotRegistered: PropTypes.func.isRequired,
  workers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedWorker: PropTypes.func.isRequired,
  postWorker: PropTypes.func.isRequired,
  selectedWorker: PropTypes.string.isRequired
};
