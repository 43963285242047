import React from 'react';
import { Tag, Switch } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useAuthContext } from '../../../contexts/AuthContext';

const renderDates = (start_date, end_date) => {
  if (start_date && end_date) {
    return `Du ${moment(start_date).format('DD/MM/YYYY')} au ${moment(
      end_date
    ).format('DD/MM/YYYY')}`;
  }
  return '-';
};

export const worksiteList = (data) => {
  const {
    reference,
    name,
    sncName,
    rcs_number,
    start_date,
    end_date,
    idController,
    idPromoter,
    type,
    phase,
    address_full,
    meetingDate,
    promoter_users,
    csps,
    project_owner,
    siret
  } = data;
  const { user } = useAuthContext();

  const isAdmin = [
    'admins:SUPER-ADMIN',
    'admins:ADMIN',
    'promoters:ADMIN-PROMOTER'
  ].includes(user?.role);

  const RenderidPromoter =
    (idPromoter && isAdmin ? (
      <Link
        to={`/promoters/show/${idPromoter?._id}`}
      >{`${idPromoter?.name}`}</Link>
    ) : (
      idPromoter?.name || '-'
    )) || '-';

  return [
    {
      label: 'worksites.form.reference',
      span: 1,
      content: reference || '-'
    },
    {
      label: 'worksites.form.name',
      span: 1,
      content: name || '-'
    },
    {
      label: 'worksites.form.sncName',
      span: 1,
      content: sncName || '-'
    },
    {
      label: 'worksites.form.rcs_number',
      span: 1,
      content: rcs_number || '-'
    },
    {
      label: 'worksites.form.meetingDate',
      span: 1,
      content: meetingDate || '-'
    },
    {
      label: 'worksites.form.siret',
      span: 1,
      content: siret || '-'
    },
    {
      label: 'worksites.form.dates',
      span: 3,
      content: renderDates(start_date, end_date) || '-'
    },
    {
      label: 'worksites.form.idController',
      span: 2,
      content:
        (idController && isAdmin ? (
          <Link
            to={`/users/usersinspectors/show/${idController?._id}`}
          >{`${idController?.first_name} ${idController?.last_name}`}</Link>
        ) : (
          `${idController?.first_name || '-'} ${idController?.last_name || '-'}`
        )) || '-'
    },
    {
      label: 'worksites.form.idPromoter',
      span: 1,
      content: RenderidPromoter
    },
    {
      label: 'worksites.form.promoter_users',
      span: 1,
      content: (promoter_users || []).map((userPromoter) => (
        <Tag>{`${userPromoter?.first_name} ${userPromoter?.last_name}`}</Tag>
      ))
    },
    {
      label: 'worksites.form.csps',
      span: 1,
      content: csps || '-'
    },
    {
      label: 'worksites.form.project_owner',
      span: 1,
      content: project_owner || '-'
    },
    {
      label: 'worksites.form.type',
      span: 1,
      content: type ? <Tag color={type.color}>{type.label}</Tag> : '-'
    },
    {
      label: 'worksites.form.phase',
      span: 2,
      content: phase ? <Tag color={phase?.color}>{phase?.label}</Tag> : '-'
    },
    {
      label: 'worksites.form.address_full',
      span: 3,
      content: address_full || '-'
    }
  ];
};

export const referentList = (data, t, isAdmin, updateAuthorizationEmail) => [
  {
    label: 'worksites.form.referent-name',
    span: 3,
    content: data ? (
      <Link to={`/users/userscompanies/show/${data?._id}`}>{`${
        data.first_name || '-'
      } ${data.last_name || '-'}`}</Link>
    ) : (
      '-'
    )
  },
  {
    label: 'worksites.form.referent-email',
    span: 3,
    content: data?.email ? (
      <a href={`mailto:${data.email}`}>{data.email}</a>
    ) : (
      '-'
    )
  },
  ...(isAdmin
    ? [
        {
          label: 'worksites.form.emailAuthorized',
          span: 2,
          content: (
            <Switch
              checked={data?.emailAuthorized}
              checkedChildren={t('switch.yes')}
              unCheckedChildren={t('switch.no')}
              valuePropName="checked"
              onChange={(checked) =>
                updateAuthorizationEmail(data._id, checked)
              }
            />
          )
        }
      ]
    : []),
  {
    label: 'worksites.form.referent-phone_number.number',
    span: 3,
    content:
      data && data.phone_number ? (
        <>
          {data.phone_number.code} {data.phone_number.number}
        </>
      ) : (
        '-'
      )
  }
];
