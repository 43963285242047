import React from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Input, Popover, Select, Switch } from 'antd';
import { ChromePicker } from 'react-color';

const { Option } = Select;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  selectType,
  checkType,
  record,
  index,
  children,
  setColorPicker,
  colorPicker,
  ...restProps
}) => {
  const popoverContent = (
    <ChromePicker
      color={colorPicker || '#008CA0'}
      onChangeComplete={(color) => {
        setColorPicker(color.hex);
      }}
    />
  );
  let inputNode;
  switch (inputType) {
    case 'text':
      inputNode = !checkType ? (
        <Input />
      ) : (
        <Switch defaultChecked={record.show} />
      );
      break;
    case 'select':
      if (selectType === 'icon') {
        inputNode = (
          <Select>
            {[].map((icon) => (
              <Option key={icon} value={icon.label}>
                {icon.value}
              </Option>
            ))}
          </Select>
        );
      } else {
        inputNode = (
          <Popover content={popoverContent} title="Title">
            <Button
              style={{
                backgroundColor: colorPicker || record.color,
                border: 'none',
                color: '#fff'
              }}
              value={colorPicker}
            >
              {colorPicker || record.color}
            </Button>
          </Popover>
        );
      }
      break;
    default:
      break;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

EditableCell.propTypes = {
  editing: PropTypes.bool,
  dataIndex: PropTypes.string,
  title: PropTypes.string,
  inputType: PropTypes.string,
  selectType: PropTypes.string,
  checkType: PropTypes.bool,
  children: PropTypes.instanceOf(Array),
  record: PropTypes.shape({
    color: PropTypes.string,
    show: PropTypes.bool
  }),
  setColorPicker: PropTypes.func,
  colorPicker: PropTypes.string,
  index: PropTypes.string
};

EditableCell.defaultProps = {
  editing: false,
  dataIndex: null,
  title: null,
  inputType: null,
  selectType: null,
  checkType: false,
  children: {},
  record: {},
  setColorPicker: undefined,
  colorPicker: null,
  index: null
};

export default EditableCell;
