import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, message, Tag, Switch, Popover, Popconfirm, Row } from 'antd';
import moment from 'moment/moment';
import Dragger from 'antd/lib/upload/Dragger';
import {
  FileDoneOutlined,
  EditOutlined,
  DownloadOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { docStatus, closureStatusColor } from '../../utils/constants/tagColors';
import { useDownloadDocument } from '../../utils/downloadDoc';
import { useAuthContext } from '../../contexts/AuthContext';

export const incidentList = (data) => {
  const {
    worksite,
    worker,
    company,
    date,
    type,
    unknown_company,
    unknown_worker,
    business_body,
    principal_company
  } = data;

  return [
    {
      label: 'incidents.form.worksite',
      span: 1,
      content:
        (worksite && (
          <Link
            to={`/worksites/show/${worksite?._id}`}
          >{`${worksite?.name}`}</Link>
        )) ||
        '-'
    },
    {
      label: 'incidents.form.datetime',
      span: 1,
      content: (date && moment(date).format('DD/MM/YYYY à HH:mm')) || '-'
    },
    {
      label: 'incidents.form.type',
      span: 1,
      content: type && <Tag color={type.color}>{type.label}</Tag>
    },
    ...(company
      ? [
          {
            label: 'incidents.form.company',
            span: 1,
            content:
              (company && (
                <Link
                  to={`/companies/show/${company?._id}`}
                >{`${company?.name}`}</Link>
              )) ||
              '-'
          }
        ]
      : [
          {
            label: 'incidents.form.company',
            span: 1,
            content: unknown_company || '-'
          }
        ]),
    ...(worker
      ? [
          {
            label: 'incidents.form.worker',
            span: 1,
            content:
              (worker && (
                <Link
                  to={`/workers/show/${worker?._id}`}
                >{`${worker?.first_name} ${worker?.last_name}`}</Link>
              )) ||
              '-'
          }
        ]
      : [
          {
            label: 'incidents.form.worker',
            span: 1,
            content: unknown_worker || '-'
          }
        ]),
    ...(business_body
      ? [
          {
            label: 'incidents.form.business_body',
            span: 1,
            content: business_body?.label || '-'
          },
          {
            label: 'incidents.form.principal_company',
            span: 1,
            content: principal_company ? (
              <Link to={`/companies/show/${principal_company._id}`}>
                {principal_company?.name}
              </Link>
            ) : (
              '-'
            )
          }
        ]
      : [])
  ];
};

export const incidentDetailedInfoList = (
  data,
  t,
  setOpenModalDocStatus,
  refresh,
  setRefresh,
  deleteFiles,
  setIsDocumentLoading,
  isDocumentLoading
) => {
  const {
    comment,
    files,
    justification_status,
    reference,
    control,
    inspector,
    link_incident,
    closure_status
  } = data;

  const { dispatchAPI, user } = useAuthContext();
  const { id } = useParams();
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';

  const { downloadDocument } = useDownloadDocument();
  const [documents, setDocuments] = useState(null);
  const [buttonColor, setButtonColor] = useState('red');

  const switchActivRecap = async (recap) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/incidents/${id}`,
        body: { recap_email: !recap }
      });
      setRefresh(!refresh);
      message.success(`Modification effectuée avec succès.`);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handlePostDocuments = async () => {
    try {
      setIsDocumentLoading(true);
      const formData = new FormData();
      documents.forEach((doc) => {
        formData.append('file', doc.originFileObj || doc);
        formData.append('type', doc.type);
      });
      await dispatchAPI('POST', {
        url: `/incidents/upload_files/${id}`,
        body: formData
      });
      setRefresh(!refresh);
      message.success(t('messages.import_success'));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsDocumentLoading(false);
  };

  const draggerProps = {
    multiple: false,
    fileList: documents,
    beforeUpload: (newFile) => {
      setDocuments([newFile]);
      return false;
    },
    onRemove: (file) =>
      setDocuments(documents.filter((f) => f.uid !== file.uid))
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setButtonColor((prevColor) => (prevColor === 'red' ? 'white' : 'red'));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const basicInfos = [
    {
      label: 'incidents.form.file',
      span: 1,
      _content: files?.length ? (
        <>
          <Popover placement="top" content={files[0]?.fileName}>
            <Button
              type="link"
              onClick={() => downloadDocument(files[0].fileId, 'show')}
              icon={<DownloadOutlined />}
              className="incident-file-btn"
            >
              {files[0]?.fileName}
            </Button>
          </Popover>
          {justification_status !== 'ACCEPTED' && (
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteFiles}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'var(--errorColor)', fontSize: 16 }}
                type="delete"
              />
            </Popconfirm>
          )}
        </>
      ) : (
        <>
          <Dragger {...draggerProps} style={{ maxWidth: 160 }}>
            <Row align="middle" style={{ gap: 8 }}>
              <FileDoneOutlined
                style={{ color: 'var(--textColorSecondary)', fontSize: 20 }}
              />
              <span style={{ color: 'var(--textColorSecondary)' }}>
                {t('files.create.action')}
              </span>
            </Row>
          </Dragger>
          {documents && (
            <Button
              onClick={handlePostDocuments}
              loading={isDocumentLoading}
              style={{ backgroundColor: buttonColor }}
            >
              Enregistrer
            </Button>
          )}
        </>
      ),
      get content() {
        return this._content;
      },
      set content(value) {
        this._content = value;
      }
    },
    ...(isAdmin
      ? [
          {
            label: 'incidents.form.status_document',
            span: 1,
            content: justification_status && (
              <>
                <Tag color={docStatus[justification_status]}>
                  {t(`incidents.form.tags.${justification_status}`)}
                </Tag>
                <Button type="link" onClick={() => setOpenModalDocStatus(true)}>
                  <EditOutlined />
                </Button>
              </>
            )
          }
        ]
      : []),
    {
      label: 'incidents.form.reference',
      span: 1,
      content: reference || '-'
    },
    {
      label: 'incidents.form.control',
      span: 1,
      content:
        (control && (
          <Link
            to={`/controls/show/${control?._id}`}
          >{`${control?.reference}`}</Link>
        )) ||
        '-'
    },
    {
      label: 'incidents.form.inspector',
      span: 1,
      content:
        (inspector && isAdmin ? (
          <Link
            to={`/users/usersinspectors/show/${inspector?._id}`}
          >{`${inspector?.first_name} ${inspector?.last_name}`}</Link>
        ) : (
          `${inspector?.first_name || '-'} ${inspector?.last_name || '-'}`
        )) || '-'
    },
    ...(isAdmin
      ? [
          {
            label: 'incidents.form.recap_email',
            span: 1,
            content: (
              <Switch
                onChange={() => switchActivRecap(data?.recap_email)}
                checked={data?.recap_email}
                checkedChildren="Oui"
                unCheckedChildren="Non"
              />
            )
          }
        ]
      : []),
    ...(link_incident
      ? [
          {
            label: 'incidents.form.link_incident',
            span: 1,
            content: (
              <Link to={`/incidents/show/${link_incident._id}`}>
                {link_incident.reference}
              </Link>
            )
          }
        ]
      : []),
    {
      label: 'incidents.form.closure_status',
      span: 1,
      content: closure_status && (
        <Tag color={closureStatusColor[closure_status]}>
          {t(`incidents.form.tags.${closure_status}`)}
        </Tag>
      )
    },
    {
      label: 'incidents.form.comment',
      span: 2,
      content: comment || '-'
    }
  ];

  const finalInfos = basicInfos;
  return finalInfos;
};
