import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import useFields from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import NewUserDrawer from '../users/NewUserDrawer';
import { useAuthContext } from '../../contexts/AuthContext';

const CreateUpdatePromoter = ({ purpose }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [base64, setBase64] = useState('');
  const { fields, isFieldsLoading } = useFields(
    setOpen,
    open,
    null,
    base64,
    setBase64
  );

  const createUser = async (data) => {
    setIsLoading(true);
    try {
      await dispatchAPI('POST', {
        url: '/users',
        body: data
      });
      setOpen(false);
      message.success(t('users.message.created'));
    } catch (e) {
      if (e.response.statusText === 'Forbidden') {
        message.warn(t('users.message.email_used'));
        setIsLoading(false);
      }
      message(e);
    }
    setIsLoading(false);
  };

  const config = {
    onGetResource: {
      setFields: (data) => {
        setBase64(data?.logo);
        return {
          ...data
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        ...(base64 ? { logo: base64 } : {})
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...(base64 ? { logo: base64 } : {})
      })
    }
  };

  return (
    <>
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="promoters"
        resource="promoters"
        config={config}
      />
      <NewUserDrawer
        type="promoters"
        isVisible={open}
        setVisibilityUserDrawer={setOpen}
        users={[]}
        createUser={createUser}
        getUser={() => {}}
        isLoading={isLoading}
      />
    </>
  );
};

CreateUpdatePromoter.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdatePromoter;
