import React, { useCallback, useEffect, useState } from 'react';
import { Select, Tag, Upload, Input, Divider, Space, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CameraOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const { Option } = Select;
const { TextArea } = Input;

const useFields = (
  isUnkownCompany,
  setIsUnknownCompany,
  cardNumber,
  dropdownOpen1,
  dropdownOpen2,
  dropdownOpen3,
  dropdownOpen4,
  setDropdownOpen1,
  setDropdownOpen2,
  setDropdownOpen3,
  setDropdownOpen4
) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { controlId } = useParams();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [workers, setWorkers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [unknownWorker, setUnknownWorker] = useState(null);
  const [isUnkownWorker, setIsUnknownWorker] = useState(false);
  const [workerSelected, setWorkerSelected] = useState(null);
  const [companyWorkers, setCompanyWorkers] = useState(null);
  const [businessBody, setBusinessBody] = useState([]);
  const [unknownCompany, setUnknownCompany] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [incidentTypes, setIncidentTypes] = useState(null);
  const [selectedWorker, setSelectedWorker] = useState(false);

  const { Dragger } = Upload;

  const getWorksiteCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `worksites/getworksiteCompanies/${controlId}`
      });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getWorkers = async (selectionCompany) => {
    try {
      const idcompanies = companies.map((item) => item._id);
      const { data } = await dispatchAPI('GET', {
        url: `/workers?idCompany=${
          selectionCompany ? [selectionCompany] : idcompanies
        }`
      });
      data.push({
        first_name: 'Refus',
        last_name: "d'obtempérer",
        _id: '642402399414292d923c7406'
      });
      setWorkers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getIncidentTypes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/incidenttypes?show=true'
      });
      setIncidentTypes(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getBusinessBody = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/batchtitle?sort=label&show=${true}`
      });
      setBusinessBody(data);
    } catch (error) {
      if (error) message(error.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getWorksiteCompanies();
    await getIncidentTypes();
    await getBusinessBody();
    setIsFieldsLoading(false);
  }, []);

  const handleOpenChange = (open, selectNumber) => {
    setDropdownOpen1(false);
    setDropdownOpen2(false);
    setDropdownOpen4(false);
    if (selectNumber === '3' && !open && !selectedWorker) {
      setDropdownOpen3(true);
    } else if (open) {
      switch (selectNumber) {
        case '1':
          setDropdownOpen1(open);
          break;
        case '2':
          setDropdownOpen2(open);
          break;
        case '3':
          setDropdownOpen3(open);
          setSelectedWorker(false);
          break;
        case '4':
          setDropdownOpen4(open);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (selectedWorker) setDropdownOpen3(false);
  }, [selectedWorker]);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  useEffect(() => {
    (async () => {
      await getWorkers();
    })();
  }, [companies]);

  const draggerProps = {
    multiple: false,
    fileList: documents,
    beforeUpload: (newFile) => {
      setDocuments([newFile]);

      return false;
    },
    onRemove: (file) =>
      setDocuments(documents.filter((f) => f.uid !== file.uid))
  };

  const WorkersInputList = () => {
    if (companyWorkers && selectedCompany) {
      return companyWorkers.map((worker) => (
        <Option key={worker._id} value={worker._id}>
          <Tag>{`${worker.first_name} ${worker.last_name} ${
            worker.email || '-'
          }`}</Tag>
        </Option>
      ));
    }
    if (isUnkownCompany) return [];
    return (workers || []).map((w) => (
      <Option key={w._id} value={w._id}>
        <Tag>{`${w.first_name} ${w.last_name} ${w.email || '-'}`}</Tag>
      </Option>
    ));
  };

  const handleSelecteCompany = (value) => {
    if (value === undefined) {
      setCompanyWorkers(null);
      setSelectedCompany(null);
    }
    const Workersarray = [];
    workers.forEach((worker) => {
      if (Array.isArray(worker?.idCompany)) {
        if (worker?.idCompany.includes(value)) Workersarray.push(worker);
      } else if (worker?.idCompany && worker?.idCompany.equals(value))
        Workersarray.push(worker);
    });
    setCompanyWorkers(Workersarray);
    setSelectedCompany(value);
  };

  const onsearch = (input, option) => {
    const result = option?.children?.props.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const companyInput = () => {
    if (workerSelected) {
      if (Array.isArray(workerSelected)) {
        return workerSelected.map((c) => (
          <Option key={c._id} value={c._id}>
            <Tag>{`${c.name}`}</Tag>
          </Option>
        ));
      }
      return (
        <Option key={workerSelected._id} value={workerSelected._id}>
          <Tag>{`${workerSelected.name}`}</Tag>
        </Option>
      );
    }
    return companies.map((c) => (
      <Option key={c._id} value={c._id}>
        <Tag>{`${c.name}`}</Tag>
      </Option>
    ));
  };

  const onsearchBusinessBody = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const fields = [
    {
      name: ['type'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={isFieldsLoading}
          allowClear
          open={dropdownOpen1}
          onDropdownVisibleChange={(value) => handleOpenChange(value, '1')}
        >
          {(incidentTypes || []).map((type) => (
            <Option key={type._id} value={type._id}>
              <Tag color={type.color}>{t(`${type.label}`)}</Tag>
            </Option>
          ))}
        </Select>
      ),
      initialValue: cardNumber && '638899759cfceda0582b52a9'
    },
    {
      name: ['company'],
      rules: [{ required: true }],
      input: (
        <Select
          mode={isUnkownCompany ? 'tags' : 'default'}
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
          onChange={(text) =>
            isUnkownCompany ? setUnknownCompany(text[0]) : handleSelecteCompany
          }
          onSelect={(value) => !isUnkownCompany && getWorkers(value)}
          loading={isFieldsLoading}
          open={dropdownOpen2}
          onDropdownVisibleChange={(value) => handleOpenChange(value, '2')}
          dropdownRender={(menu) => (
            <>
              <Space style={{ padding: '0 8px 4px' }}>
                <Button
                  type="text"
                  icon={isUnkownCompany ? <CloseOutlined /> : <PlusOutlined />}
                  onClick={() => setIsUnknownCompany(!isUnkownCompany)}
                >
                  {isUnkownCompany
                    ? 'Annuler la saisie manuelle'
                    : 'Saisir une entreprise inconnue ?'}
                </Button>
              </Space>
              <Divider style={{ margin: '8px 0' }} />
              {menu}
            </>
          )}
        >
          {isUnkownCompany ? [] : companyInput()}
        </Select>
      )
    },
    {
      name: ['worker'],
      rules: [{ required: !cardNumber }],
      input: isUnkownWorker ? (
        <div style={{ position: 'relative' }}>
          <Input.TextArea
            style={{
              width: 'calc(100% - 24px)',
              overflow: 'hidden',
              resize: 'none'
            }}
            onChange={(event) => setUnknownWorker(event.target.value)}
            placeholder="Saisissez le nom de l'utilisateur inconnu"
          />
          <Button
            type="text"
            icon={<CloseOutlined style={{ color: 'red', fontSize: '20px' }} />}
            onClick={() => {
              setIsUnknownWorker(!isUnkownWorker);
              setWorkerSelected(null);
              setUnknownWorker(null);
            }}
            style={{
              position: 'absolute',
              right: '140',
              top: '0',
              border: 'none',
              padding: '0',
              zIndex: 10
            }}
          />
        </div>
      ) : (
        <Select
          mode={isUnkownWorker ? 'tags' : 'default'}
          loading={isFieldsLoading}
          showSearch
          allowClear
          filterOption={(input, option) => onsearch(input, option)}
          onChange={() => {
            setSelectedWorker(true);
          }}
          open={dropdownOpen3}
          onDropdownVisibleChange={(value) => handleOpenChange(value, '3')}
          onSelect={() => {
            setSelectedWorker(true);
            setDropdownOpen3(false);
          }}
          dropdownRender={(menu) => (
            <>
              <Space style={{ padding: '0 8px 4px' }}>
                <Button
                  type="text"
                  icon={isUnkownWorker ? <CloseOutlined /> : <PlusOutlined />}
                  onClick={() => {
                    setIsUnknownWorker(!isUnkownWorker);
                    setWorkerSelected(null);
                  }}
                >
                  Saisir un utilisateur inconnu ?
                </Button>
              </Space>
              <Divider style={{ margin: '8px 0' }} />
              {menu}
            </>
          )}
        >
          {WorkersInputList()}
        </Select>
      )
    },
    {
      name: ['business_body'],
      rules: [{ required: isUnkownCompany }],
      hidden: !isUnkownCompany,
      input: (
        <Select
          showSearch
          filterOption={(input, option) => onsearchBusinessBody(input, option)}
          open={dropdownOpen4}
          onDropdownVisibleChange={(value) => handleOpenChange(value, '4')}
        >
          {businessBody.map((i) => (
            <Option key={i._id} value={i._id}>
              {i.label}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['file'],
      rules: [{ required: false }],
      input: (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      name: ['comment'],
      rules: [{ required: false }],
      input: <TextArea rows={5} />
    }
  ];

  return {
    fields,
    isFieldsLoading,
    unknownWorker,
    isUnkownWorker,
    unknownCompany,
    documents
  };
};

export default useFields;
