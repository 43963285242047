import React, { useCallback, useState, useEffect } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

const { Option } = Select;

const useFields = (isModalOpen) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState();
  const { message } = useErrorMessage();
  const [users, setUsers] = useState([]);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users/' });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/controls/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getUsers();
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions, isModalOpen]);

  const onsearch = (input, option) => {
    const result = option?.children?.props?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const fields = [
    {
      name: ['inspector'],
      rules: [{ required: true }],
      input: (
        <Select
          style={{ width: '300px' }}
          placeholder={t('calendar.placeholder.users')}
          showSearch
          disabled
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(users || []).map((u) => (
            <Option key={u._id} value={u._id}>
              {`${u.first_name} ${u.last_name}`}
            </Option>
          ))}
        </Select>
      ),
      endWithDivider: {
        orientation: 'left',
        plain: true,
        style: { marginTop: 30 },
        title: t('controls.form.structural_work')
      }
    },
    {
      name: ['first_phase_periodicity'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums?.periodicities || []).map((p) => (
            <Option key={p} value={p}>
              {t(`worksites.form.periodicity.${p}`)}
            </Option>
          ))}
        </Select>
      ),
      endWithDivider: {
        orientation: 'left',
        plain: true,
        style: { marginTop: 30 },
        title: t('controls.form.second_phase')
      }
    },
    {
      name: ['third_phase_periodicity'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums?.periodicities || []).map((p) => (
            <Option key={p} value={p}>
              {t(`worksites.form.periodicity.${p}`)}
            </Option>
          ))}
        </Select>
      ),
      endWithDivider: {
        orientation: 'left',
        plain: true,
        style: { marginTop: 30 },
        title: t('controls.form.third_phase')
      }
    },
    {
      name: ['second_phase_periodicity'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums?.periodicities || []).map((p) => (
            <Option key={p} value={p}>
              {t(`worksites.form.periodicity.${p}`)}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
