import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Logo from '../../assets/images/Quietus-logo.svg';
import LogoCollapsed from '../../assets/images/quietus-logo-Q.svg';
import HeaderLayout from './HeaderLayout/HeaderLayout';
import { useHandleResize } from '../../utils/HandleResize';
import { CompanyNavMenu } from './CompanyNavMenu';
import { QuietusContextProvider } from '../../contexts/QuietusContext';

const { Content, Footer, Sider } = Layout;

const StyledLayout = styled.div`
  height: 100vh;
`;

const StyledSider = styled.div`
  height: 100vh;
  z-index: 10;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  overflow-x: hidden;
  position: fixed;
  left: 0;
  background-color: var(--primaryColor);
`;

const LogoDiv = styled.div`
  position: relative;
  height: 56px;
  padding-left: 0;
  overflow: hidden;
  line-height: 56px;
  transition: all 0.3s;
  z-index: 900;
  display: flex;
  justify-content: center;
  img {
    display: inline-block;
    height: 40px;
    vertical-align: middle;
  }
`;

const StyledContent = styled.div`
  margin: 64px 0 0 256px;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 992px) {
    margin-left: 80px;
  }

  @media (max-width: 768px) {
    margin: 64px 0 0;
  }
`;

const StyledFooter = styled.footer`
  padding: 8px 25px;
  text-align: center;
  margin-left: 255px;
  background-color: var(--bodyBackground);
  color: var(--textColor);
  box-shadow: 0 -1px 4px var(--borderColor);

  @media (max-width: 992px) {
    margin-left: 80px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
  }

  a {
    color: var(--primaryColor);
  }
`;

export const CompanyLayout = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);
  const { width, height } = useHandleResize();
  const [collapseWidth, setCollapseWidth] = useState(
    window.innerWidth < 769 ? 0 : 80
  );

  const onCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed);
  };

  useEffect(() => {
    if (width < 769) {
      setCollapseWidth(0);
    } else {
      setCollapseWidth(80);
    }
  }, [width, height]);

  useEffect(() => {
    if (location.search === '') {
      document
        .getElementById('mainContent')
        .scrollTo({ behavior: 'smooth', top: 0 });
    }
  }, [location]);

  return (
    <QuietusContextProvider>
      <StyledLayout as={Layout}>
        <StyledSider
          as={Sider}
          width={256}
          breakpoint="lg"
          trigger={null}
          collapsible
          collapsedWidth={collapseWidth}
          collapsed={collapsed}
          onCollapse={onCollapse}
        >
          <LogoDiv>
            <Link to="/">
              <img alt="Logo" src={collapsed ? LogoCollapsed : Logo} />
            </Link>
          </LogoDiv>
          <CompanyNavMenu setCollapsed={setCollapsed} />
        </StyledSider>
        <Layout>
          <HeaderLayout
            collapsed={collapsed}
            collapseSider={setCollapsed}
            collapseWidth={collapseWidth}
          />
          <StyledContent as={Content} id="mainContent">
            <Outlet />
          </StyledContent>
          <StyledFooter as={Footer}>
            {`${t('global.title')} ©${moment().year()} powered by `}
            <a href="https://strateg.in">Strateg.in</a>
          </StyledFooter>
        </Layout>
      </StyledLayout>
    </QuietusContextProvider>
  );
};
