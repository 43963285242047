import React, { useState } from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { useAuthContext } from '../../contexts/AuthContext';
import { useGenerateReport } from '../../utils/generateReport';

const ListWorksite = () => {
  const { t } = useTranslation();
  const { generateReport } = useGenerateReport();
  const { headers, columns } = useColumns();
  const { user } = useAuthContext();
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';
  const [isDocLoading, setIsDocLoading] = useState(false);

  const generateWorksitesReport = async () => {
    setIsDocLoading(true);
    await generateReport('worksite_global', user._id);
    setIsDocLoading(false);
  };

  return (
    <ListResource
      resourceName="worksites"
      columns={columns}
      headers={headers}
      deleteAction={isAdmin}
      editAction={isAdmin}
      withCreateButton={isAdmin}
      extraButtons={
        isAdmin && (
          <Button
            loading={isDocLoading}
            onClick={() => generateWorksitesReport('worksite_global', user._id)}
            type="add"
            style={{ marginRight: 15 }}
          >
            {t('buttons.download_reports')}
            <DownloadOutlined />
          </Button>
        )
      }
      resourceModelName="Worksite"
      populate="idController,idPromoter,type,phase"
      scroll={{ x: 1600 }}
    />
  );
};

export default ListWorksite;
