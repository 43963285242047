import { Spin, Row, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { ContentCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import BTPCardComponent from '../users/BTPCardComponent';
import { RegisterQrCode } from './RegisterQrCode';

const WorkerHome = () => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [worker, setWorker] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [openCamera, setOpenCamera] = useState(false);

  const getUser = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/user/${user._id}`
      });
      setWorker(data);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getUser();
      setIsLoading(false);
    })();
  }, []);

  return isLoading ? (
    <Spin
      spinning={isLoading}
      size="large"
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%'
      }}
    />
  ) : (
    worker && (
      <ContentCustom>
        {!openCamera && (
          <Button type="add" onClick={() => setOpenCamera(true)}>
            <PlusOutlined />
            {`${worker?.qrCode ? 'Modifier' : 'Enregistrer'} ma carte BTP`}
          </Button>
        )}
        <Row align="center">
          {worker?.qrCode && !openCamera ? (
            <BTPCardComponent isLoading={isLoading} user={worker} />
          ) : (
            <RegisterQrCode
              user={worker}
              setOpenCamera={setOpenCamera}
              openCamera={openCamera}
              isLoading={isLoading}
            />
          )}
        </Row>
      </ContentCustom>
    )
  );
};

export default WorkerHome;
