import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListPromoters from './ListPromoters';
import CreateUpdatePromoter from './CreateUpdatePromoter';
import ShowPromoter from './ShowPromoter';

export const PromoterRouter = () => (
  <Routes>
    <Route path="/" element={<ListPromoters />} />
    <Route path="/create" element={<CreateUpdatePromoter purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdatePromoter purpose="edit" />} />
    <Route path="/show/:id" element={<ShowPromoter />} />
  </Routes>
);
