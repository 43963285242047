import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import NewUserDrawer from '../users/NewUserDrawer';
import useFields from './fields';

import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateControl = ({ purpose }) => {
  const [open, setOpen] = useState(false);
  const { fields, isFieldsLoading } = useFields(setOpen, purpose);

  const config = {
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        period_start:
          data.period_start?.[0] &&
          moment(data.period_start[0]).startOf('week').startOf('day'),
        period_end:
          data.period_start?.[1] &&
          moment(data.period_start[1]).endOf('week').startOf('day')
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        period_start:
          data.period_start?.[0] &&
          moment(data.period_start[0]).startOf('week').startOf('day'),
        period_end:
          data.period_start?.[1] &&
          moment(data.period_start[1]).endOf('week').startOf('day')
      })
    },
    onGetResource: {
      setFields: (data) => ({
        ...data,
        period_start: [
          data.period_start && moment(data.period_start).startOf('day'),
          data.period_end && moment(data.period_end).endOf('day')
        ]
      })
    }
  };

  return (
    <>
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="controls"
        resource="controls"
        config={config}
      />
      <NewUserDrawer
        type="inspectors"
        isVisible={open}
        setVisibilityUserDrawer={setOpen}
        users={[]}
        getUser={() => {}}
      />
    </>
  );
};

CreateUpdateControl.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateControl;
