import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import moment from 'moment';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const useColumns = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [worksites, setWorksites] = useState([]);
  const [controlers, setControlers] = useState([]);
  const [controlStatuses, setControlStatuses] = useState([]);

  const headers = [
    {
      label: 'worksite',
      key: 'worksite.name'
    },
    {
      label: 'reference',
      key: 'reference'
    },
    {
      label: 'status',
      key: 'status'
    },
    {
      label: 'inspector',
      key: 'inspector.name'
    },
    {
      label: 'date',
      key: 'date'
    },
    {
      label: 'start_time',
      key: 'start_time'
    },
    {
      label: 'end_time',
      key: 'end_time'
    },
    {
      label: 'incidents',
      key: `incidents`
    },
    {
      label: 'workers',
      key: 'workers'
    },
    {
      label: 'address',
      key: 'worksite.address_full'
    }
  ];

  const columns = [
    {
      title: t('controls.form.worksite'),
      key: 'worksite',
      dataIndex: 'worksite',
      sorter: true,
      render: (worksite) => (worksite && worksite.name) || '-',
      filters: worksites.map((r) => ({
        text: r.name,
        value: r._id
      }))
    },
    {
      title: t('controls.form.reference'),
      key: 'reference',
      dataIndex: 'reference' || '-',
      sorter: true
    },
    {
      title: t('controls.form.status'),
      key: 'status',
      dataIndex: 'status',
      sorter: true,
      render: (status) =>
        status && <Tag>{t(`controls.tags.${status}`) || '-'}</Tag>,
      filters: (controlStatuses?.status || []).map((r) => ({
        text: t(`controls.tags.${r}`),
        value: r
      }))
    },
    {
      title: t('controls.form.inspector'),
      key: 'inspector',
      dataIndex: 'inspector',
      sorter: true,
      render: (inspector) =>
        inspector &&
        `${inspector.first_name || '-'} ${inspector.last_name || '-'}`,
      filters: (controlers || []).map((r) => ({
        text: `${r.first_name} ${r.last_name}`,
        value: r._id
      }))
    },
    {
      title: t('controls.form.date'),
      key: 'date',
      dataIndex: 'date',
      width: 120,
      sorter: true,
      defaultSortOrder: 'descend',
      render: (date) => date && moment(date).format('DD/MM/YYYY')
    },
    {
      title: t('controls.form.start_time'),
      key: 'start_time',
      dataIndex: 'start_time',
      width: 160,
      sorter: true,
      render: (date) => date && moment(date).format('HH:mm')
    },
    {
      title: t('controls.form.end_time'),
      key: 'end_time',
      dataIndex: 'end_time',
      width: 160,
      sorter: true,
      render: (date) => date && moment(date).format('HH:mm')
    },
    {
      title: t('controls.form.incidents'),
      key: 'incidents',
      dataIndex: 'incidents',
      sorter: true,
      render: (incidents) => (incidents && incidents.length) || '-'
    },
    {
      title: t('controls.form.workers'),
      key: 'workers',
      dataIndex: 'workers',
      sorter: true,
      render: (workers) => (workers && workers.length) || '-'
    }
  ];

  const getWorksites = async () => {
    try {
      const result = await dispatchAPI('GET', { url: 'worksites' });
      setWorksites(result.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getControlers = async () => {
    try {
      const result = await dispatchAPI('GET', {
        url: 'users/?role=/inspectors:/i'
      });
      setControlers(result.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getControlStatuses = async () => {
    try {
      const result = await dispatchAPI('GET', { url: 'controls/enums' });
      setControlStatuses(result.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(async () => {
    await getWorksites();
    await getControlers();
    await getControlStatuses();
  }, []);

  return { headers, columns };
};
