import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { Card, Typography, Timeline, message, Tag } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';

const WorksiteNewFeed = ({ worksite }) => {
  const { dispatchAPI } = useAuthContext();
  const [phases, setPhases] = useState([]);
  const [users, setUsers] = useState([]);

  const getPhases = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/worksitephases`
      });
      setPhases(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users`
      });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(async () => {
    await getPhases();
    await getUsers();
  }, []);

  const sortByDateDesc = (a, b) => {
    if (a.date > b.date) return -1;
    if (a.date < b.date) return 1;
    return 0;
  };

  const formatFeedElement = (phase) => {
    const phaseToFind = phases.find((p) => p._id === phase?.phase);
    const userToFind = users.find((p) => p._id === phase?.changed_by);

    if (phaseToFind !== undefined && userToFind !== undefined) {
      const date = moment(phase.date).format('DD/MM/YYYY à HH:mm');

      return (
        <Timeline.Item key={phase.date}>
          <Typography.Title level={5} fontStyle="bold">
            {date}
          </Typography.Title>
          <Typography.Text>
            {`Statut changé à `}
            <Tag color={phaseToFind.color}>{phaseToFind.label}</Tag>
            {`par  ${userToFind.first_name} ${userToFind.last_name}`}
          </Typography.Text>
        </Timeline.Item>
      );
    }
    return (
      <Timeline.Item key={phase.date}>
        <Typography.Text>-</Typography.Text>
      </Timeline.Item>
    );
  };

  if (worksite?.phasesHistory?.length === 0) {
    return (
      <Card title="Informations" bordered={false}>
        <Typography.Text italic>
          Aucun changement pour le moment
        </Typography.Text>
      </Card>
    );
  }

  const sortedPhases = worksite?.phasesHistory?.sort(sortByDateDesc);

  return (
    <Card title="Informations" bordered={false}>
      <Timeline style={{ maxHeight: 400, overflow: 'scroll' }}>
        {sortedPhases?.map((phase) => formatFeedElement(phase))}
      </Timeline>
    </Card>
  );
};

export default WorksiteNewFeed;

WorksiteNewFeed.propTypes = {
  worksite: PropTypes.shape({
    phasesHistory: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};
