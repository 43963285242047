import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Popover } from 'antd';
import { userPoste } from '../../utils/constants/tagColors';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const useColumns = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [companies, setCompanies] = useState([]);

  const headers = [
    {
      label: 'idCompany',
      key: 'idCompany.name'
    },
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'email',
      key: 'email'
    },
    {
      label: 'phone_number',
      key: 'phone_number.number'
    },
    {
      label: 'poste',
      key: 'poste'
    },
    {
      label: 'card_number',
      key: 'card_number'
    }
  ];

  const columns = [
    {
      name: 'idCompany',
      title: t('users.form.idCompany'),
      key: 'idCompany',
      dataIndex: 'idCompany',
      sorter: true,
      render: (idCompany, data) =>
        idCompany ? (
          <div>
            {idCompany?.map((company) => (
              <p>{company.name || '-'}</p>
            ))}
          </div>
        ) : (
          <Popover placement="top" content="Entreprise non validée">
            <p style={{ color: 'red' }}>{data?.unknown_company || 'N/A'}</p>
          </Popover>
        ),
      filters: companies.map((r) => ({
        text: r.name,
        value: r._id
      }))
    },
    {
      name: 'last_name',
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      name: 'first_name',
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name' || '-',
      sorter: true
    },
    {
      name: 'email',
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email' || '-',
      sorter: true
    },
    {
      name: 'phone_number',
      title: t('users.form.phone_number.number'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: (phone_number) =>
        (phone_number &&
          `${phone_number.country_code || '-'} ${
            phone_number.number || '-'
          }`) ||
        '-',
      sorter: true
    },
    {
      name: 'poste',
      title: t('users.form.poste'),
      key: 'poste',
      dataIndex: 'poste',
      render: (poste) => (
        <Tag color={userPoste[poste]}>{t(`users.tags.${poste}`) || '-'}</Tag>
      ),
      sorter: true,
      filters: Object.entries(userPoste).map((r) => ({
        text: t(`users.tags.${r[0]}`) || '-',
        value: r[0]
      }))
    }
  ];

  const getCompanies = async () => {
    try {
      const result = await dispatchAPI('GET', { url: 'companies' });
      setCompanies(result.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getCompanies();
  }, []);

  return { columns, headers };
};
