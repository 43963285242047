import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Card, Button, Typography, Tag, Row, Col } from 'antd';
import { ClockCircleOutlined, CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';
import { t } from 'i18next';
import { LocationIcon } from '../../../utils/constants/customIcons';

const { Text } = Typography;

const ControlCard = ({ card }) => (
  <Card
    key={card._id}
    title={card.reference}
    bordered={false}
    className="control-card"
  >
    <div className="header-control-top">
      <div className="top-left">
        <Row>
          <Col>
            <Tag className="bg-primary">
              {t(`controls.tags.${card.status}`)}
            </Tag>
          </Col>
        </Row>
      </div>
      <div className="top-right">
        <div>
          <CalendarOutlined />
          <Text>{moment(card.date).format('DD/MM/YYYY')}</Text>
        </div>
        <div>
          <ClockCircleOutlined />
          <Text>{`${moment(card.start_time).format('HH:mm')} - ${moment(
            card.end_time
          ).format('HH:mm')}`}</Text>
        </div>
      </div>
    </div>
    <div className="header-control-bottom">
      <div className="bottom-left">
        <Text className="title">{card.worksite?.name}</Text>
        <div className="address">
          <LocationIcon />
          <Text> {card.worksite?.address_full}</Text>
        </div>
      </div>
      <div className="bottom-right">
        <Link
          to={{
            pathname: `/control/${card._id}`
          }}
        >
          <Button className="btn-pill btn-light" shape="round" size="large">
            Ouvrir
          </Button>
        </Link>
      </div>
    </div>
  </Card>
);

ControlCard.propTypes = {
  card: PropTypes.shape({
    _id: PropTypes.string,
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    status: PropTypes.string,
    reference: PropTypes.string,
    date: PropTypes.string,
    worksite: PropTypes.shape({
      name: PropTypes.string,
      address_full: PropTypes.string
    })
  }).isRequired
};

export default ControlCard;
