import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import ListControls from './ListControls';
import CreateUpdateControl from './CreateUpdateControl';
import ShowControl from './ShowControl/ShowControl';
import ControlDetails from '../mobile/ControlDetails/ControlDetails';
import { ControlQRCode } from '../mobile/ControlQRCode/ControlQRCode';
import { ControlQRCodeTera } from '../mobile/ControlQRCode/ControlQRCodeTera';
import ControlSecurity from '../mobile/ControlSecurity';
import SpecialsWorkers from '../mobile/ControlQRCode/SpecialsWorkers';
import WorksiteClosed from '../mobile/ControlQRCode/WorksiteClosed';
import CreateUpdateWorker from '../workers/CreateUpdateWorker';
import CreateUpdateIncident from '../mobile/MobileCreateUpdateIncident/MobileCreateUpdateIncident';
import { useAuthContext } from '../../contexts/AuthContext';
import { MobileContextProvider } from '../mobile/MobileContext';

export const ControlRouter = () => {
  const { user } = useAuthContext();
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';
  const mode = 'desktop';
  const [nav_display, setNavDisplay] = useState('');
  return (
    <MobileContextProvider>
      <Routes>
        <Route path="/" element={<ListControls />} />
        <Route
          path="/:id"
          element={
            <ControlDetails
              setNavDisplay={setNavDisplay}
              mode={mode}
              nav_display={nav_display}
            />
          }
        />
        {isAdmin && (
          <>
            <Route
              path="/create"
              element={<CreateUpdateControl purpose="create" />}
            />
            <Route
              path="/edit/:id"
              element={<CreateUpdateControl purpose="edit" />}
            />
          </>
        )}
        <Route path="/show/:id" element={<ShowControl />} />
        <Route
          path="/controlqrcodetera/:id"
          element={
            <ControlQRCodeTera setNavDisplay={setNavDisplay} mode={mode} />
          }
        />
        <Route
          path="/controlqrcode/:id"
          element={<ControlQRCode setNavDisplay={setNavDisplay} mode={mode} />}
        />
        <Route
          path="/controlsecurity/:id"
          element={<ControlSecurity setNavDisplay={setNavDisplay} />}
        />
        <Route
          path="/controlspecial/:id"
          element={
            <SpecialsWorkers setNavDisplay={setNavDisplay} mode={mode} />
          }
        />
        <Route
          path="/worksiteclosed/:id"
          element={<WorksiteClosed mode={mode} />}
        />
        <Route
          path="workers/create/:controlId/:card_number"
          element={
            <CreateUpdateWorker purpose="create" type="workers" mode={mode} />
          }
        />
        <Route
          path="/incidents/create/:controlId/:cardNumber"
          element={<CreateUpdateIncident purpose="create" mode={mode} />}
        />
        <Route
          path="/incidents/create/:controlId/"
          element={<CreateUpdateIncident purpose="create" mode={mode} />}
        />
        <Route
          path="/incidents/edit/:id"
          element={<CreateUpdateIncident purpose="edit" mode={mode} />}
        />
      </Routes>
    </MobileContextProvider>
  );
};
