// Main nav menuItems
export const routes = {
  HOME: '/',
  USERS: '/users',
  WORKERS: '/workers',
  COMPANIES: '/companies',
  WORKSITES: '/worksites',
  CONTROLS: '/controls',
  INCIDENTS: '/incidents'
};

// Main nav subMenuItems
export const subRoutes = {
  USERS: {
    COMPANIES: '/userscompanies',
    WORKERS: '/usersworkers'
  }
};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings'
};

// url search parameters
export const pathSearches = {
  INCIDENTS: '?s=-date'
};

export const pathFilteredSearches = {
  INCIDENTS: '?f=justification_status=PENDING__'
};
