import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PageHeader } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { routes } from '../../utils/constants/adminRoutes';

export const PageHeaderCustom = ({ title, extra, customNav }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  let tmpPath;
  const splitPathname = pathname.split('/').filter((p) => p !== '');
  const subMenu = [
    'userscompanies',
    'usersworkers',
    'usersadmins',
    'userspromoters',
    'usersinspectors'
  ];

  const isObjectId = (str) => {
    const objectIdRegex = /^[0-9a-fA-F]{24}$/;
    return objectIdRegex.test(str);
  };

  const breadCrumbs = splitPathname
    .slice(0, splitPathname.length - 1)
    .map((p) => {
      if (subMenu.includes(p)) {
        tmpPath = `/users/${p}`;
      } else tmpPath = `/${p}`;
      if (!isObjectId(p))
        return {
          path: tmpPath,
          breadcrumbName: t(`breadcrumbs.${p}`)
        };
      return false;
    });

  const dynamicRoutes = [
    {
      path: routes.HOME,
      breadcrumbName: <HomeOutlined />
    },
    ...breadCrumbs
  ];

  const itemRender = (
    // eslint-disable-next-line react/prop-types
    { path, breadcrumbName, state, search, ...route },
    params,
    routesList
  ) => {
    const last = routesList.indexOf(route) === routesList.length - 1;
    if (path !== '/users' && path !== '/show') {
      return last ? (
        <span key={path}>{breadcrumbName}</span>
      ) : (
        <Link
          key={path}
          to={{
            pathname: path,
            state,
            search
          }}
        >
          {breadcrumbName}
        </Link>
      );
    }
    return null;
  };

  return (
    <PageHeader
      breadcrumb={{ routes: dynamicRoutes, itemRender }}
      title={title}
      onBack={() => (customNav ? customNav() : navigate(-1))}
      ghost={false}
      extra={extra}
    />
  );
};

PageHeaderCustom.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  extra: PropTypes.element,
  customNav: PropTypes.func
};

PageHeaderCustom.defaultProps = {
  extra: null,
  customNav: undefined
};
