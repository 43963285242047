import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Drawer, Col, Select, Form, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const { Option } = Select;
const { Text } = Typography;

const SelectCompanyComponent = ({ id, batchtitle, companies }) => {
  const { t } = useTranslation();

  const onsearch = (input, option) => {
    const result = option?.children?.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  return (
    <Row key={id} style={{ marginTop: 20 }}>
      <Col span={24}>
        <Text strong>{batchtitle.label}</Text>
      </Col>
      <Col span={24}>
        <Form.Item
          label="Entreprise principale"
          name={[batchtitle._id, 'principal']}
        >
          <Select
            style={{ width: '100%' }}
            suffixIcon={<CloseCircleOutlined />}
            allowClear
            defaultValue=""
            showSearch
            filterOption={(input, option) => onsearch(input, option)}
          >
            {(companies || []).map((comp) => (
              <Option key={comp?._id} value={comp?._id}>
                {t(`${comp?.name}`)}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="Entreprises sous-traitantes"
          name={[batchtitle._id, 'subcontractors']}
        >
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            showArrow
            showSearch
            filterOption={(input, option) => onsearch(input, option)}
            onChange={() => {}}
          >
            {(companies || []).map((comp) => (
              <Option key={comp?._id} value={comp?._id}>
                {t(`${comp?.name}`)}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

const CompanyToWorksite = ({
  refresh,
  setRefresh,
  companies,
  setIsFieldsLoading,
  companiesRegistered,
  setCompaniesRegistered
}) => {
  const [batchtitles, setBatchtitles] = useState([]);
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();

  const onFinish = (values) => {
    setCompaniesRegistered(values);
    setRefresh(!refresh);
  };

  const sortByAscendingOrder = (data) => {
    const sortData = data?.sort((a, b) => (a.label > b.label ? 1 : -1));
    setBatchtitles(sortData);
  };

  const getBatchtitles = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/batchtitles/' });
      sortByAscendingOrder(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getBatchtitles();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return (
    <>
      <Button
        type="link"
        style={{ padding: 0, height: 'auto' }}
        onClick={() => setRefresh(!refresh)}
      >
        {`${t(`drawerform.company`)}`}
      </Button>
      <Drawer
        width="900px"
        title={t(`drawerform.addaddress`)}
        open={refresh}
        onCancel={() => setRefresh(!refresh)}
        onClose={() => setRefresh(!refresh)}
      >
        <Form
          onFinish={onFinish}
          layout="horizontal"
          initialValues={companiesRegistered}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ width: '100%' }}
        >
          {(batchtitles || []).map(
            (batchtitle) =>
              batchtitle?.show && (
                <div key={batchtitle?._id}>
                  <SelectCompanyComponent
                    id={batchtitle?._id}
                    batchtitle={batchtitle}
                    companies={companies}
                  />
                </div>
              )
          )}
          <Form.Item
            wrapperCol={{ offset: 8, span: 16 }}
            style={{ marginTop: 30 }}
          >
            <Button type="primary" block htmlType="submit">
              Valider l&apos;affectation des entreprises
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

SelectCompanyComponent.propTypes = {
  id: PropTypes.string,
  batchtitle: PropTypes.shape({
    _id: PropTypes.string,
    label: PropTypes.string
  }).isRequired,
  companies: PropTypes.instanceOf(Array)
};

SelectCompanyComponent.defaultProps = {
  companies: null,
  id: undefined
};

CompanyToWorksite.propTypes = {
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
  companies: PropTypes.instanceOf(Array),
  setIsFieldsLoading: PropTypes.func.isRequired,
  companiesRegistered: PropTypes.instanceOf(Object),
  setCompaniesRegistered: PropTypes.func.isRequired
};

CompanyToWorksite.defaultProps = {
  companies: null,
  companiesRegistered: null
};

export default CompanyToWorksite;
