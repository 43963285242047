import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';
import { Row, Card, Col, Typography, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { worksiteList, referentList } from './listContent';
import { DescriptionList } from '../../../components';
import MapZone from '../MapZone';
import CommentModal from './CommentModal';
import WorksiteNewFeed from './WorksiteNewFeed';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';

const InformationPanel = ({ worksite, setRefresh }) => {
  const { user, dispatchAPI } = useAuthContext();
  const { id: worksiteId } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';

  const environnement = {
    development: 'http://localhost:3000/checkcontrol/',
    staging: 'https://app.quietus.strateg.in/checkcontrol/',
    production: 'https://app.quietus.strateg.in/checkcontrol/'
  };
  const qrCodeUrl = `${environnement[process.env.NODE_ENV]}${worksiteId}`;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const updateAuthorizationEmail = async (id, checked) => {
    try {
      setRefresh(true);
      await dispatchAPI('PATCH', {
        url: `users/${id}`,
        body: { emailAuthorized: checked }
      });
    } catch (e) {
      message(e);
    }
    setRefresh(false);
  };

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xs={24} lg={14}>
          <Card title="Informations" bordered={false}>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <QRCode
                  title="Accéder au chantier"
                  value={qrCodeUrl}
                  size={128}
                />
              </Col>
              <Col span={24}>
                <Col span={24}>
                  <DescriptionList
                    skipEmpty
                    data={worksiteList(worksite || {})}
                    translate
                  />
                </Col>
              </Col>
            </Row>
          </Card>
          <Card
            title="Commentaire"
            bordered={false}
            extra={
              <Button type="link" onClick={showModal}>
                <EditOutlined />
              </Button>
            }
          >
            {worksite?.comment ? (
              worksite?.comment
            ) : (
              <Typography.Text italic>
                Aucun commentaire pour le moment
              </Typography.Text>
            )}
          </Card>
        </Col>
        <Col xs={24} lg={10} style={{ minHeight: 400 }}>
          <MapZone worksite={worksite} width="100%" height="100%" />
        </Col>
      </Row>
      <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
        <Col xs={24} lg={12}>
          <Card title="Contacts référents" bordered={false}>
            <Row>
              {Array.isArray(worksite.referents) &&
                worksite?.referents.map((referent) => (
                  <Col
                    key={referent._id}
                    span={24}
                    style={{
                      border: '1px var(--disabledColor) solid',
                      borderRadius: 3,
                      margin: 5,
                      padding: 10
                    }}
                  >
                    <DescriptionList
                      data={referentList(
                        referent || {},
                        t,
                        isAdmin,
                        updateAuthorizationEmail
                      )}
                      translate
                    />
                  </Col>
                ))}
            </Row>
          </Card>
        </Col>

        <Col xs={24} lg={12}>
          <WorksiteNewFeed worksite={worksite} />
        </Col>
      </Row>
      <CommentModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        worksite={worksite}
        setRefresh={setRefresh}
      />
    </>
  );
};

export default InformationPanel;

InformationPanel.propTypes = {
  worksite: PropTypes.shape({
    comment: PropTypes.string,
    referents: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  setRefresh: PropTypes.func
};

InformationPanel.defaultProps = {
  worksite: null,
  setRefresh: null
};
