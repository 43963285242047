import React, { useEffect, useCallback, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Row,
  Col,
  Upload,
  Input,
  message as antdMessage,
  Modal,
  notification,
  Card,
  Tag,
  Popover
} from 'antd';
import { useTranslation } from 'react-i18next';
import { InboxOutlined, WarningOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import ListDocuments from '../listDocuments';
import PrincipalInformationsPanel from './PrincipalInformationsPanel';
import IncidentExtra from './IncidentExtra';
import IncidentNewFeed from './IncidentNewFeed';
import DocumentStatusModal from './DocumentStatusModal';
import useQuietusContext from '../../../contexts/QuietusContext';

const { Dragger } = Upload;
const { TextArea } = Input;

const ShowIncident = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { setIncidentModified, incidentModified } = useQuietusContext();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { user, dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [patchIsLoading, setPatchIsLoading] = useState(false);
  const [incident, setIncident] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jusfifText, setJustifText] = useState('');
  const [openModalDocStatus, setOpenModalDocStatus] = useState(false);
  const [enums, setEnums] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const userRoleCondition = user?.role?.split(':')[0] === 'admins';

  const getIncident = useCallback(async () => {
    setIsLoading(true);
    try {
      const populate =
        'control,worksite,inspector,worker,company,status,type,documents,files.fileId,business_body,principal_company,link_incident';
      const { data } = await dispatchAPI('GET', {
        url: `/incidents/${id}?populate=${populate}`
      });
      setIncident(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/incidents/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const deleteIncident = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/incidents/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const deleteFiles = async () => {
    try {
      await dispatchAPI('PATCH', { url: `/incidents/files/${id}` });
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const props = {
    multiple: false,
    fileList: documents,
    beforeUpload: (newFile) => {
      setDocuments([newFile]);
      return false;
    },
    onRemove: (file) =>
      setDocuments(documents.filter((f) => f.uid !== file.uid))
  };

  const handlePostDocuments = async () => {
    try {
      const formData = new FormData();
      documents.forEach((doc) =>
        formData.append('documents', doc.originFileObj || doc)
      );

      await dispatchAPI('POST', {
        url: `/incidents/upload/${id}`,
        body: formData
      });
      antdMessage.success(` document(s) téléchargé(s)`);
      setDocuments(null);
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const closeIncident = async () => {
    try {
      setIsButtonLoading(true);
      await dispatchAPI('PATCH', {
        url: `/incidents/${id}`,
        body: {
          status: '634c60a28d00c8c79e6b8aba',
          closure_justification: jusfifText
        }
      });
      await getIncident();
      window.location.reload();
    } catch (e) {
      message(e);
    }
    setIsButtonLoading(false);
  };

  const showCloseModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    if (jusfifText !== '') {
      closeIncident();
    } else {
      notification.error({
        message: 'Erreur',
        description: "Impossible de clôturer l'incident sans justification"
      });
    }
  };

  const handleCancelModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    (async () => {
      await getIncident();
      await getEnums();
    })();
  }, [getIncident, refresh]);

  const patchDocStatus = async (values) => {
    setPatchIsLoading(true);
    try {
      const { data } = await dispatchAPI('PATCH', {
        url: `/incidents/${id}`,
        body: values
      });
      if (data) setOpenModalDocStatus(false);
      setIncidentModified(!incidentModified);
      getIncident();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setPatchIsLoading(false);
  };

  return (
    <>
      <PageHeaderCustom
        title={
          <>
            <Row align="middle" style={{ gap: 8 }}>
              <span>{`${t('incidents.show.title')} - ${
                incident?.reference
              }`}</span>
              {incident?.status && (
                <Tag color={incident?.status.color}>
                  {incident?.status.label}
                </Tag>
              )}
            </Row>
            {(!incident?.company || !incident?.worker) && (
              <Row>
                <Col>
                  <Popover
                    content={`
                      ${
                        !incident?.worker && !incident?.worker
                          ? t('incidents.show.both_missing')
                          : ''
                      }
                      ${
                        !incident?.worker && incident?.company
                          ? t('incidents.show.missing_worker')
                          : ''
                      }${
                      !incident?.company && incident?.worker
                        ? t('incidents.show.missing_company')
                        : ''
                    }`}
                    placement="right"
                  >
                    <WarningOutlined
                      style={{
                        color: 'var(--errorColor)',
                        fontSize: 22,
                        marginRight: 10
                      }}
                    />
                  </Popover>

                  <span style={{ fontSize: 16 }}>
                    {t('incidents.show.missing_information')}
                  </span>
                </Col>
              </Row>
            )}
          </>
        }
        extra={
          <IncidentExtra
            incident={incident}
            showCloseModal={showCloseModal}
            userRoleCondition={userRoleCondition}
            deleteIncident={deleteIncident}
            isButtonLoading={isButtonLoading}
          />
        }
      />
      <ContentCustom>
        <Row gutter={[16, 16]} style={{ alignItems: 'stretch' }}>
          <Col xs={24} xl={14} xxl={16}>
            <Card bordered={false} title="Informations">
              <PrincipalInformationsPanel
                isLoading={isLoading}
                incident={incident}
                setOpenModalDocStatus={setOpenModalDocStatus}
                refresh={refresh}
                setRefresh={setRefresh}
                deleteFiles={deleteFiles}
                setIsDocumentLoading={setIsDocumentLoading}
                isDocumentLoading={isDocumentLoading}
              />
            </Card>
          </Col>
          <Col xs={24} xl={10} xxl={8}>
            <IncidentNewFeed incident={incident} />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col xs={24}>
            <Card bordered={false} title="Documents">
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={8} xxl={6} style={{ height: 'fit-content' }}>
                  <div>
                    <Dragger {...props}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Glisser / Déposer</p>
                    </Dragger>
                    {documents && (
                      <Button type="primary" onClick={handlePostDocuments}>
                        Enregistrer
                      </Button>
                    )}
                  </div>
                </Col>
                <Col xs={24} lg={16} xxl={18}>
                  <ListDocuments data={incident} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </ContentCustom>
      <Modal
        title="Justification de la clôture de l'incident"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancelModal}
      >
        <TextArea
          rows={4}
          value={jusfifText}
          onChange={(e) => setJustifText(e.target.value)}
        />
      </Modal>
      <DocumentStatusModal
        incident={incident}
        openModalDocStatus={openModalDocStatus}
        setOpenModalDocStatus={setOpenModalDocStatus}
        patchDocStatus={patchDocStatus}
        enums={enums}
        patchIsLoading={patchIsLoading}
      />
    </>
  );
};

export default ShowIncident;
