import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';

const MultiControlPlanningModal = ({
  worksite,
  isModalOpen,
  setIsModalOpen
}) => {
  const navigate = useNavigate();

  const { fields, isFieldsLoading } = useFields(isModalOpen, worksite);

  const closeModal = () => {
    setIsModalOpen(false);
    navigate(`/worksites/show/${worksite && worksite._id}`);
  };

  const config = {
    onGetResource: {
      setFields: (data) => ({
        inspector: data?.idController && data.idController
      })
    }
  };

  return (
    <Modal
      title="Créer les contrôles de ce chantier"
      width="50%"
      open={isModalOpen}
      footer={false}
      onCancel={closeModal}
      closable={false}
      maskClosable={false}
    >
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose="edit"
        baseUrl="/controls/many"
        getBaseUrl="/worksites"
        resource="controls"
        isOverlay
        customNavigate={closeModal}
        idFromOverlay={worksite._id}
        withPageHeader={false}
        withCancelButton={false}
        config={config}
        populate="idController"
      />
    </Modal>
  );
};

MultiControlPlanningModal.propTypes = {
  worksite: PropTypes.shape({
    name: PropTypes.string,
    start_date: PropTypes.string,
    second_phase_expected_date: PropTypes.string,
    end_date: PropTypes.string,
    _id: PropTypes.string
  }).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired
};

export default MultiControlPlanningModal;
