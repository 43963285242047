import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Alert,
  Card,
  Col,
  DatePicker,
  Row,
  Switch,
  Spin,
  TreeSelect
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ContentCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useDownloadDocument } from '../../utils/downloadDoc';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { MapWrapper } from '../worksites/map';
import CardBar, { bar } from './CardBar';
import CardDonuts from './CardDonuts';

const AdminHome = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [dataAdmin, setDataAdmin] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [numberControlByYear, setNumberControlByYear] = useState(null);
  const [period, setPeriod] = useState(true);
  const [dateSelected, setDateSelected] = useState(new Date().getFullYear());
  const [WorksiteControls, setWorksiteControls] = useState(null);
  const [dataForSelect, setDataForSelect] = useState(null);
  const [selectValue, setSelectValue] = useState(null);
  const [companySelectValue, setCompanySelectValue] = useState(null);
  const [dataForCompanySelect, setDataForCompanySelect] = useState(null);
  const [switchDay, setSwitchDay] = useState(true);
  const { downloadDocument } = useDownloadDocument();
  const { t } = useTranslation();

  const getData = async (value) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/dashboard/admins?${
          value && value.promoter ? `idPromoter=${value.promoter}` : ''
        }${value && value.worksite ? `&idWorksite=${value.worksite}` : ''}${
          value && value.company ? `&idCompany=${value.company}` : ''
        }`
      });
      setDataAdmin(data);
    } catch (error) {
      message(error);
    }
  };

  const getSelectData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/dashboard/admins/select'
      });
      setDataForSelect(data);
    } catch (error) {
      message(error);
    }
  };

  const handleDatepicker = async (_, year, month, day) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/dashboard/admins/datePicker?${year && `year=${year}`}&${
          month && `month=${month}`
        }&${day && `day=${day}`}`
      });
      setWorksiteControls(data);
      setNumberControlByYear(data.totalControls);
    } catch (error) {
      message(error);
    }
  };

  const handleSwitch = () => {
    if (!period) {
      const year = dateSelected.split('-')[0];
      handleDatepicker(null, year);
    } else {
      const month =
        dateSelected.toString().split('-').length > 1
          ? dateSelected.toString().split('-')[1]
          : '01';
      const year = dateSelected.toString().split('-')[0];
      handleDatepicker(null, year, month);
    }
    setPeriod(!period);
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getData();
      await handleDatepicker(
        null,
        moment().format('YYYY'),
        moment().format('M'),
        moment().date()
      );
      await getSelectData();
      setIsLoading(false);
    })();
  }, []);

  const getCompaniesWorksite = async (id) => {
    const { data } = await dispatchAPI('GET', {
      url: `/dashboard/companySelect/${id}`
    });
    setDataForCompanySelect(data);
  };

  const handleSelected = async (value) => {
    setSelectValue(value);
    setDataForCompanySelect(null);
    if (
      dataForSelect.findIndex(
        (i) => JSON.stringify(i.value) === JSON.stringify(value)
      ) !== -1
    ) {
      getData({ promoter: value });
    } else {
      await getCompaniesWorksite(value);
      getData({ worksite: value });
    }
  };

  const handleCompanySelected = async (value) => {
    setCompanySelectValue(value);
    if (value === 'null') {
      await getData({ worksite: selectValue });
    } else await getData({ company: value, worksite: selectValue });
  };

  const handleSwitchDay = () => {
    if (!switchDay)
      handleDatepicker(
        null,
        moment().format('YYYY'),
        moment().format('M'),
        moment().date()
      );
    else
      handleDatepicker(
        null,
        dateSelected.toString().split('-')[0],
        dateSelected.toString().split('-')[1]
      );
    setSwitchDay(!switchDay);
  };

  return isLoading ? (
    <Spin
      spinning={isLoading}
      size="large"
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%'
      }}
    />
  ) : (
    dataAdmin && (
      <ContentCustom>
        <Row className="dashboard-row">
          <Col span={24} style={{ display: 'flex' }}>
            <TreeSelect
              style={{
                width: '50%',
                display: 'flex',
                margin: 10
              }}
              value={selectValue}
              treeData={dataForSelect && dataForSelect}
              onChange={handleSelected}
              treeLine
            />
            {dataForCompanySelect && (
              <TreeSelect
                style={{
                  width: '50%',
                  display: 'flex',
                  margin: 10
                }}
                value={companySelectValue}
                treeData={dataForCompanySelect && dataForCompanySelect}
                onChange={handleCompanySelected}
                treeLine
              />
            )}
          </Col>
          <Col xs={24} lg={12} xxl={8}>
            <CardDonuts
              dataAdmin={dataAdmin?.steps?.data}
              title={t('dashboard.closed_steps')}
              total={dataAdmin?.steps?.total}
              info={
                <div className="dashboard-card-infos">
                  <p>{t('dashboard.info.closed_steps.before_first_mail')}</p>
                  <p>{t('dashboard.info.closed_steps.first_mail')}</p>
                  <p>{t('dashboard.info.closed_steps.first_raise')}</p>
                  <p>{t('dashboard.info.closed_steps.LAR_raise_clos')}</p>
                  <p>{t('dashboard.info.closed_steps.LAR_raise_open')}</p>
                  <p>{t('dashboard.info.closed_steps.center')}</p>
                </div>
              }
            />
          </Col>
          <Col xs={24} lg={12} xxl={8}>
            <CardDonuts
              dataAdmin={dataAdmin?.whitoutCard?.data}
              title={t('dashboard.incidents_numbers_per_type')}
              total={dataAdmin?.whitoutCard?.total}
              info={
                <div className="dashboard-card-infos">
                  <p>{t('dashboard.info.incidents_per_type.info')}</p>
                  <p>{t('dashboard.info.incidents_per_type.center')}</p>
                </div>
              }
            />
          </Col>
          <Col xs={24} lg={12} xxl={8}>
            <CardDonuts
              dataAdmin={dataAdmin?.incidentsPerControl?.data}
              title={t('dashboard.workers_controled_and_incidents_numbers')}
              total={dataAdmin?.incidentsPerControl?.total}
              info={
                <div className="dashboard-card-infos">
                  <p>{t('dashboard.info.incidents_per_control.center')}</p>
                </div>
              }
            />
          </Col>
          <Col xs={24} lg={12} xxl={8}>
            <CardDonuts
              dataAdmin={dataAdmin?.closingRate?.data}
              title={t('dashboard.closing_rate')}
              total={dataAdmin?.closingRate.total}
              info={
                <div className="dashboard-card-infos">
                  <p>{t('dashboard.info.closing_rate.center')}</p>
                </div>
              }
            />
          </Col>
          <Col xs={24} lg={12} xxl={8}>
            <CardBar
              dataAdmin={dataAdmin?.worksitePerPromoters}
              title={t('dashboard.worksite_numbers_per_promoters')}
            />
          </Col>
          <Col xs={24} lg={12} xxl={8}>
            <Card title={t('dashboard.controls_numbers')}>
              <Alert
                className="dashboard-alert"
                description={
                  <div>
                    <Row
                      style={{
                        gap: 8,
                        flexWrap: 'nowrap',
                        fontSize: 16
                      }}
                      justify="space-between"
                      align="middle"
                    >
                      <Switch
                        defaultChecked
                        checkedChildren={t('dashboard.switchDay')}
                        unCheckedChildren={t('dashboard.switchDay')}
                        onChange={handleSwitchDay}
                      />
                      {!switchDay && (
                        <>
                          <Switch
                            defaultChecked
                            checkedChildren={t('dashboard.switchYear.check')}
                            unCheckedChildren={t(
                              'dashboard.switchYear.unCheck'
                            )}
                            onChange={() => {
                              handleSwitch();
                            }}
                          />
                          <DatePicker
                            picker={period ? 'year' : 'month'}
                            defaultValue={moment(new Date())}
                            onChange={(_, date) => {
                              handleDatepicker(
                                _,
                                date.toString().split('-')[0],
                                date.toString().split('-')[1]
                              );
                              setDateSelected(date);
                            }}
                          />
                        </>
                      )}

                      <p
                        style={{ margin: 0 }}
                      >{`${numberControlByYear} sessions`}</p>
                      <InfoCircleOutlined className="dashboard-info-icon" />
                      <div className="dashboard-card-infos">
                        <p>{t('dashboard.info.controls_per_promoter.info')}</p>
                      </div>
                    </Row>
                    <Alert
                      className="dashboard-alert"
                      description={bar(
                        WorksiteControls?.controlsPerWorksite,
                        'controls',
                        false
                      )}
                    />
                  </div>
                }
              />
            </Card>
          </Col>
          <Col xs={24} lg={12} xxl={8}>
            <Card
              title={t('dashboard.incidents_list')}
              className="incident-list"
            >
              {dataAdmin?.incidentsOpen?.map((incident) => (
                <Alert
                  key={incident?._id}
                  description={
                    <Link to={{ pathname: `/incidents/show/${incident._id}` }}>
                      <p>
                        {incident.worker?.first_name}{' '}
                        {incident.worker?.lats_name} {' - '}
                        {incident.type?.label}{' '}
                      </p>
                      <p>{moment(incident?.date).format('DD/MM/YYYY')}</p>
                    </Link>
                  }
                />
              ))}
            </Card>
          </Col>
          <Col xs={24} lg={12} xxl={8}>
            <Card
              title={t('dashboard.invoices_list')}
              className="incident-list"
            >
              {dataAdmin.invoices?.map((promoter) => (
                <Card title={promoter.promoteur} key={promoter.promoteur}>
                  {promoter?.invoices.map((i) => (
                    <Alert
                      key={i._id}
                      onClick={() => downloadDocument(i, 'show')}
                      description={
                        <p>
                          {i.metadata.originalName} {' - '}
                          {moment(i.metadata.uploadDate).format('DD/MM/YYYY')}
                        </p>
                      }
                    />
                  ))}
                </Card>
              ))}
            </Card>
          </Col>
          <Col xs={24} lg={12} xxl={8} style={{ minHeight: 400 }}>
            <MapWrapper markers={dataAdmin?.worksitesLocations} />
          </Col>
        </Row>
      </ContentCustom>
    )
  );
};

export default AdminHome;
