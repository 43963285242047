import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Avatar, Tag, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import listContent from './listContent';
import { routes } from '../../utils/constants/adminRoutes';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import DefaultAvatar from '../../assets/images/default-avatar.png';

const ShowWorker = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, user: userInfo } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [worker, setWorker] = useState(null);
  const [incidents, setIncidents] = useState([]);
  const [documents, setDocuments] = useState(null);
  const isAdmin =
    userInfo?.role === 'admins:SUPER-ADMIN' ||
    userInfo?.role === 'admins:ADMIN';
  const isCompanyAdmin = worker?.role === 'companies:ADMIN-COMPANY';

  const getWorker = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/workers/${id}?populate=idCompany,files files.fileId,business_body`
      });
      setWorker(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const getIncidents = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/incidents/?populate=status,files files.fileId&worker=${id}`
      });
      setIncidents(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getWorker();
      await getIncidents();
    })();
  }, [getWorker]);

  const deleteUser = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/workers/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const draggerProps = {
    multiple: false,
    fileList: documents,
    beforeUpload: (newFile) => {
      setDocuments([newFile]);
      return false;
    },
    onRemove: (file) =>
      setDocuments(documents.filter((f) => f.uid !== file.uid))
  };

  const handlePostDocuments = async () => {
    try {
      const formData = new FormData();
      documents.forEach((doc) => {
        formData.append('file', doc.originFileObj || doc);
        formData.append('type', doc.type);
      });
      await dispatchAPI('POST', {
        url: `/workers/upload_files/${id}`,
        body: formData
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={
          worker?.isActive ? (
            `${t('users.show.title')}`
          ) : (
            <>
              {`${t('users.show.title')} - `}
              <Tag>{t(`users.tags.${worker?.isActive}`)}</Tag>
            </>
          )
        }
        extra={
          <>
            {(isCompanyAdmin || isAdmin) && (
              <Link to={{ pathname: `${routes.WORKERS}/edit/${id}` }}>
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
            )}
            {isAdmin && (
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={deleteUser}
                icon={<WarningOutlined />}
              >
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            )}
          </>
        }
      />
      <ContentCustom>
        <Row gutter={[24, 24]}>
          <Col xs={24} md={4} xl={3}>
            <Skeleton
              loading={isLoading}
              title={0}
              paragraph={0}
              avatar={{ size: 64 }}
              active
            >
              <Avatar
                size={64}
                src={worker && worker.photo ? worker.photo : DefaultAvatar}
              />
            </Skeleton>
          </Col>
          <Col xs={24} md={20} xl={21}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <DescriptionList
                data={listContent(
                  worker || {},
                  t,
                  handlePostDocuments,
                  documents,
                  draggerProps,
                  incidents
                )}
                translate
              />
            </Skeleton>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};

export default ShowWorker;
