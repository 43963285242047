import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar, Button, Layout, Menu, message, Popover, Badge } from 'antd';
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  FolderOpenOutlined,
  AlertOutlined,
  IdcardOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import useQuietusContext from '../../../contexts/QuietusContext';
import HeaderDropdown from '../../HeaderDropdown/HeaderDropdown';
import {
  pathFilteredSearches,
  routes,
  subRoutes
} from '../../../utils/constants/adminRoutes';

const { Header } = Layout;

const StyledHeader = styled.div`
  background: var(--menuDropdownBackground);
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  padding: 0;
  z-index: 8;
  width: 100%;
  position: fixed;
`;

const StyledContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-items: center;
`;

const StyledDropdownContainer = styled.span`
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 0 12px;
  transition: all 0.3s;
  :hover {
    background: var(--contrastBackground);
  }
  .ant-btn-link {
    height: fit-content;
  }
`;

const StyledFullNameSpan = styled.span`
  color: var(--textColor);
  font-weight: 600;
`;

const StyledRoleP = styled.p`
  font-size: 14px;
  color: var(--textColorSecondary);
  margin-bottom: 0;
`;

const TriggerCollapse = styled.div`
  color: var(--textColor);
  font-size: 20px;
  padding: 0 24px;
  cursor: pointer;
  margin-left: ${(props) => props.marginleft};
  z-index: 10000;
  :hover {
    color: var(--primaryColor);
  }
`;

const HeaderLayout = ({
  collapseSider,
  collapsed,
  collapseWidth,
  themeLogo,
  triggercollapse
}) => {
  const { user, dispatchAPI } = useAuthContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { INCIDENTS, UNKNOWNCOMPANY, USERSMODIFIED } = useQuietusContext();
  const [marginTrigger, setMarginTrigger] = useState('0px');
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';
  const isPromoter = user.role === 'promoters:ADMIN-PROMOTER';
  const onProfileMenuClick = ({ key }) => {
    if (key === 'logout') {
      dispatchAPI('LOGOUT');
      message.success(t('login.logoutSuccess'));
    }
  };

  const profileMenu = (
    <Menu onClick={onProfileMenuClick}>
      {window.innerWidth <= 992 && user && (
        <Menu.Item key="user">
          <Button
            style={{
              width: '100%',
              height: 50
            }}
            type="link"
          >
            <StyledFullNameSpan>
              {`${user.first_name} ${user.last_name}`}
            </StyledFullNameSpan>
            <StyledRoleP>
              {t(`users.tags.${user.role.split(':')[1]}`)}
            </StyledRoleP>
          </Button>
        </Menu.Item>
      )}
      <Menu.Item key="profile" onClick={() => navigate('/profile')}>
        <UserOutlined
          style={{
            fontSize: 16
          }}
        />
        {` ${t('header.menu.user.profile')}`}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <LogoutOutlined
          style={{
            fontSize: 16
          }}
        />
        {` ${t('header.menu.user.logout')}`}
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (collapsed) {
      if (collapseWidth === 0) {
        setMarginTrigger('0px');
      } else {
        setMarginTrigger('80px');
      }
    } else {
      setMarginTrigger('256px');
    }
  }, [collapsed, collapseWidth]);

  return (
    <StyledHeader as={Header}>
      {window.innerWidth < 992 && triggercollapse && (
        <TriggerCollapse
          as={collapsed ? MenuUnfoldOutlined : MenuFoldOutlined}
          className={collapsed ? '' : 'trigger-collapse-btn'}
          marginleft={marginTrigger}
          onClick={() => collapseSider(!collapsed)}
        />
      )}
      {themeLogo && (
        <img
          style={{
            width: 100,
            maxHeight: 50,
            position: 'absolute',
            top: 10,
            left: '50%',
            marginLeft: '-50px'
          }}
          src={themeLogo}
          alt="Company logo"
        />
      )}
      <StyledContainer>
        {isAdmin && (
          <Popover placement="bottom" content={t('basiclayout.icon.incidents')}>
            <Link
              to={
                INCIDENTS === 0
                  ? `${routes.INCIDENTS}`
                  : `${routes.INCIDENTS}${pathFilteredSearches.INCIDENTS}`
              }
            >
              <FolderOpenOutlined
                style={{
                  fontSize: 24,
                  color: 'var(--errorColor)'
                }}
              />
              {INCIDENTS !== 0 ? (
                <Badge
                  offset={[-10, -26]}
                  count={INCIDENTS}
                  style={{ background: 'var(--errorColor)' }}
                />
              ) : (
                []
              )}
            </Link>
          </Popover>
        )}
        {(isAdmin || isPromoter) && (
          <Popover
            placement="bottom"
            content={t('basiclayout.icon.unknown_company')}
          >
            <Link
              to={
                UNKNOWNCOMPANY === 0
                  ? `${routes.INCIDENTS}`
                  : `${routes.INCIDENTS}${pathFilteredSearches.UNKNOWNCOMPANY}`
              }
            >
              <AlertOutlined
                style={{
                  fontSize: 24,
                  color: 'var(--errorColor)'
                }}
              />
              {UNKNOWNCOMPANY !== 0 ? (
                <Badge
                  offset={[-10, -26]}
                  count={UNKNOWNCOMPANY}
                  style={{ background: 'var(--errorColor)' }}
                />
              ) : (
                []
              )}
            </Link>
          </Popover>
        )}
        {isAdmin && (
          <Popover
            placement="bottom"
            content={t('basiclayout.icon.modifications')}
          >
            <Link
              to={
                USERSMODIFIED === 0
                  ? `${routes.USERS}${subRoutes.USERS.WORKERS}`
                  : `${routes.USERS}${subRoutes.USERS.WORKERS}${pathFilteredSearches.USERSMOFIFIED}`
              }
            >
              <IdcardOutlined
                style={{
                  fontSize: 24,
                  color: 'var(--errorColor)'
                }}
              />
              {USERSMODIFIED !== 0 ? (
                <Badge
                  offset={[-10, -26]}
                  count={USERSMODIFIED}
                  style={{ background: 'var(--errorColor)' }}
                />
              ) : (
                []
              )}
            </Link>
          </Popover>
        )}
        <HeaderDropdown overlayMenu={profileMenu}>
          {window.innerWidth <= 992 ? (
            <StyledDropdownContainer>
              <UserOutlined />
            </StyledDropdownContainer>
          ) : (
            <StyledDropdownContainer>
              <Avatar
                size="medium"
                icon={user?.photo ? '' : <UserOutlined />}
                src={user?.photo ? user.photo : ''}
              >
                {`${user?.first_name[0]}${user?.last_name[0]}`}
              </Avatar>
              <Button type="link">
                <StyledFullNameSpan>
                  {`${user?.first_name} ${user?.last_name}`}
                </StyledFullNameSpan>
                <StyledRoleP>
                  {t(`users.tags.${user?.role.split(':')[1]}`)}
                </StyledRoleP>
              </Button>
            </StyledDropdownContainer>
          )}
        </HeaderDropdown>
      </StyledContainer>
    </StyledHeader>
  );
};

HeaderLayout.propTypes = {
  collapseSider: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  collapseWidth: PropTypes.number.isRequired,
  themeLogo: PropTypes.string,
  triggercollapse: PropTypes.bool
};

HeaderLayout.defaultProps = {
  themeLogo: null,
  triggercollapse: true
};

export default HeaderLayout;
