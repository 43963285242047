import React, { useCallback, useEffect, useState } from 'react';
import {
  Select,
  Tag,
  DatePicker,
  Upload,
  Input,
  Divider,
  Space,
  Button
} from 'antd';
import { useTranslation } from 'react-i18next';
import { FileDoneOutlined, PlusOutlined } from '@ant-design/icons/lib';
import moment from 'moment/moment';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

const { Option } = Select;
const { TextArea } = Input;

const useFields = (purpose) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const controlId = queryParams.get('controlId');
  const worksiteId = queryParams.get('worksiteId');
  const inspectorId = queryParams.get('inspectorId');
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [inspectors, setInspectors] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [worksites, setWorksites] = useState([]);
  const [controls, setControls] = useState([]);
  const [documents, setDocuments] = useState(null);
  const [isUnkownWorker, setIsUnknownWorker] = useState(false);
  const [isUnkownCompany, setIsUnknownCompany] = useState(false);
  const [unknownWorker, setUnknownWorker] = useState(null);
  const [unknownCompany, setUnknownCompany] = useState(null);
  const [worksiteSelected, setWorksiteSelected] = useState(null);
  const [businessBody, setBusinessBody] = useState([]);
  const [workerSelected, setWorkerSelected] = useState(null);
  const [controlSelected, setControlSelected] = useState(null);
  const [incidentStatuses, setIncidentStatuses] = useState(null);
  const [incidentTypes, setIncidentTypes] = useState(null);

  const { Dragger } = Upload;

  const draggerProps = {
    multiple: false,
    fileList: documents,
    beforeUpload: (newFile) => {
      setDocuments([newFile]);
      return false;
    },
    onRemove: (file) =>
      setDocuments(documents.filter((f) => f.uid !== file.uid))
  };

  const onsearch = (input, option) => {
    const result = option?.children?.props.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const selectWorsite = (value) => {
    const worksite = worksites.filter((w) => w._id === value)[0];
    setWorksiteSelected(worksite);
  };

  const selectedControl = (value) => {
    const controlFilter = controls.filter((c) => c._id === value)[0];
    setControlSelected(controlFilter);
  };

  const handleSelectWorker = (value) => {
    const worker = workers.filter((c) => c._id === value)[0];
    if (Array.isArray(worker?.idCompany)) {
      const WorkerCompanyTab = [];
      companies.map((c) => {
        if (worker.idCompany.includes(c._id)) {
          WorkerCompanyTab.push(c);
          return WorkerCompanyTab;
        }
        return null;
      });
      setWorkerSelected(WorkerCompanyTab);
    } else {
      const companieOfWorker = companies.filter(
        (c) => c._id === worker.idCompany
      )[0];
      setWorkerSelected(companieOfWorker);
    }
  };

  const customCompaniesArray = () => {
    const newArray = [];
    const data = [];
    if (worksiteSelected) {
      Object.entries(worksiteSelected?.companies).map((companie) => {
        if (companie[1].principal) {
          newArray.push(companie[1]?.principal);
          if (companie[1]?.subcontractors) {
            companie[1]?.subcontractors.map((c) => {
              if (newArray.indexOf(c) === -1) {
                newArray.push(c);
              }
              return newArray;
            });
          }
        }

        return newArray;
      });
      newArray?.map((idCompanie) => {
        if (companies) {
          const index =
            companies && companies.findIndex((c) => c._id === idCompanie);
          if (!data.includes(companies[index])) {
            data.push(companies[index]);
          }
        }
        return data;
      });
      return data;
    }
    return null;
  };

  const inputOfCompany = () => {
    if (isUnkownCompany) {
      return <Input onInput={(text) => setUnknownCompany(text.target.value)} />;
    }
    if (workerSelected) {
      if (Array.isArray(workerSelected)) {
        return (
          <Select
            loading={isFieldsLoading}
            showSearch
            filterOption={(input, option) => onsearch(input, option)}
            dropdownRender={(menu) => (
              <>
                <Space style={{ padding: '0 8px 4px' }}>
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={() => setIsUnknownCompany(true)}
                  >
                    Entreprise inconnue ?
                  </Button>
                </Space>
                <Divider style={{ margin: '8px 0' }} />
                {menu}
              </>
            )}
          >
            {workerSelected.map((c) => (
              <Option key={c._id} value={c._id}>
                <Tag>{`${c.name}`}</Tag>
              </Option>
            ))}
          </Select>
        );
      }
      return workerSelected.name;
    }
    return (
      <Select
        loading={isFieldsLoading}
        showSearch
        filterOption={(input, option) => onsearch(input, option)}
        dropdownRender={(menu) => (
          <>
            <Space style={{ padding: '0 8px 4px' }}>
              <Button
                type="text"
                icon={<PlusOutlined />}
                onClick={() => setIsUnknownCompany(true)}
              >
                Entreprise inconnue ?
              </Button>
            </Space>
            <Divider style={{ margin: '8px 0' }} />
            {menu}
          </>
        )}
      >
        {worksiteSelected
          ? customCompaniesArray().map((company) => (
              <Option key={company._id} value={company._id}>
                <Tag>{`${company.name}`}</Tag>
              </Option>
            ))
          : companies?.map((company) => (
              <Option key={company._id} value={company._id}>
                <Tag>{`${company.name}`}</Tag>
              </Option>
            ))}
      </Select>
    );
  };

  const onsearchBusinessBody = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const fields = [
    {
      name: ['worksite'],
      rules: [{ required: true }],
      initialValue: worksiteId || null,
      input: (
        <Select
          allowClear
          disabled={purpose === 'edit' || worksiteId}
          loading={isFieldsLoading}
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
          onChange={selectWorsite}
        >
          {(worksites || []).map((worksite) => (
            <Option key={worksite._id} value={worksite._id}>
              <Tag>{t(`${worksite.name}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['control'],
      rules: [{ required: true }],
      initialValue: controlId || null,
      input: (
        <Select
          allowClear
          disabled={purpose === 'edit' || controlId || !worksiteSelected}
          loading={isFieldsLoading}
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
          onChange={selectedControl}
        >
          {(controls || []).map((control) => (
            <Option key={control._id} value={control._id}>
              <Tag>{t(`${control.reference}`) || '-'}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['inspector'],
      rules: [{ required: true }],
      initialValue: inspectorId || null,
      input: (
        <Select
          allowClear
          disabled={purpose === 'edit' || inspectorId || !controlSelected}
          loading={isFieldsLoading}
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(inspectors || []).map((u) => (
            <Option key={u._id} value={u._id}>
              <Tag>{`${u.first_name} ${u.last_name} ( ${u.email} ) `}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['status'],
      rules: [{ required: true }],
      input: (
        <Select
          disabled={purpose === 'edit'}
          loading={isFieldsLoading}
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(incidentStatuses || []).map((status) => (
            <Option key={status._id} value={status._id}>
              <Tag color={status.color}>{t(`${status.label}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['type'],
      rules: [{ required: true }],
      input: (
        <Select
          disabled={purpose === 'edit'}
          loading={isFieldsLoading}
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(incidentTypes || []).map((type) => (
            <Option key={type._id} value={type._id}>
              <Tag color={type.color}>{t(`${type.label}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: [isUnkownWorker ? 'unknown_worker' : 'worker'],
      rules: [{ required: purpose === 'create' }],
      input: isUnkownWorker ? (
        <Input onInput={(text) => setUnknownWorker(text.target.value)} />
      ) : (
        <Select
          loading={isFieldsLoading}
          showSearch
          onChange={handleSelectWorker}
          filterOption={(input, option) => onsearch(input, option)}
          dropdownRender={(menu) => (
            <>
              <Space style={{ padding: '0 8px 4px' }}>
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={() => setIsUnknownWorker(true)}
                >
                  Utilisateur inconnu ?
                </Button>
              </Space>
              <Divider style={{ margin: '8px 0' }} />
              {menu}
            </>
          )}
        >
          {(workers || []).map((u) => (
            <Option key={u._id} value={u._id}>
              <Tag>{`${u.first_name || '-'} ${u.last_name || '-'} ${
                u.email || '-'
              }`}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['company'],
      rules: [{ required: purpose === 'create' }],
      input: inputOfCompany()
    },
    {
      name: ['business_body'],
      required: isUnkownCompany,
      hidden: !isUnkownCompany,
      input: (
        <Select
          showSearch
          filterOption={(input, option) => onsearchBusinessBody(input, option)}
        >
          {businessBody.map((i) => (
            <Option key={i._id} value={i._id}>
              {i.label}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          disabled={purpose === 'edit'}
          style={{ width: '100%' }}
          format="DD/MM/YYYY HH:mm"
          defaultValue={moment()}
        />
      ),
      initialValue: moment()
    },
    {
      name: ['files'],
      rules: [{ required: false }],
      input: (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <FileDoneOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      name: ['comment'],
      rules: [{ required: false }],
      input: <TextArea rows={5} />
    }
  ];

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies/' });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getControls = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/controls?populate=worksite${
          worksiteSelected?._id ? `&worksite=${worksiteSelected?._id}` : ''
        }`
      });
      setControls(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getWorksites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/worksites/' });
      setWorksites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users?role=inspectors:INSPECTOR${
          controlSelected?.inspector ? `&_id=${controlSelected?.inspector}` : ''
        }`
      });
      setInspectors(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getWorkers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/workers/`
      });
      setWorkers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getIncidentStatuses = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/incidentstatuses?show=true'
      });
      setIncidentStatuses(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getIncidentTypes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/incidenttypes?show=true'
      });
      setIncidentTypes(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getBusinessBody = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/batchtitle?sort=label&show=${true}`
      });
      setBusinessBody(data);
    } catch (error) {
      if (error) message(error.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getCompanies();
    await getWorksites();
    await getUsers();
    await getControls();
    await getIncidentStatuses();
    await getIncidentTypes();
    await getBusinessBody();
    await getWorkers();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  useEffect(() => {
    (async () => {
      await getControls();
    })();
  }, [worksiteSelected]);

  useEffect(() => {
    (async () => {
      await getUsers();
    })();
  }, [controlSelected]);

  return {
    fields,
    isFieldsLoading,
    unknownWorker,
    unknownCompany,
    documents,
    setDocuments
  };
};

export default useFields;
