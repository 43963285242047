/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { Divider, Typography, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { ContentCustom } from '../../../components';
import WorksiteCard from './WorksiteCard';
import ControlCard from './ControlCard';

const { Title } = Typography;

export const CardsBundle = ({
  type,
  resourceName,
  populate,
  extraQuery,
  sort
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [resources, setResources] = useState([]);
  const [notReferentWorksite, setNotReferentWorksite] = useState([]);
  const params = new URLSearchParams(location.search);
  const searchValue = params.get('k');
  const currentPage = Number(params.get('p'));
  const pageSize = Number(params.get('pS'));
  const currentFilters = params.get('f');
  const currentSorter = sort;
  const [pagination, setPagination] = useState({
    pageSize: 10,
    total: 0,
    showTotal: (total, range) => `${range[1]} sur ${total} éléments`,
    showSizeChanger: true
  });

  const handlePaginationChange = (page) => {
    params.set('p', page);
    params.set('pS', 10);
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const fetchData = useCallback(
    async (page = pagination) => {
      const searchURL = searchValue ? `/search/${searchValue}` : null;
      let sortingParameter;
      if (currentSorter) sortingParameter = `sort=${currentSorter}&`;
      let filterParameter;
      if (currentFilters)
        filterParameter = `${currentFilters.replaceAll('__', '&')}`;
      try {
        const { data, headers } = await dispatchAPI('GET', {
          url: `/${resourceName}${searchURL || ''}?${
            extraQuery ? `${extraQuery}&` : ''
          }${sortingParameter || ''}${filterParameter || ''}${
            populate ? `populate=${populate}&` : ''
          }limit=${pageSize}&skip=${(currentPage - 1) * pageSize}`
        });
        setPagination({
          ...page,
          total: parseInt(headers?.['x-total-count'] || 0, 10)
        });
        setResources(data);
      } catch (e) {
        message(e);
      }
    },
    [extraQuery, currentPage, pageSize]
  );

  const getWorksiteControls = async (controls) => {
    const tabOfWorksitesId = [];
    controls?.map((control) => {
      if (!tabOfWorksitesId.includes(control?.worksite)) {
        tabOfWorksitesId.push(control.worksite);
        return tabOfWorksitesId;
      }
      return null;
    });
    const worksiteList = tabOfWorksitesId.join(',');

    try {
      const { data } = await dispatchAPI('GET', {
        url: `worksite?_id=${worksiteList}&idController!=${user._id}&populate=idController,idPromoter,type,phase`
      });
      setNotReferentWorksite(data);
    } catch (e) {
      message(e);
    }
  };

  const getControls = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `controls?inspector=${user._id}`
      });
      getWorksiteControls(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
    if (type === 'worksite') {
      (async () => {
        await getControls();
      })();
    }
  }, [fetchData]);

  return (
    <ContentCustom>
      {type === 'worksite' ? (
        <div className="worksite-wrapper">
          <Title level={3}>{t('worksites.mobile.reference')}</Title>
          {resources?.length &&
            resources?.map((card, index) => (
              <WorksiteCard key={index} card={card} />
            ))}
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            onChange={handlePaginationChange}
            onShowSizeChange={handlePaginationChange}
            total={Number(pagination?.total)}
          />
          <Divider />
          <Title level={3}>{t('worksites.mobile.my_other_worksites')}</Title>
          {notReferentWorksite?.map((worksite, index) => (
            <WorksiteCard key={index} card={worksite} />
          ))}
        </div>
      ) : (
        resources?.map((card, index) => <ControlCard key={index} card={card} />)
      )}
    </ContentCustom>
  );
};

CardsBundle.propTypes = {
  resourceName: PropTypes.string.isRequired,
  populate: PropTypes.string,
  extraQuery: PropTypes.string,
  type: PropTypes.string,
  sort: PropTypes.string
};

CardsBundle.defaultProps = {
  populate: null,
  extraQuery: null,
  type: 'control',
  sort: ''
};
