import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { message } from 'antd';
import useFields from './fields';
import { CreateUpdateContainer } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';

const UpdateProfile = ({ purpose, type }) => {
  const { card_number, controlId } = useParams();
  const { user, setUser, dispatchAPI } = useAuthContext();
  const [base64, setBase64] = useState('');
  const { fields, isFieldsLoading } = useFields(
    base64,
    setBase64,
    type,
    card_number,
    false,
    controlId
  );
  const navigate = useNavigate();
  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';

  const config = {
    onGetResource: {
      setFields: (data) => {
        setBase64(data?.photo);
        const body = {
          ...data,
          document_valid_until:
            data.document_valid_until && moment(data.document_valid_until),
          date_of_birth: data.date_of_birth && moment(data.date_of_birth),
          poste: data.poste === 'employee' ? 'Non défini' : data.poste
        };
        setInitialValues(body);
        return body;
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        if (data._id === user._id) setUser({ ...user, ...data });
        return {
          ...data,
          ...(base64 ? { photo: base64 } : {})
        };
      }
    }
  };

  const customSubmitforNotification = async (body) => {
    try {
      const finalBody =
        config.onUpdateResource && config.onUpdateResource.setBody
          ? await config.onUpdateResource.setBody(body)
          : body;

      if (!isAdmin) {
        const diff = {};
        Object.keys(initialValues).forEach((key) => {
          if (
            finalBody[key] !== undefined &&
            JSON.stringify(initialValues[key]) !==
              JSON.stringify(finalBody[key])
          ) {
            diff[key] = initialValues[key];
          }
        });
        finalBody.modifications = diff;
      }

      await dispatchAPI('PATCH', {
        url: `users/${id}`,
        body: finalBody
      });
      navigate(-1);
    } catch (e) {
      message(e);
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose="edit"
      baseUrl="users"
      resource="users"
      config={config}
      idFromOverley={user._id}
      customSubmit={purpose === 'edit' && customSubmitforNotification}
    />
  );
};

UpdateProfile.propTypes = {
  purpose: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default UpdateProfile;
