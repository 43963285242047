import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'antd';
import { BulbFilled } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import QrScanner from 'qr-scanner';
import ActionButtons from './ActionButtons';
import WorkerDetails from './WorkerDetails';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';

QrScanner.WORKER_PATH = 'https://unpkg.com/qr-scanner/qr-scanner-worker.min.js';

export const ControlQRCode = ({ setNavDisplay, mode }) => {
  const navigate = useNavigate();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const videoElem = useRef(null);
  const [flashEnabled, setFlashEnabled] = useState(false);
  const { message } = useErrorMessage();
  const [controlState, setControlState] = useState(false);
  const [dataCard, setDataCard] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const card = localStorage.getItem('invalid_card');
  const [qrScanner, setQrScanner] = useState(null);
  const [flashLightAvailable, setFlashLightAvailable] = useState(false);
  const [cameraList, setCameraList] = useState([]);

  const getAPIBTPValidation = async (hash) => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: `/btp-cards/${hash}` });
      return data;
    } catch (e) {
      message(e.toString());
      return {};
    } finally {
      setIsLoading(false);
    }
  };

  const resolveQRCodeScan = async (result) => {
    if (result && !isLoading) {
      const hash = result.slice(-64);
      const data = await getAPIBTPValidation(hash);
      if (Object.keys(data).length) {
        if (data && !data.indValide) {
          localStorage.setItem('invalid_card', JSON.stringify(data));
          message('Invalid card');
        }
        setDataCard(data);
        setControlState(true);
      } else {
        message('Unknown card');
      }
    }
  };

  const setupQRScanner = () => {
    if (!videoElem.current || qrScanner) return;

    const scanner = new QrScanner(
      videoElem.current,
      (result) => {
        resolveQRCodeScan(result.data);
      },
      {
        returnDetailedScanResult: true,
        highlightScanRegion: true,
        maxScansPerSecond: 5,
        calculateScanRegion: (video) => {
          const scale = 3;
          const size = Math.min(
            video.videoWidth / scale,
            video.videoHeight / scale
          );
          const x = (video.videoWidth - size) / 2;
          const y = (video.videoHeight - size) / 2;
          return {
            x,
            y,
            width: size,
            height: size,
            downScaledHeight: 800,
            downScaledWidth: 800
          };
        }
      }
    );

    scanner.start().then(async () => {
      setFlashLightAvailable(await scanner.hasFlash());
      const cameras = await QrScanner.listCameras(true);
      setCameraList(cameras);
      setQrScanner(scanner);
      // Set the default camera to be the one that is not the user-facing camera
      const backCamera = cameras.find(
        (cam) =>
          cam.label.toLowerCase().includes('back') ||
          cam.label.toLowerCase().includes('rear') ||
          cam.label.toLowerCase().includes('grand-angle') ||
          cam.label.toLowerCase().includes('ultra')
      );
      if (backCamera) {
        await scanner.setCamera(backCamera.id);
      } else if (cameras.length > 0) {
        await scanner.setCamera(cameras[0].id); // Fallback to the first camera if no back camera is found
      }
    });

    // eslint-disable-next-line consistent-return
    return () => {
      scanner.stop();
      if (flashEnabled) {
        scanner.turnFlashOff();
      }
    };
  };

  useEffect(() => {
    const requestCameraAccess = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' }
        });
        setupQRScanner();
      } catch (error) {
        message('camera_access_denied', 15);
      }
    };

    requestCameraAccess();

    return () => {
      if (qrScanner) {
        qrScanner.stop();
        if (flashEnabled) {
          qrScanner.turnFlashOff();
        }
      }
    };
  }, [videoElem.current]);

  useEffect(() => {
    if (!cameraList.length || !qrScanner) return;

    const targetCamera = cameraList.find((camera) =>
      camera.label.includes('ultra grand angle')
    );
    // if over 4 cameras, probably an iPhone Pro. Use the ultra wide camera that serves as a macro camera
    if (targetCamera && cameraList.length > 4 && qrScanner) {
      qrScanner.setCamera(targetCamera.id);
    }
  }, [cameraList, qrScanner]);

  const toggleFlash = async () => {
    if (!(await qrScanner.hasFlash())) return;
    if (flashEnabled) {
      await qrScanner.turnFlashOff();
      setFlashEnabled(false);
    } else {
      await qrScanner.turnFlashOn();
      setFlashEnabled(true);
    }
  };

  const goBack = () => {
    setNavDisplay('none');
    navigate(-1);
  };

  useEffect(() => {
    setNavDisplay('none');
    if (card) {
      const cardData = JSON.parse(card);
      navigate(
        `/incidents/create/${id}/?cardNumber=${cardData.identifiantCarte}`
      );
    }
  }, [card, id, navigate, setNavDisplay]);

  return (
    <>
      {!controlState && !card ? (
        <div>
          <video
            ref={videoElem}
            muted
            playsInline
            autoPlay
            style={{
              width: '100%',
              height: 'calc(100vh - 160px)',
              objectFit: 'cover'
            }}
          />
        </div>
      ) : (
        <WorkerDetails dataCard={dataCard || JSON.parse(card || '{}')} />
      )}
      {!controlState && !card && (
        <>
          <ActionButtons
            mode={mode}
            goBack={goBack}
            toggleFlash={toggleFlash}
          />
          {flashLightAvailable && (
            <Button
              onClick={toggleFlash}
              type="link"
              icon={<BulbFilled />}
              style={{
                position: 'fixed',
                bottom: '20px',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 100
              }}
            />
          )}
        </>
      )}
    </>
  );
};

ControlQRCode.propTypes = {
  setNavDisplay: PropTypes.func.isRequired,
  mode: PropTypes.string
};

ControlQRCode.defaultProps = {
  mode: null
};
