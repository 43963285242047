import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, DatePicker } from 'antd';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { useFormatter } from '../../utils/formatter';
import { useAuthContext } from '../../contexts/AuthContext';

const { RangePicker } = DatePicker;
const ListControl = ({ headless, path, extraQuery }) => {
  const { headers, columns } = useColumns();
  const { user } = useAuthContext();
  const [selectDate, setSelectDate] = useState([]);
  const [displayClosed, setDisplayClosed] = useState(false);
  const { formattedData } = useFormatter();
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';
  const isCompany =
    user?.role === 'companies:ADMIN-COMPANY' ||
    user?.role === 'companies:USER-COMPANY';

  const selectByIntervalDate = (date) =>
    date
      ? setSelectDate([
          date[0].format('YYYY-MM-DD'),
          date[1].format('YYYY-MM-DD')
        ])
      : setSelectDate(null);

  return (
    <ListResource
      headless={headless}
      resourceName="controls"
      columns={columns}
      headers={headers}
      resourceModelName="Control"
      deleteAction={isAdmin}
      editAction={isAdmin}
      withCreateButton={isAdmin}
      populate="worksite,inspector,workers"
      path={path}
      formatter={formattedData}
      scroll={{ x: 1700 }}
      multipleSelection={isAdmin && true}
      extraQuery={`${extraQuery || ''}${
        selectDate?.length
          ? `&date>=${selectDate[0]}&date<=${selectDate[1]}`
          : ''
      }${
        // eslint-disable-next-line no-nested-ternary
        isCompany
          ? '&status=DONE'
          : displayClosed
          ? `&status=DONE`
          : `&status!=DONE`
      }`}
      extraButtons={
        <Switch
          onChange={() => setDisplayClosed(!displayClosed)}
          checkedChildren="Terminé"
          unCheckedChildren="Terminé"
          style={{ marginRight: '20px' }}
        />
      }
    >
      <RangePicker
        style={{ marginTop: '1rem' }}
        format="DD/MM/YYYY"
        onChange={selectByIntervalDate}
      />
    </ListResource>
  );
};

ListControl.propTypes = {
  headless: PropTypes.bool,
  path: PropTypes.string,
  extraQuery: PropTypes.string
};
ListControl.defaultProps = {
  headless: false,
  path: null,
  extraQuery: undefined
};

export default ListControl;
