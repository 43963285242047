import React from 'react';
import PropTypes from 'prop-types';

import { ListResource } from '../../components/ListResource/ListResource';
import { useColumns } from '../workers/columns';
import { usersData } from '../users/listFields';
import { useAuthContext } from '../../contexts/AuthContext';

const CompanyUsers = ({ idCompany, type }) => {
  const { columns } = useColumns(type, false);
  const { user } = useAuthContext();
  const populate = usersData[type].populate;
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';

  return (
    <ListResource
      headless
      resourceName="users"
      extraQuery={`idCompany=${idCompany}&isActive=${true}`}
      columns={columns}
      deleteAction={isAdmin}
      editAction={isAdmin}
      withCreateButton={isAdmin}
      headers={[]}
      resourceModelName="User"
      populate={populate}
      extraTitle={type}
      path={`/users/users${type}`}
    />
  );
};

CompanyUsers.propTypes = {
  idCompany: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default CompanyUsers;
