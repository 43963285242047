import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import useFields from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import NewUserDrawer from '../users/NewUserDrawer';
import { useAuthContext } from '../../contexts/AuthContext';

const CreateUpdateCompany = ({ purpose }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { fields, isFieldsLoading } = useFields(setOpen, open);

  const createUser = async (data) => {
    setIsLoading(true);
    try {
      await dispatchAPI('POST', {
        url: '/users',
        body: data
      });
      setOpen(false);
      message.success(t('users.message.created'));
    } catch (e) {
      if (e.response.statusText === 'Forbidden') {
        message.warn(t('users.message.email_used'));
        setIsLoading(false);
      }
      message(e);
    }
    setIsLoading(false);
  };

  return (
    <>
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="companies"
        resource="companies"
      />
      <NewUserDrawer
        type="companies"
        isVisible={open}
        setVisibilityUserDrawer={setOpen}
        users={[]}
        createUser={createUser}
        getUser={() => {}}
        isLoading={isLoading}
      />
    </>
  );
};

CreateUpdateCompany.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateCompany;
