import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useParams, useNavigate, Link } from 'react-router-dom';
import {
  Button,
  Popconfirm,
  Skeleton,
  Row,
  Tabs,
  Modal,
  Input,
  Col,
  Card,
  Typography,
  Upload,
  message as antdMessage
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  InboxOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import listContent from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import PromoterWorksites from './PromoterWorksites';
import PromoterUsers from './PromoterUsers';
import ListDocuments from './listDocuments';
import ListInvoices from './ListInvoices';

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Dragger } = Upload;

const CommentModal = ({
  isModalOpen,
  setIsModalOpen,
  promoter,
  setRefresh,
  modalType
}) => {
  const { dispatchAPI } = useAuthContext();
  const [comment, setComment] = useState(promoter?.comment);
  const [legalNotice, setLegalNotice] = useState(promoter?.legal_notice);
  const { t } = useTranslation();

  const handleOk = async () => {
    setRefresh(true);
    const values =
      modalType === 'comment' ? { comment } : { legal_notice: legalNotice };
    await dispatchAPI('PATCH', {
      url: `/promoters/${promoter._id}`,
      body: values
    });
    setRefresh(false);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setComment(promoter?.comment);
    setLegalNotice(promoter?.legal_notice);
  }, [promoter]);

  return (
    <Modal
      title={t(`promoters.modal.title.${modalType}`)}
      onCancel={handleCancel}
      open={isModalOpen}
      footer={[
        <Button key="back" danger type="link" onClick={handleCancel}>
          Annuler
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Enregistrer
        </Button>
      ]}
    >
      {modalType === 'comment' ? (
        <TextArea
          rows={8}
          value={comment}
          onChange={(event) => setComment(event.target.value)}
        />
      ) : (
        <TextArea
          rows={8}
          value={legalNotice}
          onChange={(event) => setLegalNotice(event.target.value)}
        />
      )}
    </Modal>
  );
};

const ShowPromoter = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [documents, setDocuments] = useState(null);
  const [promoter, setPromoter] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invoices, setInvoices] = useState(null);
  const [modalType, setModalType] = useState(null);

  const showModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const getPromoter = useCallback(async () => {
    setIsLoading(true);
    try {
      const populate = 'contact,documents,invoices';
      const { data } = await dispatchAPI('GET', {
        url: `/promoters/${id}?populate=${populate}`
      });
      setPromoter(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getPromoter();
    })();
  }, [getPromoter, refresh]);

  const deletePromoter = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/promoters/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const props = {
    multiple: false,
    fileList: documents,
    beforeUpload: (newFile) => {
      setDocuments([newFile]);
      return false;
    },
    onRemove: (file) =>
      setDocuments(documents.filter((f) => f.uid !== file.uid))
  };

  const invoicesProps = {
    multiple: false,
    fileList: invoices,
    beforeUpload: (newFile) => {
      setInvoices([newFile]);
      return false;
    },
    onRemove: (file) => setInvoices(documents.filter((f) => f.uid !== file.uid))
  };

  const handlePostDocuments = async () => {
    try {
      const formData = new FormData();
      documents.forEach((doc) =>
        formData.append('documents', doc.originFileObj || doc)
      );

      await dispatchAPI('POST', {
        url: `/promoters/upload/${id}`,
        body: formData
      });
      antdMessage.success(` document(s) téléchargé(s)`);
      window.location.reload();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handlePostInvoices = async () => {
    try {
      const formData = new FormData();
      invoices.forEach((doc) =>
        formData.append('invoices', doc.originFileObj || doc)
      );

      await dispatchAPI('POST', {
        url: `/promoters/upload_invoices/${id}`,
        body: formData
      });
      antdMessage.success(` Facture(s) téléchargé(s)`);
      window.location.reload();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('promoters.show.title')}
        extra={
          <>
            <Link to={{ pathname: `${routes.PROMOTERS}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deletePromoter}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Row gutter={[24, 24]}>
          <Col xs={24} xl={16}>
            <Card title="Informations" bordered={false}>
              <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                <DescriptionList
                  data={listContent(promoter || {}, t)}
                  translate
                />
              </Skeleton>
            </Card>
          </Col>
          <Col xs={24} xl={8}>
            <Card
              title="Commentaire"
              bordered={false}
              extra={
                <Button type="link" onClick={() => showModal('comment')}>
                  <EditOutlined />
                </Button>
              }
            >
              {promoter?.comment ? (
                promoter?.comment
              ) : (
                <Typography.Text italic>
                  Aucun commentaire pour le moment
                </Typography.Text>
              )}
            </Card>
            <Card
              title={t('promoters.form.legal_notice')}
              bordered={false}
              extra={
                <Button type="link" onClick={() => showModal('legal_notice')}>
                  <EditOutlined />
                </Button>
              }
            >
              {promoter?.legal_notice ? (
                promoter?.legal_notice
              ) : (
                <Typography.Text italic>Aucune mention légale</Typography.Text>
              )}
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: 24 }}>
          <Col xs={24}>
            <Card bordered={false}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Chantiers" key="1">
                  <PromoterWorksites idPromoter={id} />
                </TabPane>
                <TabPane tab="Factures" key="2">
                  <Row gutter={[16, 16]}>
                    <Col
                      xs={24}
                      lg={8}
                      xxl={6}
                      style={{ height: 'fit-content' }}
                    >
                      <Dragger {...invoicesProps}>
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Glisser / Déposer</p>
                      </Dragger>
                      {invoices && (
                        <Button type="primary" onClick={handlePostInvoices}>
                          Enregistrer
                        </Button>
                      )}
                    </Col>
                    <Col xs={24} lg={16} xxl={18}>
                      <ListInvoices data={promoter} />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Utilisateurs" key="3">
                  <PromoterUsers idPromoter={id} />
                </TabPane>
                <TabPane tab="Documents" key="4">
                  <Row gutter={[16, 16]}>
                    <Col
                      xs={24}
                      lg={8}
                      xxl={6}
                      style={{ height: 'fit-content' }}
                    >
                      <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Glisser / Déposer</p>
                      </Dragger>
                      {documents && (
                        <Button type="primary" onClick={handlePostDocuments}>
                          Enregistrer
                        </Button>
                      )}
                    </Col>
                    <Col xs={24} lg={16} xxl={18}>
                      <ListDocuments data={promoter} />
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </ContentCustom>
      <CommentModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        promoter={promoter}
        setRefresh={setRefresh}
        modalType={modalType}
      />
    </>
  );
};

CommentModal.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  promoter: PropTypes.shape({
    comment: PropTypes.string,
    legal_notice: PropTypes.string,
    _id: PropTypes.string
  }),
  setRefresh: PropTypes.func,
  modalType: PropTypes.string
};

CommentModal.defaultProps = {
  isModalOpen: false,
  setIsModalOpen: null,
  promoter: null,
  setRefresh: null,
  modalType: null
};

export default ShowPromoter;
