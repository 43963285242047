import React, { useCallback, useEffect, useState } from 'react';
import {
  DatePicker,
  Form,
  Input,
  Switch,
  Select,
  Tag,
  Upload,
  Space,
  Button,
  Divider
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  CameraOutlined,
  FileDoneOutlined,
  PlusOutlined,
  CloseOutlined
} from '@ant-design/icons/lib';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { userRoles } from '../../utils/constants/tagColors';
import { useEmailPattern } from '../../utils/emailPattern';
import { requiredIsWCard } from '../users/listFields';

const { Option } = Select;
const { Dragger } = Upload;

const useFields = (
  type,
  cardNumber,
  controlId,
  isUnkownCompany,
  setIsUnknownCompany,
  isFromExtranet,
  openDrawer,
  forceSelectRefresh
) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const idControl = params.get('idControl');
  const currentUser = params.get('user');
  const [enums, setEnums] = useState({});
  const [companies, setCompanies] = useState([]);
  const [businessBody, setBusinessBody] = useState([]);
  const [unknownCompany, setUnknownCompany] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [displayDate, setDisplayDate] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [base64, setBase64] = useState('');
  const [isWCard, setIsWCard] = useState(true);
  const [selectedPoste, setSelectedPoste] = useState(null);
  const isCompanyUser = user?.role === 'companies:ADMIN-COMPANY';

  const companySelect = () =>
    (companies || []).map((company) => (
      <Option key={company._id} value={company._id}>
        <Tag color={userRoles[company.name]}>{t(`${company.name}`)}</Tag>
      </Option>
    ));

  const handleSelect = (value) => {
    setSelectedPoste(value);
    if (value === 'employee_cdi') {
      setDisplayDate(true);
    } else setDisplayDate(false);
    if (requiredIsWCard.indexOf(value) !== -1 || currentUser === 'manager') {
      setIsWCard(false);
    } else setIsWCard(true);
  };

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpeg' ||
        fileExtension === 'JPEG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
        const base = await fileToBase64(file);
        setBase64(base);
        return false;
      }
      message('Not a PNG or JPG file.');
      return true;
    },
    fileList
  };

  const draggerPropsCard = {
    multiple: true,
    fileList: documents,
    beforeUpload: (newFile) => {
      const newArray = [...documents];
      newArray.push(newFile);
      setDocuments(newArray);
      return false;
    },
    onRemove: (file) =>
      setDocuments(documents.filter((f) => f.uid !== file.uid))
  };
  const onsearch = (input, option) => {
    const result = option?.children?.props.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const fields = [
    {
      name: ['photo'],
      input: (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      name: ['idCompany'],
      rules: [{ required: true }],
      initialValue: isCompanyUser ? user?.idCompany[0] : undefined,
      input: (
        <Select
          mode={isUnkownCompany ? 'tags' : 'default'}
          allowClear
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
          disabled={isCompanyUser}
          loading={isFieldsLoading}
          onChange={(text) => isUnkownCompany && setUnknownCompany(text[0])}
          dropdownRender={(menu) => (
            <>
              {isFromExtranet ? (
                <Space style={{ padding: '0 8px 4px' }}>
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={() => openDrawer('companies')}
                  >
                    Créer une nouvelle entreprise
                  </Button>
                </Space>
              ) : (
                <Space style={{ padding: '0 8px 4px' }}>
                  <Button
                    type="text"
                    icon={
                      isUnkownCompany ? <CloseOutlined /> : <PlusOutlined />
                    }
                    onClick={() => setIsUnknownCompany(!isUnkownCompany)}
                  >
                    {isUnkownCompany
                      ? 'Annuler la saisie manuelle'
                      : 'Saisir une entreprise inconnue ?'}
                  </Button>
                </Space>
              )}
              <Divider style={{ margin: '8px 0' }} />
              {menu}
            </>
          )}
        >
          {isUnkownCompany ? [] : companySelect()}
        </Select>
      )
    },
    {
      name: ['business_body'],
      required: isUnkownCompany,
      hidden: !isUnkownCompany,
      input: (
        <Select
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {businessBody.map((i) => (
            <Option key={i._id} value={i._id}>
              {i.label}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [{ required: false }, ...useEmailPattern()]
    },
    {
      name: ['phone_number'],
      label: 'phone_number.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['poste'],
      rules: [{ required: true }],
      input: (
        <Select
          onChange={handleSelect}
          loading={isFieldsLoading}
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(enums?.postes || [])
            .filter((f) => f !== 'employee')
            .map((poste) => (
              <Option key={poste} value={poste}>
                <Tag color={userRoles[poste]}>{t(`workers.tags.${poste}`)}</Tag>
              </Option>
            ))}
        </Select>
      ),
      initialValue: cardNumber !== 'null' && '-'
    },
    {
      name: ['files'],
      rules: [{ required: false }],
      input: (
        <Dragger {...draggerPropsCard}>
          <p className="ant-upload-drag-icon">
            <FileDoneOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      name: ['document_valid_until'],
      rules: [{ required: false }],
      input: (
        <DatePicker
          disabled={displayDate}
          style={{ width: '100%' }}
          format="DD/MM/YYYY"
        />
      )
    },
    {
      name: ['isWCard'],
      rules: [{ required: isWCard }],
      hidden: selectedPoste === 'intern',
      valuePropName: 'checked',
      input: <Switch defaultChecked={cardNumber && true} />,
      initialValue: cardNumber && true
    },
    ...(!['manager', 'intern', 'renter', 'provider'].includes(currentUser)
      ? [
          {
            name: ['card_number'],
            hidden: selectedPoste === 'intern',
            rules: [{ required: cardNumber === undefined && isWCard }],
            input: !cardNumber ? <Input /> : cardNumber,
            initialValue: cardNumber && cardNumber
          }
        ]
      : []),
    ...(currentUser === 'manager'
      ? [
          {
            name: ['siret_number'],
            rules: [{ required: cardNumber === undefined && isWCard }],
            input: !cardNumber ? <Input type="number" /> : cardNumber,
            initialValue: cardNumber && cardNumber
          }
        ]
      : []),
    ...(controlId || idControl
      ? [
          {
            name: ['control'],
            initialValue: controlId || idControl,
            hidden: true
          }
        ]
      : [])
  ];

  const getEnums = async () => {
    try {
      const data_enums = await dispatchAPI('GET', { url: '/users/enums/' });
      if (cardNumber === 'null') {
        const postes = data_enums.data.postes.filter(
          (poste) =>
            poste === 'intern' ||
            poste === 'tenant' ||
            poste === 'service_providers'
        );
        data_enums.data.postes = postes;
        setEnums(data_enums.data);
      } else setEnums(data_enums.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      if (controlId) {
        const { data } = await dispatchAPI('GET', {
          url: `worksites/getworksiteCompanies/${controlId}`
        });
        setCompanies(data);
      } else {
        const data_companies = await dispatchAPI('GET', { url: '/companies/' });
        setCompanies(data_companies.data);
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getBusinessBody = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/batchtitle?sort=label?show=${true}`
      });
      setBusinessBody(data);
    } catch (error) {
      if (error) message(error.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getCompanies();
    await getEnums();
    await getBusinessBody();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  useEffect(() => {
    (async () => {
      await getCompanies();
    })();
  }, [forceSelectRefresh]);

  return {
    fields,
    isFieldsLoading,
    documents,
    base64,
    setDocuments,
    unknownCompany
  };
};

export default useFields;
