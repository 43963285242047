import React, { useCallback, useEffect, useState } from 'react';
import {
  Input,
  Select,
  Tag,
  Space,
  Button,
  Divider,
  Switch,
  Upload
} from 'antd';
import { PlusOutlined, CameraOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { userRoles } from '../../utils/constants/tagColors';

const { Option } = Select;
const { Dragger } = Upload;

const useFields = (setOpen, open, withoutContacts, base64, setBase64) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [fileList, setFileList] = useState([]);

  const onsearch = (input, option) => {
    const result = option?.children?.props.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpeg' ||
        fileExtension === 'JPEG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
        const base = await fileToBase64(file);
        setBase64(base);
        return false;
      }
      message('Not a PNG or JPG file.');
      return true;
    },
    fileList
  };

  const fields = [
    {
      name: ['logo'],
      input: (
        <>
          <Dragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
              <CameraOutlined style={{ color: 'var(--textColor)' }} />
            </p>
            <p className="ant-upload-text">{t('files.create.action')}</p>
          </Dragger>
          {base64 && (
            <img
              src={base64}
              alt="uploaded"
              style={{ marginTop: '10px', maxHeight: '200px' }}
            />
          )}
        </>
      )
    },
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['siret_number'],
      rules: [{ required: false }]
    },
    {
      name: ['vat_number'],
      rules: [{ required: false }]
    },
    {
      label: 'address.number',
      name: ['address', 'number'],
      input: <Input type="number" />
    },
    {
      label: 'address.street',
      name: ['address', 'street']
    },
    {
      label: 'address.additional',
      name: ['address', 'additional']
    },
    {
      label: 'address.postcode',
      name: ['address', 'postal_code']
    },
    {
      label: 'address.city',
      name: ['address', 'city']
    },
    ...(!withoutContacts
      ? [
          {
            name: ['contact'],
            rules: [{ required: true }],
            input: (
              <Select
                loading={isFieldsLoading}
                showSearch
                filterOption={(input, option) => onsearch(input, option)}
                dropdownRender={(menu) => (
                  <>
                    <Space style={{ padding: '0 8px 4px' }}>
                      <Button
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={() => setOpen(true)}
                      >
                        Créer un nouvel utilisateur
                      </Button>
                    </Space>
                    <Divider style={{ margin: '8px 0' }} />
                    {menu}
                  </>
                )}
              >
                {(users || []).map((user) => {
                  if (user.role.includes('promoter')) {
                    return (
                      <Option key={user._id} value={user._id}>
                        <Tag color={userRoles[user]}>
                          {`${user.first_name} ${user.last_name} ( ${user.email} ) `}
                        </Tag>
                      </Option>
                    );
                  }
                  return null;
                })}
              </Select>
            )
          }
        ]
      : []),
    {
      name: ['activate_mail'],
      label: 'activate_mail',
      valuePropName: 'checked',
      input: <Switch />
    }
  ];

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users/' });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getUsers();
    setIsFieldsLoading(false);
  }, [open]);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading,
    base64
  };
};

export default useFields;
