import { Button, Card, Col, List, Row, Tag, Typography } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import MobileHeader from './MobileHeader';

const ShowIncident = () => {
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { Text } = Typography;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [incident, setIncident] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getIncident = useCallback(async () => {
    setIsLoading(true);
    try {
      const populate =
        'control,worksite,inspector,worker,company,status,type,documents,files.fileId';
      const { data } = await dispatchAPI('GET', {
        url: `/incidents/${id}?populate=${populate}`
      });
      setIncident(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getIncident();
    })();
  }, []);

  const collapseInfos = () => {
    if (incident)
      return [
        {
          title: t('controls.mobile.incident.reference'),
          value: incident?.reference || '-'
        },
        {
          title: t('controls.mobile.incident.date'),
          value: moment(incident?.date).format('DD/MM/YYYY - h:mm')
        },
        {
          title: t('controls.mobile.incident.status'),
          value: (
            <Tag color={incident?.status?.color || '-'}>
              {incident?.status?.label || '-'}
            </Tag>
          )
        },
        {
          title: t('controls.mobile.incident.type'),
          value: (
            <Tag color={incident?.type?.color || '-'}>
              {incident?.type?.label || '-'}
            </Tag>
          )
        },
        {
          title: t('controls.mobile.incident.company'),
          value: incident?.company
            ? incident?.company?.name
            : incident?.unknown_company
        },
        {
          title: t('controls.mobile.incident.mail'),
          value: t(
            `controls.mobile.incident.${incident?.closure_status || '-'}`
          )
        },
        {
          title: t('controls.mobile.incident.worker'),
          value:
            incident?.unknown_worker ||
            `${incident?.worker?.first_name || '-'} ${
              incident?.worker?.last_name || '-'
            }`
        },
        {
          title: t('controls.mobile.incident.worksite'),
          value: incident?.worksite?.name
        },
        {
          title: t('controls.mobile.incident.inspector'),
          value: `${incident?.inspector?.first_name || '-'} ${
            incident?.inspector?.last_name || '-'
          }`
        },
        {
          title: t('controls.mobile.incident.comment'),
          value: incident?.comment || '-'
        }
      ];
    return [];
  };

  return (
    <>
      <MobileHeader title="Détails incident" />
      <ContentCustom>
        <Row>
          <Col span={24}>
            <Card
              loading={isLoading}
              bordered={false}
              bodyStyle={{ padding: 8 }}
            >
              <List
                size="small"
                dataSource={collapseInfos()}
                renderItem={(elem) => (
                  <List.Item style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <Text>{elem.title}</Text>
                    <Text style={{ fontWeight: 'bold' }}>{elem.value}</Text>
                  </List.Item>
                )}
              />
            </Card>
          </Col>
        </Row>
      </ContentCustom>
      <Button
        style={{ marginTop: 20 }}
        type="primary"
        onClick={() => navigate(-1)}
      >
        Retour
      </Button>
    </>
  );
};

export default ShowIncident;
