import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { message, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import useFields from './fields';
import { CreateUpdateContainer } from '../../components';
import { MobileCreateUpdateContainer } from '../../components/CreateUpdateContainer/MobileCreateUpdateContainer';
import { useAuthContext } from '../../contexts/AuthContext';
import NewStructureDrawer from '../users/NewStructureDrawer';
import MobileContextProvider from '../mobile/MobileContext';

const CreateUpdateWorker = ({ purpose, type, mode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { card_number, controlId } = useParams();
  const { user, setUser, dispatchAPI } = useAuthContext();
  const [isUnkownCompany, setIsUnknownCompany] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [structureType, setStructureType] = useState('');
  const [forceSelectRefresh, setForceSelectRefresh] = useState(false);
  const { customNav, setControlState } = MobileContextProvider();

  const openDrawer = (currentUser) => {
    setStructureType(currentUser);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setStructureType('');
    setIsDrawerOpen(false);
  };

  const customNavigate = () => {
    closeDrawer();
    setForceSelectRefresh(!forceSelectRefresh);
  };

  const {
    fields,
    isFieldsLoading,
    documents,
    base64,
    setDocuments,
    unknownCompany
  } = useFields(
    type,
    card_number,
    controlId,
    isUnkownCompany,
    setIsUnknownCompany,
    !user.role.includes('inspectors'),
    openDrawer,
    forceSelectRefresh
  );

  const card = JSON.parse(localStorage.getItem('invalid_card'));

  const config = {
    onGetResource: {
      setFields: (data) => {
        setDocuments(
          data.files.map((file) => ({
            ...file,
            name: file.fileName,
            uid: file.fileId
          }))
        );
        return {
          ...data,
          document_valid_until:
            data.document_valid_until && moment(data.document_valid_until),
          date_of_birth: data.date_of_birth && moment(data.date_of_birth)
        };
      }
    },
    onUpdateResource: {
      setBody: async (data) => {
        if (data._id === user._id) setUser({ ...user, ...data });
        let documents_saved;
        const files = [...documents];
        if (documents?.length) {
          const formData = new FormData();
          documents.forEach((doc) => {
            formData.append('file', doc.originFileObj || doc);
            formData.append('type', doc.type);
          });
          documents_saved = await dispatchAPI('POST', {
            url: `/files/upload`,
            body: formData
          });
          if (documents_saved) {
            documents_saved.data.description.forEach((file) =>
              files.push({ fileId: file.id, fileName: file.fileName })
            );
          }
        }
        return {
          ...data,
          ...(base64 ? { photo: base64 } : {}),
          ...(documents_saved
            ? {
                files
              }
            : { files: documents })
        };
      }
    },
    onCreateResource: {
      setBody: async (data) => {
        let documents_saved = null;
        const files = [];

        if (documents?.length) {
          const formData = new FormData();
          documents.forEach((doc) => {
            formData.append('file', doc.originFileObj || doc);
            formData.append('type', doc.type);
          });
          documents_saved = await dispatchAPI('POST', {
            url: `/files/upload`,
            body: formData
          });
          if (documents_saved) {
            documents_saved.data.description.forEach((file) =>
              files.push({ fileId: file.id, fileName: file.fileName })
            );
          }
        }
        let remaningBody = {};
        if (card_number)
          remaningBody = {
            ...remaningBody,
            card_number,
            isWCard: !card_number
          };

        return {
          ...data,
          ...remaningBody,
          ...(documents_saved
            ? {
                files
              }
            : {})
        };
      }
    }
  };

  // Only if its from mobile
  const customSubmit = async (data) => {
    const newData =
      purpose === 'create'
        ? await config.onCreateResource.setBody(data)
        : await config.onUpdateResource.setBody(data);
    try {
      let newBody;
      if (unknownCompany) {
        newBody = {
          ...newData,
          unknown_company: unknownCompany,
          idCompany: null
        };
      }
      await dispatchAPI('POST', {
        url: 'workers/mobile',
        body: unknownCompany
          ? {
              ...newBody,
              isValid: card?.indValide === false ? card?.indValide : true,
              date: moment()
            }
          : {
              ...newData,
              isValid: card?.indValide === false ? card?.indValide : true,
              date: moment()
            }
      });
      setControlState(false);
      navigate(
        card
          ? `/controlqrcode/${controlId}`
          : `/control${mode === 'desktop' ? 's' : ''}/${controlId}`
      );
    } catch (e) {
      if (e.response.data?.description?.idWorker) {
        navigate({
          pathname: `/workers/edit/${controlId}/${e.response.data.description?.idWorker}`
        });
        notification.success({
          message: t('controls.messages.workerAdded_edit')
        });
      } else if (e.response)
        message.error(t(`errors.message.${e.response.status}`));
    }
  };

  return user.role.includes('inspectors') ? (
    <MobileCreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="workers"
      resource="workers"
      config={config}
      customSubmit={(card_number || card_number === 'null') && customSubmit}
      controlId={controlId}
      customNav={customNav}
    />
  ) : (
    <>
      {isDrawerOpen && (
        <NewStructureDrawer
          resource={structureType}
          isVisible={isDrawerOpen}
          closeDrawer={closeDrawer}
          customNavigate={customNavigate}
        />
      )}
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="workers"
        resource="workers"
        config={config}
      />
    </>
  );
};

CreateUpdateWorker.propTypes = {
  purpose: PropTypes.string.isRequired,
  type: PropTypes.string,
  mode: PropTypes.string
};

CreateUpdateWorker.defaultProps = {
  type: undefined,
  mode: null
};

export default CreateUpdateWorker;
