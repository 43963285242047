import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal, Form, Button, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../utils/constants/formLayout';

const ReportWorksiteModal = ({
  worksite,
  setOpenModal,
  openModal,
  generateWorksiteReport
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onValidate = async (values) => {
    await form.validateFields();
    setOpenModal(false);
    generateWorksiteReport(
      values.start_date || worksite?.start_date,
      values.end_date || worksite?.end_date
    );
  };

  const onCancel = () => {
    setOpenModal(false);
    form.resetFields();
  };

  return (
    <Modal
      title={t('worksite.modal.title')}
      centered
      visible={openModal}
      width={550}
      footer={false}
      onCancel={onCancel}
    >
      <Form form={form} onFinish={onValidate} {...formItemLayout}>
        <Form.Item name="start_date" label={t('worksite.modal.start_date')}>
          <DatePicker
            format="DD/MM/YYYY"
            defaultValue={moment(worksite?.start_date)}
            // disabledDate={(current) => {
            //   const before =
            //     current < moment(worksite?.start_date).startOf('day');
            //   const after = current > moment(worksite?.end_date).endOf('day');
            //   return before || after;
            // }}
          />
        </Form.Item>
        <Form.Item name="end_date" label={t('worksite.modal.end_date')}>
          <DatePicker
            format="DD/MM/YYYY"
            defaultValue={moment(worksite?.end_date)}
            // disabledDate={(current) => {
            //   const before =
            //     current < moment(worksite?.start_date).startOf('day');
            //   const after = current > moment(worksite?.end_date).endOf('day');
            //   return before || after;
            // }}
          />
        </Form.Item>
        <Form.Item
          style={{
            marginTop: 35
          }}
          {...tailFormItemLayout}
        >
          <Button type="primary" htmlType="submit">
            {t('buttons.download')}
          </Button>
          <Button type="link" danger onClick={onCancel}>
            {t('buttons.cancel')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReportWorksiteModal;

ReportWorksiteModal.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  generateWorksiteReport: PropTypes.func.isRequired,
  worksite: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string
  }).isRequired
};
