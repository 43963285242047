import { Button, Card, Col, List, Row, Typography } from 'antd';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import MobileHeader from './MobileHeader';

const ShowWorker = () => {
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { Text } = Typography;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [worker, setWorker] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getWorker = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/workers/${id}`
      });
      setWorker(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getWorker();
    })();
  }, []);

  const collapseInfos = () => {
    if (worker)
      return [
        {
          title: t('controls.mobile.worker.first_name'),
          value: worker?.first_name || '-'
        },
        {
          title: t('controls.mobile.worker.last_name'),
          value: worker?.last_name || '-'
        },
        {
          title: t('controls.mobile.worker.poste'),
          value: worker?.poste ? t(`users.tags.${worker?.poste}`) : '-'
        },
        {
          title: t('controls.mobile.worker.email'),
          value: worker?.email || '-'
        },
        {
          title: t('controls.mobile.worker.phone_number'),
          value: `${worker?.phone_number?.country_code || '-'} ${
            worker?.phone_number?.number || '-'
          }`
        },
        {
          title: t('controls.mobile.worker.card_number'),
          value: worker?.card_number
        }
      ];
    return [];
  };

  return (
    <>
      <MobileHeader title="Détails travailleur" />
      <ContentCustom>
        <Row>
          <Col span={24}>
            <Card
              loading={isLoading}
              bordered={false}
              bodyStyle={{ padding: 8 }}
            >
              <List
                size="small"
                dataSource={collapseInfos()}
                renderItem={(elem) => (
                  <List.Item style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <Text>{elem.title}</Text>
                    <Text style={{ fontWeight: 'bold' }}>{elem.value}</Text>
                  </List.Item>
                )}
              />
            </Card>
          </Col>
        </Row>
      </ContentCustom>
      <Button
        style={{ marginTop: 20 }}
        type="primary"
        onClick={() => navigate(-1)}
      >
        Retour
      </Button>
    </>
  );
};

export default ShowWorker;
