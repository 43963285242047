import React, { useCallback, useEffect, useState } from 'react';
import {
  DatePicker,
  Form,
  Input,
  Switch,
  Select,
  Tag,
  Upload,
  Space,
  Button,
  Divider
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  CameraOutlined,
  FileDoneOutlined,
  PlusOutlined
} from '@ant-design/icons/lib';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { userRoles, activeStatus } from '../../utils/constants/tagColors';
import { useEmailPattern } from '../../utils/emailPattern';
import { usersData, requiredIsWCard } from './listFields';

const { Option } = Select;
const { Dragger } = Upload;

const useFields = (
  type,
  cardNumber,
  isfrommodal,
  controlId,
  purpose,
  openDrawer,
  forceSelectRefresh,
  base64,
  setBase64
) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentUser = params.get('user');
  const [enums, setEnums] = useState({});
  const [companies, setCompanies] = useState([]);
  const [promoters, setPromoters] = useState([]);
  const [documents, setDocuments] = useState(null);
  const [displayDate, setDisplayDate] = useState(false);
  const [subcontracting, setSubcontracting] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isWCard, setIsWCard] = useState(true);
  const isSuperAdmin = user?.role === 'admins:SUPER-ADMIN';
  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';
  const isCompanyUser = user?.role === 'companies:ADMIN-COMPANY';
  const initialValueRole = {
    companies: 'companies:USER-COMPANY',
    inspectors: 'inspectors:INSPECTOR'
  };

  const getEnums = async () => {
    try {
      const data_enums = await dispatchAPI('GET', { url: '/users/enums/' });
      if (type === 'admins') {
        const postes = data_enums.data.postes.filter(
          (role) => role === 'responsable' || role === 'other'
        );
        data_enums.data.postes = postes;
        setEnums(data_enums.data);
      } else if (cardNumber === 'null') {
        const postes = data_enums.data.postes.filter(
          (poste) =>
            poste === 'intern' ||
            poste === 'tenant' ||
            poste === 'service_providers'
        );
        data_enums.data.postes = postes;
        setEnums(data_enums.data);
      } else {
        setEnums(data_enums.data);
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const data_companies = await dispatchAPI('GET', { url: '/companies/' });
      setCompanies(data_companies.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getWorksiteCompanies = async () => {
    try {
      const data_companies = await dispatchAPI('GET', {
        url: `/worksites/getworksiteCompanies/${controlId}`
      });
      setCompanies(data_companies.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPromoters = async () => {
    try {
      const data_promoters = await dispatchAPI('GET', { url: '/promoters/' });
      setPromoters(data_promoters.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getPromoters();
    await getEnums();
    if (controlId) {
      await getWorksiteCompanies();
    } else await getCompanies();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  useEffect(() => {
    (async () => {
      await getPromoters();
      await getCompanies();
    })();
  }, [forceSelectRefresh]);

  const handleSelect = (value) => {
    if (value === 'employee_cdi') {
      setDisplayDate(true);
    } else setDisplayDate(false);
    if (requiredIsWCard.indexOf(value) !== -1 || currentUser === 'manager') {
      setIsWCard(false);
    } else setIsWCard(true);
  };

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
    });

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpeg' ||
        fileExtension === 'JPEG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
        const base = await fileToBase64(file);
        setBase64(base);
        return false;
      }
      message('Not a PNG or JPG file.');
      return true;
    },
    fileList
  };

  const draggerPropsCard = {
    multiple: true,
    fileList: documents,
    beforeUpload: (newFile) => {
      const newArray = [...documents];
      newArray.push(newFile);
      setDocuments(newArray);

      return false;
    },
    onRemove: (file) =>
      setDocuments(documents.filter((f) => f.uid !== file.uid))
  };
  const onsearch = (input, option) => {
    const result = option?.children?.props.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const fieldsToDisplay = usersData[type].fields;

  const data = [
    {
      name: ['photo'],
      input: (
        <>
          <Dragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
              <CameraOutlined style={{ color: 'var(--textColor)' }} />
            </p>
            <p className="ant-upload-text">{t('files.create.action')}</p>
          </Dragger>
          {base64 && (
            <img
              src={base64}
              alt="uploaded"
              style={{ marginTop: '10px', maxHeight: '200px' }}
            />
          )}
        </>
      )
    },
    ...(companies.length
      ? [
          {
            name: ['idCompany'],
            initialValue:
              companies?.length === 1 ? companies[0]._id : undefined,
            input: (
              <Select
                loading={isFieldsLoading}
                showSearch
                disabled={isCompanyUser}
                mode={type === 'workers' && 'multiple'}
                filterOption={(input, option) => onsearch(input, option)}
                defaultValue={
                  companies?.length === 1 ? companies[0]._id : undefined
                }
                dropdownRender={(menu) => (
                  <>
                    <Space style={{ padding: '0 8px 4px' }}>
                      <Button
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={() => openDrawer('companies')}
                      >
                        Créer une nouvelle entreprise
                      </Button>
                    </Space>
                    <Divider style={{ margin: '8px 0' }} />
                    {menu}
                  </>
                )}
              >
                {(companies || []).map((company) => (
                  <Option key={company._id} value={company._id}>
                    <Tag color={userRoles[company.name]}>
                      {t(`${company.name}`)}
                    </Tag>
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : []),
    {
      name: ['idPromoter'],
      rules: [{ required: false }],
      input: (
        <Select
          loading={isFieldsLoading}
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
          dropdownRender={(menu) => (
            <>
              <Space style={{ padding: '0 8px 4px' }}>
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={() => openDrawer('promoters')}
                >
                  Créer un nouveau Promoter
                </Button>
              </Space>
              <Divider style={{ margin: '8px 0' }} />
              {menu}
            </>
          )}
        >
          {(promoters || []).map((promoter) => (
            <Option key={promoter._id} value={promoter._id}>
              <Tag color={userRoles[promoter.name]}>
                {t(`${promoter.name}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      ),
      hidden: !/users/.test(location.pathname)
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [{ required: true }, ...useEmailPattern()],
      input: <Input disabled={purpose === 'edit' && !isAdmin} />
    },
    {
      name: ['activate_mail'],
      label: 'activate_mail',
      valuePropName: 'checked',
      input: <Switch />,
      initialValue: false
    },
    {
      name: ['phone_number'],
      label: 'phone_number.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['phone_number_2'],
      label: 'phone_number_2.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number_2', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number_2', 'number']}>
            <Input type="number" style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    ...(isAdmin || isCompanyUser
      ? [
          {
            name: ['role'],
            rules: [{ required: true }],
            initialValue:
              type === 'companies' || type === 'inspectors'
                ? initialValueRole[type]
                : null,
            input:
              type === 'inspectors' ? (
                t('users.tags.INSPECTOR')
              ) : (
                <Select
                  loading={isFieldsLoading}
                  showSearch
                  filterOption={(input, option) => onsearch(input, option)}
                  disabled={isCompanyUser}
                >
                  {(enums?.roles || [])
                    .filter((f) =>
                      !isSuperAdmin ? f !== 'admins:SUPER-ADMIN' : f
                    )
                    .map((role) => {
                      if (role.includes(type)) {
                        return (
                          <Option key={role} value={role}>
                            <Tag color={userRoles[role.split(':')[1]]}>
                              {t(`users.tags.${role.split(':')[1]}`)}
                            </Tag>
                          </Option>
                        );
                      }
                      return null;
                    })}
                </Select>
              )
          },
          {
            name: ['isActive'],
            rules: [{ required: true }],
            input: (
              <Select loading={isFieldsLoading}>
                {[true, false].map((is_active) => (
                  <Option key={is_active} value={is_active}>
                    <Tag color={activeStatus[is_active]}>
                      {t(`users.form.${is_active}`)}
                    </Tag>
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : []),

    {
      name: ['status'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={isFieldsLoading}
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(enums?.status || []).map((status) => (
            <Option key={status} value={status}>
              <Tag color={userRoles[status]}>{t(`users.tags.${status}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['poste'],
      rules: [{ required: true }],
      input: (
        <Select
          onChange={handleSelect}
          loading={isFieldsLoading}
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(enums?.postes || [])
            .filter((f) => f !== 'employee')
            .map((poste) => (
              <Option key={poste} value={poste}>
                <Tag color={userRoles[poste]}>{t(`users.tags.${poste}`)}</Tag>
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['files'],
      rules: [{ required: false }],
      input: (
        <Dragger {...draggerPropsCard}>
          <p className="ant-upload-drag-icon">
            <FileDoneOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    ...(type !== 'workers'
      ? [
          {
            name: ['document_valid_until'],
            rules: [{ required: false }],
            input: (
              <DatePicker
                disabled={displayDate}
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
              />
            )
          }
        ]
      : []),
    {
      name: ['start_work_at'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
    },
    {
      name: ['end_work_at'],
      rules: [{ required: true }],
      input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
    },
    {
      name: ['isWCard'],
      rules: [{ required: isWCard }],
      valuePropName: 'checked',
      input: <Switch defaultChecked={cardNumber && true} />
    },
    {
      name: ['subcontracting'],
      valuePropName: 'checked',
      input: <Switch onChange={setSubcontracting} checked={subcontracting} />
    },
    ...(currentUser !== 'manager' &&
    currentUser !== 'intern' &&
    currentUser !== 'renter' &&
    currentUser !== 'provider'
      ? [
          {
            name: ['card_number'],
            input: !cardNumber ? <Input /> : cardNumber,
            initialValue: cardNumber && cardNumber
          }
        ]
      : []),
    ...(currentUser === 'manager'
      ? [
          {
            name: ['siret_number'],
            rules: [{ required: cardNumber === undefined && isWCard }],
            input: !cardNumber ? <Input type="number" /> : cardNumber,
            initialValue: cardNumber && cardNumber
          }
        ]
      : []),
    ...(currentUser === 'intern'
      ? [
          {
            name: ['intern_contract_number'],
            rules: [{ required: cardNumber === undefined && isWCard }],
            input: !cardNumber ? <Input type="number" /> : cardNumber,
            initialValue: cardNumber && cardNumber
          }
        ]
      : []),
    ...(currentUser === 'renter'
      ? [
          {
            name: ['renter_proof'],
            rules: [{ required: cardNumber === undefined && isWCard }],
            input: !cardNumber ? <Input /> : cardNumber,
            initialValue: cardNumber && cardNumber
          }
        ]
      : []),
    ...(currentUser === 'provider'
      ? [
          {
            name: ['provider_proof'],
            rules: [{ required: cardNumber === undefined && isWCard }],
            input: !cardNumber ? <Input /> : cardNumber,
            initialValue: cardNumber && cardNumber
          }
        ]
      : []),
    {
      name: ['send_email'],
      valuePropName: 'checked',
      input: (
        <Switch
          checkedChildren={t('switch.yes')}
          unCheckedChildren={t('switch.no')}
          disabled={purpose === 'edit'}
        />
      )
    },
    {
      name: ['incident_closed_email'],
      valuePropName: 'checked',
      initialValue: true,
      input: (
        <Switch
          checkedChildren={t('switch.yes')}
          unCheckedChildren={t('switch.no')}
        />
      )
    },
    {
      name: ['reminder_incident_email'],
      valuePropName: 'checked',
      initialValue: true,
      input: (
        <Switch
          checkedChildren={t('switch.yes')}
          unCheckedChildren={t('switch.no')}
        />
      )
    },
    {
      name: ['doc_refused_incident_email'],
      valuePropName: 'checked',
      initialValue: true,
      input: (
        <Switch
          checkedChildren={t('switch.yes')}
          unCheckedChildren={t('switch.no')}
        />
      )
    },
    {
      name: ['worksite_closed_email'],
      valuePropName: 'checked',
      initialValue: true,
      input: (
        <Switch
          checkedChildren={t('switch.yes')}
          unCheckedChildren={t('switch.no')}
        />
      )
    },
    {
      name: ['incident_email'],
      valuePropName: 'checked',
      initialValue: true,
      input: (
        <Switch
          checkedChildren={t('switch.yes')}
          unCheckedChildren={t('switch.no')}
        />
      )
    },
    {
      name: ['worksite_added_email'],
      valuePropName: 'checked',
      initialValue: true,
      input: (
        <Switch
          checkedChildren={t('switch.yes')}
          unCheckedChildren={t('switch.no')}
        />
      )
    }
  ];

  const fields = data?.filter(
    (elem) =>
      fieldsToDisplay?.includes(elem?.name[0]) &&
      !(
        (elem?.name[0] === 'idCompany' || elem?.name[0] === 'idPromoter') &&
        isfrommodal
      )
  );

  return {
    fields,
    isFieldsLoading,
    documents,
    base64,
    setDocuments
  };
};

export default useFields;
