import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { outOfNavRoutes, routes } from '../utils/constants/adminRoutes';
import { Login } from '../routes/login';
import Home from '../routes/home/Home';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { UserLayout } from '../components/layouts/UserLayout';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/Settings';
import { WorksiteRouter } from '../routes/worksites/WorksiteRouter';
import { CompanyRouter } from '../routes/company/CompanyRouter';
import { PromoterRouter } from '../routes/promoter/PromoterRouter';
import { ControlRouter } from '../routes/controls/ControlRouter';
import { IncidentRouter } from '../routes/incidents/IncidentRouter';
import { UserTheme } from '../assets/styles/Theme/UserTheme';

const RequireAuth = ({ isValid }) => {
  const location = useLocation();

  if (!isValid) {
    return (
      <Navigate to={outOfNavRoutes.LOGIN} state={{ from: location }} replace />
    );
  }

  return <UserLayout />;
};

RequireAuth.propTypes = {
  isValid: PropTypes.bool.isRequired
};

export const UserRouter = () => {
  const { isValid } = useAuthContext();
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'user';
    }
  }, []);

  return (
    <BrowserRouter>
      <UserTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route element={<RequireAuth isValid={isValid} />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          {/* ----------------------------------------------------- */}
          <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
          <Route path={routes.HOME} element={<Home />} />
          <Route path={`${routes.WORKSITES}/*`} element={<WorksiteRouter />} />
          <Route path={`${routes.CONTROLS}/*`} element={<ControlRouter />} />
          <Route path={`${routes.INCIDENTS}/*`} element={<IncidentRouter />} />
          <Route
            path={`${routes.PROMOTERS}/*`}
            element={<PromoterRouter type="promoters" />}
          />
          <Route
            path={`${routes.COMPANIES}/*`}
            element={<CompanyRouter type="companies" />}
          />
          {/* ----------------------------------------------------- */}
          <Route path={routes.HOME} element={<Home />} />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
