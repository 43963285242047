/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import WorksiteTypesTable from './worksiteTypesTable';

const initialColumns = [
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: (text) => <Input InitialValue={text} />
  }
];

const WorksiteTypesSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [worksitetypesCols, setWorksiteTypesCols] = useState(initialColumns);
  const newWorksiteTypesCol = {
    label: 'Nouvelle ligne',
    icon: null,
    color: '#008CA0',
    show: true
  };

  const getWorksiteTypesCols = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/worksitetypes' });
      setWorksiteTypesCols(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addWorksiteTypesCol = async () => {
    try {
      await dispatchAPI('POST', {
        url: '/worksitetypes',
        body: newWorksiteTypesCol
      });
      getWorksiteTypesCols();
    } catch (e) {
      message.error(e.message);
    }
  };

  const deleteWorksiteTypesCol = async (worksitetypesCol) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/worksitetypes/${worksitetypesCol._id}`
      });
    } catch (e) {
      message.error(e.message);
    }
    getWorksiteTypesCols();
  };

  const editWorksiteTypesCol = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/worksitetypes/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    getWorksiteTypesCols();
  };
  useEffect(() => {
    getWorksiteTypesCols();
  }, []);

  return (
    <>
      <WorksiteTypesTable
        worksitetypesCols={worksitetypesCols}
        editWorksiteTypesCol={editWorksiteTypesCol}
        deleteWorksiteTypesCol={deleteWorksiteTypesCol}
      />
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addWorksiteTypesCol}
      >
        {t('settings.worksitetypesTable.addworksitetypescol')}
      </Button>
    </>
  );
};

export default WorksiteTypesSettings;
