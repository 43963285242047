import React from 'react';
import { Button, message, Tag } from 'antd';
import { Link } from 'react-router-dom';
import Dragger from 'antd/lib/upload/Dragger';
import { FileDoneOutlined } from '@ant-design/icons';
import { userRoles } from '../../utils/constants/tagColors';
import { useDownloadDocument } from '../../utils/downloadDoc';

export const userList = (
  data,
  t,
  type,
  handlePostDocuments,
  documents,
  draggerProps
) => {
  const {
    last_name,
    first_name,
    email,
    phone_number,
    phone_number_2,
    role,
    poste,
    idCompany,
    idPromoter,
    files
  } = data;
  const { downloadDocument } = useDownloadDocument();
  const postDocument = async () => {
    await handlePostDocuments();
    message.success(t('users.message.post_documents'));
    window.location.reload();
  };

  const userContent = [
    {
      label: 'users.form.last_name',
      content: last_name || '-'
    },
    {
      label: 'users.form.first_name',
      content: first_name || '-'
    },
    {
      label: 'users.form.email',
      content: email || '-',
      span: 2
    },
    {
      label: 'users.form.phone_number_full',
      content:
        phone_number && phone_number.number ? (
          <div>
            {`${phone_number.country_code} ${phone_number.number.slice(
              0,
              1
            )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}
                    `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: 'users.form.phone_number_full_2',
      content:
        phone_number_2 && phone_number_2.number ? (
          <div>
            {`${phone_number_2.country_code} ${phone_number_2.number.slice(
              0,
              1
            )} ${phone_number_2.number.slice(1).replace(/(.{2})/g, '$1 ')}
                    `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: 'users.form.role',
      content:
        (role && (
          <Tag color={userRoles[role.split(':')[1]]}>
            {t(`users.tags.${role.split(':')[1]}`) || '-'}
          </Tag>
        )) ||
        '-'
    },
    {
      label: 'users.form.poste',
      content:
        (role && (
          <Tag color={userRoles[poste]}>{t(`users.tags.${poste}`) || '-'}</Tag>
        )) ||
        '-'
    }
  ];

  const promoterContent = [
    {
      label: 'users.form.idPromoter',
      content: idPromoter ? (
        <Link to={`/promoters/show/${idPromoter._id}`}>
          {idPromoter?.name || '-'}
        </Link>
      ) : (
        '-'
      )
    }
  ];

  const compnayContent = [
    {
      label: 'users.form.idCompany',
      content: idCompany
        ? idCompany.map((company) => (
            <p>
              <Link to={`/companies/show/${company._id}`}>
                {company?.name || '-'}
              </Link>
            </p>
          ))
        : '-'
    }
  ];

  const workerContent = [
    {
      label: 'users.form.idCompany',
      content: idCompany
        ? idCompany.map((company) => (
            <p>
              <Link to={`/companies/show/${company._id}`}>
                {company?.name || '-'}
              </Link>
            </p>
          ))
        : '-'
    },
    {
      label: 'users.form.files',
      content: files?.length ? (
        <Button onClick={() => downloadDocument(files[0]?.fileId, 'show')}>
          {files[0]?.fileName}
        </Button>
      ) : (
        <>
          <Dragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
              <FileDoneOutlined style={{ color: 'var(--textColor)' }} />
            </p>
            <p className="ant-upload-text">{t('files.create.action')}</p>
          </Dragger>
          {documents && <Button onClick={postDocument}>Enregistrer</Button>}
        </>
      )
    }
  ];

  switch (type) {
    case 'admin':
      return [...userContent];
    case 'promoters':
      return [...userContent, ...promoterContent];
    case 'companies':
      return [...userContent, ...compnayContent];
    case 'workers':
      return [...userContent, ...workerContent];
    default:
      return userContent;
  }
};

export const modificationsList = (data) => {
  const {
    modifications,
    last_name,
    first_name,
    poste,
    card_number,
    siret_number
  } = data;
  const list = [
    {
      label: 'users.form.modified.first_name_before',
      content: modifications?.first_name,
      span: modifications?.first_name ? 1 : 0
    },
    {
      label: 'users.form.modified.first_name_after',
      content: first_name,
      span: modifications?.first_name ? 2 : 0
    },
    {
      label: 'users.form.modified.last_name_before',
      content: last_name,
      span: modifications?.last_name ? 1 : 0
    },
    {
      label: 'users.form.modified.last_name_after',
      content: modifications?.last_name && modifications?.last_name,
      span: modifications?.last_name ? 1 : 0
    },
    {
      label: 'users.form.modified.poste_before',
      content: modifications?.poste,
      span: modifications?.poste ? 1 : 0
    },
    {
      label: 'users.form.modified.poste_after',
      content: poste,
      span: modifications?.poste ? 2 : 0
    },
    {
      label: 'users.form.modified.siret_number_before',
      content: siret_number,
      span: modifications?.siret_number ? 1 : 0
    },
    {
      label: 'users.form.modified.siret_number_after',
      content: modifications?.siret_number,
      span: modifications?.siret_number ? 2 : 0
    },
    {
      label: 'users.form.modified.card_number_before',
      content: card_number,
      span: modifications?.card_number ? 1 : 0
    },
    {
      label: 'users.form.modified.card_number_after',
      content: modifications?.card_number,
      span: modifications?.card_number ? 2 : 0
    }
  ];
  const filterList = list.filter((obj) => obj.span !== 0);
  return filterList;
};

export const emailList = (data) => {
  const {
    incident_closed_email,
    reminder_incident_email,
    doc_refused_incident_email,
    worksite_closed_email,
    incident_email,
    worksite_added_email
  } = data;
  const list = [
    {
      label: 'users.form.incident_closed_email',
      content: !incident_closed_email ? 'NON' : 'OUI',
      span: 1
    },
    {
      label: 'users.form.reminder_incident_email',
      content: !reminder_incident_email ? 'NON' : 'OUI',
      span: 1
    },
    ...(data?.role?.startsWith('companies')
      ? [
          {
            label: 'users.form.doc_refused_incident_email',
            content: !doc_refused_incident_email ? 'NON' : 'OUI',
            span: 1
          }
        ]
      : []),
    ...(data?.role?.startsWith('promoters')
      ? [
          {
            label: 'users.form.worksite_closed_email',
            content: !worksite_closed_email ? 'NON' : 'OUI',
            span: 1
          }
        ]
      : []),
    {
      label: 'users.form.incident_email',
      content: !incident_email ? 'NON' : 'OUI',
      span: 1
    },
    ...(data?.role?.startsWith('companies')
      ? [
          {
            label: 'users.form.worksite_added_email',
            content: !worksite_added_email ? 'NON' : 'OUI',
            span: 1
          }
        ]
      : [])
  ];
  return list;
};
