import React, { useState, useEffect } from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const useColumns = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [worksiteTypes, setWorksiteTypes] = useState([]);
  const [worksitePhases, setWorksitePhases] = useState([]);
  // const [batchtitles, setBatchtitles] = useState([]);

  const headers = [
    {
      label: 'reference',
      key: 'reference'
    },
    {
      label: 'name',
      key: 'name'
    },
    {
      label: 'idController',
      key: 'idController.first_name'
    },
    {
      label: 'idPromoter',
      key: 'idPromoter.name'
    },
    {
      label: 'type',
      key: 'type.label'
    },
    {
      label: 'phase',
      key: 'phase.label'
    },
    {
      label: 'Address_full',
      key: 'address_full'
    },
    {
      label: 'referent',
      key: 'referent.email'
    }
  ];

  const columns = [
    {
      title: t('worksites.form.reference'),
      key: 'reference',
      dataIndex: 'reference',
      sorter: true
    },
    {
      title: t('worksites.form.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('worksites.form.idController'),
      key: 'idController',
      dataIndex: 'idController',
      sorter: true,
      render: (controller) =>
        controller && `${controller.first_name} ${controller.last_name}`
    },
    {
      title: t('worksites.form.idPromoter'),
      key: 'idPromoter',
      dataIndex: 'idPromoter',
      sorter: true,
      render: (promoter) => (promoter && promoter.name) || '-'
    },
    {
      title: t('worksites.form.type'),
      key: 'type',
      dataIndex: 'type',
      sorter: true,
      render: (type) => <Tag color={type?.color}>{type?.label}</Tag>,
      filters: worksiteTypes.map((r) => ({
        text: <Tag color={r?.color}>{r?.label || '-'}</Tag>,
        value: r?._id
      }))
    },
    {
      title: t('worksites.form.phase'),
      key: 'phase',
      dataIndex: 'phase',
      sorter: true,
      render: (phase) => <Tag color={phase?.color}>{phase?.label || '-'}</Tag>,
      filters: worksitePhases.map((r) => ({
        text: <Tag color={r?.color}>{r?.label}</Tag>,
        value: r?._id
      }))
    },
    {
      title: t('worksites.form.Address_full'),
      key: 'address_full',
      dataIndex: 'address_full',
      sorter: true,
      render: (address_full) => address_full
    },
    {
      title: t('worksites.form.referent'),
      key: 'referent',
      dataIndex: 'referent',
      sorter: true,
      render: (referent) => referent && referent.email
    }
  ];

  const getWorksiteTypes = async () => {
    try {
      const result = await dispatchAPI('GET', { url: 'worksitetypes' });
      setWorksiteTypes(result.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  // const getBatchtitles = async () => {
  //   try {
  //     const result = await dispatchAPI('GET', { url: 'batchtitles' });
  //     setBatchtitles(result.data);
  //   } catch (e) {
  //     if (e.response) message(e.response.status);
  //   }
  // };

  const getWorksitePhases = async () => {
    try {
      const result = await dispatchAPI('GET', { url: 'worksitephases' });
      setWorksitePhases(result.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getWorksiteTypes();
    getWorksitePhases();
    // getBatchtitles();
  }, []);

  return { headers, columns };
};
