import React, { useEffect, useRef } from 'react';
import { add, format, getDay, setDay, differenceInMinutes } from 'date-fns';
import { Table } from 'antd';
import { useDrop, useDrag } from 'react-dnd';
import {
  GenericEvent,
  CalendarBodyProps,
  EventsObject,
  EventBlockProps,
  ColumnNode
} from './types.tsx';
import { getDayHoursEvents, sizeEventBox, MIN_BOX_SIZE } from './utils.ts';
import { useTranslation } from 'react-i18next';

const BOX_POSITION_OFFSET = 26;
const TURQUOISE = '#36CFC9';
const ALL_DAY_ROW = 0;

type DragItem = {
  column: string;
  time: any;
  events: GenericEvent;
  _id: string;
  type: string;
};

const TimeCell: React.FC<{
  day: string;
  events: ColumnNode<GenericEvent>;
  row: EventsObject<GenericEvent>;
  columnDate: Date;
  onEventClick?: (event: GenericEvent) => void;
  setOpenModal: (open: boolean) => void;
  setEventToUpdate: (open: string) => void;
  setDate: (data: { date: Date; hour: string }) => void;
}> = ({
  day,
  events,
  row,
  columnDate,
  onEventClick,
  setOpenModal,
  setEventToUpdate,
  setDate
}) => {
  const [{ isOver }, drop] = useDrop<DragItem, any, any>({
    accept: 'GRID',
    drop: (item) => {
      if (item?.events?.type === 'CONTROL' || item?.type === 'CONTROL') {
        setDate({ date: columnDate, hour: row.hour });
        setEventToUpdate(item?.events?.key || item._id);
        setOpenModal(true);
      }
      return {};
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  const dropStyle = {
    backgroundColor: isOver
      ? 'var(--itemActiveBackground)'
      : 'var(--clientColor)'
  };

  return <div ref={drop} className="drop-cell" style={{ ...dropStyle }} />;
};

const EventCell: React.FC<{
  day: string;
  events: ColumnNode<GenericEvent>;
  row: EventsObject<GenericEvent>;
  columnDate: Date;
  onEventClick?: (event: GenericEvent) => void;
}> = ({ day, events, row, columnDate, onEventClick }) => {
  const [{ isDragging }, drag] = useDrag(() => {
    return {
      type: 'GRID',
      item: { column: day, time: row.hourObject, events: events[0] },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    };
  });

  const eventsBlock =
    Array.isArray(events) &&
    events.map((event, index) => {
      if (format(event.startTime, 'P') === format(columnDate, 'P')) {
        return (
          <EventBlock
            key={event.eventId}
            event={event}
            index={index}
            hour={row.hourObject}
            events={events.length}
            onEventClick={onEventClick}
          />
        );
      }
    });
  return <div ref={drag}>{eventsBlock}</div>;
};

const EventBlock = <T extends GenericEvent>({
  event,
  index,
  hour,
  events,
  onEventClick
}: EventBlockProps<T>) => {
  const getEventDay = getDay(new Date(event.endTime));
  const fitHourToDate = setDay(hour, getEventDay);

  const boxStyle = event.allDay
    ? { boxSize: MIN_BOX_SIZE, boxPosition: index * BOX_POSITION_OFFSET }
    : sizeEventBox(event, fitHourToDate);
  const boxLeftPosition = event.allDay ? 0 : BOX_POSITION_OFFSET * index;

  return (
    <div
      style={{
        display:
          !event.allDay &&
          differenceInMinutes(new Date(event.endTime), fitHourToDate) === 0
            ? 'none'
            : 'block',
        height: boxStyle.boxSize + '%',
        width: event.allDay ? 80 + '%' : 80 / events + '%',
        position: 'absolute',
        top: boxStyle.boxPosition + '%',
        left: boxLeftPosition + '%',
        borderColor: 'white',
        borderStyle: 'solid',
        borderWidth: '0.01rem',
        borderRadius: '5px',
        backgroundColor: event.backgroundColor
          ? event.backgroundColor
          : TURQUOISE,
        zIndex: 1
      }}
      onClick={onEventClick ? () => onEventClick(event) : undefined}
      key={index}
      className="weekly-calendar-event"
    >
      <div style={{ color: 'white', fontSize: '12px', paddingLeft: '5px' }}>
        {event.title}
      </div>
    </div>
  );
};

function Calendar<T extends GenericEvent>({
  weekDates,
  getDayEvents,
  onEventClick,
  dayRange,
  hourStart,
  hourEnd,
  noAllDayRow,
  setOpenModal,
  setEventToUpdate,
  setDate
}: CalendarBodyProps<T>) {
  const { t } = useTranslation();
  const rowRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (rowRef.current) {
      rowRef.current?.scrollIntoView();
    }
  }, [rowRef]);

  const dayList = (dayRange: string | undefined) => {
    switch (dayRange) {
      case 'withSaturday':
        return [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday'
        ];
      case 'weekends':
        return [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
          'Sunday'
        ];
      default:
        return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    }
  };

  const dayColumns = dayList(dayRange).map((day, counter) => {
    const columnDate = add(new Date(weekDates.startDate), {
      days: 1 + counter
    });
    const formattedDayandMonth =
      t(`calendar.days.${format(columnDate, 'iii')}`) +
      ' ' +
      format(columnDate, 'dd');
    return {
      title: formattedDayandMonth,
      dataIndex: day,
      key: day,
      width: 2,
      render: function (
        events: ColumnNode<T>,
        row: EventsObject<T>
      ): React.ReactNode | undefined {
        if (events && events.length > 0 && events instanceof Array) {
          return (
            <EventCell
              day={day}
              events={events}
              row={row}
              key={`${day}-${counter}`}
              columnDate={columnDate}
              onEventClick={(event: GenericEvent) => {}}
            />
          );
        }
        return (
          <TimeCell
            day={day}
            events={events}
            row={row}
            columnDate={columnDate}
            onEventClick={(event: GenericEvent) => {}}
            setOpenModal={setOpenModal}
            setEventToUpdate={setEventToUpdate}
            setDate={setDate}
          />
        );
      }
    };
  });
  const hourColumn = {
    title: t('calendar.hours.title'),
    dataIndex: 'hour',
    key: 'hour',
    width: 1,
    render: (hour: ColumnNode<T>, {}, id: number) => {
      return {
        props: {
          style: { width: '10%' }
        },
        children: <div>{hour}</div>
      };
    }
  };
  const tableColumns = [hourColumn, ...dayColumns];

  return (
    <div>
      <Table
        rowKey={(record) => record.id}
        dataSource={getDayHoursEvents(
          weekDates,
          getDayEvents,
          hourStart,
          hourEnd,
          noAllDayRow
        )}
        columns={tableColumns}
        pagination={false}
        bordered={true}
        showHeader={true}
        scroll={{
          y: 1000
        }}
      />
    </div>
  );
}

export default Calendar;
