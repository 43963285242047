import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './createFields';

const ControlDrawer = ({
  showDrawer,
  setShowDrawer,
  users,
  purpose,
  closeOverlay,
  idFromOverlay
}) => {
  const { fields, isFieldsLoading } = useFields(users);

  const config = {
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        period_start:
          data.period_start?.[0] &&
          moment(data.period_start[0]).startOf('week').startOf('day'),
        period_end:
          data.period_start?.[1] &&
          moment(data.period_start[1]).endOf('week').startOf('day')
      })
    }
  };

  return (
    <Drawer
      centered
      open={showDrawer}
      onClose={() => {
        setShowDrawer(false);
      }}
      width={700}
      footer={false}
    >
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="controls"
        resource="controls"
        isOverlay
        customNavigate={closeOverlay}
        idFromOverlay={idFromOverlay}
        config={config}
      />
    </Drawer>
  );
};

ControlDrawer.propTypes = {
  showDrawer: PropTypes.bool.isRequired,
  setShowDrawer: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  purpose: PropTypes.string,
  closeOverlay: PropTypes.func.isRequired,
  idFromOverlay: PropTypes.string
};

ControlDrawer.defaultProps = {
  users: null,
  purpose: null,
  idFromOverlay: null
};

export default ControlDrawer;
