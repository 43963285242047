import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col } from 'antd';
import { BulbFilled, CloseOutlined } from '@ant-design/icons';
import QrScanner from 'qr-scanner';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import BTPCardComponent from '../users/BTPCardComponent';

QrScanner.WORKER_PATH = 'https://unpkg.com/qr-scanner/qr-scanner-worker.min.js';

export const RegisterQrCode = ({
  user,
  setOpenCamera,
  openCamera,
  isLoading
}) => {
  const { dispatchAPI } = useAuthContext();
  const videoElem = useRef(null);
  const [flashEnabled, setFlashEnabled] = useState(false);
  const { message } = useErrorMessage();
  const [qrScanner, setQrScanner] = useState(null);
  const [flashLightAvailable, setFlashLightAvailable] = useState(false);
  const [cameraList, setCameraList] = useState(null);

  const regitserQrCodeScan = async (result) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/users/${user._id}`,
        body: { qrCode: result.data }
      });
      window.location.reload();
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (!videoElem.current) return;
    if (qrScanner) return;
    const qrSc = new QrScanner(
      videoElem.current,
      async (result) => {
        await regitserQrCodeScan(result);
      },
      { returnDetailedScanResult: true }
    );
    qrSc.start().then(async () => {
      qrSc.hasFlash().then((hasFlash) => {
        setFlashLightAvailable(hasFlash);
      });
      setCameraList(await QrScanner.listCameras(true));
    });

    setQrScanner(qrSc);
    // eslint-disable-next-line consistent-return
    return () => {
      if (flashEnabled && qrSc.hasFlash()) {
        qrSc.turnFlashOff();
      }
      qrSc.stop();
    };
  }, [videoElem, openCamera]);

  useEffect(() => {
    if (!cameraList || !qrScanner) return;

    const targetCamera = cameraList.find(
      (camera) => camera.label === 'Double appareil photo grand angle arrière'
    );

    if (targetCamera && qrScanner) {
      qrScanner.setCamera(targetCamera.id);
    }
  }, [cameraList, qrScanner]);

  const toggleFlash = async () => {
    if ((await qrScanner.hasFlash()) === false) return;
    qrScanner.toggleFlash();
    if (!videoElem.current) return;
    setFlashEnabled(!flashEnabled);
  };

  return (
    <div>
      {openCamera ? (
        <Row>
          <Col span={24}>
            <video
              ref={videoElem}
              muted
              playsInline
              style={{
                width: '100%',
                height: 'calc(100vh - 160px)',
                padding: 0,
                margin: 0,
                objectFit: 'cover'
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0, 0, 0, 0)'
              }}
            />
          </Col>
          <Col span={24}>
            <Button type="link" danger onClick={() => setOpenCamera(false)}>
              Annuler
              <CloseOutlined />
            </Button>
            {flashLightAvailable && (
              <Button onClick={toggleFlash} type="link" icon={<BulbFilled />} />
            )}
          </Col>
        </Row>
      ) : (
        <BTPCardComponent isLoading={isLoading} user={user} />
      )}
    </div>
  );
};

RegisterQrCode.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  setOpenCamera: PropTypes.func.isRequired,
  openCamera: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired
};
