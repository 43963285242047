import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CardsBundle } from './CardsBundle/CardsBundle';
import MobileHeader from './MobileHeader';
import { useAuthContext } from '../../contexts/AuthContext';

const Worksites = ({ headless, path, setNavDisplay }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();

  useEffect(() => {
    setNavDisplay('block');
  }, []);

  return (
    <>
      <MobileHeader title={t('inspector.worksites')} />
      <div className="list_card_container">
        <CardsBundle
          headless={headless}
          resourceName="worksites"
          extraQuery={`idController=${user._id}`}
          resourceModelName="Worksite"
          populate="idController,idPromoter,type,phase"
          path={path}
          type="worksite"
        />
      </div>
    </>
  );
};

Worksites.propTypes = {
  headless: PropTypes.bool,
  path: PropTypes.string,
  setNavDisplay: PropTypes.string
};
Worksites.defaultProps = {
  headless: false,
  path: null,
  setNavDisplay: 'block'
};

export default Worksites;
