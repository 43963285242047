import React, { useState, useEffect } from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { WarningOutlined } from '@ant-design/icons';

import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const useColumns = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [worksites, setWorksites] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [controlers, setControlers] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [incidentStatuses, setIncidentStatuses] = useState([]);
  const [incidentTypes, setIncidentTypes] = useState([]);

  const getWorksites = async () => {
    try {
      const result = await dispatchAPI('GET', { url: 'worksites' });
      setWorksites(result.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getIncidentStatuses = async () => {
    try {
      const result = await dispatchAPI('GET', { url: 'incidentstatuses' });
      setIncidentStatuses(result.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getIncidentTypes = async () => {
    try {
      const result = await dispatchAPI('GET', { url: 'incidenttypes' });
      setIncidentTypes(result.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getControlers = async () => {
    try {
      const result = await dispatchAPI('GET', {
        url: 'users?role=/inspectors:/i'
      });

      setControlers(result.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getWorkers = async () => {
    try {
      const result = await dispatchAPI('GET', {
        url: 'users?role=/workers:/i'
      });

      setWorkers(result.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const result = await dispatchAPI('GET', { url: 'companies' });
      setCompanies(result.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    getWorksites();
    getIncidentStatuses();
    getIncidentTypes();
    getControlers();
    getWorkers();
    getCompanies();
  }, []);

  const headers = [
    {
      label: 'worksite',
      key: 'worksite.name'
    },
    {
      label: 'date',
      key: 'date'
    },
    {
      label: 'worker',
      key: 'worker.first_name'
    },
    {
      label: 'type',
      key: 'type.label'
    },
    {
      label: 'company',
      key: 'company.name'
    },
    {
      label: 'inspector_first_name',
      key: 'inspector.first_name'
    },
    {
      label: 'inspector_last_name',
      key: 'inspector.last_name'
    },
    {
      label: 'status',
      key: 'status.label'
    },
    {
      label: 'reference',
      key: 'reference'
    }
  ];

  const columns = [
    {
      title: t('incidents.form.worksite'),
      key: 'worksite',
      dataIndex: 'worksite',
      sorter: true,
      render: (worksite) => worksite && `${worksite.name || '-'}`,
      filters: worksites.map((r) => ({
        text: r.name,
        value: r._id
      }))
    },
    {
      title: t('incidents.form.date'),
      key: 'date',
      width: 120,
      dataIndex: 'date',
      sorter: true,
      render: (date) => date && moment(date).format('DD/MM/YYYY')
    },
    {
      key: 'warning',
      dataIndex: 'date',
      width: 60,
      render: (date) =>
        moment().diff(date, 'days') > 6 && (
          <WarningOutlined style={{ color: 'red', fontSize: '18px' }} />
        )
    },
    {
      title: t('incidents.form.worker'),
      key: 'worker',
      dataIndex: '',
      sorter: true,
      render: (data) =>
        data.worker
          ? `${data.worker.first_name || '-'} ${data.worker.last_name || '-'}`
          : `${data.unknown_worker || '-'}`,
      filters: workers.map((r) => ({
        text: `${r.first_name} ${r.last_name}`,
        value: r._id
      }))
    },
    {
      title: t('incidents.form.type'),
      key: 'type',
      dataIndex: 'type',
      render: (type) => (
        <Tag color={type?.color || '-'}>{type?.label || '-'}</Tag>
      ),
      sorter: true,
      filters: (incidentTypes || []).map((r) => ({
        text: r.label,
        value: r._id
      }))
    },
    {
      title: t('incidents.form.company'),
      key: 'company',
      dataIndex: '',
      sorter: true,
      render: (data) =>
        data.company
          ? `${data.company.name || '-'}`
          : `${data.unknown_company || '-'}`,
      filters: companies.map((r) => ({
        text: r.name,
        value: r._id
      }))
    },
    {
      title: t('incidents.form.inspector'),
      key: 'inspector',
      dataIndex: 'inspector',
      sorter: true,
      render: (inspector) =>
        inspector &&
        `${inspector.first_name || '-'} ${inspector.last_name || '-'}`,
      filters: controlers.map((r) => ({
        text: `${r.first_name} ${r.last_name}`,
        value: r._id
      }))
    },
    {
      title: t('controls.form.status'),
      key: 'status',
      dataIndex: 'status',
      width: 110,
      render: (status) => (
        <Tag color={status?.color || '-'}>{status?.label || '-'}</Tag>
      ),
      sorter: true,
      filters: (incidentStatuses || []).map((r) => ({
        text: r.label,
        value: r._id
      }))
    },
    {
      title: t('incidents.form.reference'),
      key: 'reference',
      dataIndex: 'reference' || '-',
      sorter: true
    }
  ];

  return { headers, columns };
};
