import React, { useCallback, useEffect, useState } from 'react';
import { InputNumber, Select, Tag, DatePicker, Switch, Input } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import { userRoles, colorsRD } from '../../../utils/constants/tagColors';
import { DrawerMap } from '../DrawerMap';
import CompanyToWorksite from './CompanyToWorksite';

const { Option } = Select;
const { TextArea } = Input;

const useFields = (setSelectedPromoter, linkedUsersPromoter) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [promoters, setPromoters] = useState([]);
  const [users, setUsers] = useState([]);
  const [referents, setReferents] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [refreshC, setRefreshC] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [worksitetypes, setWorksitetypes] = useState([]);
  const [worksitephases, setWorksitephases] = useState([]);
  const [predictedAddress, setPredictedAddress] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [secondPhase, setSecondPhase] = useState();
  const [thirdPhase, setThirdPhase] = useState();
  const [clicks, setClicks] = useState({
    lat: 43.604,
    lng: 1.44305
  });

  const disableDatesBeforeStartDate = (currentDate) => {
    if (!startDate) {
      return false;
    }
    return (
      currentDate &&
      currentDate.format('YYYY-MM-DD') <= startDate.format('YYYY-MM-DD')
    );
  };

  const disableDatesBeforeSecondPhase = (currentDate) => {
    if (!secondPhase) {
      return false;
    }
    return (
      currentDate &&
      currentDate.format('YYYY-MM-DD') <= secondPhase.format('YYYY-MM-DD')
    );
  };

  const disableDatesBeforeThirdPhase = (currentDate) => {
    if (!thirdPhase) {
      return false;
    }
    return (
      currentDate &&
      currentDate.format('YYYY-MM-DD') <= thirdPhase.format('YYYY-MM-DD')
    );
  };

  const getcoordinates = async (event, selected) => {
    setIsLoading(true);
    try {
      if (event) {
        const { data } = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${event}&key=${process.env.REACT_APP_GOOGLE_MAP_API}`
        );
        setPredictedAddress(data.results);
        if (selected) {
          const newLocation = {
            lat: data.results[0].geometry?.location?.lat,
            lng: data.results[0].geometry?.location?.lng,
            city: data.results[0]?.address_components[2]?.short_name || '-',
            address_full: event
          };
          setClicks(newLocation);
        }
      }
    } catch (error) {
      message(error);
    }
    setIsLoading(false);
  };

  const [companiesRegistered, setCompaniesRegistered] = useState([]);

  const onsearch = (input, option) => {
    const result = option?.children?.props.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['siret'],
      rules: [{ message: t('errors.form.SIRET'), len: 14 }]
    },
    {
      name: ['sncName'],
      rules: [{ required: false }]
    },
    {
      name: ['rcs_number'],
      rules: [{ required: false }]
    },
    {
      name: ['meetingDate'],
      rules: [{ required: false }]
    },
    {
      name: ['idController'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={isFieldsLoading}
          showSearch
          allowClear
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(users || []).map((user) => {
            if (user.role.includes('inspectors')) {
              return (
                <Option key={user._id} value={user._id}>
                  <Tag color={userRoles[user]}>
                    {`${user.first_name} ${user.last_name} ( ${user.email} ) `}
                  </Tag>
                </Option>
              );
            }
            return null;
          })}
        </Select>
      )
    },
    {
      name: ['idPromoter'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={isFieldsLoading}
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
          onSelect={(value) => setSelectedPromoter(value)}
        >
          {(promoters || []).map((promoter) => (
            <Option key={promoter._id} value={promoter._id}>
              <Tag>{t(`${promoter.name}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['promoter_users'],
      rules: [{ required: true }],
      input: (
        <Select
          mode="multiple"
          loading={isFieldsLoading}
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(linkedUsersPromoter || []).map((userPromoter) => (
            <Option key={userPromoter._id} value={userPromoter._id}>
              <Tag>
                {t(`${userPromoter.first_name} ${userPromoter.last_name}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['type'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={isFieldsLoading}
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(worksitetypes || []).map((type) => (
            <Option key={type._id} value={type._id}>
              <Tag color={type.color}>{t(`${type.label}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['nbOfBatches'],
      rules: [{ required: true }],
      input: <InputNumber min={0} style={{ width: '100%' }} />
    },
    {
      name: ['phase'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={isFieldsLoading}
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(worksitephases || []).map((phase) => (
            <Option key={phase._id} value={phase._id}>
              <Tag color={phase.color}>{t(`${phase.label}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },

    {
      name: ['start_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          style={{ width: '100%' }}
          format="DD/MM/YYYY"
          onSelect={(value) => setStartDate(value)}
        />
      )
    },
    {
      name: ['second_phase_expected_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          style={{ width: '100%' }}
          format="DD/MM/YYYY"
          onSelect={(value) => setSecondPhase(value)}
          disabledDate={(current) => disableDatesBeforeStartDate(current)}
          disabled={!startDate}
        />
      )
    },
    {
      name: ['third_phase_expected_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          style={{ width: '100%' }}
          format="DD/MM/YYYY"
          onSelect={(value) => setThirdPhase(value)}
          disabledDate={(current) => disableDatesBeforeSecondPhase(current)}
          disabled={!secondPhase}
        />
      )
    },
    {
      name: ['end_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          style={{ width: '100%' }}
          format="DD/MM/YYYY"
          disabledDate={(current) => disableDatesBeforeThirdPhase(current)}
          disabled={!thirdPhase}
        />
      )
    },
    {
      name: ['isSecurityActive'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Switch />
    },
    {
      name: ['latlgn'],
      required: false,
      input: (
        <DrawerMap
          refresh={refresh}
          setRefresh={setRefresh}
          clicks={clicks}
          setClicks={setClicks}
          setPredictedAddress={setPredictedAddress}
        />
      )
    },
    {
      label: 'location.latitude',
      name: ['location', 'latitude'],
      rules: [{ required: !clicks }],
      input: (clicks && clicks.lat) || ''
    },
    {
      label: 'location.longitude',
      name: ['location', 'longitude'],
      rules: [{ required: !clicks }],
      input: (clicks && clicks.lng) || ''
    },
    {
      label: 'address_full',
      name: ['address_full'],
      rules: [{ required: true }],
      input: (
        <Select
          filterOption={false}
          showSearch
          loading={isLoading}
          showArrow={false}
          notFoundContent={null}
          onSearch={getcoordinates}
          onSelect={async (event) => {
            await getcoordinates(event, true);
          }}
          options={
            predictedAddress.length &&
            (predictedAddress || []).map((predictions) => ({
              label: predictions?.formatted_address,
              value: predictions?.formatted_address
            }))
          }
          autoComplete="anyrandomstring"
        />
      )
    },
    {
      name: ['color'],
      rules: [{ required: true }],
      input: (
        <Select>
          {colorsRD.map((color) => (
            <Option key={color.value} value={color.value}>
              <Tag color={color.value}>{color.name}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'referents',
      name: ['referents'],
      input: (
        <Select
          mode="multiple"
          loading={isFieldsLoading}
          showSearch
          filterOption={(input, option) => onsearch(input, option)}
        >
          {(referents || []).map((user) => {
            if (user.role.includes('promoters')) {
              return (
                <Option key={user._id} value={user._id}>
                  <Tag color={userRoles[user]}>
                    {`${user.first_name} ${user.last_name} ( ${user.email} ) `}
                  </Tag>
                </Option>
              );
            }
            return null;
          })}
        </Select>
      )
    },
    {
      name: ['csps'],
      input: <Input type="email" />
    },
    {
      name: ['project_owner'],
      input: <Input type="email" />
    },
    {
      name: ['comment'],
      input: <TextArea rows={5} />
    },
    {
      name: ['assigncompany'],
      required: false,
      input: (
        <CompanyToWorksite
          refresh={refreshC}
          setRefresh={setRefreshC}
          companies={companies}
          isFieldsLoading={isFieldsLoading}
          setIsFieldsLoading={setIsFieldsLoading}
          companiesRegistered={companiesRegistered}
          setCompaniesRegistered={setCompaniesRegistered}
        />
      )
    }
  ];

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies/' });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getPromoters = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/promoters/' });
      setPromoters(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users/' });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getReferents = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users/' });
      setReferents(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getWorksitetypes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/worksitetypes?show=true'
      });
      setWorksitetypes(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getWorksitephases = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/worksitephases?show=true'
      });
      setWorksitephases(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getPromoters();
    await getUsers();
    await getReferents();
    await getCompanies();
    await getWorksitetypes();
    await getWorksitephases();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    clicks,
    companiesRegistered,
    setCompaniesRegistered,
    isFieldsLoading,
    setClicks,
    setPredictedAddress
  };
};

export default useFields;
