import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col, Image, Skeleton, Typography, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import Hemlet from '../../../assets/images/icons/hemlet.svg';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import InformationPanel from '../InformationPanel';
import {
  StartControlBtn,
  StartedControlButtonsPanel
} from './ControlDetailsComponents';
import WorkerDetails from '../ControlQRCode/WorkerDetails';
import collapseInfos from './collapseInfos';
import MobileContextProvider from '../MobileContext';

const { Title, Text } = Typography;

const ControlDetails = ({ setNavDisplay, mode }) => {
  const { id } = useParams();
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const {
    control,
    setControl,
    controlStarted,
    setControlStarted,
    getWorksite,
    worksite,
    getIncidents,
    getComments,
    getCompanies,
    getBatchTitles,
    controlState,
    isLoading,
    setIsLoading
  } = MobileContextProvider();

  const getControl = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/controls/${id}?populate=worksite,worksite.phase,worksite.type,workers.worker,comments,documents`
      });
      setControl(data);
      setControlStarted(data.status === 'IN_PROGRESS');
      getWorksite(data.worksite._id, id);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      setNavDisplay('block');
      await getControl();
      await getIncidents(id);
      await getComments(id);
      await getCompanies();
      await getBatchTitles();
    })();
  }, []);

  return controlState ? (
    <WorkerDetails mode={mode} />
  ) : (
    <div className={`control-wrapper${mode === 'desktop' ? '-desktop' : ''}`}>
      <Row className={`control-header${mode === 'desktop' ? '-desktop' : ''}`}>
        <Col span={4}>
          <Image src={Hemlet} preview={false} />
        </Col>
        <Col span={18} offset={2}>
          <Title>{worksite?.name}</Title>
          <Text>{control?.reference}</Text>
          <Tag style={{ marginLeft: '20px' }}>
            {t(`controls.tags.${control?.status}`)}
          </Tag>
        </Col>
      </Row>
      <Row>
        {controlStarted ? (
          <StartedControlButtonsPanel
            controlId={id}
            security={worksite?.isSecurityActive}
            mode={mode}
          />
        ) : (
          <StartControlBtn user={user} />
        )}
      </Row>
      <Row value={worksite}>
        <Skeleton loading={isLoading}>
          <Col span={24} className="control-details-wrapper">
            <InformationPanel content={collapseInfos(t, mode, id)} />
          </Col>
        </Skeleton>
      </Row>
    </div>
  );
};

ControlDetails.propTypes = {
  setNavDisplay: PropTypes.string,
  mode: PropTypes.string
};

ControlDetails.defaultProps = {
  setNavDisplay: 'block',
  mode: null
};

export default ControlDetails;
