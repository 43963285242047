import React from 'react';
import { Alert, Card, Row } from 'antd';
import { Pie } from '@ant-design/plots';
import PropTypes from 'prop-types';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';

const CardDonuts = ({ dataAdmin, title, total, info }) => {
  const { user } = useAuthContext();
  const donuts = () => {
    const data = dataAdmin;
    const config = {
      appendPadding: 10,
      height: user.role.includes('companies') ? 300 : 200,
      data,
      angleField: 'value',
      colorField: 'type',
      radius: 0.7,
      innerRadius: 0.7,
      statistic: {
        title: false,
        content: {
          style: {
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: 18
          },
          content: `${total}`
        }
      },
      legend: {
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
        position: 'left'
      },
      label: {
        type: 'inner',
        offset: '-50%',
        style: {
          textAlign: 'center'
        },
        autoRotate: false,
        content: '{value}',
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif"
      }
    };
    return <Pie {...config} />;
  };

  return (
    <Card
      title={
        <Row justify="space-between" align="middle">
          {title}
          <InfoCircleOutlined className="dashboard-info-icon" />
          {info}
        </Row>
      }
      bordered={false}
      className="dashboard-pie-card"
    >
      <Alert description={donuts()} type="info" className="dashboard-alert" />
    </Card>
  );
};

CardDonuts.propTypes = {
  dataAdmin: PropTypes.instanceOf(Array),
  title: PropTypes.string.isRequired,
  total: PropTypes.string,
  info: PropTypes.element
};

CardDonuts.defaultProps = {
  total: null,
  dataAdmin: undefined,
  info: null
};

export default CardDonuts;
