import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Select } from 'antd';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useFormatter } from '../../utils/formatter';
import { Calendar } from '../../components/Calendar/Calendar';
import ControlDrawer from './ControlDrawer';
import ControlModal from './ControlModal';
import {
  annualCellRender,
  monthCellRender,
  weekCellRender
} from '../../components/Calendar/CellRenders.tsx';
import { headers } from './headers';

const { Option } = Select;

export const Planning = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { formattedData } = useFormatter();
  const [users, setUsers] = useState([]);
  const [worksites, setWorksites] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedWorksite, setSelectedWorksite] = useState();
  const [firstDayOfWeek, setFirstDayOfWeek] = useState();
  const [forceRefresh, setForceRefresh] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [controlToUpdate, setControlToUpdate] = useState();
  const [date, setDate] = useState({});

  const openDrawer = () => {
    setShowDrawer(true);
  };

  const deleteEvent = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/controls/${id}` });
      setForceRefresh(forceRefresh + 1);
    } catch (e) {
      message(e);
    }
  };

  const onsearchInspector = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const onsearchWorksite = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const getUsers = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users?role=admins:SUPER-ADMIN,admins:ADMIN,inspectors:INSPECTOR`
      });
      setUsers(data);
    } catch (e) {
      message(e);
    }
  }, []);

  const getControls = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/control?status=CREATED&user=${
          selectedUser || null
        }&populate=worksite`
      });
      const arrayWorksites = [];
      (data || []).forEach((control) => {
        if (
          !arrayWorksites.some(
            (worksite) => worksite._id === control.worksite._id
          )
        ) {
          arrayWorksites.push(control.worksite);
        }
      });
      setWorksites(arrayWorksites);
    } catch (e) {
      message(e);
    }
  }, []);

  const closeOverlay = () => {
    getControls();
    setShowDrawer(false);
    setOpenModal(false);
    setForceRefresh(forceRefresh + 1);
    setDate({});
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getUsers();
      await getControls();
    })();
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setForceRefresh(forceRefresh + 1);
    // eslint-disable-next-line
  }, [firstDayOfWeek]);

  return (
    <>
      {showDrawer && (
        <ControlDrawer
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          users={users}
          purpose="create"
          closeOverlay={closeOverlay}
          idFromOverlay={controlToUpdate}
          forceRefresh={forceRefresh}
          setForceRefresh={setForceRefresh}
        />
      )}
      {openModal && (
        <ControlModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          users={users}
          purpose="edit"
          closeOverlay={closeOverlay}
          idFromOverlay={controlToUpdate}
          date={date}
        />
      )}
      <PageHeaderCustom title={t('calendar.title')} />
      <DndProvider backend={HTML5Backend}>
        <Calendar
          modes={['week', 'month']}
          resourceName="events"
          monthCellRender={monthCellRender}
          weekCellRender={weekCellRender}
          annualCellRender={annualCellRender}
          initialMode="week"
          dayRange="weekends"
          hourStart={7}
          hourEnd={22}
          extraQuery={`user=${selectedUser || null}`}
          populate="user"
          withExtraHeader
          withCreateButton
          withUploadButton
          formatter={formattedData}
          headers={headers}
          withImportButton={false}
          openDrawer={openDrawer}
          setOpenModal={setOpenModal}
          setEventToUpdate={setControlToUpdate}
          setDate={setDate}
          deleteEvent={deleteEvent}
          setFirstDayOfWeek={setFirstDayOfWeek}
          selectedUser={selectedUser}
          selectedWorksite={selectedWorksite}
          firstDayOfWeek={firstDayOfWeek}
          forceRefresh={forceRefresh}
          setForceRefresh={setForceRefresh}
          resource="planning"
          extraButtons={
            <>
              <Select
                style={{ width: '300px', marginRight: 8 }}
                placeholder={t('calendar.placeholder.users')}
                showSearch
                loading={isLoading}
                filterOption={(input, option) =>
                  onsearchInspector(input, option)
                }
                onSelect={(value) => {
                  setSelectedUser(value);
                  setForceRefresh(forceRefresh + 1);
                }}
              >
                {users.map((user) => (
                  <Option key={user._id} value={user._id}>
                    {`${user.first_name} ${user.last_name}`}
                  </Option>
                ))}
              </Select>
              <Select
                style={{ width: '300px', marginRight: 8 }}
                placeholder={t('calendar.placeholder.worksites')}
                showSearch
                loading={isLoading}
                filterOption={(input, option) =>
                  onsearchWorksite(input, option)
                }
                onSelect={(value) => {
                  setSelectedWorksite(value);
                  setForceRefresh(forceRefresh + 1);
                }}
              >
                {worksites.map((worksite) => (
                  <Option key={worksite._id} value={worksite._id}>
                    {`${worksite.name}`}
                  </Option>
                ))}
              </Select>
            </>
          }
        />
      </DndProvider>
      <ContentCustom />
    </>
  );
};
