import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useColumns } from '../../company/columns';

const ListCompanyOfWorksite = ({ customResources }) => {
  const navigate = useNavigate();

  const { columns } = useColumns();
  const data = [];
  customResources?.map((companie) => {
    if (companie.subcontractors) {
      companie.subcontractors.map((sub) => {
        data.push(sub);
        return data;
      });
    }
    data.sort((a, b) => a.name.localeCompare(b.name));
    return data;
  });
  const expandedRowRender = (record) => {
    const dataFilter = data.filter((c) => {
      if (record?.subcontractors?.includes(c)) return c;
      return null;
    });
    const unique = [...new Set(dataFilter)];
    return (
      <Table
        columns={columns}
        dataSource={unique}
        pagination={false}
        onRow={(item) => ({
          onDoubleClick: () => navigate(`/companies/show/${item._id}`)
        })}
      />
    );
  };

  return (
    <Table
      dataSource={customResources}
      columns={columns}
      className="full-screen-width"
      onRow={(record) => ({
        onDoubleClick: () => navigate(`/companies/show/${record._id}`)
      })}
      expandable={{
        expandedRowRender: (record) => expandedRowRender(record),
        rowExpandable: (record) => record.subcontractors
      }}
    />
  );
};

ListCompanyOfWorksite.propTypes = {
  customResources: PropTypes.arrayOf(PropTypes.shape())
};
ListCompanyOfWorksite.defaultProps = {
  customResources: null
};

export default ListCompanyOfWorksite;
