import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import { Outlet, useLocation } from 'react-router-dom';
import { InspectorNavMenu } from './InspectorNavMenu';

const { Content, Footer } = Layout;

const StyledLayout = styled.div`
  height: 100vh;
`;

const StyledContent = styled.div`
  overflow: auto;
`;

const StyledFooter = styled.div`
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const InspectorLayout = ({ nav_display }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.search === '') {
      document
        .getElementById('mainContent')
        .scrollTo({ behavior: 'smooth', top: 0 });
    }
  }, [location]);

  return (
    <StyledLayout as={Layout} className="inspector_container">
      <Layout>
        <StyledContent as={Content} id="mainContent">
          <Outlet />
        </StyledContent>
        <StyledFooter
          as={Footer}
          className="inspector_nav_container"
          style={{ display: nav_display }}
        >
          <InspectorNavMenu />
        </StyledFooter>
      </Layout>
    </StyledLayout>
  );
};

InspectorLayout.propTypes = {
  nav_display: PropTypes.string
};
InspectorLayout.defaultProps = {
  nav_display: ''
};
