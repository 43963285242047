// Main nav menuItems
export const routes = {
  HOME: '/',
  USERS: '/users',
  WORKERS: '/workers',
  PROMOTERS: '/promoters',
  COMPANIES: '/companies',
  WORKSITES: '/worksites',
  CALENDAR: '/calendar',
  PLANNING: '/planification',
  CONTROLS: '/controls',
  INCIDENTS: '/incidents',
  SETTINGS: '/settings',
  MAILS: '/mail'
};

// Main nav subMenuItems
export const subRoutes = {
  USERS: {
    ADMINS: '/usersadmins',
    PROMOTERS: '/userspromoters',
    COMPANIES: '/userscompanies',
    INSPECTORS: '/usersinspectors',
    WORKERS: '/usersworkers'
  }
};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings'
};

// url search parameters
export const pathSearches = {
  INCIDENTS: '?s=-date',
  CONTROLS: '?s=-date',
  MAILS: '?s=-created_at',
  WORKSITES: '?s=-created_at'
};

export const pathFilteredSearches = {
  INCIDENTS: '?s=-date&f=justification_status=PENDING__',
  UNKNOWNCOMPANY: '?f=unknown_company!=null__',
  USERSMOFIFIED: '?f=modifications!=null__'
};
