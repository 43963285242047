import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined } from '@ant-design/icons';

const ControlSpecialWorker = ({ createWorker, id, setIsNotRegistered }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div>
      <Button
        onClick={() => setIsNotRegistered(true)}
        type="link"
        icon={<ArrowLeftOutlined />}
        style={{
          position: 'fixed',
          top: 10,
          right: 10,
          color: '#000'
        }}
      />
      <h2>{t('controls.mobile.title.special_worker')}</h2>
      <div
        style={{
          marginTop: 30,
          display: 'flex',
          justifyContent: 'space-between',
          gap: 8
        }}
      >
        <Button onClick={() => navigate(`/incidents/create/${id}`)}>
          {t('controls.mobile.button.documents_invalid')}
        </Button>
        <Button type="primary" onClick={() => createWorker()}>
          {t('controls.mobile.button.documents_valid')}
        </Button>
      </div>
    </div>
  );
};

export default ControlSpecialWorker;

ControlSpecialWorker.propTypes = {
  props: PropTypes.shape({}).isRequired,
  createWorker: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  setIsNotRegistered: PropTypes.bool.isRequired
};
