/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import BatchTitlesTable from './batchTitlesTable';

const initialColumns = [
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: (text) => <Input InitialValue={text} />
  }
];

const BatchTitlesSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [batchtitlesCols, setBatchTitlesCols] = useState(initialColumns);
  const newBatchTitlesCol = {
    label: 'Nouvelle ligne',
    icon: null,
    color: '#008CA0',
    show: true
  };

  const getBatchTitlesCols = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/batchtitles' });
      setBatchTitlesCols(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addBatchTitlesCol = async () => {
    try {
      await dispatchAPI('POST', {
        url: '/batchtitles',
        body: newBatchTitlesCol
      });
      getBatchTitlesCols();
    } catch (e) {
      message.error(e.message);
    }
  };

  const deleteBatchTitlesCol = async (batchtitlesCol) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/batchtitles/${batchtitlesCol._id}`
      });
    } catch (e) {
      message.error(e.message);
    }
    getBatchTitlesCols();
  };

  const editBatchTitlesCol = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/batchtitles/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    getBatchTitlesCols();
  };
  useEffect(() => {
    getBatchTitlesCols();
  }, []);

  return (
    <>
      <BatchTitlesTable
        batchTitlesCols={batchtitlesCols}
        editBatchTitlesCol={editBatchTitlesCol}
        deleteBatchTitlesCol={deleteBatchTitlesCol}
      />
      <Button
        title="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addBatchTitlesCol}
      >
        {t('settings.batchtitlesTable.addbatchtitlescol')}
      </Button>
    </>
  );
};

export default BatchTitlesSettings;
