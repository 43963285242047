import React from 'react';
import { Skeleton, Row } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { incidentList, incidentDetailedInfoList } from '../listContent';
import { DescriptionList } from '../../../components';

const PrincipalInformationsPanel = ({
  isLoading,
  incident,
  setOpenModalDocStatus,
  refresh,
  setRefresh,
  deleteFiles,
  setIsDocumentLoading,
  isDocumentLoading
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          <DescriptionList data={incidentList(incident || {})} translate />
        </Skeleton>
      </Row>
      <Row>
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          <DescriptionList
            data={incidentDetailedInfoList(
              incident || {},
              t,
              setOpenModalDocStatus,
              refresh,
              setRefresh,
              deleteFiles,
              setIsDocumentLoading,
              isDocumentLoading
            )}
            translate
          />
        </Skeleton>
      </Row>
    </>
  );
};

PrincipalInformationsPanel.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  incident: PropTypes.shape({}),
  setOpenModalDocStatus: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
  deleteFiles: PropTypes.func.isRequired,
  setIsDocumentLoading: PropTypes.func.isRequired,
  isDocumentLoading: PropTypes.bool.isRequired
};

PrincipalInformationsPanel.defaultProps = {
  incident: {}
};

export default PrincipalInformationsPanel;
