import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import MultiControlPlanningModal from '../MultiControlPlanningModal/MultiControlPlanningModal';

const CreateUpdateWorksite = ({ purpose }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [selectedPromoter, setSelectedPromoter] = useState(null);
  const [linkedUsersPromoter, setLinkedUsersPromoter] = useState([]);
  const {
    fields,
    clicks,
    isFieldsLoading,
    base64,
    companiesRegistered,
    setClicks,
    setCompaniesRegistered
  } = useFields(setSelectedPromoter, linkedUsersPromoter);
  const [newReference, setNewReference] = useState('');
  const [body, setBody] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (modalWorksiteBody) => {
    setBody(modalWorksiteBody);
    setIsModalOpen(true);
  };

  const getRefNumber = async () => {
    if (clicks?.city) {
      let nbOfWorksites = 0;
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/worksites?reference=/QUIETUS-${clicks.city}/g`
        });
        nbOfWorksites = data.filter((elem) => elem._id !== id);
        nbOfWorksites = nbOfWorksites.length;
      } catch (e) {
        if (e.response) message(e.response.status);
      }
      const result = `QUIETUS-${clicks.city}-${`0000${nbOfWorksites}`.slice(
        -5
      )}`;
      return result;
    }
    return '-';
  };

  const getControls = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/controls?worksite=${id}`
      });
      if (data.length < 1) {
        const response = await dispatchAPI('GET', {
          url: `/worksite/${id}`
        });
        if (response?.data) openModal(response.data);
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      if (purpose === 'edit') await getControls();
    })();
  }, []);

  const config = {
    onGetResource: {
      setFields: (data) => {
        setClicks({
          lat: data.location.latitude,
          lng: data.location.longitude,
          city: data.location.city
        });
        setCompaniesRegistered(data.companies);
        if (data?.idPromoter) setSelectedPromoter(data?.idPromoter);
        return {
          ...data,
          start_date: data.start_date && moment(data.start_date),
          second_phase_expected_date:
            data.second_phase_expected_date &&
            moment(data.second_phase_expected_date),
          third_phase_expected_date:
            data.third_phase_expected_date &&
            moment(data.third_phase_expected_date),
          end_date: data.end_date && moment(data.end_date),
          latitude: data.latitude,
          longitude: data.longitude
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...(companiesRegistered ? { companies: companiesRegistered } : {}),
        ...(base64 ? { photo: base64 } : {})
      })
    }
  };

  const formatData = (data) => {
    let newData = {};

    if (clicks) {
      newData = {
        ...data,
        reference:
          !data.reference || data.reference === ''
            ? newReference
            : data.reference,
        address_full: clicks.address_full,
        location: {
          latitude: clicks.lat,
          longitude: clicks.lng,
          city: clicks.city
        },
        companies: companiesRegistered || {}
      };
    } else
      newData = {
        ...data,
        companies: companiesRegistered || {}
      };

    return newData;
  };

  const customSubmit = async (data, setIsSubmitting) => {
    try {
      setIsSubmitting(true);
      const newData = formatData(data);

      if (purpose === 'create') {
        const response = await dispatchAPI('POST', {
          url: '/worksites/',
          body: newData
        });
        openModal(response.data);
      } else {
        const response = await dispatchAPI('PATCH', {
          url: `/worksites/${id}`,
          body: newData
        });
        navigate(`/worksites/show/${response.data && response.data._id}`);
      }
    } catch (e) {
      message(e);
    }
    setIsSubmitting(false);
  };

  const getLinkedUsersPromoter = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users?idPromoter=${selectedPromoter}`
      });
      setLinkedUsersPromoter(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(async () => {
    setNewReference(await getRefNumber());
  }, [clicks]);

  useEffect(async () => {
    if (selectedPromoter) await getLinkedUsersPromoter();
  }, [selectedPromoter]);

  return (
    <>
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="worksites"
        resource="worksites"
        config={config}
        customSubmit={customSubmit}
      />
      <MultiControlPlanningModal
        worksite={body}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

CreateUpdateWorksite.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateWorksite;
