import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';

const IncidentExtra = ({
  incident,
  showCloseModal,
  userRoleCondition,
  deleteIncident,
  isButtonLoading
}) => {
  const { t } = useTranslation();
  const canShowCloseButton = () => {
    const incidentStatusCondition =
      incident?.status?._id !== '634c60a28d00c8c79e6b8aba';
    return incidentStatusCondition && userRoleCondition;
  };

  const docStatus = ['ACCEPTED', 'REFUSED'];

  return (
    <>
      {canShowCloseButton() &&
        (!incident?.files?.length ||
        !docStatus.includes(incident?.justification_status) ? (
          <Tooltip
            title={t('incidents.show.close_button_tooltip')}
            overlayInnerStyle={{ textAlign: 'center' }}
          >
            <Button
              loading={isButtonLoading}
              type="primary"
              disabled
              style={{
                marginRight: 8,
                color: 'var(--errorColor)',
                borderColor: 'var(--errorColor)'
              }}
            >
              {`${t('incidents.show.close_button_blocked')}`}
              <WarningOutlined />
            </Button>
          </Tooltip>
        ) : (
          <Button
            loading={isButtonLoading}
            type="primary"
            onClick={showCloseModal}
            style={{ marginRight: 8 }}
          >
            {`${t('incidents.show.close_button')}`}
            <CheckCircleOutlined />
          </Button>
        ))}

      {userRoleCondition && (
        <>
          <Link to={{ pathname: `/incidents/edit/${incident?._id}` }}>
            <Button type="primary">
              {`${t('buttons.edit')} `}
              <EditOutlined />
            </Button>
          </Link>
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={deleteIncident}
            icon={<WarningOutlined />}
          >
            <Button type="link" danger>
              {`${t('buttons.delete')} `}
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </>
      )}
    </>
  );
};

IncidentExtra.propTypes = {
  incident: PropTypes.shape({
    status: PropTypes.shape({
      _id: PropTypes.string
    }),
    files: PropTypes.arrayOf(PropTypes.shape({})),
    _id: PropTypes.string,
    justification_status: PropTypes.string
  }),
  showCloseModal: PropTypes.func.isRequired,
  userRoleCondition: PropTypes.bool.isRequired,
  deleteIncident: PropTypes.func.isRequired,
  isButtonLoading: PropTypes.bool.isRequired
};

IncidentExtra.defaultProps = {
  incident: {}
};

export default IncidentExtra;
