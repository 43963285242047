import React, { useCallback, useEffect, useState } from 'react';
import { Select, DatePicker, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

const { Option } = Select;
const { RangePicker } = DatePicker;

const useFields = (setOpen, purpose) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [worksites, setWorksites] = useState([]);
  const [inspectors, setInspectors] = useState([]);
  const [enums, setEnums] = useState([]);

  const onsearchInspector = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const onsearchWorksite = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const onsearchStatus = (input, option) => {
    const result = option?.children.toLowerCase();
    return result.includes(input.toLowerCase());
  };

  const fields = [
    {
      name: ['inspector'],
      rules: [{ required: true }],
      input: (
        <Select
          style={{ width: '300px' }}
          placeholder={t('calendar.placeholder.users')}
          showSearch
          loading={isFieldsLoading}
          filterOption={(input, option) => onsearchInspector(input, option)}
        >
          {(inspectors || []).map((u) => (
            <Option key={u._id} value={u._id}>
              {`${u.first_name} ${u.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['worksite'],
      rules: [{ required: true }],
      input: (
        <Select
          style={{ width: '300px' }}
          showSearch
          loading={isFieldsLoading}
          filterOption={(input, option) => onsearchWorksite(input, option)}
        >
          {(worksites || []).map((worksite) => (
            <Option key={worksite._id} value={worksite._id}>
              {worksite.name}
            </Option>
          ))}
        </Select>
      )
    },
    ...(purpose === 'edit'
      ? [
          {
            name: ['status'],
            rules: [{ required: true }],
            input: (
              <Select
                style={{ width: '300px' }}
                showSearch
                loading={isFieldsLoading}
                filterOption={(input, option) => onsearchStatus(input, option)}
              >
                {(enums?.status || []).map((status) => (
                  <Option key={status} value={status}>
                    {t(`controls.tags.${status}`)}
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : []),
    {
      name: ['reference'],
      hidden: purpose === 'create',
      input: <Input disabled />
    },
    {
      name: ['period_start'],
      rules: [{ required: true }],
      tooltip: true,
      input: <RangePicker format="DD-MM-YYYY" picker="week" />
    }
  ];

  const getWorksites = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/worksites/' });
      setWorksites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getInspectors = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users?role=/inspectors:/i'
      });
      setInspectors(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/controls/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getWorksites();
    await getInspectors();
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
