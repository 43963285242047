import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { message } from 'antd';
import useFields from './fields';
import { CreateUpdateContainer } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import NewStructureDrawer from './NewStructureDrawer';

const CreateUpdateUser = ({ purpose, type }) => {
  const { card_number, controlId } = useParams();
  const { user, setUser, dispatchAPI } = useAuthContext();
  const navigate = useNavigate();
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [structureType, setStructureType] = useState('');
  const [forceSelectRefresh, setForceSelectRefresh] = useState(false);
  const [base64, setBase64] = useState('');

  const openDrawer = (currentUser) => {
    setStructureType(currentUser);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setStructureType('');
    setIsDrawerOpen(false);
  };

  const customNavigate = () => {
    closeDrawer();
    setForceSelectRefresh(!forceSelectRefresh);
  };

  const { fields, isFieldsLoading, documents, setDocuments } = useFields(
    type,
    card_number,
    false,
    controlId,
    purpose,
    openDrawer,
    forceSelectRefresh,
    base64,
    setBase64
  );

  const isAdmin =
    user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN';

  const config = {
    onGetResource: {
      setFields: (data) => {
        setDocuments(
          data.files.map((file) => ({
            ...file,
            name: file.fileName,
            uid: file.fileId
          }))
        );
        setBase64(data?.photo);
        const body = {
          ...data,
          document_valid_until:
            data.document_valid_until && moment(data.document_valid_until),
          date_of_birth: data.date_of_birth && moment(data.date_of_birth),
          poste: data.poste === 'employee' ? 'Non défini' : data.poste
        };
        setInitialValues(body);
        return body;
      }
    },
    onUpdateResource: {
      setBody: async (data) => {
        if (data._id === user._id) setUser({ ...user, ...data });
        let documents_saved;
        const files = [];
        if (documents?.length) {
          const formData = new FormData();
          documents.forEach((doc) => {
            formData.append('file', doc.originFileObj || doc);
            formData.append('type', doc.type);
          });
          documents_saved = await dispatchAPI('POST', {
            url: `/files/upload`,
            body: formData
          });
          if (documents_saved) {
            documents_saved.data.description.forEach((file) =>
              files.push({ fileId: file.id, fileName: file.fileName })
            );
          }
        }
        return {
          ...data,
          ...(base64 ? { photo: base64 } : {}),
          ...(documents_saved
            ? {
                files
              }
            : { files: documents })
        };
      }
    },
    onCreateResource: {
      setBody: async (data) => {
        let documents_saved = null;
        const files = [];
        if (documents) {
          const formData = new FormData();
          documents.forEach((doc) => {
            formData.append('file', doc.originFileObj || doc);
            formData.append('type', doc.type);
          });
          documents_saved = await dispatchAPI('POST', {
            url: `/files/upload`,
            body: formData
          });
          if (documents_saved) {
            documents_saved.data.description.forEach((file) =>
              files.push({ fileId: file.id, fileName: file.fileName })
            );
          }
        }

        let remaningBody = {};

        if (card_number)
          remaningBody = {
            ...remaningBody,
            card_number,
            isWCard: !card_number
          };

        return {
          ...data,
          ...(base64 ? { photo: base64 } : {}),
          ...remaningBody,
          ...(documents_saved
            ? {
                files
              }
            : {})
        };
      }
    }
  };

  const customSubmitforNotification = async (body) => {
    try {
      const finalBody =
        config.onUpdateResource && config.onUpdateResource.setBody
          ? await config.onUpdateResource.setBody(body)
          : body;

      if (!isAdmin) {
        const diff = {};
        Object.keys(initialValues).forEach((key) => {
          if (
            finalBody[key] !== undefined &&
            JSON.stringify(initialValues[key]) !==
              JSON.stringify(finalBody[key])
          ) {
            diff[key] = initialValues[key];
          }
        });
        finalBody.modifications = diff;
      }

      await dispatchAPI('PATCH', {
        url: `users/${id}`,
        body: finalBody
      });
      navigate(-1);
    } catch (e) {
      message(e);
    }
  };

  return (
    <>
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="users"
        resource="users"
        config={config}
        customSubmit={purpose === 'edit' && customSubmitforNotification}
      />
      {isDrawerOpen && (
        <NewStructureDrawer
          resource={structureType}
          isVisible={isDrawerOpen}
          closeDrawer={closeDrawer}
          customNavigate={customNavigate}
        />
      )}
    </>
  );
};

CreateUpdateUser.propTypes = {
  purpose: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default CreateUpdateUser;
