import React from 'react';
import { Avatar, Button, Typography, Menu, message } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import HeaderDropdown from '../../components/HeaderDropdown/HeaderDropdown';

const { Title } = Typography;

const StyledDropdownContainer = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 0 12px;
  transition: all 0.3s;
  :hover {
    background: var(--contrastBackground);
  }
`;

const StyledFullNameSpan = styled.span`
  color: var(--textColor);
  font-weight: bold;
`;

const StyledRoleP = styled.p`
  font-size: 14px;
  color: var(--textColor);
`;

const MobileHeader = ({ title }) => {
  const { user, dispatchAPI } = useAuthContext();
  const { t } = useTranslation();

  const onProfileMenuClick = ({ key }) => {
    if (key === 'logout') {
      dispatchAPI('LOGOUT');
      message.success(t('login.logoutSuccess'));
    }
  };

  const profileMenu = (
    <Menu onClick={onProfileMenuClick}>
      {window.innerWidth <= 992 && user && (
        <Menu.Item key="user">
          <Button
            style={{
              width: '100%',
              height: 50
            }}
            type="link"
          >
            <StyledFullNameSpan>
              {`${user.first_name} ${user.last_name}`}
            </StyledFullNameSpan>
            <StyledRoleP>
              {t(`users.tags.${user.role.split(':')[1]}`)}
            </StyledRoleP>
          </Button>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item key="logout">
        <LogoutOutlined
          style={{
            fontSize: 16
          }}
        />
        {` ${t('header.menu.user.logout')}`}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="header_inspector">
      <Title>{title}</Title>
      <HeaderDropdown overlayMenu={profileMenu}>
        {window.innerWidth <= 992 ? (
          <StyledDropdownContainer>
            <UserOutlined />
          </StyledDropdownContainer>
        ) : (
          <StyledDropdownContainer>
            <Avatar
              size="medium"
              icon={user?.photo && <UserOutlined />}
              src={user?.photo ? user.photo : ''}
            >
              {`${user?.first_name[0]}${user?.last_name[0]}`}
            </Avatar>
            <Button type="link">
              <StyledFullNameSpan>
                {`${user?.first_name} ${user?.last_name}`}
              </StyledFullNameSpan>
              <StyledRoleP>
                {t(`users.tags.${user?.role.split(':')[1]}`)}
              </StyledRoleP>
            </Button>
          </StyledDropdownContainer>
        )}
      </HeaderDropdown>
    </div>
  );
};

MobileHeader.propTypes = {
  title: PropTypes.string.isRequired
};

export default MobileHeader;
