import React, { useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { UserRouter } from './UserRouter';
import { AdminRouter } from './AdminRouter';
import { InspectorRouter } from './InspectorRouter';
import { WorkerRouter } from './WorkerRouter';
import { CompanyUserRouter } from './CompanyRouter';
import { PromotersRouter } from './PromotersRouter';
import { useThemeContext } from '../contexts/ThemeContext';

export const RouterDispatch = () => {
  const { user } = useAuthContext();
  const { setTheme } = useThemeContext();

  useEffect(() => {
    const body = document.body;
    if (body && !user) {
      body.className = 'user';
      setTheme('user');
    }
  }, [user]);

  const dispatchRouter = () => {
    switch (user?.role) {
      case 'admins:ADMIN':
      case 'admins:SUPER-ADMIN':
        return <AdminRouter />;
      case 'inspectors:INSPECTOR':
        return <InspectorRouter />;
      case 'workers:WORKER':
        return <WorkerRouter />;
      case 'companies:USER-COMPANY':
      case 'companies:ADMIN-COMPANY':
        return <CompanyUserRouter />;
      case 'promoters:ADMIN-PROMOTER':
      case 'promoters:USER-PROMOTER':
        return <PromotersRouter />;
      default:
        return <UserRouter />;
    }
  };
  return dispatchRouter();
};
